import yuyan from '@/js/app/yuyan';

export default function alert(alert) {
    if (alert) {
        if (alert.length === 1) {
            const alertId = alert[0];

            return `<span class="badge alert-contract-${alertId}">${yuyan.t(`contract.alert.${alertId}`)}</span>`;
        } else if (alert.length > 1) {
            return '<span class="badge badge-danger">' + yuyan.t('contract.alert.title') +
                '<span class="badge badge-light">' + alert.length + '</span>' +
                '</span>';
        }
    }
}
