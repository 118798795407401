import { Collection } from 'backbone';
import ModalView from '@/js/app/generic/views/modal';
import SpinnerView from '@/js/app/generic/views/spinner';
import ContractBillsCollection from '../collections/contract-bills';
import template from '../templates/bills-modal.hbs';

export default class ContractBillsModalView extends ModalView {
    preinitialize(options) {
        this.collection = new ContractBillsCollection([], { contractId: options.contractId });

        this.preinitializeModal();
    }

    initialize() {
        this.listenTo(this.collection, 'reset', this.renderList);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.modalContent.querySelector('.modal-header'));
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('ContractBillsModal#render');

        this.renderModal({ size: 'lg', centered: true })
            .modalContent.innerHTML = template({
                isLoading: true
            });

        this.collection.fetch({ reset: true });

        return this;
    }

    renderList() {
        console.debug('ContractBillsModal#render');

        this.modalContent.innerHTML = template({
            bills: this.collection.toJSON()
        });

        return true;
    }
}
