import { View } from 'backbone';
import OffenderReadUpdateView from './read-update';
import template from '../templates/detail.hbs';

export default class OffenderDetailView extends View {
    preinitialize() {
        // Declare true instance props
        this.className = 'container';
    }

    constructor(options) {
        super(options);

        // Initialize defaults
        this.subviews = {
            offenderCard: new OffenderReadUpdateView({
                model: this.model
            })
        };
    }

    render() {
        console.debug('OffenderDetail#render');

        // Attach template to el
        this.el.innerHTML = template({
            id: this.model.id
        });

        // Set el of offender detail view as #details then render
        this.subviews.offenderCard.setElement(this.el.querySelector('#details')).render();

        return this;
    }
}
