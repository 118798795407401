import dayjs from 'dayjs';
import numeral from 'numeral';
import formToObject from '@/js/libs/form-utils';
import config from '@/js/app/config';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import oauth2Client from '@/js/app/oauth2-client';
import yuyan from '@/js/app/yuyan';
import updateTemplate from '../templates/edit.hbs';
import cancelProgressTemplate from '../templates/progress-cancel.hbs';
import withdrawProgressTemplate from '../templates/progress-withdraw.hbs';
import progressTemplate from '../templates/progress.hbs';
import readTemplate from '../templates/read.hbs';
import ProductView from './product';

export default class ProductContractReadUpdateView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('productContract.details');

        this.events = {
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.renderRead,
            'click [data-action="advance"]': this.advanceStatus,
            'click [data-action="withdraw"]': this.withdrawContract,
            'click [data-action="end"]': this.cancelContract,
            'change [name="start_date"]': this.handleStartDateChange,
            'change [data-rate="flat"]': this.handleFlatRateChange,
            'click [data-action="autofill"]': this.autoFill,
            'submit form#frmEditProductContract': this.saveUpdate
        };

        this.buttons = [
            {
                cntrData: 'withdrawBtnCntr',
                text: yuyan.t('contract.action.withdraw'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'withdraw'
                    }
                ]
            },
            {
                cntrData: 'endBtnCntr',
                text: yuyan.t('contract.action.cancel'),
                className: 'btn-danger',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'end'
                    }
                ]
            },
            {
                cntrData: 'advanceBtnCntr',
                text: '',
                className: 'btn-primary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'advance'
                    }
                ]
            },
            {
                cntrData: 'editBtnCntr',
                text: yuyan.t('generic.edit'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'edit'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            }
        ];
    }

    initialize() {
        this.subviews = {
            product: new ProductView()
        };

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });

        // When model changes; render
        this.listenTo(this.model, 'change', this.renderRead);
    }

    render() {
        console.debug('ProductContractReadUpdate#render');

        this.renderCard();

        return this;
    }

    renderRead() {
        console.debug('ProductContractReadUpdate#renderRead');

        const status = this.model.get('status');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('editBtnCntr')
            .changeAdvanceButton(status)
            .changeEndButton(status)
            .changeWithdrawButton(status)
            .renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('ProductContractReadUpdate#renderReadTemplate');

        // Attach readTemplate to el with productType model
        this.cardBody.innerHTML = readTemplate({
            productContract: this.model.toJSON(),
        });

        const status = this.model.get('status');
        if (status) {
            if (['pending', 'price_confirmed', 'preparing', 'active'].includes(status)) {
                this.el.querySelector('[data-part="progress"]').innerHTML = progressTemplate();
            } else if (['withdraw_requested', 'withdrawn'].includes(status)) {
                this.el.querySelector('[data-part="progress"]').innerHTML = withdrawProgressTemplate();
            } else if (['cancel_applied', 'billing_stopped', 'cancelled'].includes(status)) {
                this.el.querySelector('[data-part="progress"]').innerHTML = cancelProgressTemplate();
            }

            this.renderProgress();
        }

        return this;
    }

    renderProgress() {
        console.debug('ProductContractReadUpdate#renderProgress');

        const progressElement = this.el.querySelector('[data-progress="' + this.model.get('status') + '"]');
        if (progressElement) {
            progressElement.classList.add('status-product-contract-' + this.model.get('status'));
        }
    }

    advanceStatus() {
        console.debug('ProductContractReadUpdate#advanceStatus');

        if (confirm("Are you sure?")) {
            let status = '';
            switch (this.model.get('status')) {
                case 'pending':
                    status = 'price_confirmed';
                    break;
                case 'price_confirmed':
                    if (this.model.get('productType').is_intangible) {
                        status = 'active';
                    } else {
                        status = 'preparing';
                    }
                    break;
                case 'preparing':
                    status = 'active';
                    break;
                case 'withdraw_requested':
                    status = 'withdrawn';
                    break;
                case 'billing_stopped':
                    status = 'cancelled';
                    break;
            }

            // Trigger save of model with changes as attributes
            this.model.save({ status: status }, {
                patch: true,
                wait: true,
                error: this.errorOnSave
            });
        }
    }

    changeAdvanceButton(status) {
        console.debug('ProductContractReadUpdate#changeAdvanceButton');

        const advanceButton = this.el.querySelector('[data-action="advance"]');
        if (['withdrawn', 'active', 'cancel_applied', 'cancelled'].includes(status)) {
            advanceButton.innerText = '';
            this.hideButtonContainer('advanceBtnCntr');
        } else {
            advanceButton.innerText = yuyan.t('productContract.status.' + this.model.get('next_status') + '.title');
            this.showButtonContainer('advanceBtnCntr');
        }

        return this;
    }

    changeEndButton(status) {
        console.debug('ContractReadUpdate#changeEndButton');

        if (['price_confirmed', 'preparing', 'active'].includes(status)) {
            this.showButtonContainer('endBtnCntr');
        } else {
            this.hideButtonContainer('endBtnCntr');
        }

        return this;
    }

    changeWithdrawButton(status) {
        console.debug('ContractReadUpdate#changeWithdrawButton');

        if (['pending'].includes(status)) {
            this.showButtonContainer('withdrawBtnCntr');
        } else {
            this.hideButtonContainer('withdrawBtnCntr');
        }

        return this;
    }

    withdrawContract() {
        console.debug('ProductContractReadUpdate#withdrawContract');

        if (confirm("Are you sure?")) {
            // Trigger save of model with changes as attributes
            this.model.save({ status: 'withdraw_requested' }, {
                patch: true,
                wait: true
            });
        }
    }

    cancelContract() {
        console.debug('ProductContractReadUpdate#cancelContract');

        if (confirm("Are you sure?")) {
            // Trigger save of model with changes as attributes
            this.model.save({ status: 'cancel_applied' }, {
                patch: true,
                wait: true
            });
        }
    }

    renderEdit() {
        console.debug('ProductContractReadUpdate#renderEdit');

        this.hideButtonContainer('editBtnCntr')
            .hideButtonContainer('advanceBtnCntr')
            .hideButtonContainer('withdrawBtnCntr')
            .hideButtonContainer('endBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderEditTemplate();

        return this;
    }

    renderEditTemplate() {
        console.debug('ProductContractReadUpdate#renderEditTemplate');

        // Attach updateTemplate to el
        this.cardBody.innerHTML = updateTemplate();

        // Set el of product view as #divProduct then render
        this.subviews.product.setElement(this.el.querySelector('#divProduct')).render()
            .setShopId(this.model.get('shop').id)
            .setValue(this.model.get('product_type_id'), this.model.get('product_stock_id'));

        // If model exists
        if (this.model) {
            // Contract ID
            this.el.querySelector('[data-slot="contractId"]').innerText = this.model.get('contract_id');

            // Shop name
            this.el.querySelector('[data-slot="shopName"]').innerText = this.model.get('shop').name;

            // For each model attribute
            _.each(this.model.toJSON(), (val, key) => {
                // Initialize field as element with name = key
                const field = this.el.querySelector('[name="' + key + '"]');

                // If field exists, set value
                if (field) {
                    field.value = val;
                }
            });

            // Status pending read only rates
            if (!['pending', 'price_confirmed', 'preparing', 'active', 'cancel_applied'].includes(this.model.get('status'))) {
                this.el.querySelector('[data-action="autofill"]').classList.add('d-none');
                this.el.querySelector('[name="price"]').disabled = true;
                this.el.querySelector('[name="prorated_month"]').disabled = true;
            }
        }

        return this;
    }

    setFees(fees) {
        console.debug('ProductTypeReadUpdate#setFees');

        this.defaultFees = fees;
    }

    handleProductTypeChange(e) {
        console.debug('ProductTypeReadUpdate#handleProductTypeChange');

        if (e instanceof Object) {
            e.preventDefault();

            // Set id of model then trigger fetch
            this.productTypeModel.set('id', e.target.value).fetch();
        }
    }

    handleStartDateChange(e) {
        console.debug('ProductTypeReadUpdate#handleStartDateChange');

        if (e instanceof Object) {
            // Set payment due date to 3 days before start date
            this.el.querySelector('#field-payment_due_date').value = dayjs(e.currentTarget.value).subtract(3, 'day').format('YYYY-MM-DD');

            const price = this.el.querySelector('[name="price"]').value;
            if (price !== '') {
                this.changeFirstMonth(Number(price));
            }
        }
    }

    handleFlatRateChange(e) {
        console.debug('ProductTypeReadUpdate#handleFlatRateChange');

        if (e instanceof Object) {
            const element = e.currentTarget;
            const value = numeral(element.value).value();
            this.changeFirstMonth(value);
        }
    }

    changeFirstMonth(rate) {
        console.debug('ProductTypeReadUpdate#changeFirstMonth');

        const processedStartDate = new Date(this.el.querySelector('[name="start_date"]').value);
        const daysOfMonth = new Date(processedStartDate.getFullYear(), processedStartDate.getMonth() + 1, 0).getDate();
        const remainingDays = daysOfMonth - processedStartDate.getDate() + 1;
        const firstMonth = Math.round((rate / daysOfMonth) * remainingDays);

        this.$el.find('[name="prorated_month"]').val(firstMonth).trigger('change');
    }

    autoFill() {
        console.debug('ProductTypeReadUpdate#autoFill');

        const params = {
            start_date: this.el.querySelector('[name="start_date"]').value,
            price: this.subviews.product.typeModel.get('price'),
            free_days: this.subviews.product.typeModel.get('free_days')
        };

        const url = new URL(config.api.url + 'product-contracts/costs');
        url.search = new URLSearchParams(params);

        oauth2Client.fetch(url, {})
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.json();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((data) => {
                _.each(data, (val, key) => {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector(`[name="${key}"]`);

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                });
            });
    }

    saveUpdate(e) {
        console.debug('ProductTypeReadUpdate#saveUpdate');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            if (e.currentTarget.checkValidity() === false) {
                // If form validity is false return
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Initialize changes as result of changedAttributes of model based on data
            const changes = this.model.changedAttributes(data);

            // If changes exist
            if (changes !== false) {
                // Trigger save of model with changes as attributes
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    // On error: errorOnSave
                    error: this.errorOnSave
                });
            } else {
                // Else trigger change of model
                this.model.trigger('change', this.model);
            }
        }
    }

    errorOnSave(model, response) {
        console.debug('ProductContractReadUpdate#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
