import { queue } from 'async';
import { Collection } from 'backbone';
import ErrorModalView from '@/js/app/error/views/modal';
import uploader from '@/js/app/file-uploader/uploader';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import template from '../templates/list.hbs';
import FileItemView from './item';

export default class ContractFileListView extends CardView {
    preinitialize() {
        this.title = yuyan.t('file.list');
        this.events = {
            'click [data-action="add"]': this.handleAddClick,
            'change [name="files"]': this.handleFilesChange,
        };
        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: '<i class="fa fa-plus" aria-hidden="true"></i> ' + yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
            {
                isHiddenInput: true,
                isMultiple: true,
                name: 'files'
            }
        ];
    }

    initialize() {
        // When collection updates; render
        this.listenTo(this.collection, 'add', this.addFileItem);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('ContractFileList#render');

        this.renderCard().cardBody.innerHTML = template();

        return this;
    }

    handleAddClick() {
        console.debug('ContractFileList#handleAddClick');

        this.$el.find('[name="files"]').trigger('click');
    }

    handleFilesChange(e) {
        console.debug('ContractFileList#handleFilesChange');

        const files = e.currentTarget.files;
        const name = e.currentTarget.name;

        // Check all file sizes
        if (this.checkAllFileSizes(files, 10)) {
            this.uploadFiles(name, files);
        } else {
            // Display error message in modal
            const errorModalView = new ErrorModalView({
                error: {
                    name: 'ConsusUiError',
                    message: 'file_size'
                }
            });

            errorModalView.render();
        }

        // Clear file selector
        e.currentTarget.value = '';
    }

    checkAllFileSizes(files, maxSize) {
        // Loop through the files
        for (const file of files) {
            // Convert file size to MB
            const sizeInMB = file.size / 1024 / 1024;

            // If file size is greater than max size
            if (sizeInMB > maxSize) {
                return false;
            }
        }

        return true;
    }

    uploadFiles(name, files) {
        console.debug('ContractFileList#uploadFiles');

        const uploadStatusContainer = this.$el.find('.cntr-upload-status');

        // Create file upload queue with handler
        const q = queue((file, callback) => {
            uploader(name, file, {
                method: 'PUT',
                url: this.collection.url() + '/' + file.name,
            }, {
                uploadStatusContainer: uploadStatusContainer,
            })
                .then((response) => {
                    // Add new file model to collection using response data
                    this.collection.add(response);
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (let i = 0; i < files.length; i++) {
            // Add file to queue
            q.push(files[i]);
        }
    }

    addFileItem(model) {
        console.debug('ContractFileList#addFileItem');

        const fileItem = new FileItemView({
            model: model
        })

        this.$el.find('#filesTableBody').append(fileItem.render().el);
    }

}
