var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <tr>\n                    <th class=\"text-center\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n                </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"text-center\" colspan=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"count") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <th class=\"text-center\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[0][0],{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":41,"column":20},"end":{"line":51,"column":29}}})) != null ? stack1 : "")
    + "                </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":50,"column":27}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <td class=\"text-right\">\n                        "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n                    </td>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <td class=\"text-center\">\n                        -\n                    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex\">\n    <div>\n        <table class=\"table table-sm table-bordered table-hover text-nowrap\">\n            <thead class=\"thead-light\">\n                <tr>\n                    <th>&nbsp;</th>\n                </tr>\n                <tr>\n                    <th class=\"text-center\">"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":9,"column":44},"end":{"line":9,"column":61}}}))
    + "</th>\n                </tr>\n            </thead>\n\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"shopNames") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":14,"column":16},"end":{"line":18,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n    </div>\n\n    <div class=\"table-responsive\">\n        <table class=\"table table-sm table-bordered table-hover text-nowrap\">\n            <thead class=\"thead-light\">\n                <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"years") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":27,"column":20},"end":{"line":29,"column":29}}})) != null ? stack1 : "")
    + "                </tr>\n                <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"months") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":32,"column":20},"end":{"line":34,"column":29}}})) != null ? stack1 : "")
    + "                </tr>\n            </thead>\n\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"rowData") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":39,"column":16},"end":{"line":53,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n    </div>\n</div>";
},"useData":true,"useBlockParams":true});