import { Collection } from 'backbone';

export default class CollectionWithPagination extends Collection {
    constructor(models, options) {
        super(models, options);

        this.fetchOptions = {};
        this.metadata = {
            offset: 0,
            limit: 10,
            count: 0,
            countTotal: 0,
            page: 1,
            pageTotal: 1
        };
    }

    parse(response) {
        const { results, metadata } = response;

        this.metadata = metadata;

        return results;
    }

    setPage(page) {
        this.fetchOptions.data.page = page;

        this.fetch(this.fetchOptions);
    }

    setLimit(limit) {
        const page = Math.ceil((this.metadata.offset + 1) / limit);

        this.fetchOptions.data.page = page;
        this.fetchOptions.data.limit = limit;

        this.fetch(this.fetchOptions);
    }

    setSort(sort) {
        this.fetchOptions.data.sort = sort;

        // Reset page to 1
        this.fetchOptions.data.page = 1;

        // Remove order_by
        this.fetchOptions.data.order_by = undefined;

        this.fetch(this.fetchOptions);
    }

    search(options) {
        this.fetchOptions = {
            ...options,
            data: {
                ...options.data,
                page: this.metadata.page,
                limit: this.metadata.limit
            }
        };

        this.fetch(this.fetchOptions);
    }
}
