import { Collection } from 'backbone';
import config from '@/js/app/config';
import ContractFileModel from '../models/contract-file';

export default class ContractFilesCollection extends Collection {
    preinitialize() {
        // Reference to this collection's model.
        this.model = ContractFileModel;
    }

    initialize(models, options) {
        this.contractId = options.contractId;
    }

    url() {
        return config.api.url + 'contracts/' + this.contractId + '/files';
    }
}
