import { Collection } from 'backbone';
import config from '@/js/app/config';

export default class ShopReportCollection extends Collection {
    initialize(models, options) {
        if (options) {
            this.shopId = options.shopId;
        }
    }

    url() {
        return config.api.url + 'shops/' + this.shopId + '/reports';
    }

    comparator(model) {
        return new Date(model.get('version')).getTime();
    }
}
