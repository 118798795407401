var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form id=\"frmEditShop\" autocomplete=\"off\" novalidate>\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-name\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":112}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-name\" name=\"name\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-name_en\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name_en",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":98},"end":{"line":14,"column":118}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-name_en\" name=\"name_en\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-code\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":95},"end":{"line":25,"column":112}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-code\" name=\"code\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-owner_id\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":99},"end":{"line":36,"column":117}}}))
    + "</label>\n                <div id=\"divOwners\" class=\"col-md\"></div>\n            </div>\n        </div>\n\n        <div class=\"col-lg d-none\" data-part=\"status\">\n            <div class=\"form-group row\">\n                <label for=\"field-status\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":97},"end":{"line":43,"column":122}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <select id=\"field-status\" name=\"status\" class=\"form-control\">\n                        <option value=\"hidden\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.hidden",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":47},"end":{"line":46,"column":73}}}))
    + "</option>\n                        <option value=\"coming\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.coming",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":47},"end":{"line":47,"column":73}}}))
    + "</option>\n                        <option value=\"active\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.status.active",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":47},"end":{"line":48,"column":73}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-start-date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.start_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":101},"end":{"line":58,"column":124}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-start-date\" name=\"start_date\" type=\"date\" class=\"form-control\" maxlength=\"8\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-initial-leased-rooms\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.initial_leased_rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":111},"end":{"line":67,"column":144}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-initial-leased-rooms\" name=\"initial_leased_rooms\" type=\"text\" class=\"form-control\" data-number>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-open-date\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.open_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":100},"end":{"line":78,"column":122}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-open-date\" name=\"open_date\" type=\"date\" class=\"form-control\" maxlength=\"8\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-payment_tech\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.payment_tech.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":103},"end":{"line":87,"column":134}}}))
    + "</label>\n                <div class=\"col-md-6\">\n                    <select id=\"field-payment_tech\" name=\"payment_tech\" class=\"form-control\">\n                        <option value=\"br\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.payment_tech.br",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":43},"end":{"line":90,"column":71}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-campaign_duration\" class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_duration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":99},"end":{"line":100,"column":129}}}))
    + "</label>\n                <div class=\"col-md col-lg-3\">\n                    <div class=\"input-group\">\n                        <input id=\"field-campaign_duration\" name=\"campaign_duration\" type=\"text\" class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">月</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-campaign_eligible_min_months\" class=\"col-form-label col-md-3  col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_eligible_min_months",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":120},"end":{"line":114,"column":161}}}))
    + "</label>\n                <div class=\"col-md col-lg-3\">\n                    <div class=\"input-group\">\n                        <input id=\"field-campaign_eligible_min_months\" name=\"campaign_eligible_min_months\" type=\"text\" class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">月</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.billing_robot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":32},"end":{"line":129,"column":58}}}))
    + "</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-br_user_id\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.br_user_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":134,"column":101},"end":{"line":134,"column":124}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-br_user_id\" name=\"br_user_id\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-br_access_key\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.br_access_key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":104},"end":{"line":143,"column":130}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-br_access_key\" name=\"br_access_key\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label for=\"field-br_aid\" class=\"col-form-label col-lg-2 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.br_aid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":152,"column":80},"end":{"line":152,"column":99}}}))
    + "</label>\n        <div class=\"col-4\">\n            <input id=\"field-br_aid\" name=\"br_aid\" type=\"text\" class=\"form-control\" maxlength=\"6\">\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">FREEE</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-freee_account_item_id\" class=\"col-form-label col-md-3 col-lg-4 text-right\">勘定科目 ID</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-freee_account_item_id\" name=\"freee_account_item_id\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-freee_partner_id\" class=\"col-form-label col-md-3 col-lg-4 text-right\">取引先 ID</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-freee_partner_id\" name=\"freee_partner_id\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":184,"column":32},"end":{"line":184,"column":52}}}))
    + "</p>\n\n    <div id=\"divAddress\"></div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-latitude\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.latitude",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":191,"column":99},"end":{"line":191,"column":120}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-latitude\" name=\"latitude\" type=\"text\" class=\"form-control\" data-number>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-longitude\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.longitude",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":200,"column":100},"end":{"line":200,"column":122}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-longitude\" name=\"longitude\" type=\"text\" class=\"form-control\" data-number>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">GIRAFFE</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-giraffe_facility_id\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_facility_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":216,"column":20},"end":{"line":216,"column":52}}}))
    + "\n                </label>\n                <div class=\"col-md\">\n                    <input id=\"field-giraffe_facility_id\" name=\"giraffe_facility_id\" type=\"text\" class=\"form-control\" readonly>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"row\">\n                <label class=\"col-form-label col-md-3 col-lg-4 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":227,"column":20},"end":{"line":227,"column":68}}}))
    + "\n                </label>\n                <div class=\"form-group col-md\">\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-giraffe_qr_code_access_status-0\" type=\"radio\" class=\"form-check-input\" name=\"giraffe_qr_code_access_status\" value=\"0\" data-number>\n                        <label for=\"field-giraffe_qr_code_access_status-0\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.0",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":233,"column":28},"end":{"line":233,"column":72}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-giraffe_qr_code_access_status-1\" type=\"radio\" class=\"form-check-input\" name=\"giraffe_qr_code_access_status\" value=\"1\" data-number>\n                        <label for=\"field-giraffe_qr_code_access_status-1\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":239,"column":28},"end":{"line":239,"column":72}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-giraffe_qr_code_access_status-2\" type=\"radio\" class=\"form-check-input\" name=\"giraffe_qr_code_access_status\" value=\"2\" data-number>\n                        <label for=\"field-giraffe_qr_code_access_status-2\" class=\"col-form-label form-check-label\">\n                            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_qr_code_access_status.2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":245,"column":28},"end":{"line":245,"column":72}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <p class=\"font-weight-bold\">Campaign Monitor</p>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-cm_list_id\" class=\"col-form-label col-md-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_monitor_list_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":260,"column":92},"end":{"line":260,"column":129}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <input id=\"field-cm_list_id\" name=\"cm_list_id\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <hr>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-notes\" class=\"col-form-label col-md-3 col-lg-3 text-md-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":274,"column":20},"end":{"line":274,"column":38}}}))
    + "\n                </label>\n                <div class=\"col-md\">\n                    <textarea id=\"field-notes\" name=\"notes\" rows=\"10\" type=\"text\" class=\"form-control\"></textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-md-auto\">\n            <button type=\"button\" class=\"btn btn-secondary btn-block\" data-action=\"cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":285,"column":91},"end":{"line":285,"column":113}}}))
    + "</button>\n        </div>\n        <div class=\"col-md-auto\">\n            <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":288,"column":68},"end":{"line":288,"column":88}}}))
    + "</button>\n        </div>\n    </div>\n</form>";
},"useData":true});