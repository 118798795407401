import { Collection } from 'backbone';
import config from '@/js/app/config';
import SecurityKeyModel from '../models/security-key';

export default class SecurityKeysCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'security-keys';

        // Reference to this collection's model.
        this.model = SecurityKeyModel;
    }
}
