var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form id=\"frmEditProductStock\" autocomplete=\"off\" novalidate>\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-product_type_id\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":44}}}))
    + "\n                </label>\n                <div class=\"position-relative col\">\n                    <div id=\"divProductType\"></div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-shop_id\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":37}}}))
    + "\n                </label>\n                <div class=\"position-relative col-md\">\n                    <div id=\"divShop\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-label\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":46}}}))
    + "\n                </label>\n                <div class=\"col\">\n                    <input id=\"field-label\" name=\"label\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-status\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":41,"column":53}}}))
    + "\n                </label>\n                <div class=\"col\">\n                    <select id=\"field-status\" name=\"status\" class=\"form-control\">\n                        <option value=\"in_house\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.status.in_house",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":49},"end":{"line":45,"column":85}}}))
    + "</option>\n                        <option value=\"reserved\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.status.reserved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":49},"end":{"line":46,"column":85}}}))
    + "</option>\n                        <option value=\"on_loan\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.status.on_loan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":48},"end":{"line":47,"column":83}}}))
    + "</option>\n                        <option value=\"lost\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.status.lost",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":45},"end":{"line":48,"column":77}}}))
    + "</option>\n                        <option value=\"repair\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.status.repair",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":47},"end":{"line":49,"column":81}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-auto\">\n            <button type=\"button\" class=\"btn btn-secondary btn-block\" data-action=\"cancel\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":59,"column":38}}}))
    + "\n            </button>\n        </div>\n        <div class=\"col-auto\">\n            <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":68},"end":{"line":63,"column":88}}}))
    + "</button>\n        </div>\n    </div>\n</form>";
},"useData":true});