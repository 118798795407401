import { View } from 'backbone';
import config from '@/js/app/config';
import ErrorModalView from '@/js/app/error/views/modal';
import oauth2Client from '@/js/app/oauth2-client';
import ContractDocumentsCollection from '../collections/contract-documents';
import HistoryListView from './history-list';
import template from '../templates/item.hbs';

export default class ContractDocumentItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click [data-action="generate"]': this.handleGenerateClick,
            'click [data-action="download"]': this.handleDownloadClick,
            'click [data-action="history"]': this.handleHistoryClick,
        };
    }

    initialize(options) {
        this.isLoading = true;
        this.isGenerating = false;
        this.contractId = options.contractId;
        this.key = options.key;
        this.title = options.title;

        this.collection = new ContractDocumentsCollection([], {
            contractId: this.contractId,
            document: this.key
        });

        this.collection.fetch({
            reset: true
        });

        this.listenTo(this.collection, 'update reset', this.handleCollectionUpdate);
    }

    render() {
        console.debug('ContractDocumentItem#render');

        this.el.innerHTML = template({
            isLoading: this.isLoading,
            isGenerating: this.isGenerating,
            title: this.title,
            model: this.model ? this.model.toJSON() : this.model
        });

        return this;
    }

    handleCollectionUpdate() {
        console.debug('ContractDocumentItem#handleCollectionUpdate');
        this.isLoading = false;

        if (this.collection.isEmpty()) {
            this.model = undefined;
        } else {
            this.model = this.collection.at(-1);
        }

        this.render();
    }

    handleGenerateClick() {
        console.debug('ContractDocumentItem#handleGenerateClick');

        this.isGenerating = true;
        this.render();

        oauth2Client.fetchJSON(`${config.api.url}contracts/${this.contractId}/documents/${this.key}`, {
            method: 'POST',
        }).then(data => {
            this.isGenerating = false;
            this.collection.add(data);
        }).catch(error => {
            this.isGenerating = false;
            this.render();

            const errorModalView = new ErrorModalView({ error });
            errorModalView.render();
        });
    }

    handleDownloadClick() {
        console.debug('ContractDocumentPackage#handleDownloadClick');

        oauth2Client.xlsxDownload(
            this.model.url(),
            {
                method: 'GET',
            },
            {
                filename: `${this.contractId}-${this.title}.xlsx`,
            }
        );
    }

    handleHistoryClick() {
        console.debug('ContractDocumentPackage#handleDownloadClick');

        const historyListView = new HistoryListView({
            title: this.title,
            collection: this.collection
        });

        historyListView.render();
    }
}
