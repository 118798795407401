export default function brPaymentStatus(code) {
    switch (code) {
        case 0:
            return '未処理';
        case 1:
            return '完了';
        case 2:
            return '確認済み';
        case 3:
            return '未収';
        case 4:
            return '貸倒';
        case 5:
            return '手数料';
        case 6:
            return '現金';
        case 7:
            return '長期滞留債権';
        case 8:
            return '破産更生債権';
        case 9:
            return '売上取消';
        case 10:
            return '繰越';
        default:
            return '';
    }
}
