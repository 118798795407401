var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form class=\"form-login mx-auto my-5\">\n\n    <div class=\"text-center mb-4\">\n        <img src=\"/img/logo_trt_square_2022.png\" alt=\"Consus\">\n    </div>\n\n    <h2 class=\"text-center\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.trt",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":54}}}))
    + "</h2>\n\n    <div class=\"form-group\">\n        <label for=\"field-email\" class=\"col-form-label sr-only\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.email_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":64},"end":{"line":10,"column":100}}}))
    + "</label>\n        <input type=\"email\" id=\"field-email\" class=\"form-control\" placeholder=\""
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.email_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":79},"end":{"line":11,"column":115}}}))
    + "\" required autofocus>\n    </div>\n\n    <div class=\"form-group\">\n        <label for=\"field-password\" class=\"col-form-label sr-only\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":67},"end":{"line":15,"column":98}}}))
    + "</label>\n        <input type=\"password\" id=\"field-password\" class=\"form-control\" placeholder=\""
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":85},"end":{"line":16,"column":116}}}))
    + "\" required>\n    </div>\n\n    <div class=\"alert alert-danger d-none\" role=\"alert\"></div>\n\n    <div class=\"form-group\">\n        <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":64},"end":{"line":22,"column":92}}}))
    + "</button>\n    </div>\n\n</form>\n";
},"useData":true});