var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"dashboard.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":27}}}))
    + "</h1>\n\n<hr class=\"my-3\">\n\n<div>\n    <h2>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"dashboard.welcome",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":33}}}))
    + "</h2>\n</div>";
},"useData":true});