var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<option value=\"inactive\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"room.inactive",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":83},"end":{"line":25,"column":104}}}))
    + "</option>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"frmEditRoom\" autocomplete=\"off\" novalidate>\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-room_no\" class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":89},"end":{"line":5,"column":104}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <input id=\"field-room_no\" name=\"room_no\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-status\" class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":88},"end":{"line":14,"column":107}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <select id=\"field-status\" name=\"status\" class=\"form-control\" data-scope=\"consus:rooms.status\">\n                        <option value=\"vacant\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.vacant",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":47},"end":{"line":17,"column":66}}}))
    + "</option>\n                        <option value=\"unavailable\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.unavailable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":52},"end":{"line":18,"column":76}}}))
    + "</option>\n                        <option value=\"hold\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.hold",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":45},"end":{"line":19,"column":62}}}))
    + "</option>\n                        <option value=\"reserved\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.reserved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":49},"end":{"line":20,"column":70}}}))
    + "</option>\n                        <option value=\"occupied\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.occupied",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":49},"end":{"line":21,"column":70}}}))
    + "</option>\n                        <option value=\"vacating\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.vacating",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":49},"end":{"line":22,"column":70}}}))
    + "</option>\n                        <option value=\"contents\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.contents",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":49},"end":{"line":23,"column":70}}}))
    + "</option>\n                        <option value=\"repair\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.repair",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":47},"end":{"line":24,"column":66}}}))
    + "</option>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:rooms",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":25,"column":30},"end":{"line":25,"column":56}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":120}}})) != null ? stack1 : "")
    + "\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-base_rate\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.base_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":66},"end":{"line":36,"column":88}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <div class=\"input-group\">\n                        <div class=\"input-group-prepend\">\n                            <span class=\"input-group-text\">¥</span>\n                        </div>\n                        <input id=\"field-base_rate\" name=\"base_rate\" type=\"text\" class=\"form-control text-right\"\n                            data-number>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-target-base_rate\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.target_base_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":66},"end":{"line":52,"column":95}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <div class=\"input-group\">\n                        <div class=\"input-group-prepend\">\n                            <span class=\"input-group-text\">¥</span>\n                        </div>\n                        <input id=\"field-target-base_rate\" name=\"target_base_rate\" type=\"text\"\n                            class=\"form-control text-right\" data-number readonly>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-campaign_rate\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":66},"end":{"line":70,"column":92}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <div class=\"input-group\">\n                        <div class=\"input-group-prepend\">\n                            <span class=\"input-group-text\">¥</span>\n                        </div>\n                        <input id=\"field-campaign_rate\" name=\"campaign_rate\" type=\"text\" class=\"form-control text-right\"\n                            data-number>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-campaign_duration\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_duration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":66},"end":{"line":86,"column":96}}}))
    + "</label>\n                <div class=\"col-md col-lg-3\">\n                    <div class=\"input-group\">\n                        <input id=\"field-campaign_duration\" name=\"campaign_duration\" type=\"text\"\n                            class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">月</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"offset-6 col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-campaign_eligible_min_months\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_eligible_min_months",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":66},"end":{"line":104,"column":107}}}))
    + "</label>\n                <div class=\"col-md col-lg-3\">\n                    <div class=\"input-group\">\n                        <input id=\"field-campaign_eligible_min_months\" name=\"campaign_eligible_min_months\" type=\"text\"\n                            class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">月</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-floorspace\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.floorspace",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":66},"end":{"line":122,"column":89}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <div class=\"input-group\">\n                        <input id=\"field-floorspace\" name=\"floorspace\" type=\"text\" class=\"form-control text-right\"\n                            data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">帖</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-area\" class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.area",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":137,"column":86},"end":{"line":137,"column":103}}}))
    + "</label>\n                <div class=\"col-md col-lg-4\">\n                    <div class=\"input-group\">\n                        <input id=\"field-area\" name=\"area\" type=\"text\" class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">m²</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-width\" class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.width",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":153,"column":87},"end":{"line":153,"column":105}}}))
    + "</label>\n                <div class=\"col-md col-lg-6\">\n                    <div class=\"input-group\">\n                        <input id=\"field-width\" name=\"width\" type=\"text\" class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">cm</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-height\" class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.height",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":167,"column":88},"end":{"line":167,"column":107}}}))
    + "</label>\n                <div class=\"col-md col-lg-6\">\n                    <div class=\"input-group\">\n                        <input id=\"field-height\" name=\"height\" type=\"text\" class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">cm</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-depth\" class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.depth",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":181,"column":87},"end":{"line":181,"column":105}}}))
    + "</label>\n                <div class=\"col-md col-lg-6\">\n                    <div class=\"input-group\">\n                        <input id=\"field-depth\" name=\"depth\" type=\"text\" class=\"form-control text-right\" data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">cm</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-door_width\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.door_width",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":196,"column":66},"end":{"line":196,"column":89}}}))
    + "</label>\n                <div class=\"col-md col-lg-6\">\n                    <div class=\"input-group\">\n                        <input id=\"field-door_width\" name=\"door_width\" type=\"text\" class=\"form-control text-right\"\n                            data-number>\n                        <div class=\"input-group-append\">\n                            <span class=\"input-group-text\">cm</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-lock_number\"\n                    class=\"col-form-label col-md-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.lock_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":214,"column":66},"end":{"line":214,"column":90}}}))
    + "</label>\n                <div class=\"col-md col-lg-6\">\n                    <input id=\"field-lock_number\" name=\"lock_number\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"form-group row align-items-center\">\n        <label class=\"col-form-label col-md-3 col-lg-2 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":223,"column":70},"end":{"line":223,"column":100}}}))
    + "</label>\n        <div class=\"col-md\">\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-ceiling\"\n                    value=\"ceiling\">\n                <label class=\"form-check-label\" for=\"description-ceiling\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.ceiling",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":228,"column":74},"end":{"line":228,"column":106}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-pillar\"\n                    value=\"pillar\">\n                <label class=\"form-check-label\" for=\"description-pillar\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.pillar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":233,"column":73},"end":{"line":233,"column":104}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-shape\" value=\"shape\">\n                <label class=\"form-check-label\" for=\"description-shape\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.shape",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":237,"column":72},"end":{"line":237,"column":102}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-damage\"\n                    value=\"damage\">\n                <label class=\"form-check-label\" for=\"description-damage\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.damage",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":242,"column":73},"end":{"line":242,"column":104}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-small_unit\"\n                    value=\"small_unit\">\n                <label class=\"form-check-label\" for=\"description-small_unit\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.small_unit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":247,"column":77},"end":{"line":247,"column":112}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-free_rack\"\n                    value=\"free_rack\">\n                <label class=\"form-check-label\" for=\"description-free_rack\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.free_rack",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":252,"column":76},"end":{"line":252,"column":110}}}))
    + "</label>\n            </div>\n\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-bike_garage\"\n                    value=\"bike_garage\">\n                <label class=\"form-check-label\"\n                    for=\"description-bike_garage\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.bike_garage",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":259,"column":50},"end":{"line":259,"column":86}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-bike_park\"\n                    value=\"bike_park\">\n                <label class=\"form-check-label\" for=\"description-bike_park\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.bike_park",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":264,"column":76},"end":{"line":264,"column":110}}}))
    + "</label>\n            </div>\n\n            <br>\n\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box1_upper\"\n                    value=\"box1_upper\">\n                <label class=\"form-check-label\" for=\"description-box1_upper\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box1_upper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":272,"column":77},"end":{"line":272,"column":112}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box1_middle\"\n                    value=\"box1_middle\">\n                <label class=\"form-check-label\"\n                    for=\"description-box1_middle\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box1_middle",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":278,"column":50},"end":{"line":278,"column":86}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box1_lower\"\n                    value=\"box1_lower\">\n                <label class=\"form-check-label\" for=\"description-box1_lower\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box1_lower",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":283,"column":77},"end":{"line":283,"column":112}}}))
    + "</label>\n            </div>\n\n            <br>\n\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box2_upper\"\n                    value=\"box2_upper\">\n                <label class=\"form-check-label\" for=\"description-box2_upper\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box2_upper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":291,"column":77},"end":{"line":291,"column":112}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box2_middle\"\n                    value=\"box2_middle\">\n                <label class=\"form-check-label\"\n                    for=\"description-box2_middle\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box2_middle",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":297,"column":50},"end":{"line":297,"column":86}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box2_lower\"\n                    value=\"box2_lower\">\n                <label class=\"form-check-label\" for=\"description-box2_lower\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box2_lower",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":302,"column":77},"end":{"line":302,"column":112}}}))
    + "</label>\n            </div>\n\n            <br>\n\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box3_upper\"\n                    value=\"box3_upper\">\n                <label class=\"form-check-label\" for=\"description-box3_upper\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box3_upper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":310,"column":77},"end":{"line":310,"column":112}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box3_middle\"\n                    value=\"box3_middle\">\n                <label class=\"form-check-label\"\n                    for=\"description-box3_middle\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box3_middle",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":316,"column":50},"end":{"line":316,"column":86}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box3_lower\"\n                    value=\"box3_lower\">\n                <label class=\"form-check-label\" for=\"description-box3_lower\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box3_lower",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":321,"column":77},"end":{"line":321,"column":112}}}))
    + "</label>\n            </div>\n\n            <br>\n\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box4_upper\"\n                    value=\"box4_upper\">\n                <label class=\"form-check-label\" for=\"description-box4_upper\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box4_upper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":329,"column":77},"end":{"line":329,"column":112}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box4_middle\"\n                    value=\"box4_middle\">\n                <label class=\"form-check-label\"\n                    for=\"description-box4_middle\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box4_middle",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":335,"column":50},"end":{"line":335,"column":86}}}))
    + "</label>\n            </div>\n            <div class=\"form-check form-check-inline\">\n                <input class=\"form-check-input\" type=\"checkbox\" name=\"description\" id=\"description-box4_lower\"\n                    value=\"box4_lower\">\n                <label class=\"form-check-label\" for=\"description-box4_lower\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.box4_lower",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":340,"column":77},"end":{"line":340,"column":112}}}))
    + "</label>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-is_featured\"\n                    class=\"col-form-label col-md-4 col-lg-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.is_featured",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":349,"column":75},"end":{"line":349,"column":99}}}))
    + "</label>\n                <div class=\"col-md d-md-flex\">\n                    <div class=\"form-check form-check-inline\">\n                        <input class=\"form-check-input\" type=\"checkbox\" name=\"is_featured\" id=\"is_featured\"\n                            value=\"1\" data-unchecked-value=\"0\" data-number>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-notes\"\n                    class=\"col-form-label col-md-4 col-lg-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":364,"column":75},"end":{"line":364,"column":93}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <textarea id=\"field-notes\" name=\"notes\" rows=\"10\" type=\"text\" class=\"form-control\"\n                        data-scope=\"consus:rooms.notes\"></textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-md-auto\">\n            <button type=\"button\" class=\"btn btn-secondary btn-block\"\n                data-action=\"cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":376,"column":37},"end":{"line":376,"column":59}}}))
    + "</button>\n        </div>\n        <div class=\"col-md-auto\">\n            <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":379,"column":68},"end":{"line":379,"column":88}}}))
    + "</button>\n        </div>\n    </div>\n</form>";
},"useData":true});