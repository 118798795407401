var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-lg-8\">\n        <div class=\"form-group row\">\n            <label for=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"building") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"col-form-label col-md-3 col-lg-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"address.building",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":96},"end":{"line":54,"column":120}}}))
    + "</label>\n            <div class=\"col-md\">\n                <input id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"building") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"building") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" type=\"text\" class=\"form-control\">\n            </div>\n        </div>\n    </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-lg-6\">\n        <div class=\"form-group row\">\n            <label for=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"postcode") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"address.postcode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":120}}}))
    + "</label>\n            <div class=\"col\">\n                <div class=\"row\">\n                    <div class=\"col\">\n                        <input id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"postcode") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"postcode") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" type=\"text\" class=\"form-control\" maxlength=\"7\" pattern=\"[0-9]+\">\n                    </div>\n                    <div class=\"col\">\n                        <button id=\"lookup\" type=\"button\" class=\"btn btn-secondary \">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"generic.fill",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":85},"end":{"line":11,"column":105}}}))
    + "</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-lg\">\n        <div class=\"form-group row\">\n            <label for=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"prefecture") : depth0), depth0))
    + "\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"address.prefecture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":95},"end":{"line":22,"column":121}}}))
    + "</label>\n            <div class=\"position-relative col-md col-lg-5\">\n                <div id=\"divPrefecture\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-lg\">\n        <div class=\"form-group row\">\n            <label for=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"city") : depth0), depth0))
    + "\" class=\"col-form-label col-md-3 col-lg-4 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"address.city",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":89},"end":{"line":31,"column":109}}}))
    + "</label>\n            <div class=\"position-relative col-md col-lg-7\">\n                <div id=\"divCity\"></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-lg-8\">\n        <div class=\"form-group row\">\n            <label for=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"col-form-label col-md-3 col-lg-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"address.neighborhood",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":95},"end":{"line":42,"column":123}}}))
    + "</label>\n            <div class=\"col-md\">\n                <input id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" type=\"text\" class=\"form-control\">\n            </div>\n        </div>\n    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"building") : depth0)) != null ? lookupProperty(stack1,"show") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":0},"end":{"line":61,"column":7}}})) != null ? stack1 : "");
},"useData":true});