import { View } from 'backbone';
import Footer from './footer';
import Nav from './nav';
import template from '../templates/layout_default.hbs';

export default class DefaultLayoutView extends View {
    initialize() {
        console.debug('DefaultLayoutView#initialize');

        // Create subviews
        this.subviews = {
            nav: new Nav(),
            footer: new Footer(),
        };
    }

    render() {
        console.debug('DefaultLayoutView#render');

        this.el.innerHTML = template();

        // Render nav
        this.subviews.nav.setElement(this.el.querySelector('nav')).render();

        // Render footer
        this.subviews.footer.setElement(this.el.querySelector('footer')).render();
    }
}
