import { View } from 'backbone';
import ChangelogCollection from '@/js/app/changelog/collections/changelog';
import ChangelogModalView from '@/js/app/changelog/views/modal';
import yuyan from '@/js/app/yuyan';
import ContractBillsModalView from './bills-modal';
import template from '../templates/action-bar.hbs';

export default class ContractActionBarView extends View {
    preinitialize(options) {
        // Initialize defaults
        this.events = {
            'click [data-modal="bills"]': this.handleBillsClick,
            'click [data-modal="changelog"]': this.handleChangelogClick,
        };

        this.contractId = options.contractId;
    }

    render() {
        console.debug('ContractActionBar#render');

        // Attach template to el
        this.el.innerHTML = template();

        return this;
    }

    handleBillsClick() {
        console.debug('ContractActionBar#handleBillsClick');

        const view = new ContractBillsModalView({
            contractId: this.contractId
        });

        view.render();
    }

    handleChangelogClick() {
        console.debug('ContractActionBar#handleChangelogClick');

        const view = new ChangelogModalView({
            title: `${yuyan.t('contract.title')} ID: ${this.contractId} Changelog`,
            collection: new ChangelogCollection([], {
                resource: 'contracts',
                resourceId: this.contractId
            })
        });

        view.render();
    }
}
