import { history } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import ModalView from '@/js/app/generic/views/modal';
import OffenderModel from '../models/offender';
import yuyan from '@/js/app/yuyan';
import template from '../templates/modal.hbs';

export default class OffenderModal extends ModalView {
    preinitialize() {
        this.title = yuyan.t('offender.list_as_offender');
        this.events = {
            'submit form': this.handleFormSubmit,
        };
        this.preinitializeModal();
    }

    initialize(options) {
        _.bindAll(this, 'navigate', 'updateCustomerModel');

        this.customerModel = options.customerModel;
    }

    render() {
        console.debug('OffenderModal#render');

        this.renderModal().modalContent.innerHTML = template();

        const customerBlacklist = this.customerModel.get('blacklist');
        if (Array.isArray(customerBlacklist)) {
            customerBlacklist.forEach(blacklist => {
                const field = this.el.querySelector(`[name="reason"][value="${blacklist}"]`);

                // If field exists, set as checked
                if (field) {
                    field.checked = true;
                }
            });
        }

        return this;
    }

    handleFormSubmit(e) {
        console.debug('OffenderModal#handleFormSubmit');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const formData = formToObject(e.currentTarget);

            const data = {
                dob: this.customerModel.get('dob'),
                postcode: this.customerModel.get('postcode'),
                address: this.customerModel.get('address'),
                building: this.customerModel.get('building'),
                tel: this.customerModel.get('tel'),
                ...formData
            };

            if (this.customerModel.get('customer_type') === 'personal') {
                data.last_name_phonetic = this.customerModel.get('last_name_phonetic');
                data.first_name_phonetic = this.customerModel.get('first_name_phonetic');
            } else {
                data.company_name_phonetic = this.customerModel.get('company_name_phonetic');
            }

            this.model = new OffenderModel();

            // Trigger save of model with data as attributes
            this.model.save(data, {
                // On success: updateCustomerModel
                success: this.updateCustomerModel,
                // On error: handleSaveError
                error: this.handleSaveError
            });
        }
    }

    updateCustomerModel(model) {
        console.debug('OffenderModal#updateCustomerModel');

        this.customerModel.save({
            offender_check: 'flagged',
            blacklist: model.get('reason')
        }, {
            patch: true,
            wait: true,
            // On success: navigate
            success: this.navigate,
            // On error: errorOnSave
            error: this.handleSaveError
        });
    }

    navigate() {
        console.debug('OffenderModal#navigate');

        this.$el.modal('hide');

        // Navigate to offenders/:id
        history.navigate('offenders/' + this.model.id, { trigger: true });
    }

    handleSaveError(model, response) {
        console.debug('OffenderModal#handleSaveError');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
