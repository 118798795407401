import { View } from 'backbone';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/history-item.hbs';

export default class ShopFileHistoryItemView extends View {
    preinitialize() {
        this.tagName = 'tr';
        this.events = {
            'click [data-action="download"]': this.handleDownloadClick,
        };
    }


    render() {
        console.debug('ShopFileHistoryItem#render');

        this.el.innerHTML = template({
            model: this.model.toJSON()
        });

        return this;
    }

    handleDownloadClick() {
        console.debug('ShopFileHistoryItem#handleDownloadClick');

        oauth2Client.download(
            this.model.url(),
            {
                method: 'GET',
            },
            {
                filename: this.model.get('filename'),
            }
        );
    }
}
