import { Model } from 'backbone';
import config from '@/js/app/config';

export default class CustomerFileModel extends Model {
    preinitialize() {
        this.idAttribute = 'filename';
    }

    initialize(attrs, options) {
        this.customerId = options.customerId || options.collection.customerId || null;
    }

    urlRoot() {
        return config.api.url + 'customers/' + this.customerId + '/files';
    }
}
