import { Collection } from 'backbone';
import dayjs from 'dayjs';
import config from '@/js/app/config';

export default class LeasingStatusCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'reports/leasing-status';
        this.total = null;
    }

    parse(response) {
        const { results, total } = response;

        this.total = total;

        return results;
    }

    filterHistory(options = {}) {
        const now = dayjs();

        // Override default options
        options = _.extend({
            fromYear: now.year(),
            fromMonth: now.month() + 1,
            toYear: now.year(),
            toMonth: now.month() + 1,
        }, options);

        // Return filtered leasing status collection
        return this.filter(leasingStatus => {
            return (
                (
                    leasingStatus.get('year') > options.fromYear
                    || (
                        leasingStatus.get('year') === options.fromYear
                        && leasingStatus.get('month') >= options.fromMonth
                    )
                ) && (
                    leasingStatus.get('year') < options.toYear
                    || (
                        leasingStatus.get('year') === options.toYear
                        && leasingStatus.get('month') <= options.toMonth
                    )
                )
            );
        });
    }
}
