import { View } from 'backbone';
import Footer from './footer';
import template from '../templates/layout_blank.hbs';

export default class BlankLayoutView extends View {
    initialize() {
        console.debug('BlankLayoutView#initialize');

        // Create subviews
        this.subviews = {
            footer: new Footer(),
        };
    }

    render() {
        console.debug('BlankLayoutView#render');

        this.el.innerHTML = template();

        // Render footer
        this.subviews.footer.setElement(this.el.querySelector('footer')).render();
    }
}
