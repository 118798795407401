import { View } from 'backbone';
import ErrorModalView from '@/js/app/error/views/modal';
import ProductStocksCollection from '../collections/product-stocks';
import template from '../templates/index.hbs';
import ProductStockListAddView from './list-add';

export default class ProductStockIndexView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';
    }

    initialize() {
        this.subviews = {
            listAddProductStockCard: new ProductStockListAddView({
                collection: new ProductStocksCollection,
                showProductType: true
            })
        };
    }

    render() {
        console.debug('ProductStockIndex#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of product type list view as #listAddProductStock then render
        this.subviews.listAddProductStockCard.setElement(this.el.querySelector('#listAddProductStock')).render();

        // Trigger fetch of shop collection with data as query
        this.subviews.listAddProductStockCard.collection.fetch({
            data: {
                inclusions: ['product_type']
            },
            reset: true,
            // On error: errorOnSearch
            error: this.errorOnSearch
        });

        return this;
    }

    errorOnSearch(collection, response) {
        console.debug('ProductStockIndex#errorOnSearch');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
