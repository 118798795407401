var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div id=\"listRoomHistory\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>\n    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":22}}}))
    + ": <span id=\"shop-name\"></span> / "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":73}}}))
    + ": <span id=\"title\"></span>\n</h1>\n\n<hr class=\"my-3\">\n\n<div id=\"actionBar\" class=\"position-relative mb-3\"></div>\n\n<div id=\"details\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"listAddContract\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRoomHistory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "")
    + "\n<div id=\"listAddSecurityKey\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});