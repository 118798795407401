import dayjs from 'dayjs';
import { View } from 'backbone';
import template from '../templates/select-year-month.hbs';

export default class SelectYearMonth extends View {
    preinitialize(options = {}) {
        // Option to set the minimum selectable year (defaults to 2018 when TRT was founded)
        this.minYear = options.minYear || 2018;

        // Option to include next year if current month is December
        this.includeNextYear = options.includeNextYear || false;

        // Option to default to previous month
        this.defaultPreviousMonth = options.defaultPreviousMonth || false;
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('SelectYearMonth#render');

        this.el.innerHTML = template();

        this.loadDates();
    }

    loadDates() {
        console.debug('SelectYearMonth#loadDates');

        const options = document.createDocumentFragment();
        const today = dayjs();

        // If includeNextYear option is set, and month is December (11)
        if (this.includeNextYear && today.month() === 11) {
            const o = document.createElement('option');
            o.value = today.year() + 1;
            o.innerText = today.year() + 1;
            options.appendChild(o);
        }

        for (let y = today.year(); y >= this.minYear; y--) {
            const value = y;
            const o = document.createElement('option');
            o.value = value;
            o.innerText = value;
            options.appendChild(o);
        }

        // Add options to year select
        this.el.querySelector('[name="year"]').appendChild(options);

        // Determine default date
        let defaultDate = today;
        if (this.defaultPreviousMonth) {
            defaultDate = today.subtract(1, 'month');
        }

        // Preset year and month
        this.el.querySelector('[name="year"]').value = defaultDate.format('YYYY');
        this.el.querySelector('[name="month"]').value = defaultDate.format('MM');
    }
}