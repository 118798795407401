import { View } from 'backbone';
import OwnerModel from '@/js/app/owners/models/owner';
import OwnerReportCollection from '../../collections/owner-reports';
import template from '../../templates/owner-downloads/card.hbs';
import PmOwnerDownloadsCardItemView from './card-item';
import PmOwnerDownloadsCardItemNoCollectionView from './card-item-no-collection';

// Report types

/**
 * Export only report type
 */
const EXPORT = 'EXPORT';

/**
 * Download with history report type
 */
const DOWNLOAD = 'DOWNLOAD';

/**
 * Generate and download with history report type
 */
const SNAPSHOT = 'SNAPSHOT';

/**
 * Upload and download with history report type
 */
const UPLOAD = 'UPLOAD';

export default class PmOwnerDownloadsCardView extends View {
    preinitialize(options) {
        this.collection = new OwnerReportCollection();
        this.model = new OwnerModel();
        this.reports = [
            {
                type: {
                    label: '各種費用計算',
                    value: 'various_fees'
                },
                cardItemType: EXPORT,
                fileExtension: 'xlsx'
            },
            {
                type: {
                    label: 'Contract Data',
                    value: 'contract_data'
                },
                cardItemType: UPLOAD
            },
            {
                type: {
                    label: '入金データ',
                    value: 'deposit_data'
                },
                cardItemType: UPLOAD
            },
        ];
        this.criteria = options.criteria;
        this.previousViews = [];
    }

    initialize() {
        this.listenTo(this.criteria, 'change', this.handleCriteriaChange);
        this.listenTo(this.model, 'change', this.handleModelChange);
        this.listenTo(this.collection, 'reset', this.handleCollectionReset)
    }

    render() {
        console.debug('PmOwnerDownloadsCard#render');

        this.el.innerHTML = template({
            ownerName: this.model.get('name')
        });

        return this;
    }

    handleCriteriaChange(changedCriteria) {
        console.debug('PmOwnerDownloadsCard#handleCriteriaChange');

        if (changedCriteria.ownerId !== undefined) {
            this.model.set('id', changedCriteria.ownerId, { silent: true });
            if (changedCriteria.ownerId) {
                this.trigger('request');

                this.model.fetch();
            } else {
                while (this.previousViews.length > 0) {
                    const view = this.previousViews.pop();
                    view.remove();
                }

                this.el.removeChild(this.el.firstChild);
            }
        } else if (changedCriteria.period) {
            this.trigger('request');

            this.collection.fetch({
                data: {
                    period: changedCriteria.period
                },
                reset: true
            });
        }
    }

    handleModelChange() {
        console.debug('PmOwnerDownloadsCard#handleModelChange');

        this.render();

        this.collection.ownerId = this.model.id;
        this.collection.fetch({
            data: {
                period: this.criteria.period
            },
            reset: true
        });
    }

    handleCollectionReset() {
        console.debug('PmOwnerDownloadsCard#handleCollectionReset');

        this.trigger('sync');

        const el = this.el.querySelector('[data-slot="items"]');

        while (this.previousViews.length > 0) {
            const view = this.previousViews.pop();
            view.remove();
        }

        const cardItemListFragment = document.createDocumentFragment();

        this.reports.forEach(report => {
            if (report.cardItemType === EXPORT) {
                const view = new PmOwnerDownloadsCardItemNoCollectionView({
                    model: this.model,
                    type: report.type,
                    criteria: this.criteria,
                    fileExtension: report.fileExtension
                });
                this.previousViews.push(view);

                cardItemListFragment.appendChild(view.render().el);
            } else {
                const viewOptions = {
                    model: this.model,
                    type: report.type,
                    collection: new OwnerReportCollection(this.collection.filter(model => model.get('type') === report.type.value), { ownerId: this.collection.ownerId }),
                    criteria: this.criteria,
                    hasUpload: report.hasUpload,
                    hasGenerate: report.hasGenerate
                };

                if (report.cardItemType === UPLOAD) {
                    viewOptions.hasUpload = true;
                    viewOptions.hasGenerate = false;
                } else if (report.cardItemType === SNAPSHOT) {
                    viewOptions.hasUpload = false;
                    viewOptions.hasGenerate = true;
                } else if (report.cardItemType === DOWNLOAD) {
                    viewOptions.hasUpload = false;
                    viewOptions.hasGenerate = false;
                }

                const view = new PmOwnerDownloadsCardItemView(viewOptions);
                this.previousViews.push(view);

                cardItemListFragment.appendChild(view.render().el);
            }
        });

        el.appendChild(cardItemListFragment);

        return this;
    }
}
