import { View } from 'backbone';
import DeleteModalView from '@/js/app/generic/views/delete-modal';
import oAuth2Client from '@/js/app/oauth2-client';
import template from '../templates/item.hbs';

export default class ContractFileItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click [data-action="download"]': this.handleDownloadClick,
            'click [data-action="delete"]': this.handleDeleteClick,
        };
    }

    initialize() {
        // When model is destroyed: remove
        this.listenTo(this.model, 'destroy', this.remove);
    }

    render() {
        console.debug('ContractFileItem#render');

        this.el.innerHTML = template({
            file: this.model.toJSON()
        });

        return this;
    }

    handleDownloadClick($e) {
        console.debug('ContractFileItem#handleDownloadClick');

        $e.preventDefault();

        this.download();
    }

    download() {
        console.debug('ContractFileItem#download');

        oAuth2Client.download(
            this.model.url(),
            { method: 'GET' },
            { filename: this.model.get('filename') }
        );
    }

    handleDeleteClick($e) {
        console.debug('ContractFileItem#handleDeleteClick');

        $e.preventDefault();

        this.showDeleteConfirmationModal();
    }

    showDeleteConfirmationModal() {
        console.debug('ContractFileItem#showDeleteConfirmationModal');

        const deleteConfirmation = new DeleteModalView({
            model: this.model
        });

        deleteConfirmation.render();
    }
}
