import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import ProductTypesCollection from '@/js/app/product-types/collections/product-types';
import ProductTypeCntrView from '@/js/app/product-types/views/cntr';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import yuyan from '@/js/app/yuyan';
import updateTemplate from '../templates/edit.hbs';
import readTemplate from '../templates/read.hbs';

export default class ProductStockReadUpdateView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('productStock.details');

        this.events = {
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.renderRead,
            'submit form#frmEditProductStock': this.saveUpdate
        };

        this.buttons = [
            {
                cntrData: 'editBtnCntr',
                text: yuyan.t('generic.edit'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'edit'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            }
        ];
    }

    initialize() {
        // Create subviews
        this.subviews = {
            productTypeCntr: new ProductTypeCntrView({
                collection: new ProductTypesCollection,
                field_id: "field-product_type_id"
            }),
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id",
                readonly: true
            })
        };

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });

        // When model changes; render
        this.listenTo(this.model, 'change', this.renderRead);
    }

    render() {
        console.debug('ProductStockReadUpdate#render');

        this.renderCard();

        return this;
    }

    renderRead() {
        console.debug('ProductStockReadUpdate#renderRead');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('editBtnCntr')
            .renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('ProductStockReadUpdate#renderReadTemplate');

        // Attach readTemplate to el with productStock model
        this.cardBody.innerHTML = readTemplate({
            productStock: this.model.toJSON(),
        });

        return this;
    }

    renderEdit() {
        console.debug('ProductStockReadUpdate#renderEdit');

        this.hideButtonContainer('editBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderEditTemplate();

        return this;
    }

    renderEditTemplate() {
        console.debug('ProductStockReadUpdate#renderEditTemplate');

        // Attach updateTemplate to el
        this.cardBody.innerHTML = updateTemplate();

        this.subviews.productTypeCntr.setElement(this.el.querySelector('#divProductType')).render();
        this.subviews.productTypeCntr.collection.fetch();
        this.$el.find('#field-product_type_id').select2({
            theme: 'bootstrap4',
        });

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop_id').select2({
            theme: 'bootstrap4',
        });

        // If model exists
        if (this.model) {

            // For each model attribute
            _.each(this.model.toJSON(), (val, key) => {
                // Initialize field as element with name = key
                const field = this.el.querySelector('[name="' + key + '"]');

                // If field exists, set value
                if (field) {
                    field.value = val;
                }
            });

            this.subviews.productTypeCntr.setValue(this.model.get('product_type_id'));

            this.subviews.shopcntr.setValue(this.model.get('shop_id'));
        }

        // Disable shop and unit select fields
        this.$el.find('#divProductType select').prop('disabled', true);

        return this;
    }

    saveUpdate(e) {
        console.debug('ProductStockReadUpdate#saveUpdate');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            if (e.currentTarget.checkValidity() === false) {
                // If form validity is false return
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Initialize changes as result of changedAttributes of model based on data
            const changes = this.model.changedAttributes(data);

            // If changes exist
            if (changes !== false) {
                // Trigger save of model with changes as attributes
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    // On error: errorOnSave
                    error: this.errorOnSave
                });
            } else {
                // Else trigger change of model
                this.model.trigger('change', this.model);
            }
        }
    }

    errorOnSave(model, response) {
        console.debug('ProductStockReadUpdate#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
