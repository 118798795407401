import ModalView from '@/js/app/generic/views/modal';
import CustomerLoginHistoryCollection from '../collections/login-history';
import template from '../templates/login-history-modal.hbs';
import SpinnerView from '@/js/app/generic/views/spinner';
import { Collection } from 'backbone';

export default class CustomerLoginHistoryModalView extends ModalView {
    preinitialize(options) {
        this.collection = new CustomerLoginHistoryCollection([], { customerId: options.customerId });

        this.preinitializeModal();
    }

    initialize() {
        this.listenTo(this.collection, 'update reset', this.renderList);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.modalContent.querySelector('.modal-body'));
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('CustomerLoginHistoryModal#render');

        this.renderModal({
            size: 'xl',
            centered: true,
            scrollable: true
        }).modalContent.innerHTML = template({
            isLoading: true
        });

        this.collection.fetch({ reset: true });

        return this;
    }

    renderList() {
        console.debug('CustomerLoginHistoryModal#renderList');

        this.modalContent.innerHTML = template({
            loginHistory: this.collection.toJSON()
        });

        return true;
    }
}
