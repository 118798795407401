var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form autocomplete=\"off\" novalidate>\n    <div class=\"modal-header\">\n        <h5 class=\"modal-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_registration_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":71}}}))
    + "</h5>\n\n        <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n            <span aria-hidden=\"true\">&times;</span>\n        </button>\n    </div>\n\n    <div class=\"modal-body\">\n        <div class=\"form-group row mb-0\">\n            <label for=\"field-giraffe_facility_id\" class=\"col-form-label col-md-3 col-lg-4\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.giraffe_facility_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":48}}}))
    + "\n            </label>\n            <div class=\"col-md\">\n                <input id=\"field-giraffe_facility_id\" type=\"text\" name=\"giraffe_facility_id\" class=\"form-control\" required>\n            </div>\n        </div>\n\n        <p id=\"giraffeRegistrationError\" class=\"text-danger mt-3 mb-0 d-none\"></p>\n    </div>\n\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":77},"end":{"line":24,"column":99}}}))
    + "</button>\n        <button type=\"submit\" class=\"btn btn-primary\" data-action=\"register\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.register",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":77},"end":{"line":25,"column":101}}}))
    + "</button>\n    </div>\n</form>";
},"useData":true});