import ModalView from '@/js/app/generic/views/modal';
import HistoryItemView from './history-item';
import template from '../templates/history-list.hbs';

export default class ContractDocumentHistoryList extends ModalView {
    preinitialize(options) {
        this.title = options.title;
        this.preinitializeModal();
    }

    render() {
        console.debug('ContractDocumentHistoryList#render');

        this.renderModal().modalContent.innerHTML = template({
            title: this.title
        });

        this.collection.each((model) => {
            const documentItem = new HistoryItemView({
                title: this.title,
                model: model
            })

            this.$el.find('[data-slot="documents"]').prepend(documentItem.render().el);
        });

        return this;
    }
}
