import { bindAll } from 'underscore';
import { View, history } from 'backbone';
import oAuth2Client from '@/js/app/oauth2-client';
import User from '@/js/app/user';
import template from '../templates/logout.hbs';

export default class LogoutView extends View {
    preinitialize() {
        this.className = 'container';

        this.events = {
            'click .btn-primary': this.logout,
            'click .btn-secondary': this.cancel,
        };
    }

    initialize() {
        // Make sure these functions run with correct 'this' context
        bindAll(this, 'logout', 'cancel');
    }

    render() {
        console.debug('Logout#render');

        this.el.innerHTML = template();

        return this;
    }

    logout() {
        console.debug('Logout#logout');

        // Clear user data
        User.clearInfo();

        // Clear access token
        oAuth2Client.clearToken();

        console.info('Logout#logout: Logout successful');

        // Redirect to top page
        history.navigate('', { trigger: true });
    }

    cancel() {
        console.debug('Logout#cancel')

        // Go back
        window.history.back();
    }
}
