import { Collection } from 'backbone';
import SpinnerView from '@/js/app/generic/views/spinner';
import CustomerFilesCollection from '../collections/customer-files';
import UnitCustomerFileView from './unit';
import yuyan from '@/js/app/yuyan';
import CardView from '@/js/app/generic/views/card';
import template from '../templates/index.hbs';

export default class CustomerFileIndexView extends CardView {
    preinitialize() {
        this.title = yuyan.t('customerFile.title', 2);
    }

    initialize() {
        // Initialize defaults
        this.subviews = {
            firstUnit: new UnitCustomerFileView({
                inputName: 'firstId'
            }),

            secondUnit: new UnitCustomerFileView({
                inputName: 'secondId'
            }),

            thirdUnit: new UnitCustomerFileView({
                inputName: 'thirdId'
            }),

            fourthUnit: new UnitCustomerFileView({
                noInput: true
            }),

            fifthUnit: new UnitCustomerFileView({
                noInput: true
            })
        }

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);

        this.listenTo(this.model, 'change:customer_type', this.setPrerequisites);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    setPrerequisites() {
        console.debug('CustomerFileIndex#setPrerequisites');

        this.customerType = this.model.get('customer_type');

        if (this.customerType && this.collectionFetched) {
            this.render();
        }
    }

    render() {
        console.debug('CustomerFileIndex#render');

        if (this.customerType) {
            this.subviews.firstUnit.unitType = 'front';
            this.subviews.firstUnit.collection = new CustomerFilesCollection(this.collection.where({ type: 'front' }), {
                customerId: this.model.get('id')
            });

            this.subviews.secondUnit.unitType = 'back';
            this.subviews.secondUnit.collection = new CustomerFilesCollection(this.collection.where({ type: 'back' }), {
                customerId: this.model.get('id')
            });

            const isCorporate = this.customerType === 'corporate';

            this.renderCard().cardBody.innerHTML = template({
                isCorporate: isCorporate
            });
            this.stopListening(this.collection);
            this.subviews.firstUnit.setElement(this.el.querySelector('#firstUnit')).setupListener().render();
            this.subviews.secondUnit.setElement(this.el.querySelector('#secondUnit')).setupListener().render();

            if (isCorporate) {
                this.subviews.thirdUnit.unitType = 'registration';
                this.subviews.thirdUnit.collection = new CustomerFilesCollection(this.collection.where({ type: 'registration' }), {
                    customerId: this.model.get('id')
                });
                this.subviews.thirdUnit.setElement(this.el.querySelector('#thirdUnit')).setupListener().render();

                this.subviews.fourthUnit.unitType = 'history';
                this.subviews.fourthUnit.collection = new CustomerFilesCollection(this.collection.where({ type: 'history' }), {
                    customerId: this.model.get('id')
                });
                if (this.subviews.fourthUnit.collection.length > 0) {
                    this.subviews.fourthUnit.setElement(this.el.querySelector('#fourthUnit')).setupListener().render();
                } else {
                    this.el.querySelector('#fourthUnit').classList.add('d-none');
                }

                this.subviews.fifthUnit.unitType = 'stamp';
                this.subviews.fifthUnit.collection = new CustomerFilesCollection(this.collection.where({ type: 'stamp' }), {
                    customerId: this.model.get('id')
                });
                if (this.subviews.fifthUnit.collection.length > 0) {
                    this.subviews.fifthUnit.setElement(this.el.querySelector('#fifthUnit')).setupListener().render();
                } else {
                    this.el.querySelector('#fifthUnit').classList.add('d-none');
                }
            }
        } else {
            this.collectionFetched = true;
        }
    }
}
