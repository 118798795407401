import { View } from 'backbone';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/history-item.hbs';

export default class ContractDocumentHistoryItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click [data-action="download"]': this.download,
        };
    }

    initialize(options) {
        this.title = options.title;
    }

    render() {
        console.debug('ContractDocumentHistoryItem#render');

        this.el.innerHTML = template({
            model: this.model.toJSON()
        });

        return this;
    }

    download() {
        console.debug('ContractDocumentHistoryItem#download');

        oauth2Client.xlsxDownload(
            this.model.url(),
            {
                method: 'GET',
            },
            {
                filename: `${this.model.contractId}-${this.title}.xlsx`,
            }
        );
    }
}
