import { queue } from 'async';
import { View } from 'backbone';
import uploader from '@/js/app/file-uploader/uploader';
import ShopFilesCollection from '../collections/shop-files';
import oauth2Client from '@/js/app/oauth2-client';
import ShopFileHistoryView from './history';
import template from '../templates/list-item.hbs';

export default class ShopFileListItemView extends View {
    preinitialize(options = {}) {
        // Initialize defaults
        this.shopId = options.shopId;
        this.title = options.title;
        this.events = {
            'change [name="file"]': this.handleFileChange,
            'click [data-action="upload"]': this.handleUploadClick,
            'click [data-action="history"]': this.handleHistoryClick,
            'click [data-action="download"]': this.handleDownloadClick,
        };
        this.collection = new ShopFilesCollection([], {
            shopId: this.shopId,
            fileType: options.type
        });

        this.isLoading = true;
        this.isUploading = false;
    }

    initialize() {
        this.listenTo(this.collection, 'update reset', this.handleCollectionUpdate);

        this.collection.fetch({
            reset: true
        });
    }

    render() {
        console.debug('ShopFileListItem#render');

        this.el.innerHTML = template({
            title: this.title,
            isLoading: this.isLoading,
            isUploading: this.isUploading,
            model: this.model ? this.model.toJSON() : this.model
        });

        return this;
    }

    handleCollectionUpdate() {
        console.debug('ShopFileListItem#handleCollectionUpdate');
        this.isLoading = false;

        if (this.collection.isEmpty()) {
            this.model = undefined;
        } else {
            this.model = this.collection.at(-1);
        }

        this.render();
    }

    handleFileChange(e) {
        console.debug('ShopFileListItem#handleFileChange');

        this.isUploading = true;
        this.render();

        const files = e.currentTarget.files;
        const name = e.currentTarget.name;
        const uploadStatusContainer = this.$el.find('[data-part="uploadStatus"]');

        // Create file upload queue with handler
        const q = queue((file, callback) => {
            uploader(name, file,
                {
                    method: 'PUT',
                    url: this.collection.url()
                },
                {
                    uploadStatusContainer
                })
                .then((response) => {
                    this.isUploading = false;

                    // Add new file model to collection using response data
                    this.collection.add(response);
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (let i = 0; i < files.length; i++) {
            // Add file to queue
            q.push(files[i]);
        }

        // Clear file selector
        e.currentTarget.value = '';
    }

    handleUploadClick() {
        console.debug('ShopFileListItem#handleUploadClick');

        this.$el.find('[name="file"]').trigger('click');
    }

    handleDownloadClick() {
        console.debug('ShopFileListItem#handleDownloadClick');

        oauth2Client.download(
            this.model.url(),
            {
                method: 'GET'
            },
            {
                filename: this.model.get('filename')
            }
        );
    }

    handleHistoryClick() {
        console.debug('ShopFileListItem#handleHistoryClick');

        const historyListView = new ShopFileHistoryView({
            collection: this.collection
        });

        historyListView.render();
    }
}
