var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\n                        checked";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <th>"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":37}}}))
    + "</th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <th>"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"reports.period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":34,"column":42}}}))
    + "</th>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.area",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":39},"end":{"line":37,"column":61}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.target_rent_per_area",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":39},"end":{"line":38,"column":77}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rent_per_area",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":39},"end":{"line":39,"column":70}}})) != null ? stack1 : "")
    + "</th>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.area_tsubo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":39},"end":{"line":41,"column":67}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.target_rent_per_area_tsubo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":39},"end":{"line":42,"column":83}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rent_per_area_tsubo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":39},"end":{"line":43,"column":76}}})) != null ? stack1 : "")
    + "</th>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.contracts_started",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":39},"end":{"line":48,"column":74}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.contracts_ended",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":39},"end":{"line":49,"column":72}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rooms_occupied",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":39},"end":{"line":50,"column":71}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.room_occupancy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":39},"end":{"line":51,"column":71}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.target_max_rent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":39},"end":{"line":52,"column":72}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":39},"end":{"line":53,"column":61}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rent_occupancy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":39},"end":{"line":54,"column":71}}})) != null ? stack1 : "")
    + "</th>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.contracts_started_eom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":39},"end":{"line":56,"column":78}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.contracts_ended",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":39},"end":{"line":57,"column":72}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rooms_occupied_eom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":39},"end":{"line":58,"column":75}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.room_occupancy_eom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":39},"end":{"line":59,"column":75}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.target_max_rent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":39},"end":{"line":60,"column":72}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rent_eom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":39},"end":{"line":61,"column":65}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rent_occupancy_eom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":39},"end":{"line":62,"column":75}}})) != null ? stack1 : "")
    + "</th>\n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"isTsuboShown") : depths[1]),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.program(18, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":72,"column":16},"end":{"line":80,"column":28}}})) != null ? stack1 : "")
    + "                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rooms") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"contracts_signed") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"isEomShown") : depths[1]),{"name":"unless","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(22, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":99,"column":27}}})) != null ? stack1 : "")
    + "                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"ltv_avg") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":100,"column":39},"end":{"line":100,"column":70}}}))
    + "</td>\n            </tr>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"text-right\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"area") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"target_rent_per_area") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":74,"column":39},"end":{"line":74,"column":83}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"rent_per_area") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":75,"column":39},"end":{"line":75,"column":76}}}))
    + "</td>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"text-right\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"area_tsubo") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"target_rent_per_area_tsubo") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":78,"column":39},"end":{"line":78,"column":89}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"rent_per_area_tsubo") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":79,"column":39},"end":{"line":79,"column":82}}}))
    + "</td>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"contracts_started") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"contracts_ended") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rooms_occupied") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"room_occupancy") : depth0),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":87,"column":39},"end":{"line":87,"column":76}}}))
    + "%</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"target_max_rent") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":88,"column":39},"end":{"line":88,"column":78}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"rent") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":89,"column":39},"end":{"line":89,"column":67}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"rent_occupancy") : depth0),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":90,"column":39},"end":{"line":90,"column":76}}}))
    + "%</td>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"contracts_started_eom") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"contracts_ended_eom") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rooms_occupied_eom") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"room_occupancy_eom") : depth0),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":95,"column":39},"end":{"line":95,"column":80}}}))
    + "%</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"target_max_rent") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":96,"column":39},"end":{"line":96,"column":78}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"rent_eom") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":97,"column":39},"end":{"line":97,"column":71}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"rent_occupancy_eom") : depth0),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":98,"column":39},"end":{"line":98,"column":80}}}))
    + "%</td>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tfoot>\n            <tr>\n                <td>TOTAL</td>\n                "
    + ((stack1 = __default(require("../../../../handlebars-helpers/reports/leasingStatusTotal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"total") : depth0),(depth0 != null ? lookupProperty(depth0,"isEomShown") : depth0),(depth0 != null ? lookupProperty(depth0,"isTsuboShown") : depth0),{"name":"reports/leasingStatusTotal","hash":{},"data":data,"loc":{"start":{"line":109,"column":16},"end":{"line":109,"column":80}}})) != null ? stack1 : "")
    + "\n            </tr>\n        </tfoot>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row mb-2 justify-content-between align-items-end\">\n    <div class=\"col-auto\">\n        <div class=\"row\">\n            <div class=\"col-auto\">\n                <div class=\"custom-control custom-switch\">\n                    <input type=\"checkbox\" class=\"custom-control-input\" id=\"eomSwitch\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEomShown") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":87},"end":{"line":7,"column":38}}})) != null ? stack1 : "")
    + ">\n                    <label class=\"custom-control-label\" for=\"eomSwitch\">Show EOM</label>\n                </div>\n            </div>\n\n            <div class=\"col-auto\">\n                <div class=\"custom-control custom-switch\">\n                    <input type=\"checkbox\" class=\"custom-control-input\" id=\"tsuboSwitch\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTsuboShown") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":89},"end":{"line":15,"column":38}}})) != null ? stack1 : "")
    + ">\n                    <label class=\"custom-control-label\" for=\"tsuboSwitch\">Show Tsubo</label>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-auto\">\n        <button type=\"button\" class=\"btn btn-success\" data-action=\"export\">"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.export.excel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":75},"end":{"line":23,"column":103}}}))
    + "</button>\n    </div>\n</div>\n\n<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap border\">\n        <thead>\n            <tr class=\"thead-light\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMonthPerspective") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTsuboShown") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":44,"column":27}}})) != null ? stack1 : "")
    + "                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":39},"end":{"line":45,"column":62}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.contracts_signed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":39},"end":{"line":46,"column":73}}})) != null ? stack1 : "")
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEomShown") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":63,"column":27}}})) != null ? stack1 : "")
    + "                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.ltv_avg",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":39},"end":{"line":64,"column":64}}})) != null ? stack1 : "")
    + "</th>\n            </tr>\n        </thead>\n\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"leasingStatus") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":102,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":8},"end":{"line":112,"column":15}}})) != null ? stack1 : "")
    + "    </table>\n</div>";
},"useData":true,"useDepths":true});