var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.giraffe_registration_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":71}}}))
    + "</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n    <p class=\"mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.giraffe_registration_message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":65}}}))
    + "</p>\n\n    <p id=\"giraffeRegistrationError\" class=\"text-danger mt-3 mb-0 d-none\"></p>\n</div>\n\n<div class=\"modal-footer\">\n    <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":73},"end":{"line":16,"column":95}}}))
    + "</button>\n    <button type=\"button\" class=\"btn btn-primary\" data-action=\"register\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.register",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":73},"end":{"line":17,"column":97}}}))
    + "</button>\n</div>";
},"useData":true});