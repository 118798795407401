import { Model } from 'backbone';
import config from '@/js/app/config';

export default class ShopCashflowModel extends Model {
    initialize(_, options) {
        this.shopId = options.shopId || options.collection.shopId || null;
    }

    urlRoot() {
        return config.api.url + 'shops/' + this.shopId + '/cashflows';
    }
}
