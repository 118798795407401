var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <div class=\"d-flex mb-3\">\n        <div class=\"mr-auto\">\n            <button type=\"button\" class=\"btn btn-danger\" data-action=\"delete\" disabled>\n                "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":16},"end":{"line":62,"column":38}}}))
    + "\n            </button>\n\n            <button type=\"button\" class=\"btn btn-success ml-2\" data-action=\"add\">\n                "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":35}}}))
    + "\n            </button>\n\n            <button type=\"button\" class=\"btn btn-success ml-2\" data-action=\"upload\">\n                "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.bulk_upload",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":16},"end":{"line":70,"column":43}}}))
    + "\n            </button>\n            <input id=\"uploadFile\" name=\"file\" class=\"d-none\" type=\"file\" accept=\".xlsx\">\n        </div>\n\n        <div class=\"ml-auto\">\n            <button class=\"btn btn-success\" data-modal=\"importIncomes\">\n                請求管理ロボ収入インポート\n            </button>\n\n            <button class=\"btn btn-success ml-2\" data-modal=\"importExpenses\">\n                Freee支出インポート\n            </button>\n        </div>\n    </div>\n\n    <div data-part=\"uploadStatus\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>Cashflows</h1>\n\n<hr class=\"my-3\">\n\n<div class=\"container mb-3\">\n    <div class=\"row\">\n        <div class=\"col-4\">\n            <div class=\"form-group row\">\n                <label for=\"field-shop_id\" class=\"col-form-label col-4\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":72},"end":{"line":9,"column":89}}}))
    + "</label>\n                <div id=\"divShop\" class=\"col-8\"></div>\n            </div>\n        </div>\n    </div>\n\n    <form id=\"frmSearch\" autocomplete=\"off\" class=\"d-none\">\n        <div class=\"row\">\n            <div class=\"col-4\">\n                <div class=\"form-group row\">\n                    <label for=\"field-period\" class=\"col-form-label col-4\">Date</label>\n                    <div class=\"col-8\">\n                        <input id=\"field-period\" name=\"period\" type=\"month\" class=\"form-control\" min=\"2019-01\">\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-3\">\n                <div class=\"form-group row\">\n                    <label for=\"field-type\" class=\"col-form-label col-4\">Type</label>\n                    <div class=\"col-8\">\n                        <select id=\"field-type\" name=\"type\" class=\"form-control\">\n                            <option value=\"\">収入 + 支出</option>\n                            <option value=\"income\">収入のみ</option>\n                            <option value=\"expense\">支出のみ</option>\n                        </select>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-5\">\n                <div class=\"form-group row\">\n                    <label for=\"field-vendor\" class=\"col-form-label col-lg-4\">Counterparty</label>\n                    <div class=\"col-8\">\n                        <input id=\"field-vendor\" name=\"vendor_search\" type=\"text\" class=\"form-control\">\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row justify-content-end align-items-center\">\n            <div class=\"col-md-auto\">\n                <button type=\"submit\" class=\"btn btn-primary custom-btn\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":73},"end":{"line":51,"column":95}}}))
    + "</button>\n            </div>\n        </div>\n    </form>\n</div>\n\n<div id=\"cashflowActionBar\" class=\"d-none\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm.upload",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":58,"column":10},"end":{"line":58,"column":40}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":4},"end":{"line":87,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"cashflowList\" class=\"d-none mb-3\"></div>";
},"useData":true});