import { View } from 'backbone';

export default class PrefectureIndexView extends View {
    preinitialize() {
        this.tagName = 'select';

        this.className = 'form-control pref';
    }

    initialize() {
        // Bind this on render
        _.bindAll(this, 'render');

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('PrefectureIndex#render');

        // If collection contains models
        if (this.collection.length > 0) {
            // Initialize options as document fragment
            const options = document.createDocumentFragment();

            // Append option element to options
            options.appendChild(document.createElement('option'));

            // For each model in collection
            this.collection.each((v) => {
                // Initialize o as option element
                const o = document.createElement('option');

                // Set value and innerText of o
                o.value = v.get('id');
                o.innerText = v.get('name');

                // Append o to options
                options.appendChild(o);
            });

            // Clear options of el
            while (this.el.firstChild) {
                this.el.removeChild(this.el.firstChild);
            }

            // Attach options to el
            this.el.appendChild(options);
        }


        // If value exists, setValue
        if (this.value) {
            this.setValue();
        }

        return this;
    }

    setValue(value) {
        console.debug('PrefectureIndex#setValue');

        if (value !== undefined) {
            this.value = value;
        }

        // Set value of select element and trigger change event
        this.el.value = this.value;
        this.$el.trigger('change');
    }
}
