import { Collection } from 'backbone';
import config from '@/js/app/config';
import OwnerModel from '../models/owner';

export default class OwnersCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'owners';

        // Reference to this collection's model.
        this.model = OwnerModel;
    }
}
