import { Collection } from 'backbone';
import config from '@/js/app/config';
import ProductTypeModel from '../models/product-type';

export default class ProductTypesCollection extends Collection {
    preinitialize() {
        // Initialize defaults
        this.url = config.api.url + 'product-types';

        this.model = ProductTypeModel;
    }
}
