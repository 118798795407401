import { View } from 'backbone';
import RoomsCollection from '@/js/app/room/collections/rooms';
import RoomModel from '@/js/app/room/models/room';
import RoomCntrView from '@/js/app/room/views/cntr';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import template from '../templates/unit.hbs';

export default class UnitView extends View {
    preinitialize() {
        // Initialize defaults
        this.events = {
            'change #field-shop-edit': this.shopChange,
            'change #field-room-edit': this.roomChange,
        };

        this.subviews = {
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop-edit"
            }),

            roomcntr: new RoomCntrView({
                collection: new RoomsCollection,
                field_id: "field-room-edit"
            }),
        };

        this.model = new RoomModel;
    }

    render() {
        console.debug('Unit#render');

        this.el.innerHTML = template();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();

        this.subviews.roomcntr.setElement(this.el.querySelector('#divRoom')).render();

        this.$el.find('#field-shop-edit').select2({
            theme: 'bootstrap4',
        });
        this.$el.find('#field-room-edit').select2({
            theme: 'bootstrap4',
        });

        return this;
    }

    shopChange(e) {
        console.debug('Unit#shopChange');

        if (e instanceof Object) {
            e.preventDefault();

            // Initiate fetch with form data
            this.subviews.roomcntr.collection.fetch({
                data: {
                    shop_id: e.target.value
                },
            });
        }
    }

    roomChange(e) {
        console.debug('Unit#roomChange');

        if (e instanceof Object) {
            e.preventDefault();

            // Set id of model then trigger fetch
            this.model.set('id', e.target.value).fetch({
                data: {
                    inclusions: ['shop']
                }
            });
        }
    }

    setValue(shopId, roomId) {
        this.subviews.shopcntr.setValue(shopId);
        this.subviews.roomcntr.setValue(roomId);
    }
}
