import { View } from 'backbone';
import dayjs from 'dayjs';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import OwnerCntrView from '@/js/app/owners/views/cntr';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import RentalDiscountCollection from './collection';
import template from './templates/index.hbs';
import tableLoadingTemplate from './templates/table-loading.hbs';
import tableTemplate from './templates/table.hbs';

export default class RentalDiscountView extends View {
    preinitialize() {
        this.className = 'container';
        this.collection = new RentalDiscountCollection();
        this.events = {
            'change [name="owner_id"]': this.handleOwnerIdChange,
            'change [name="shop_id"]': this.handleShopIdChange,
            'click [data-action="export"]': this.handleExportClick,
        };
        this.subviews = {
            ownercntr: new OwnerCntrView({
                hasAll: true
            }),
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id",
                hasAll: true
            })
        };
        this.shopPerspective = false;
    }

    initialize() {
        this.listenTo(this.collection, 'sync', this.renderTable);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.renderTableLoading);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'error', function () {
            this.el.querySelector('#reportList').innerHTML = tableTemplate({
                shopPerspective: this.shopPerspective,
                rentalDiscount: false
            });
        });
    }

    render() {
        console.debug('RentalDiscount#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.collection.fetch();

        this.subviews.ownercntr.setElement(this.el.querySelector('#divOwners')).render();
        this.subviews.ownercntr.collection.fetch();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });

        return this;
    }

    renderTableLoading() {
        console.debug('RentalDiscount#renderTableLoading');

        this.el.querySelector('#reportList').innerHTML = tableLoadingTemplate({
            shopPerspective: this.shopPerspective
        });

        return this;
    }

    renderTable() {
        console.debug('RentalDiscount#renderTable');

        const rentalDiscountReport = this.collection.toJSON();

        this.el.querySelector('#reportList').innerHTML = tableTemplate({
            shopPerspective: this.shopPerspective,
            rentalDiscount: rentalDiscountReport
        });

        // Transform table to DataTable
        this.$el.find('table').DataTable({
            searching: false,
            paging: false,
            info: false,
            order: [0, 'desc'],
        });

        return this;
    }

    handleOwnerIdChange(e) {
        console.debug('RentalDiscount#handleOwnerIdChange');

        const value = e.currentTarget.value;

        this.shopPerspective = false;

        if (value === 'all') {
            this.collection.fetch();
            this.subviews.shopcntr.collection.fetch();
        } else {
            const data = {
                owner_id: Number(value)
            };

            this.collection.fetch({ data });
            this.subviews.shopcntr.collection.fetch({ data });
        }

        return this;
    }

    handleShopIdChange(e) {
        console.debug('RentalDiscount#handleShopIdChange');

        const shopValue = e.currentTarget.value;
        const ownerValue = this.el.querySelector('#field-owner_id').value;
        const data = {};

        if (ownerValue !== 'all') {
            data.owner_id = Number(ownerValue);
        }

        if (shopValue === 'all') {
            this.shopPerspective = false;
        } else {
            this.shopPerspective = true;
            data.shop_id = Number(shopValue);
        }

        this.collection.fetch({ data });

        return this;
    }

    handleExportClick() {
        console.debug('RentalDiscount#handleExportClick');

        const ownerSelectEl = this.el.querySelector('#field-owner_id');
        const ownerValue = ownerSelectEl.value;
        const ownerText = ownerSelectEl.selectedOptions[0].innerText;

        const shopSelectEl = this.el.querySelector('#field-shop_id');
        const shopValue = shopSelectEl.value;
        const shopText = shopSelectEl.selectedOptions[0].innerText;

        const params = {};
        let title = 'All Shops';
        if (shopValue !== 'all') {
            params.shop_id = Number(shopValue);
            title = shopText;
        } else if (ownerValue !== 'all') {
            params.owner_id = Number(ownerValue);
            title = ownerText
        }

        const url = new URL(`${config.api.url}reports/rental-discount`);
        url.search = new URLSearchParams(params);

        oauth2Client.xlsxDownload(
            url,
            { method: 'GET' },
            { filename: `Rental Discount Report (${title}) ${dayjs().format('YYYY-MM-DD')}.xlsx`}
        );
    }
}
