var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias2(__default(require("../../handlebars-helpers/t.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":15}}}))
    + "</h1>\n<hr class=\"my-3\">\n\n<form id=\"frmSearch\" autocomplete=\"off\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-owner_id\" class=\"col-form-label col-md-3 col-lg-4\">"
    + alias2(__default(require("../../handlebars-helpers/t.js")).call(alias1,"reports.owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":89},"end":{"line":9,"column":110}}}))
    + "</label>\n                    <div id=\"divOwners\" class=\"col-md\"></select>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-date\" class=\"col-form-label col-md-3 col-lg-4\">\n                        "
    + alias2(__default(require("../../handlebars-helpers/t.js")).call(alias1,"reports.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":44}}}))
    + "\n                    </label>\n                    <div class=\"col-md\" id=\"dateCntr\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</form>\n\n<div id=\"reportList\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});