import { Collection, history } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import ProductTypesCollection from '@/js/app/product-types/collections/product-types';
import ProductTypeCntrView from '@/js/app/product-types/views/cntr';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import yuyan from '@/js/app/yuyan';
import ProductStockModel from '../models/product-stock';
import addTemplate from '../templates/edit.hbs';
import listTemplate from '../templates/list.hbs';

export default class ProductStockListAddView extends CardView {
    preinitialize(options = {}) {
        // Initialize defaults
        this.title = yuyan.t('productStock.list');

        this.events = {
            'click [data-action="add"]': this.renderAdd,
            'click [data-action="cancel"]': this.renderList,
            'submit form#frmEditProductStock': this.saveAdd
        };

        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];

        if (options.buttons === 'hide') {
            this.buttons = [];
        }

        this.showProductType = options.showProductType;
        this.productTypeModel = options.productTypeModel;
        this.productStockModel = new ProductStockModel(options.modelAttributes);
    }

    initialize() {
        this.subviews = {
            productTypeCntr: new ProductTypeCntrView({
                collection: new ProductTypesCollection,
                field_id: "field-product_type_id"
            }),
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id"
            }),
        };
        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });

        // When collection updates/resets; renderList
        this.listenTo(this.collection, 'update reset', this.renderList);
    }

    render() {
        console.debug('ProductStockListAdd#render');

        this.renderCard();

        return this;
    }

    renderList() {
        console.debug('ProductStockListAdd#renderList');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('addBtnCntr')
            .renderListTemplate();

        return this;
    }

    renderListTemplate() {
        console.debug('ProductStockListAdd#renderListTemplate');

        // Attach listTemplate to cardBody with product stock collection
        this.cardBody.innerHTML = listTemplate({
            productStocks: this.collection.toJSON(),
            showProductType: this.showProductType
        });

        return this;
    }

    renderAdd() {
        console.debug('ProductStockListAdd#renderAdd');

        this.hideButtonContainer('addBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderAddTemplate();

        return this;
    }

    renderAddTemplate() {
        console.debug('ProductStockListAdd#renderAddTemplate');

        // Attach addTemplate to el
        this.cardBody.innerHTML = addTemplate();

        // Set el of address view as #divShop then render
        this.subviews.productTypeCntr.setElement(this.el.querySelector('#divProductType')).render();
        this.subviews.productTypeCntr.collection.fetch();
        this.$el.find('#field-product_type_id').select2({
            theme: 'bootstrap4',
        });

        // Set el of address view as #divShop then render
        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop_id').select2({
            theme: 'bootstrap4',
        });

        if (this.productTypeModel) {
            this.subviews.productTypeCntr.setValue(this.productTypeModel.id);

            // Disable shop and unit select fields
            this.$el.find('#divProductType select').prop('disabled', true);
        }
    }

    saveAdd(e) {
        console.debug('ProductStockListAdd#saveAdd');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Trigger save of productStock with data as attributes
            this.productStockModel.save(data, {
                // On success: navigate
                success: this.navigate,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    errorOnSave(model, response) {
        console.debug('ProductStockListAdd#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    navigate(model) {
        console.debug('ProductStockListAdd#navigate');

        // Navigate to product-stocks/:id
        history.navigate('product-stocks/' + model.id, { trigger: true });
    }
}
