var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"d-flex justify-content-between align-items-center\">\n    <h1 class=\"mb-0\">TRT収入取引</h1>\n\n    <div id=\"spinner\" class=\"spinner-border text-primary d-none\" role=\"status\">\n        <span class=\"sr-only\">Loading...</span>\n    </div>\n</div>\n\n<hr class=\"my-3\">\n\n<form id=\"frmSearch\" autocomplete=\"off\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-shop-id\" class=\"col-form-label col-md-3 col-lg-4\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":88},"end":{"line":16,"column":105}}}))
    + "</label>\n                    <div id=\"divShop\" class=\"col-md\"></div>\n                </div>\n            </div>\n\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-date\" class=\"col-form-label col-md-3 col-lg-4\">\n                        "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":24,"column":44}}}))
    + "\n                    </label>\n                    <div class=\"col-md\" id=\"dateCntr\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</form>\n\n<div id=\"actions\" class=\"d-flex mb-3\"></div>\n\n<div id=\"reportList\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});