import { View } from 'backbone';
import template from '../templates/welcome.hbs';

export default class WelcomeDashboardView extends View {
    preinitialize() {
        this.className = 'container';
    }

    render() {
        console.debug('WelcomeDashboard#render');

        this.el.innerHTML = template();

        return this;
    }
}
