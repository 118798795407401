import { Model } from 'backbone';
import config from '@/js/app/config';

export default class ShopFileModel extends Model {
    preinitialize(_, options) {
        this.shopId = options.shopId || options.collection.shopId || null;
        this.fileType = options.fileType || options.collection.fileType || null;
        this.idAttribute = 'filename';

        this.urlRoot = () => config.api.url + 'shops/' + this.shopId + '/files/' + this.fileType;
    }
}
