import { View } from 'backbone';
import dayjs from 'dayjs';
import config from '@/js/app/config';
import SelectYearMonth from '@/js/app/generic/views/select-year-month';
import oauth2Client from '@/js/app/oauth2-client';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import actionsTemplate from '../templates/income-deals/actions.hbs';
import errorTemplate from '../templates/income-deals/error.hbs';
import template from '../templates/income-deals/index.hbs';
import tableTemplate from '../templates/income-deals/table.hbs';

export default class PmIncomeDealsView extends View {
    preinitialize() {
        this.className = 'container';
        this.events = {
            'change [name="shop_id"]': this.handleShopIdChange,
            'change [name="year"], [name="month"]': this.handleDateChange,
            'click [data-action="send"]': this.handleSendClick,
        };
        this.subviews = {
            shopCntrView: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id"
            }),
            selectYearMonth: new SelectYearMonth({
                minYear: 2021,
                includeNextYear: true,
                defaultPreviousMonth: true,
            }),
        };
        this.period = dayjs().subtract(1, 'month').format('YYYY.MM');
        this.shopId = null;
    }

    render() {
        console.debug('PmIncomeDeals#render');

        // Attach template to el
        this.el.innerHTML = template({
            title: this.title
        });

        // Set el of owner cntr view as #divOwners, render then fetch collection
        this.subviews.shopCntrView.setElement(this.el.querySelector('#divShop')).render().collection.fetch();

        // Attach select year/month subview to #dateCntr and render
        this.subviews.selectYearMonth.setElement(this.el.querySelector('#dateCntr')).render();

        return this;
    }

    renderTable(deals) {
        console.debug('PmIncomeDeals#renderTable');

        this.el.querySelector('#actions').innerHTML = actionsTemplate({
            isSending: false
        });

        // Attach table template to el
        this.el.querySelector('#reportList').innerHTML = tableTemplate({
            deals
        });
    }

    unrenderTable() {
        console.debug('PmIncomeDeals#unrenderTable');

        // Clear inner html of el
        this.el.querySelector('#reportList').innerHTML = '';
    }

    renderError(errorData) {
        console.debug('PmIncomeDeals#unrenderTable');

        // Clear inner html of el
        this.el.querySelector('#reportList').innerHTML = errorTemplate(errorData);

    }

    async handleSendClick() {
        console.debug('PmIncomeDeals#handleSendClick');

        this.el.querySelector('#actions').innerHTML = actionsTemplate({
            isSending: true
        });

        const response = await oauth2Client.fetch(`${config.api.url}shops/${this.shopId}/income-deals/${this.period}`, {
            method: 'POST'
        });

        if (response.ok) {
            this.renderTable(await response.json());
        }

        this.el.querySelector('#actions').innerHTML = actionsTemplate({
            isSending: false
        });
    }

    handleShopIdChange(e) {
        console.debug('PmIncomeDeals#handleShopIdChange');

        const shopValue = e.currentTarget.value;

        if (shopValue) {
            this.shopId = Number(shopValue);

            this.fetchDeals();
        } else {
            this.shopId = null;

            this.unrenderTable();
        }

        return this;
    }

    handleDateChange() {
        console.debug('PmIncomeDeals#handleDateChange');

        this.period = this.el.querySelector('[name="year"]').value + '.' + this.el.querySelector('[name="month"]').value;

        if (this.shopId) {
            this.fetchDeals();
        }
    }

    async fetchDeals() {
        console.debug('PmIncomeDeals#fetchDeals');

        this.el.querySelector('#spinner').classList.remove('d-none');

        this.unrenderTable();

        const response = await oauth2Client.fetch(`${config.api.url}shops/${this.shopId}/income-deals/${this.period}`, {});

        if (response.ok) {
            this.renderTable(await response.json());
        } else {
            const errorData = await response.json();

            if (errorData.name === 'IncomeDealCashflowNoteError') {
                this.renderError({
                    title: errorData.name,
                    cashflows: errorData.cashflows,
                });
            } else {
                this.renderError({
                    title: errorData.name,
                    message: errorData.message,
                });
            }
        }

        this.el.querySelector('#spinner').classList.add('d-none');
    }
}
