import { Model } from 'backbone';
import config from '@/js/app/config';

export default class ContractBillModel extends Model {
    preinitialize() {
        this.idAttribute = 'period';
    }

    initialize(attrs, options) {
        this.contractId = options.contractId || options.collection.contractId || null;
    }

    urlRoot() {
        return config.api.url + 'contracts/' + this.contractId + '/bills';
    }
}
