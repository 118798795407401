var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td><a href=\"#\" data-action=\"download\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"file") : depth0)) != null ? lookupProperty(stack1,"filename") : stack1), depth0))
    + "</a></td>\n<td>"
    + alias2(__default(require("../../../handlebars-helpers/fileSize.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"file") : depth0)) != null ? lookupProperty(stack1,"size") : stack1),{"name":"fileSize","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":26}}}))
    + "</td>\n<td>"
    + alias2(__default(require("../../../handlebars-helpers/localtime.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"file") : depth0)) != null ? lookupProperty(stack1,"created_at") : stack1),"YYYY-MM-DD HH:mm:ss",{"name":"localtime","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":55}}}))
    + "</td>\n<td>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"file") : depth0)) != null ? lookupProperty(stack1,"created_by_name") : stack1), depth0))
    + "</td>\n<td>\n    <button type=\"button\" class=\"close\" aria-label=\"Close\" data-action=\"delete\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</td>";
},"useData":true});