import formatCurrency from '../formatCurrency';

export default function leasingCommissionTotal(rows) {
    const total = {
        contracts_commissioned_initial: 0,
        contracts_commissioned_final: 0,
        contracts_commissioned_total: 0,
        commission: 0
    };

    for (let row of rows) {
        total.contracts_commissioned_initial += row.contracts_commissioned_initial;
        total.contracts_commissioned_final += row.contracts_commissioned_final;
        total.contracts_commissioned_total += row.contracts_commissioned_total;
        total.commission += row.commission;
    }

    total.commission = formatCurrency(total.commission);

    return '<td class="text-right">' + total.contracts_commissioned_initial + '</td>' +
        '<td class="text-right">' + total.contracts_commissioned_final + '</td>' +
        '<td class="text-right">' + total.contracts_commissioned_total + '</td>' +
        '<td class="text-right">' + total.commission + '</td>';
}
