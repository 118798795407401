import { Collection, history } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import ProductTypeModel from '../models/product-type';
import addTemplate from '../templates/edit.hbs';
import listTemplate from '../templates/list.hbs';

export default class ProductTypeListAddView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('productType.list');

        this.events = {
            'click [data-action="add"]': this.renderAdd,
            'click [data-action="cancel"]': this.renderList,
            'submit form#frmEditProductType': this.saveAdd
        };

        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];

        this.productTypeModel = new ProductTypeModel;
    }

    initialize() {
        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });

        // When collection updates/resets; renderList
        this.listenTo(this.collection, 'update reset', this.renderList);
    }

    render() {
        console.debug('ProductTypeListAdd#render');

        this.renderCard();

        return this;
    }

    renderList() {
        console.debug('ProductTypeListAdd#renderList');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('addBtnCntr')
            .renderListTemplate();

        return this;
    }

    renderListTemplate() {
        console.debug('ProductTypeListAdd#renderListTemplate');

        // Attach listTemplate to cardBody with product type collection
        this.cardBody.innerHTML = listTemplate({
            productTypes: this.collection.toJSON(),
        });

        return this;
    }

    renderAdd() {
        console.debug('ProductTypeListAdd#renderAdd');

        this.hideButtonContainer('addBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            // Attach addTemplate to cardBody
            .cardBody.innerHTML = addTemplate();

        return this;
    }

    saveAdd(e) {
        console.debug('ProductTypeListAdd#saveAdd');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Trigger save of productType with data as attributes
            this.productTypeModel.save(data, {
                // On success: navigate
                success: this.navigate,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    errorOnSave(model, response) {
        console.debug('ProductTypeListAdd#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    navigate(model) {
        console.debug('ProductTypeListAdd#navigate');

        // Navigate to product-types/:id
        history.navigate('product-types/' + model.id, { trigger: true });
    }
}
