var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loginHistory") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":34,"column":25}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias2(__default(require("../../../handlebars-helpers/localtime.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"timestamp") : depth0),"YYYY-MM-DD HH:mm:ss",{"name":"localtime","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":74}}}))
    + "</td>\n                    <td>\n                        <span class=\"badge "
    + alias2(__default(require("../../../handlebars-helpers/customer/loginEventClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event") : depth0),{"name":"customer/loginEventClass","hash":{},"data":data,"loc":{"start":{"line":28,"column":43},"end":{"line":28,"column":84}}}))
    + "\">"
    + alias2(__default(require("../../../handlebars-helpers/customer/loginEvent.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"event") : depth0),{"name":"customer/loginEvent","hash":{},"data":data,"loc":{"start":{"line":28,"column":86},"end":{"line":28,"column":122}}}))
    + "</span>\n                    </td>\n                    <td>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"ip_address") : depth0), depth0))
    + "</td>\n                    <td>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"country_code") : depth0), depth0))
    + "</td>\n                    <td>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"user_agent") : depth0), depth0))
    + "</td>\n                </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.login_history.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":64}}}))
    + "</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n    <div class=\"table-responsive\">\n        <table class=\"table table-sm table-hover text-nowrap\">\n            <thead>\n                <tr class=\"thead-light\">\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.login_history.timestamp",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":64}}}))
    + "</th>\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.login_history.event",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":60}}}))
    + "</th>\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.login_history.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":65}}}))
    + "</th>\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.login_history.country_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":67}}}))
    + "</th>\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.login_history.user_agent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":65}}}))
    + "</th>\n                </tr>\n            </thead>\n\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLoading") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":35,"column":27}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n    </div>\n</div>";
},"useData":true});