import { Collection } from 'backbone';
import config from '@/js/app/config';
import RoomHistoryModel from '../models/room-history';

export default class RoomHistoryCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'room-history';

        // Reference to this collection's model.
        this.model = RoomHistoryModel;
    }
}
