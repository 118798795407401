import { Collection } from 'backbone';
import config from '@/js/app/config';
import ShopFileModel from '../models/shop-file';

export default class ShopFilesCollection extends Collection {
    preinitialize(_, options) {
        this.shopId = options.shopId;
        this.fileType = options.fileType;

        this.url = () => config.api.url + 'shops/' + this.shopId + '/files/' + this.fileType;

        this.model = ShopFileModel;
    }

    comparator(model) {
        return new Date(model.get('created_at')).getTime();
    }
}
