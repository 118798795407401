var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"align-middle\">\n    "
    + alias2(__default(require("../../../../handlebars-helpers/localtime.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"version") : stack1),"YYYY-MM-DD HH:mm:ss",{"name":"localtime","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":53}}}))
    + "\n</td>\n<td class=\"align-middle\">\n    "
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"uploaded_by_name") : stack1), depth0))
    + "\n</td>\n<td class=\"align-middle\">\n    <button type=\"button\" class=\"btn btn-success\" title=\""
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.download",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":81}}}))
    + "\" data-action=\"download\">\n        <i class=\"fa fa-download\" aria-hidden=\"true\"></i>\n    </button>\n</td>";
},"useData":true});