var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.titles",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":27}}}))
    + "</h1>\n<hr class=\"my-3\">\n\n<form id=\"frmSearch\" autocomplete=\"off\">\n    <div class=\"container mb-3\">\n        <div class=\"form-group row\">\n            <label for=\"field-room_no\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":89},"end":{"line":7,"column":108}}}))
    + "</label>\n            <div class=\"col-sm col-md-3 col-lg-2\">\n                <input id=\"field-room_no\" name=\"id\" type=\"text\" class=\"form-control\">\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field-name\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":86},"end":{"line":14,"column":107}}}))
    + "</label>\n            <div class=\"col-sm col-lg-4\">\n                <input id=\"field-name\" name=\"name\" type=\"text\" class=\"form-control\">\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field-shop\" class=\"col-form-label col-md-4 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.shop",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":77},"end":{"line":21,"column":98}}}))
    + "</label>\n            <div class=\"col-sm col-lg-2\">\n                <div id=\"divShop\"></div>\n            </div>\n\n            <label for=\"field-payment_method\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":96},"end":{"line":26,"column":133}}}))
    + "</label>\n            <div class=\"col-sm col-lg-2\">\n                <select id=\"field-payment_method\" name=\"payment_method\" class=\"form-control\">\n                    <option></option>\n                    <option value=\"credit\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.credit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":43},"end":{"line":30,"column":81}}}))
    + "</option>\n                    <option value=\"direct_debit\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.direct_debit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":49},"end":{"line":31,"column":93}}}))
    + "</option>\n                    <option value=\"money_transfer\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.money_transfer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":51},"end":{"line":32,"column":97}}}))
    + "</option>\n                </select>\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field.applied_month\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.applied_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":95},"end":{"line":38,"column":125}}}))
    + "</label>\n            <div class=\"col-sm-3 col-lg-2\">\n                <input id=\"field.applied_month\" name=\"applied_month\" type=\"month\" class=\"form-control\">\n            </div>\n\n            <label for=\"field.contracted_month\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.contracted_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":98},"end":{"line":43,"column":131}}}))
    + "</label>\n            <div class=\"col-sm-3 col-lg-2\">\n                <input id=\"field.contracted_month\" name=\"contracted_month\" type=\"month\" class=\"form-control\">\n            </div>\n\n            <label for=\"field.start_month\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.start_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":93},"end":{"line":48,"column":121}}}))
    + "</label>\n            <div class=\"col-sm-3 col-lg-2\">\n                <input id=\"field.start_month\" name=\"start_month\" type=\"month\" class=\"form-control\">\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field.end_month\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.end_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":91},"end":{"line":55,"column":117}}}))
    + "</label>\n            <div class=\"col-sm-3 col-lg-2\">\n                <input id=\"field.end_month\" name=\"end_month\" type=\"month\" class=\"form-control\">\n            </div>\n\n            <label for=\"field.withdrawn_month\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.withdrawn_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":97},"end":{"line":60,"column":129}}}))
    + "</label>\n            <div class=\"col-sm-3 col-lg-2\">\n                <input id=\"field.withdrawn_month\" name=\"withdrawn_month\" type=\"month\" class=\"form-control\">\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":69},"end":{"line":67,"column":98}}}))
    + "</label>\n            <div class=\"col-sm\">\n                <div class=\"d-flex flex-wrap border-bottom\">\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"pending\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.pending.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":106},"end":{"line":72,"column":143}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"approved_us\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.approved_us.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":110},"end":{"line":77,"column":151}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"documents_sent\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.documents_sent.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":113},"end":{"line":82,"column":157}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"applied\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":106},"end":{"line":87,"column":143}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"approved_bank\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.approved_bank.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":112},"end":{"line":92,"column":155}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"initial_payment_confirmed\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.initial_payment_confirmed.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":124},"end":{"line":97,"column":179}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"keys_sent\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.keys_sent.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":108},"end":{"line":102,"column":147}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"active\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.active.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":105},"end":{"line":107,"column":141}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n                <div class=\"d-flex flex-wrap border-bottom\">\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"withdraw_requested\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.withdraw_requested.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":117},"end":{"line":114,"column":165}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"withdrawn\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.withdrawn.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":108},"end":{"line":119,"column":147}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n                <div class=\"d-flex flex-wrap\">\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"cancel_applied\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":113},"end":{"line":126,"column":157}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"billing_stopped\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.billing_stopped.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":131,"column":114},"end":{"line":131,"column":159}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"cancel_short_settled\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_short_settled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":136,"column":119},"end":{"line":136,"column":169}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"cancel_settled\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_settled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":113},"end":{"line":141,"column":157}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"keys_returned\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.keys_returned.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":146,"column":112},"end":{"line":146,"column":155}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"cancelled\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancelled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":108},"end":{"line":151,"column":147}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field-tags\" class=\"col-form-label col-md-4 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":159,"column":77},"end":{"line":159,"column":104}}}))
    + "</label>\n            <div class=\"col-sm\">\n                <div class=\"d-flex flex-wrap\">\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"tags\" type=\"checkbox\" class=\"form-check-input\" value=\"size_mismatch\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.size_mismatch",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":164,"column":110},"end":{"line":164,"column":145}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"tags\" type=\"checkbox\" class=\"form-check-input\" value=\"flexible_discount\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.flexible_discount",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":169,"column":114},"end":{"line":169,"column":153}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"tags\" type=\"checkbox\" class=\"form-check-input\" value=\"proxy_contract\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.proxy_contract",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":174,"column":111},"end":{"line":174,"column":147}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"tags\" type=\"checkbox\" class=\"form-check-input\" value=\"payment_method_change\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.payment_method_change",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":118},"end":{"line":179,"column":161}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"tags\" type=\"checkbox\" class=\"form-check-input\" value=\"move_campaign\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.move_campaign",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":184,"column":110},"end":{"line":184,"column":145}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"tags\" type=\"checkbox\" class=\"form-check-input\" value=\"transfer_campaign\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.transfer_campaign",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":189,"column":114},"end":{"line":189,"column":153}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"tags\" type=\"checkbox\" class=\"form-check-input\" value=\"referral_campaign\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.tags.referral_campaign",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":194,"column":114},"end":{"line":194,"column":153}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n\n\n        <div class=\"row justify-content-end align-items-center\">\n            <div class=\"col-md-auto\">\n                <button type=\"submit\" class=\"btn btn-primary custom-btn\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":73},"end":{"line":205,"column":95}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>\n\n<div id=\"listAddContract\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});