import formToObject from '@/js/libs/form-utils';
import ModalView from '@/js/app/generic/views/modal';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/giraffe-modal.hbs';

export default class ShopGiraffeModalView extends ModalView {
    preinitialize() {
        this.events = {
            'submit form': this.handleFormSubmit,
        };
        this.preinitializeModal();
    }

    render() {
        console.debug('ShopGiraffeModal#render');

        this.renderModal().modalContent.innerHTML = template();

        return this;
    }

    handleFormSubmit($e) {
        console.debug('ShopGiraffeModal#handleFormSubmit');

        if ($e instanceof Object) {
            $e.preventDefault();
            $e.stopPropagation();

            const form = $e.currentTarget;
            form.classList.add('was-validated');

            this.register(form);
        }
    }

    async register(form) {
        // Hide giraffe error
        const errorEl = this.el.querySelector('#giraffeRegistrationError');
        errorEl.classList.add('d-none');

        // Trim field value
        const giraffeFacilityIdInput = form.querySelector('[name="giraffe_facility_id"]');
        giraffeFacilityIdInput.value = giraffeFacilityIdInput.value.trim();

        // If form is invalid; return
        if (form.checkValidity() === false) {
            return;
        }

        const data = formToObject(form);

        try {
            const responseData = await oauth2Client.fetchJSON(this.model.url() + '/register-giraffe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            this.model.set(responseData);

            this.$el.modal('hide');
        } catch (responseData) {
            let errorMessage = '';

            if (responseData.name === 'GiraffeError') {
                errorMessage = `Giraffe Error: ${responseData.code} - ${responseData.message}`;
            } else {
                errorMessage = responseData.message;
            }

            // Set giraffe error text and show
            errorEl.innerText = errorMessage;
            errorEl.classList.remove('d-none');
        }
    }
}
