import yuyan from '@/js/app/yuyan';

export default function cancelSource(source) {
    const translationKey = 'contract.cancel_source.' + source;

    if (yuyan.has(translationKey)) {
        return yuyan.t(translationKey);
    } else if (source) {
        return source;
    }
}
