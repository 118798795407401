export default function zendeskTicketStatus(code) {
    switch (code) {
        case 'new':
            return '新規';
        case 'open':
            return 'オープン';
        case 'pending':
            return '保留中';
        case 'hold':
            return '待機中';
        case 'solved':
            return '解決済み';
        case 'closed':
            return '終了';
        default:
            return '';
    }
}
