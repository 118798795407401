import yuyan from '@/js/app/yuyan';

export default function cancelReason(reason) {
    const translationKey = 'contract.cancel_reason.' + reason;

    if (yuyan.has(translationKey)) {
        return yuyan.t(translationKey);
    } else if (reason) {
        return reason;
    }
}
