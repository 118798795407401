import { View } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import objectToForm from '@/js/libs/object-to-form';
import ErrorModalView from '@/js/app/error/views/modal';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import CustomersCollection from '../collections/customers';
import template from '../templates/index.hbs';
import ListAddCustomerView from './list-add';

export default class CustomerIndexView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';
        this.events = {
            'submit form#frmSearch': this.search,
        };
        this.subviews = {
            listAddCustomerCard: new ListAddCustomerView({
                collection: new CustomersCollection,
            }),

            shopCntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop"
            })
        };
    }

    render() {
        console.debug('CustomerIndex#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of customer list view as #listAddCustomer then render
        this.subviews.listAddCustomerCard.setElement(this.el.querySelector('#listAddCustomer')).render();

        this.subviews.shopCntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopCntr.collection.fetch();

        // Get customer search conditions from sessionStorage
        this.searchConditions = JSON.parse(sessionStorage.getItem('customerSearchCondition'));
        // If search conditions exists
        if (this.searchConditions) {
            objectToForm(this.searchConditions, this.el);

            this.subviews.shopCntr.setValue(this.searchConditions.shop_id);
        }

        // Initiate search
        this.search();

        return this;
    }

    search(e) {
        console.debug('CustomerIndex#search');

        // Initialize data
        let data = {
            fields: 'id,customer_type,first_name,last_name,company_name,blacklist,zendesk_id',
            inclusions: ['address', 'shop']
        };

        if (e instanceof Object) {
            e.preventDefault();

            // Get form data
            const formData = formToObject(e.target);

            data = {
                ...data,
                ...formData
            };

            // Store search conditions to sessionStorage
            sessionStorage.setItem('customerSearchCondition', JSON.stringify(data));
        }
        // Else if searchConditions exist, use as data
        else if (this.searchConditions) {
            data = this.searchConditions;
        }

        // Trigger fetch of customer collection with data as query
        this.subviews.listAddCustomerCard.collection.search({
            data: data,
            reset: true,
            // On error: errorOnSearch
            error: this.errorOnSearch
        });
    }

    errorOnSearch(_, response) {
        console.debug('CustomerIndex#errorOnSearch');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
