import { Collection } from 'backbone';
import config from '@/js/app/config';
import ShopCashflowModel from '../models/shop-cashflow';

export default class ShopCashflowCollection extends Collection {
    preinitialize() {
        // Reference to this collection's model.
        this.model = ShopCashflowModel;
    }

    initialize(models, options) {
        if (options) {
            this.shopId = options.shopId;
        }
    }

    url() {
        return config.api.url + 'shops/' + this.shopId + '/cashflows';
    }
}
