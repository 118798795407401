import { Events } from 'backbone';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';

export default class RoomOccupancyCollection {

    constructor() {
        _.extend(this, Events)
        this.data = null;
    }

    fetch(options) {
        this.trigger('request');

        oauth2Client.$ajax(_.extend({
            url: config.api.url + 'reports/room-occupancy/',
            dataType: 'json',
        }, options))
            .then((response) => {
                this.data = response;

                this.trigger('sync');
            });
    }

    toJSON() {
        return this.data;
    }
}
