import { extend } from 'underscore';
import Polyglot from 'polyglot';

/**
 * Yuyan - Language manager
 * Maintains current language, supported languages, and translations (via Polyglot).
 * Detects language from browser, and correctly maps various Chinese regions to their associated scripts.
 * Also handles persisting/fetching chosen language from local storage.
 * @param {object} options
 */
export default class Yuyan {
    constructor(options) {
        /** @type {Polyglot} */
        this.polyglot = options.polyglot || new Polyglot;

        /** @type {string} */
        this.storageNamespace = options.storageNamespace || 'lang';

        /** @type {object} */
        this.supportedLanguages = extend({
            'en': 'English',
        }, options.supportedLanguages);

        /**
         * @type {string}
         * @private
         */
        this._currentLanguage = '';

        /**
         * @type {string}
         * @private
         */
        this._defaultLanguage = '';

        // Set default language
        this.defaultLanguage(options.defaultLanguage || 'en');

        // Set language
        this.language(options.language || localStorage.getItem(this.storageNamespace) || this.detectLanguage(navigator));
    }

    /**
     * Default language
     * @param {string} lang - Default language
     * @returns {object} Self
     */
    defaultLanguage(lang) {
        if (lang) {
            // Ensure parameter is string
            if (typeof lang !== 'string') {
                throw 'Paramter "lang" must be a string';
            }

            // If language not supported, fallback to default
            if (typeof this.supportedLanguages[lang] === 'undefined') {
                throw 'Cannot set default language to unsupported language';
            }

            this._defaultLanguage = lang;
        }
        return this._defaultLanguage;
    }

    /**
     * Get or set language code
     * @param {string} lang - Language code
     * @returns {string} Language code
     */
    language(lang) {
        if (lang) {
            // Ensure parameter is string
            if (typeof lang !== 'string') {
                throw 'Paramter "lang" must be a string';
            }

            // If language not supported, fallback to default
            if (typeof this.supportedLanguages[lang] === 'undefined') {
                lang = this._defaultLanguage;
            }

            // Set current language
            this._currentLanguage = lang;

            // Set app language in local storage
            localStorage.setItem(this.storageNamespace, this._currentLanguage);
        }
        return this._currentLanguage;
    }

    /**
     * Get language name
     * @param {string} lang - Language code
     * @returns {string} Language name
     */
    languageName(lang) {
        // If language not specified, use current language
        if (!lang) {
            lang = this._currentLanguage;
        }
        return this.supportedLanguages[lang];
    }

    /**
     * Detect language
     * @param {string} navigator - Browser instance
     * @returns {string} Browser language
     */
    detectLanguage(navigator) {
        const lang = navigator.language.toLowerCase();
        switch (lang) {
            case 'zh-tw':
            case 'zh-hk':
                return 'zh_hant';
            case 'zh-cn':
            case 'zh-sg':
                return 'zh_hans';
            default:
                return lang.split('-', 1)[0];
        }
    }

    /**
     * Load translations from URL
     * @param {object} options
     * @returns {promise}
     */
    loadTranslations(options) {
        // Merge options with defaults
        options = extend({
            cache: 'no-cache',
        }, options);

        // Add headers
        options.headers = extend({}, options.headers, {
            'Accept': 'application/json',
        });

        return fetch('/translations-' + this.language() + '.json', options)
            .then((response) => response.json())
            .then((translations) => {
                // Add translations
                this.addTranslations(translations);
            })
            .catch((err) => {
                // Log message
                console.error(err.message);

                // Throw new error
                throw new Error('Unable to load translations');
            });
    }

    /**
     * Add translations
     * @param {object} translations
     * @param {string} prefix
     */
    addTranslations(translations, prefix) {
        // Add translations to polyglot
        this.polyglot.extend(translations, prefix);
    }

    /**
     * Proxy to Polyglot.t
     */
    t() {
        return this.polyglot.t.apply(this.polyglot, arguments).replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    /**
     * Proxy to Polyglot.has
     */
    has() {
        return this.polyglot.has.apply(this.polyglot, arguments);
    }
}
