import { View } from 'backbone';
import ProductStocksCollection from '@/js/app/product-stocks/collections/product-stocks';
import ProductStockListAddView from '@/js/app/product-stocks/views/list-add';
import template from '../templates/detail.hbs';
import ProductTypeReadUpdateView from './read-update';

export default class ProductTypeDetailView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';
    }

    initialize() {
        this.subviews = {
            productTypeCard: new ProductTypeReadUpdateView({
                model: this.model,
            }),

            listAddProductStockCard: new ProductStockListAddView({
                modelAttributes: {
                    product_type_id: this.model.id
                },
                productTypeModel: this.model,
                collection: new ProductStocksCollection
            })
        };

        // When model changes name; updateTitle
        this.listenTo(this.model, 'change:name', this.updateTitle);

        // When model changes is_intangible; renderStocks
        this.listenTo(this.model, 'change:is_intangible', this.renderStocks);
    }

    render() {
        console.debug('ProductTypeDetail#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of product type detail view as #details then render
        this.subviews.productTypeCard.setElement(this.el.querySelector('#details')).render();

        // Set el of product type list view as #listAddProductStock
        this.subviews.listAddProductStockCard.setElement(this.el.querySelector('#listAddProductStock'))

        return this;
    }

    updateTitle(model, value) {
        console.debug('ProductTypeDetail#updateTitle');

        // Change text of #title based on value
        this.el.querySelector('#title').innerText = value;
    }

    renderStocks(model, value) {
        console.debug('ProductTypeDetail#renderStocks');

        if (!value) {
            // Render listAddProductStockCard subview and fetch product stocks
            this.subviews.listAddProductStockCard.render().collection.fetch({
                data: {
                    product_type_id: this.model.id
                },
                reset: true
            });

            this.el.querySelector('#listAddProductStock').classList.remove('d-none');
        } else {
            this.el.querySelector('#listAddProductStock').classList.add('d-none');
        }
    }
}
