'use strict';

import Backbone from 'backbone';
import oAuth2Client from './app/oauth2-client';
import Router from './app/router';
import User from './app/user';
import yuyan from './app/yuyan';
import { Workbox, messageSW } from 'workbox-window';
import UpdateAlertView from './app/layout/views/update-alert';
import config from './app/config';
import '../scss/bootstrap.scss';
import '../css/login.css';
import '../css/select2-bootstrap.min.css';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const appInsights = new ApplicationInsights({
    config: {
        connectionString: config.applicationInsights.connectionString,
        disableFetchTracking: false,
        maxAjaxCallsPerView: -1,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
    }
});

appInsights.loadAppInsights();

appInsights.trackPageView();

appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags["ai.cloud.role"] = "Consus";
});

// Define required resources
const requiredResources = [
    yuyan.loadTranslations(),   // Load translations
];

jQuery(() => {

    // $.fn.select2.defaults.set( "theme", "bootstrap" );

    // Override AJAX in Backbone to oAuth2Client.$ajax
    Backbone.ajax = oAuth2Client.$ajax.bind(oAuth2Client);

    // If token exists, fetch user info
    if (oAuth2Client.hasToken()) {
        requiredResources.push(
            User.fetchInfo()
                .catch((err) => {
                    console.error(err.message);
                })
        );
    }

    // Create router
    new Router();

    // When all required resources are loaded
    Promise.all(requiredResources)
        .then(() => {
            // Start Backbone
            Backbone.history.start();
        });

});

// Check that service workers are supported
if ('serviceWorker' in navigator && config.enableServiceWorker) {
    const wb = new Workbox('/sw.js');
    let registration;

    // Assuming the user accepted the update, set up a listener
    // that will reload the page as soon as the previously waiting
    // service worker has taken control.
    let refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing) return;
        refreshing = true;
        window.location.reload();
    });

    const showSkipWaitingPrompt = () => {
        const updateAlert = new UpdateAlertView({
            el: document.getElementById('serviceWorkerUpdateCntr'),
            onUpdate: () => {
                if (registration && registration.waiting) {
                    // Send a message to the waiting service worker,
                    // instructing it to activate.
                    messageSW(registration.waiting, { type: 'SKIP_WAITING' });
                }
            }
        });

        updateAlert.render();
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', showSkipWaitingPrompt);
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

    wb.register().then(reg => {
        // Check for update every 1 hour
        setInterval(() => {
            reg.update();
        }, 3600000);

        registration = reg;
    });
}