import ModalView from '@/js/app/generic/views/modal';
import yuyan from '@/js/app/yuyan';
import template from '../templates/modal.hbs';

export default class ErrorModalView extends ModalView {
    preinitialize(options) {
        this.title = '';
        this.content = '';
        this.handleError(options.error);
        this.preinitializeModal();
    }

    render() {
        console.debug('ErrorModal#render');

        this.renderModal({ centered: true }).modalContent.innerHTML = template({
            title: this.title,
            content: this.content
        });

        return this;
    }

    handleError(error) {
        console.debug('ErrorModal#handleError');

        if (error.name === 'ValidationError') {
            this.title = yuyan.t('error.validation.title');
            this.content = this.processValidationErrorContent(error);
        } else if (error.name === 'ConflictError') {
            this.title = yuyan.t('error.conflict.title');
            this.content = yuyan.t(`error.conflict.${error.field.name}.${error.field.message}`);
        } else if (error.name === 'BillingRobotError') {
            this.title = yuyan.t('error.billing_robot.title');
            this.content = this.processBillingRobotErrorContent(error);
        } else if (error.name === 'UnprocessableEntityError') {
            this.title = yuyan.t('error.unprocessable_entity.title');
            this.content = yuyan.t(`error.unprocessable_entity.${error.entity}.${error.part}.${error.reason}`);
        } else if (error.name === 'GiraffeError') {
            this.title = yuyan.t('error.giraffe');
            this.content = `${error.code}: ${error.message}`;
        } else if (error.name === 'ConsusUiError') {
            this.title = yuyan.t('error.title');
            this.content = yuyan.t(`error.ui.${error.message}`);
        } else {
            this.title = yuyan.t('error.title');
            this.content = 'Something went wrong';
        }
    }

    processValidationErrorContent(error) {
        console.debug('ErrorModal#processValidationErrorContent');

        let content = '';

        error.fields.forEach(field => {
            content += `${field.name}: ${field.message}<br>`;
        });

        return content;
    }

    processBillingRobotErrorContent(error) {
        console.debug('ErrorModal#processBillingRobotErrorContent');

        let content = '';
        const tab = '&emsp;';

        if (Array.isArray(error.errors)) {
            error.errors.forEach((e, index) => {
                if (e instanceof Object) {
                    if (Array.isArray(e.individual)) {
                        content += `${index}:<br>${tab}individual:<br>`;

                        e.individual.forEach((individual, i) => {
                            if (individual instanceof Object) {
                                content += `${tab}${tab}${i}:${individual.code} (${individual.message})<br>`;
                            }
                        });
                    } else if (Array.isArray(e.payment)) {
                        content += `${index}:<br>${tab}payment:<br>`;
                        e.payment.forEach((payment, i) => {
                            if (payment instanceof Object) {
                                content += `${tab}${tab}${i}:${payment.code} (${payment.message})<br>`;
                            }
                        });
                    } else {
                        content += `${index}: ${e.code} - ${e.message}<br>`;
                    }
                }
            });
        } else {
            content = `${error.code}: ${error.message}`;
        }

        return content;
    }
}
