import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import ModalView from '@/js/app/generic/views/modal';
import SelectYearMonth from '@/js/app/generic/views/select-year-month';
import SpinnerView from '@/js/app/generic/views/spinner';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../../templates/cashflows/import-modal.hbs';

dayjs.extend(isBetween)

export default class PmCashflowsIncomesModalView extends ModalView {
    preinitialize() {
        // Initialize defaults
        this.events = {
            'click [data-action="import"]': this.handleImportClick
        };
        this.preinitializeModal();
        this.spinner = new SpinnerView();

        this.subviews = {
            selectYearMonth: new SelectYearMonth({
                minYear: 2020,
                defaultPreviousMonth: true,
            }),
        };
    }

    render() {
        console.debug('PmCashflowsIncomesModal#render');

        this.renderModal({ centered: true }).modalContent.innerHTML = template({
            title: 'Cashflows Incomes Import',
            buttonText: 'Import Incomes'
        });

        // Attach select year/month subview to #dateCntr and render
        this.subviews.selectYearMonth.setElement(this.el.querySelector('#dateCntr')).render();

        return this;
    }

    async handleImportClick() {
        console.debug('PmCashflowsIncomesModal#handleImportClick');

        this.el.querySelector('[data-action="import"]').disabled = true;
        this.spinner.spin(this.el.querySelector('[data-action="import"]'));

        const errorEl = this.el.querySelector('#importError');
        errorEl.classList.add('d-none');

        const year = this.el.querySelector('[name="year"]').value;
        const month = this.el.querySelector('[name="month"]').value;

        try {
            const responseData = await oauth2Client.fetchJSON(`${this.collection.url()}/import_incomes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    year,
                    month
                })
            });

            const date = dayjs(`${year}-${month}-01`);

            // Remove deleted models
            this.collection.remove(this.collection.filter(model => {
                const transactionDate = dayjs(model.get('transaction_date'));

                return model.get('income') > 0 && transactionDate.isBetween(date, date.endOf('month'), 'day', '[]');
            }));

            // Add imported cashflows
            this.collection.add(responseData);

            this.el.querySelector('[data-action="import"]').disabled = false;
            this.spinner.stop();
            this.$el.modal('hide');
        }
        catch (responseData) {
            if (responseData.name === 'NotFoundError') {
                errorEl.innerText = responseData.message;
            } else {
                errorEl.innerText = 'Something went wrong';
            }

            this.el.querySelector('[data-action="import"]').disabled = false;
            this.spinner.stop();
            errorEl.classList.remove('d-none');
        }
    }
}
