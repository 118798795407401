import { Collection } from 'backbone';
import config from '@/js/app/config';
import ContractDocumentModel from '../models/contract-document';

export default class ContractDocumentsCollection extends Collection {
    preinitialize() {
        // Reference to this collection's model.
        this.model = ContractDocumentModel;
    }

    initialize(models, options) {
        this.contractId = options.contractId;
        this.document = options.document;
    }

    url() {
        return config.api.url + 'contracts/' + this.contractId + '/documents/' + this.document;
    }

    comparator(model) {
        return new Date(model.get('version')).getTime();
    }
}
