var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"shop") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</td>\n                <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"shop") : depth0)) != null ? lookupProperty(stack1,"start_date") : stack1), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"months_open") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rooms") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"shop") : depth0)) != null ? lookupProperty(stack1,"initial_leased_rooms") : stack1), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rooms_occupied") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"room_occupancy") : depth0),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":76}}}))
    + "%</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"room_speed") : depth0),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":26,"column":39},"end":{"line":26,"column":72}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"room_occupancy_speed") : depth0),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":27,"column":39},"end":{"line":27,"column":82}}}))
    + "%</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap border\">\n        <thead>\n            <tr class=\"thead-light\">\n                <th>"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":37}}}))
    + "</th>\n                <th>"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.start_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":45}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.months_open",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":68}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":62}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.initial_leased_rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":39},"end":{"line":9,"column":77}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.rooms_occupied",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":71}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.room_occupancy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":71}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.room_speed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":67}}})) != null ? stack1 : "")
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.room_occupancy_speed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":39},"end":{"line":13,"column":77}}})) != null ? stack1 : "")
    + "</th>\n            </tr>\n        </thead>\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"leasingSpeed") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":29,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n        <tfoot>\n            <tr>\n                <td>TOTALS</td>\n                <td></td>\n                <td></td>\n                "
    + ((stack1 = __default(require("../../../handlebars-helpers/reports/leasingSpeedTotal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"leasingSpeed") : depth0),{"name":"reports/leasingSpeedTotal","hash":{},"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":36,"column":62}}})) != null ? stack1 : "")
    + "\n            </tr>\n        </tfoot>\n    </table>\n</div>";
},"useData":true});