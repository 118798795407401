var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>Rental Discount Report</h1>\n<hr class=\"my-3\">\n\n<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-owner_id\" class=\"col-form-label col-md-3 col-lg-4\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":85},"end":{"line":8,"column":106}}}))
    + "</label>\n\n                <div id=\"divOwners\" class=\"col-md\"></div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-shop_id\" class=\"col-form-label col-md-3 col-lg-4\">\n                    "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":37}}}))
    + "\n                </label>\n                <div id=\"divShop\" class=\"col-md\"></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"d-flex mb-2 justify-content-end\">\n    <button type=\"button\" class=\"btn btn-success\" data-action=\"export\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.export.excel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":71},"end":{"line":26,"column":99}}}))
    + "</button>\n</div>\n\n<div id=\"reportList\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});