var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n    <div class=\"table-responsive\">\n        <table class=\"table table-sm table-hover text-nowrap\">\n            <thead>\n                <tr class=\"thead-light\">\n                    <th>"
    + alias1(__default(require("../../../handlebars-helpers/t.js")).call(alias2,"file.generated_at",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":49}}}))
    + "</th>\n                    <th>"
    + alias1(__default(require("../../../handlebars-helpers/t.js")).call(alias2,"file.generated_by",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":49}}}))
    + "</th>\n                    <th></th>\n                </tr>\n            </thead>\n            <tbody data-slot=\"documents\"></tbody>\n        </table>\n    </div>\n</div>";
},"useData":true});