var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#customers\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.customers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":101},"end":{"line":19,"column":122}}}))
    + "</a>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#contracts\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.contracts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":101},"end":{"line":20,"column":122}}}))
    + "</a>";
},"5":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#product-contracts\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.product-contracts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":117},"end":{"line":21,"column":146}}}))
    + "</a>";
},"7":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#offenders\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.offenders",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":90},"end":{"line":22,"column":111}}}))
    + "</a>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.pm",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":29,"column":34}}}))
    + "\n                </a>\n\n                <div class=\"dropdown-menu\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.leasing_status",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":33,"column":26},"end":{"line":33,"column":69}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":33,"column":164}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.leasing_status",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":69}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":34,"column":166}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":35,"column":26},"end":{"line":35,"column":54}}}),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":35,"column":154}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":36,"column":26},"end":{"line":36,"column":54}}}),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":149}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.tenants",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":37,"column":26},"end":{"line":37,"column":62}}}),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":37,"column":141}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":38,"column":26},"end":{"line":38,"column":54}}}),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":38,"column":151}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports.price_check",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":39,"column":26},"end":{"line":39,"column":66}}}),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":39,"column":155}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/leasing-status\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":127},"end":{"line":33,"column":153}}}))
    + "</a>";
},"12":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/leasing-history\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-history",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":128},"end":{"line":34,"column":155}}}))
    + "</a>";
},"14":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/leasing-speed\" class=\"dropdown-item d-none\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-speed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":118},"end":{"line":35,"column":143}}}))
    + "</a>";
},"16":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/room-occupancy\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.room-occupancy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":112},"end":{"line":36,"column":138}}}))
    + "</a>";
},"18":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/tenant\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.tenant",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":112},"end":{"line":37,"column":130}}}))
    + "</a>";
},"20":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/rental-discount\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.rental-discount",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":113},"end":{"line":38,"column":140}}}))
    + "</a>";
},"22":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#reports/price-check\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.price-check",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":121},"end":{"line":39,"column":144}}}))
    + "</a>";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.reports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":47,"column":39}}}))
    + "\n                </a>\n\n                <div class=\"dropdown-menu\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":51,"column":26},"end":{"line":51,"column":49}}}),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":147}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":52,"column":26},"end":{"line":52,"column":49}}}),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":20},"end":{"line":52,"column":123}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":53,"column":26},"end":{"line":53,"column":49}}}),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":53,"column":129}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":54,"column":26},"end":{"line":54,"column":49}}}),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":20},"end":{"line":54,"column":129}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":55,"column":26},"end":{"line":55,"column":49}}}),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":55,"column":135}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm.download",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":56,"column":26},"end":{"line":56,"column":58}}}),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":144}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm.download",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":57,"column":26},"end":{"line":57,"column":58}}}),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":20},"end":{"line":57,"column":142}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":58,"column":26},"end":{"line":58,"column":54}}}),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":20},"end":{"line":58,"column":146}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/leasing-commission\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.leasing-commission",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":106},"end":{"line":51,"column":136}}}))
    + "</a>";
},"27":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/pm-fee\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.pm-fee",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":94},"end":{"line":52,"column":112}}}))
    + "</a>";
},"29":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/cashflows\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.cashflows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":97},"end":{"line":53,"column":118}}}))
    + "</a>";
},"31":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/unpaid-bills\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.unpaid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":100},"end":{"line":54,"column":118}}}))
    + "</a>";
},"33":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/income-deals\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.income-deals",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":100},"end":{"line":55,"column":124}}}))
    + "</a>";
},"35":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/owner-downloads\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.owner-pmr",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":112},"end":{"line":56,"column":133}}}))
    + "</a>";
},"37":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#pm/shop-downloads\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.shop-pmr",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":111},"end":{"line":57,"column":131}}}))
    + "</a>";
},"39":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#cs/cancelled-rooms\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.cancelled-rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":108},"end":{"line":58,"column":135}}}))
    + "</a>";
},"41":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#product-types\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.product-types",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":109},"end":{"line":69,"column":134}}}))
    + "</a>";
},"43":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#product-stocks\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.product-stocks",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":110},"end":{"line":70,"column":136}}}))
    + "</a>";
},"45":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#files\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":86},"end":{"line":71,"column":103}}}))
    + "</a>";
},"47":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#cs/zendesk-bulk\" class=\"dropdown-item\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"nav.zendesk-bulk",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":96},"end":{"line":72,"column":120}}}))
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n\n    <a class=\"navbar-brand\" href=\"#\">\n        <img alt=\"Consus\" src=\"/img/logo_trt_dark_2022.png\" width=\"200\">\n    </a>\n\n    <button type=\"button\" class=\"navbar-toggler\" data-toggle=\"collapse\" data-target=\"#nav\" aria-controls=\"navbarText\"\n        aria-expanded=\"false\" aria-label=\"Toggle navigation\">\n        <span class=\"navbar-toggler-icon\"></span>\n    </button>\n\n    <div id=\"nav\" class=\"collapse navbar-collapse\" aria-expanded=\"false\">\n        <ul class=\"navbar-nav ml-auto navbar-right\">\n            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.customer-relations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":50}}}))
    + "\n                </a>\n                <div class=\"dropdown-menu\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:customers",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":19,"column":26},"end":{"line":19,"column":56}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":133}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:contracts",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":20,"column":56}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":133}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:product_contracts",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":64}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":157}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":45}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":122}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:reports","consus:reports.leasing_status","consus:reports.tenants","consus:reports.price_check",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":132}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:pm","consus:pm.download","consus:reports",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":44,"column":18},"end":{"line":44,"column":79}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":61,"column":19}}})) != null ? stack1 : "")
    + "\n            <li class=\"nav-item dropdown\">\n                <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":65,"column":40}}}))
    + "\n                </a>\n                <div class=\"dropdown-menu\">\n                    <a class=\"dropdown-item\" href=\"#shops\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.shops",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":59},"end":{"line":68,"column":76}}}))
    + "</a>\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:product_types",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":69,"column":26},"end":{"line":69,"column":60}}}),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":20},"end":{"line":69,"column":145}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:product_types",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":70,"column":26},"end":{"line":70,"column":60}}}),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":20},"end":{"line":70,"column":147}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":71,"column":26},"end":{"line":71,"column":45}}}),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":20},"end":{"line":71,"column":114}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":72,"column":26},"end":{"line":72,"column":45}}}),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":20},"end":{"line":72,"column":131}}})) != null ? stack1 : "")
    + "\n                </div>\n            </li>\n\n            <li class=\"nav-item\"><a class=\"nav-link\" href=\"#logout\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.logout",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":68},"end":{"line":76,"column":86}}}))
    + "</a></li>\n        </ul>\n    </div>\n\n</div>";
},"useData":true});