import formatCurrency from '../formatCurrency';
import numeral from '../numeral';

export default function leasingStatusTotal(total, isEomShown, isTsuboShown) {
    total.area = numeral(total.area, '0.0');
    total.area_tsubo = numeral(total.area_tsubo, '0.0');
    total.target_rent_per_area = formatCurrency(total.target_rent_per_area);
    total.target_rent_per_area_tsubo = formatCurrency(total.target_rent_per_area_tsubo);
    total.rent_per_area = formatCurrency(total.rent_per_area);
    total.rent_per_area_tsubo = formatCurrency(total.rent_per_area_tsubo);
    total.room_occupancy = numeral(total.room_occupancy, '0.0');
    total.room_occupancy_eom = numeral(total.room_occupancy_eom, '0.0');
    total.target_max_rent = formatCurrency(total.target_max_rent);
    total.rent = formatCurrency(total.rent);
    total.rent_eom = formatCurrency(total.rent_eom);
    total.rent_occupancy = numeral(total.rent_occupancy, '0.0');
    total.rent_occupancy_eom = numeral(total.rent_occupancy_eom, '0.0');
    total.ltv_avg = formatCurrency(total.ltv_avg);

    let totalTableData = '<td class="text-right">' + total.area + '</td>' +
        '<td class="text-right">' + total.target_rent_per_area + '</td>' +
        '<td class="text-right">' + total.rent_per_area + '</td>';

    if (isTsuboShown) {
        totalTableData = '<td class="text-right">' + total.area_tsubo + '</td>' +
            '<td class="text-right">' + total.target_rent_per_area_tsubo + '</td>' +
            '<td class="text-right">' + total.rent_per_area_tsubo + '</td>';
    }

    totalTableData += '<td class="text-right">' + total.rooms + '</td>' +
        '<td class="text-right">' + total.contracts_signed + '</td>';

    if (isEomShown) {
        totalTableData +=
            '<td class="text-right">' + total.contracts_started_eom + '</td>' +
            '<td class="text-right">' + total.contracts_ended_eom + '</td>' +
            '<td class="text-right">' + total.rooms_occupied_eom + '</td>' +
            '<td class="text-right">' + total.room_occupancy_eom + '%</td>' +
            '<td class="text-right">' + total.target_max_rent + '</td>' +
            '<td class="text-right">' + total.rent_eom + '</td>' +
            '<td class="text-right">' + total.rent_occupancy_eom + '%</td>';
    } else {
        totalTableData +=
            '<td class="text-right">' + total.contracts_started + '</td>' +
            '<td class="text-right">' + total.contracts_ended + '</td>' +
            '<td class="text-right">' + total.rooms_occupied + '</td>' +
            '<td class="text-right">' + total.room_occupancy + '%</td>' +
            '<td class="text-right">' + total.target_max_rent + '</td>' +
            '<td class="text-right">' + total.rent + '</td>' +
            '<td class="text-right">' + total.rent_occupancy + '%</td>';
    }

    return totalTableData +
        '<td class="text-right">' + total.ltv_avg + '</td>';
}
