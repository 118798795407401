import yuyan from '@/js/app/yuyan';

export default function todo(status, paymentMethod) {
    if (status) {
        if (paymentMethod && yuyan.has('contract.status.' + status + '.todo.' + paymentMethod)) {
            return yuyan.t('contract.status.' + status + '.todo.' + paymentMethod);
        } else {
            return yuyan.t('contract.status.' + status + '.todo');
        }
    }
}
