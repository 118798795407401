import { View } from 'backbone';
import config from '@/js/app/config';
import ListContractDocumentView from '@/js/app/contract-document/views/list';
import ContractFilesCollection from '@/js/app/contract-file/collections/contract-files';
import ListContractFileView from '@/js/app/contract-file/views/list';
import CustomerFilesCollection from '@/js/app/customer-file/collections/customer-files';
import CustomerFileIndexView from '@/js/app/customer-file/views/index';
import CustomerModel from '@/js/app/customer/models/customer';
import CustomerReadUpdateView from '@/js/app/customer/views/read-update';
import oauth2Client from '@/js/app/oauth2-client';
import ProductContractsCollection from '@/js/app/product-contracts/collections/product-contracts';
import ProductContractListAddView from '@/js/app/product-contracts/views/list-add';
import template from '../templates/detail.hbs';
import ContractActionBarView from './action-bar';
import ContractBillsView from './bills';
import ReadUpdateContractView from './read-update';

export default class ContractDetailView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';

        this.customerModel = new CustomerModel;
    }

    initialize() {
        this.subviews = {
            actionBar: new ContractActionBarView({
                contractId: this.model.id
            }),

            contractCard: new ReadUpdateContractView({
                model: this.model,
            }),

            listAddProductContractCard: new ProductContractListAddView({
                modelAttributes: {
                    contract_id: this.model.id
                },
                contractModel: this.model,
                collection: new ProductContractsCollection,
            }),

            billsCard: new ContractBillsView({
                model: this.model
            }),

            listContractDocumentCard: new ListContractDocumentView({
                contractId: this.model.get('id')
            }),

            listContractFileCard: new ListContractFileView({
                collection: new ContractFilesCollection([], {
                    contractId: this.model.get('id')
                })
            }),

            customerCard: new CustomerReadUpdateView({
                isButtonsHidden: true,
                model: this.customerModel,
            }),

            listCustomerFileCard: new CustomerFileIndexView({
                collection: new CustomerFilesCollection([], {
                    customerId: 0
                }),
                model: this.customerModel,
            }),
        };

        oauth2Client.fetch(`${config.api.url}contracts/fees`, {})
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.json();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((data) => {
                this.subviews.contractCard.setFees(data);
            });

        this.listenTo(this.model, 'change:customer_id', this.changeCustomer);

        this.hasStatusLoaded = false;
        this.listenTo(this.model, 'change:status', this.handleStatusChange);

        // When model changes is_migrated; handleMigratedBadgeDisplay
        this.listenTo(this.model, 'change:is_migrated', this.handleMigratedBadgeDisplay);
    }

    render() {
        console.debug('ContractDetail#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of action bar view as #actionBar then render
        this.subviews.actionBar.setElement(this.el.querySelector('#actionBar')).render();

        // Set el of contract detail view as #details then render
        this.subviews.contractCard.setElement(this.el.querySelector('#details')).render();

        // Set el of product contract list add view as #listAddProduct then render
        this.subviews.listAddProductContractCard.setElement(this.el.querySelector('#listAddProduct')).render();

        // Set el of bills view as #bills then render
        this.subviews.billsCard.setElement(this.el.querySelector('#bills')).render();

        // Set el of contract document list view as #contractDocuments then render
        this.subviews.listContractDocumentCard.setElement(this.el.querySelector('#listDocument')).render();

        // Set el of contract file list view as #listAddFile then render
        this.subviews.listContractFileCard.setElement(this.el.querySelector('#listAddFile')).render();

        // Set el of customer detail view as #customerDetails then render
        this.subviews.customerCard.setElement(this.el.querySelector('#customerDetails')).render();

        // Set el of customer file list view as #listAddFile then render
        this.subviews.listCustomerFileCard.setElement(this.el.querySelector('#listAddCustomerFile')).render();

        // Trigger fetch of product contract collection
        this.subviews.listAddProductContractCard.collection.fetch({
            data: {
                contract_id: this.model.get('id'),
                inclusions: ['product_type']
            }
        });

        // Trigger fetch of contract file collection
        this.subviews.listContractFileCard.collection.fetch();

        // Change text of #title based on model.id
        this.el.querySelector('#title').innerText = this.model.get('id');

        return this;
    }

    changeCustomer() {
        this.customerModel.set('id', this.model.get('customer_id'));

        this.customerModel.fetch({
            data: {
                inclusions: ['address', 'account']
            }
        });

        this.subviews.listCustomerFileCard.collection.customerId = this.model.get('customer_id');

        // Trigger fetch of customer file collection
        this.subviews.listCustomerFileCard.collection.fetch({
            reset: true
        });
    }

    handleStatusChange() {
        if (this.hasStatusLoaded) {
            // Trigger fetch of product contract collection
            this.subviews.listAddProductContractCard.collection.fetch({
                data: {
                    contract_id: this.model.get('id'),
                    inclusions: ['product_type']
                }
            });
        }

        this.hasStatusLoaded = true;
    }

    handleMigratedBadgeDisplay(model, value) {
        console.debug('ContractDetail#handleMigratedBadgeDisplay');

        if (value === 1) {
            // Remove "d-none" from class list
            this.el.querySelector('[data-part="migratedBadge"]').classList.remove('d-none');
        }
    }
}
