import { queue } from 'async';
import { View } from 'backbone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ErrorModalView from '@/js/app/error/views/modal';
import uploader from '@/js/app/file-uploader/uploader';
import oauth2Client from '@/js/app/oauth2-client';
import User from '@/js/app/user';
import template from '../../templates/owner-downloads/card-item.hbs';
import PmOwnerDownloadsCardItemHistoryView from './card-item-history';

dayjs.extend(utc);

export default class PmOwnerDownloadsCardItemView extends View {
    preinitialize(options) {
        this.className = 'col my-2';
        this.type = options.type;
        this.criteria = options.criteria;
        this.hasUpload = options.hasUpload !== false;
        this.hasGenerate = options.hasGenerate;
        this.events = {
            'click [data-action="upload"]': this.handleUploadClick,
            'change [name="file"]': this.handleFileChange,
            'click [data-action="generate"]': this.handleGenerateClick,
            'click [data-action="download"]': this.handleDownloadClick,
            'click [data-action="history"]': this.handleHistoryClick,
        };
        this.isUploading = false;
        this.isGenerating = false;
    }

    initialize() {
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('PmOwnerDownloadsCardItem#render');

        const hasUpload = User.isAllowed('consus:pm.upload') && this.hasUpload;
        const hasGenerate = User.isAllowed('consus:pm.upload') && this.hasGenerate;
        const hasDownload = this.collection.length > 0;

        // Attach template to el
        this.el.innerHTML = template({
            title: this.type.label,
            hasUpload,
            isUploading: this.isUploading,
            hasGenerate,
            isGenerating: this.isGenerating,
            isLoading: this.isUploading || this.isGenerating,
            hasDownload,
            hasHistory: hasDownload,
            hasButton: hasUpload || hasDownload || hasGenerate
        });

        return this;
    }

    handleUploadClick() {
        console.debug('PmOwnerDownloadsCardItem#handleUploadClick');

        this.$el.find('[name="file"]').trigger('click');
    }

    handleFileChange(e) {
        console.debug('PmOwnerDownloadsCardItem#handleFileChange');

        this.isUploading = true;
        this.render();

        const files = e.currentTarget.files;
        const name = e.currentTarget.name;
        const uploadStatusContainer = this.$el.find('[data-part="uploadStatus"]');

        // Create file upload queue with handler
        const q = queue((file, callback) => {
            uploader(name, file,
                {
                    method: 'PUT',
                    url: this.collection.url() + '/' + this.criteria.period + '/' + this.type.value,
                },
                {
                    uploadStatusContainer
                })
                .then((response) => {
                    this.isUploading = false;

                    // Add new file model to collection using response data
                    this.collection.add(response);
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (let i = 0; i < files.length; i++) {
            // Add file to queue
            q.push(files[i]);
        }

        // Clear file selector
        e.currentTarget.value = '';
    }

    handleGenerateClick() {
        console.debug('PmOwnerDownloadsCardItem#handleDownloadClick');

        if (confirm("Are you sure?")) {
            this.generateReport();
        }
    }

    async generateReport() {
        try {
            this.isGenerating = true;
            this.render();

            const responseData = await oauth2Client.fetchJSON(this.collection.url() + '/' + this.criteria.period + '/' + this.type.value, {
                method: 'POST',
            });

            this.isGenerating = false;

            // Add new file model to collection using response data
            this.collection.add(responseData);
        } catch (error) {
            this.isGenerating = false;
            this.render();

            const errorModalView = new ErrorModalView({ error });
            errorModalView.render();
        }
    }

    handleDownloadClick() {
        console.debug('PmOwnerDownloadsCardItem#handleDownloadClick');

        const file = this.collection.at(-1).toJSON();

        oauth2Client.download(
            this.collection.url() + '/' + file.period + '/' + file.type + '/' + file.version,
            {
                method: 'GET'
            },
            {
                filename: `${this.model.get('name')}_${file.period}_${this.type.label}_v${dayjs.utc(file.version).local().format('YYYYMMDDHHmmss')}.${file.filename.split('.')[1]}`
            }
        );
    }

    handleHistoryClick() {
        console.debug('PmOwnerDownloadsCardItem#handleHistoryClick');

        const historyView = new PmOwnerDownloadsCardItemHistoryView({
            type: this.type,
            model: this.model,
            collection: this.collection
        });

        historyView.render();
    }
}