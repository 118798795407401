import { history, Collection } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import { getJidai } from '@/js/libs/jidai';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import OffenderModel from '../models/offender';
import listTemplate from '../templates/list.hbs';
import addTemplate from '../templates/edit.hbs';

export default class OffenderListAddView extends CardView {
    preinitialize() {
        this.title = yuyan.t('offender.list');
        // Declare true instance props
        this.events = {
            'click [data-action="add"]': this.handleAddClick,
            'click [data-action="cancel"]': this.handleCancelClick,
            'submit form': this.handleFormSubmit,
        }
    }

    constructor(options) {
        super(options);

        // Initialize defaults
        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];

        this.offenderModel = new OffenderModel;

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.renderList);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('OffenderListAdd#render');

        this.renderCard().renderList();

        return this;
    }

    renderList() {
        console.debug('OffenderListAdd#renderList');

        // Attach listTemplate to cardBody with customers collection
        this.cardBody.innerHTML = listTemplate({
            offenders: this.collection.toJSON(),
        });

        // Transform customers table to DataTable
        this.$el.find('table').DataTable({
            searching: false
        });

        return this;
    }

    renderAdd() {
        console.debug('OffenderListAdd#renderAdd');

        // Attach addTemplate to cardBody
        this.cardBody.innerHTML = addTemplate();

        this.loadYears();

        return this;
    }

    loadYears() {
        console.debug('OffenderListAdd#loadYears');

        const year = (new Date).getFullYear();
        const options = document.createDocumentFragment();

        for (let i = 0; i < 120; i++) {
            const value = year - i;
            const o = document.createElement('option');
            o.value = value.toString();
            o.innerText = value + ' (' + getJidai(value) + ')';
            options.appendChild(o);
        }

        this.el.querySelector('#dobYY').appendChild(options);
    }

    handleAddClick() {
        console.debug('OffenderListAdd#handleAddClick');

        this.hideButtonContainer('addBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderAdd();

        return this;
    }

    handleCancelClick() {
        console.debug('OffenderListAdd#handleCancelClick');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('addBtnCntr')
            .renderList();

        return this;
    }

    handleFormSubmit(e) {
        console.debug('OffenderListAdd#handleFormSubmit');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            const dobYY = this.el.querySelector('#dobYY').value;
            const dobMM = this.el.querySelector('#dobMM').value;
            const dobDD = this.el.querySelector('#dobDD').value;
            const dobEl = this.el.querySelector('[name="dob"]');

            if (dobEl && dobYY && dobMM && dobDD) {
                dobEl.value = dobYY + '-' + dobMM + '-' + dobDD;
            }

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Trigger save of offenderModel with data as attributes
            this.offenderModel.save(data, {
                // On success: navigate
                success: this.navigate,
                // On error: handleSaveError
                error: this.handleSaveError
            });
        }
    }

    handleSaveError(model, response) {
        console.debug('OffenderListAdd#handleSaveError');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    navigate(model) {
        console.debug('OffenderListAdd#navigate');

        // Navigate to offenders/:id
        history.navigate('offenders/' + model.id, { trigger: true });
    }
}
