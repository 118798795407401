var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <th>Shop</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover border\">\n        <thead>\n            <tr class=\"thead-light\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allShops") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":7,"column":23}}})) != null ? stack1 : "")
    + "                <th>Contract ID</th>\n                <th>Name</th>\n                <th class=\"text-right\">Target<br>Rent</th>\n                <th class=\"text-right\">Asking<br>Rent</th>\n                <th class=\"text-right\">Contract<br>Rent</th>\n                <th class=\"text-right\">Contract<br>vs<br>Target<br>Rent</th>\n                <th class=\"text-right\">Rental<br>Increase</th>\n                <th class=\"text-right\">Rental<br>Increase<br>Pct</th>\n                <th class=\"text-right\">New<br>Rent</th>\n                <th class=\"text-right\">Months Since<br>Last Increase</th>\n                <th></th>\n                <th></th>\n            </tr>\n        </thead>\n        <tbody></tbody>\n        <tfoot></tfoot>\n    </table>\n</div>";
},"useData":true});