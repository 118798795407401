var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"dashboard.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":27}}}))
    + "</h1>\n\n<hr class=\"my-3\">\n\n<div id=\"actionBar\" class=\"mb-3 d-none\"></div>\n\n<div id=\"badContractAlert\" class=\"alert alert-danger d-none\" role=\"alert\">\n    <h2 class=\"alert-heading\">\n        <i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i>\n        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"error.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":27}}}))
    + "\n    </h2>\n    <p>ITにお知らせてください。</p>\n</div>\n\n<div id=\"divExpressContract\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"divRegularContract\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"divProductContract\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"divCancelRequestedContract\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});