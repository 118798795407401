import ModalView from '@/js/app/generic/views/modal';
import SpinnerView from '@/js/app/generic/views/spinner';
import template from '../templates/skip-modal.hbs';

export default class PriceCheckSkipModalView extends ModalView {
    preinitialize(options) {
        this.preinitializeModal();

        this.events = {
            'click [data-action="skip"]': this.handleClickSkip,
            'keypress': this.handleKeypress,
        };

        this.contractId = options.contractId;
        this.rowView = options.rowView;

        this.spinner = new SpinnerView();
    }

    render() {
        console.debug('PriceCheckSkipModalView#render');

        this.renderModal({ centered: true }).modalContent.innerHTML = template({
            title: this.title,
            isLoading: true
        });

        return this;
    }

    handleKeypress(e) {
        // If [enter] key pressed
        if (e.keyCode === 13) {
            const button = this.el.querySelector('[data-action="skip"]');
            this.skip(button);
        }
    }

    handleClickSkip(e) {
        console.debug('PriceCheckSkipModalView#handleClickSkip');
        const button = e.currentTarget;
        this.skip(button);
    }

    skip(button) {
        // Disable button and start spinner
        button.disabled = true;
        this.spinner.spin(button);

        // Skip
        this.model.skip()
            .then(() => {
                // Hide modal
                this.$el.modal('hide');
            })
            .catch((error) => {
                console.error(error);

                // Stop spinner and enable button
                this.spinner.stop();
                button.disabled = false;
            });
    }
}