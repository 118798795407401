import ModalView from '@/js/app/generic/views/modal';
import template from '../templates/card-item-history.hbs';
import FileDownloadsCardItemHistoryItemView from './card-item-history-item';

export default class FileDownloadsCardItemHistoryView extends ModalView {
    preinitialize(options) {
        this.type = options.type;
        this.preinitializeModal();
    }

    render() {
        console.debug('FileDownloadsCardItemHistory#render');

        this.renderModal().modalContent.innerHTML = template({
            title: this.type.label
        });

        this.collection.each((model) => {
            const historyItem = new FileDownloadsCardItemHistoryItemView({
                type: this.type,
                model: model
            });

            this.$el.find('[data-slot="history"]').prepend(historyItem.render().el);
        });

        return this;
    }
}
