import { history, View } from 'backbone';
import { bindAll } from 'underscore';
import SpinnerView from '@/js/app/generic/views/spinner';
import oAuth2Client from '@/js/app/oauth2-client';
import User from '@/js/app/user';
import yuyan from '@/js/app/yuyan';
import template from '../templates/login.hbs';

export default class LoginView extends View {
    preinitialize() {
        this.className = 'container';

        this.events = {
            'submit form': this.handleSubmit,
        };

        this.spinner = new SpinnerView;
    }

    initialize() {
        // Make sure these functions run with correct 'this' context
        bindAll(this, 'handleSubmit');
    }

    render() {
        console.debug('Login#render');

        this.el.innerHTML = template();

        return this;
    }

    handleSubmit($e) {
        console.debug('Login#login');

        $e.preventDefault();

        // Get alert and button elements
        const cntrAlert = this.el.querySelector('.alert');
        const submitButton = this.el.querySelector('button[type="submit"]');

        // Hide existing alerts while attempting to login
        cntrAlert.classList.add('d-none');

        // Disable button and start spinner
        submitButton.disabled = true;
        this.spinner.spin(submitButton);

        // Get username and password
        const username = this.el.querySelector('#field-email').value;
        const password = this.el.querySelector('#field-password').value;

        // Make AJAX call to login
        oAuth2Client.authenticate(username, password)
            .then(() => User.fetchInfo())
            .then(() => {
                // Navigate to dashboard
                const redirect = sessionStorage.getItem('redirect');
                if (redirect) {
                    sessionStorage.removeItem('redirect');
                    history.navigate(redirect, { trigger: true });
                } else {
                    history.navigate('', { trigger: true });
                }
            })
            .catch((err) => {
                // Log error message
                console.error(err.message);

                // Set error key depending on error name
                let errorKey = 'authentication.error.generic';
                if (err.name === 'OAuth2Error' && yuyan.has('authentication.error.' + err.error)) {
                    errorKey = 'authentication.error.' + err.error;
                }

                // Update alert text and show
                cntrAlert.innerHTML = yuyan.t(errorKey);
                cntrAlert.classList.remove('d-none');

                // Stop spinner and enable button
                this.spinner.stop();
                submitButton.disabled = false;
            });
    }
}
