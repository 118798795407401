var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <th>"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"room.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":35}}}))
    + "</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <th>"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":37}}}))
    + "</th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <th>Days<br>Vacant</th>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"results") : stack1),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":40,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n\n        <tfoot>\n            <tr>\n                <td>"
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"area") : stack1),"0,0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":46,"column":39},"end":{"line":46,"column":84}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"targetRent") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":47,"column":39},"end":{"line":47,"column":89}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"askingRent") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":48,"column":39},"end":{"line":48,"column":89}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"askingRentDiff") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":49,"column":39},"end":{"line":49,"column":93}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"askingRentDiffPercentage") : stack1),"0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":50,"column":39},"end":{"line":50,"column":100}}}))
    + "%</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"contractedRent") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":51,"column":39},"end":{"line":51,"column":93}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"contractedRentDiff") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":52,"column":39},"end":{"line":52,"column":97}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"contractedRentDiffPercentage") : stack1),"0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":53,"column":39},"end":{"line":53,"column":104}}}))
    + "%</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shopPerspective") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + "            </tr>\n        </tfoot>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"area") : depth0),"0,0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":68}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"targetRent") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":29,"column":39},"end":{"line":29,"column":73}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"askingRent") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":30,"column":39},"end":{"line":30,"column":73}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"askingRentDiff") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":31,"column":39},"end":{"line":31,"column":77}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"askingRentDiffPercentage") : depth0),"0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":32,"column":39},"end":{"line":32,"column":84}}}))
    + "%</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"contractedRent") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":33,"column":39},"end":{"line":33,"column":77}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"contractedRentDiff") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":34,"column":39},"end":{"line":34,"column":81}}}))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"contractedRentDiffPercentage") : depth0),"0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":35,"column":39},"end":{"line":35,"column":88}}}))
    + "%</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depths[1] != null ? lookupProperty(depths[1],"shopPerspective") : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + "            </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"text-right\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"daysVacant") : depth0), depth0))
    + "</td>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <td class=\"text-right\">-</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap border\">\n        <thead>\n            <tr class=\"thead-light\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shopPerspective") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "                <th>Area</th>\n                <th>Target<br>Rent</th>\n                <th>Asking<br>Rent</th>\n                <th>Asking Rent<br>Difference</th>\n                <th>Asking Rent<br>Difference %</th>\n                <th>Contracted<br>Rent</th>\n                <th>Contracted Rent<br>Difference</th>\n                <th>Contracted Rent<br>Difference %</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shopPerspective") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "            </tr>\n        </thead>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"rentalDiscount") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":59,"column":15}}})) != null ? stack1 : "")
    + "    </table>\n</div>";
},"useData":true,"useDepths":true});