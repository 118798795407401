import { View } from 'backbone';

export default class ShopPickerView extends View {
    preinitialize(options) {
        // Initialize defaults
        this.tagName = 'select';

        this.className = 'form-control';

        this.attributes = {
            'name': 'shop_id',
            'data-number': ''
        };

        this.hasLoaded = false;
        this.hasAll = options.hasAll || false;
    }

    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('ShopPicker#render');

        // If collection contains models
        if (this.collection.length > 0) {
            // Initialize options as document fragment
            const options = document.createDocumentFragment();

            if (this.hasAll) {
                const all = document.createElement('option');

                // Set value and innerText of all
                all.value = 'all';
                all.innerText = 'ALL';

                // Append all to options
                options.appendChild(all);
            } else {
                // Append option element to options
                options.appendChild(document.createElement('option'));
            }

            // For each model in collection
            this.collection.each((v) => {
                // Initialize o as option element
                const o = document.createElement('option');

                // Set value and innerText of o
                o.value = v.get('id');
                o.innerText = v.get('name');

                // Append o to options
                options.appendChild(o);
            });

            // Clear options of el
            while (this.el.firstChild) {
                this.el.removeChild(this.el.firstChild);
            }

            // Attach options to el
            this.el.appendChild(options);
        }

        this.hasLoaded = true;

        // If value exists, setValue
        if (this.value) {
            this.setValue();
        }

        return this;
    }

    setValue(value) {
        console.debug('ShopPicker#setValue');

        if (value !== undefined) {
            this.value = value;
        }

        // Set value of select element and trigger change event
        if (this.hasLoaded) {
            this.el.value = this.value;
            this.$el.trigger('change');
        }
    }
}
