import CardView from '@/js/app/generic/views/card';
import yuyan from '@/js/app/yuyan';
import ShopFileListItemView from './list-item';
import template from '../templates/list.hbs';

export default class ShopFileListView extends CardView {
    preinitialize(options = {}) {
        // Initialize defaults
        this.title = options.title || yuyan.t('file.list');

        this.shopId = options.shopId;

        this.subviews = {
            agreementView: new ShopFileListItemView({
                shopId: this.shopId,
                type: 'agreement',
                title: '契約約款'
            }),
            keyboxInfoView: new ShopFileListItemView({
                shopId: this.shopId,
                type: 'keybox_info',
                title: '現地キーボックス情報'
            })
        };
    }

    render() {
        console.debug('ShopFileList#render');

        this.renderCard().cardBody.innerHTML = template()

        this.subviews.agreementView.setElement(this.el.querySelector('#agreementItem')).render();

        this.subviews.keyboxInfoView.setElement(this.el.querySelector('#keyboxInfoItem')).render();

        return this;
    }
}
