import { View } from 'backbone';
import template from '../templates/status.hbs';

export default class UploadStatusView extends View {
    preinitialize() {
        this.className = 'row';

        this.events = {
            'click [data-action="clear"]': this.clear,
        };

        this.progressBar = null;
    }

    initialize(options) {
        _.bindAll(this, 'handleProgress', 'handleSuccess', 'handleError', 'handleAbort');

        this.file = options.file;
        this.request = options.request;

        // Add "progress" event listener on request object; update progress
        this.request.upload.addEventListener('progress', this.handleProgress, false);

        // Add "success" event listener on request object; complete upload
        this.request.addEventListener('success', this.handleSuccess, false);

        // Add "error" event listener on request object
        this.request.addEventListener('error', this.handleError, false);

        // Add "abort" event listener on request object
        this.request.addEventListener('abort', this.handleAbort, false);

        // this.listenTo(this.request.upload, 'progress', this.handleProgress);

        // this.listenTo(this.request, 'success', this.handleSuccess);

        // this.listenTo(this.request, 'error', this.handleError);

        // this.listenTo(this.request, 'abort', this.handleAbort);
    }

    render() {
        this.el.innerHTML = template({
            file: this.file
        }, {
            allowProtoPropertiesByDefault: true
        });

        this.progressBar = this.$el.find('.progress .progress-bar');

        return this;
    }

    clear($e) {
        $e.preventDefault();
        this.remove();
    }

    abort($e) {
        $e.preventDefault();

        this.request.abort();

        $e.currentTarget.style.display = 'none';
    }

    handleProgress(e) {
        if (e instanceof ProgressEvent && e.lengthComputable) {
            this.updateProgressBar((e.loaded / e.total) * 100);
        }
    }

    updateProgressBar(value) {
        this.progressBar.css('width', value + '%');
        this.progressBar.prop('aria-valuenow', value);

        return this;
    }

    handleSuccess() {
        this.progressBar.addClass('progress-success').removeClass('progress-striped progress-bar-animated');

        // After 800ms, remove view
        setTimeout(() => {
            this.remove();
        }, 800);
    }

    handleError(e) {
        // Set progress to 100%
        this.updateProgressBar(100);

        this.progressBar.addClass('progress-danger').removeClass('progress-striped progress-bar-animated');

        // Determine error message
        let message;
        if (e instanceof ErrorEvent) {
            message = e.error.message;
        } else {
            message = 'Unable to connect to server. Please try again.';
        }

        // Display error message
        this.$el.find('.status-text').text(message);

        // Show "remove" button
        this.$el.find('[data-action="clear"]').show();
    }

    handleAbort() {
        this.progressBar.addClass('progress-danger').removeClass('progress-striped progress-bar-animated');

        // Display status message
        this.$el.find('.status-text').text('Cancelled.');

        // After 2000ms, remove view
        setTimeout(() => {
            this.remove();
        }, 2000);
    }
}
