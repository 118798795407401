import { View } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import ContractsCollection from '../collections/contracts';
import ListAddContractView from './list-add';
import template from '../templates/index.hbs';

export default class ContractIndexView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';

        this.events = {
            'submit form#frmSearch': this.search,
        };

        this.subviews = {
            listAddContractCard: new ListAddContractView({
                buttons: 'hide',
                collection: new ContractsCollection,
                columns: {
                    status: true
                }
            }),

            shopCntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop"
            })
        };
    }

    render() {
        console.debug('ContractIndex#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of contract list view as #listAddContract then render
        this.subviews.listAddContractCard.setElement(this.el.querySelector('#listAddContract')).render();

        this.subviews.shopCntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopCntr.collection.fetch();

        // this.$el.find('#field-shop').select2({
        //     theme: 'bootstrap4',
        // });

        // Get contract search conditions from sessionStorage
        this.searchConditions = JSON.parse(sessionStorage.getItem('contractSearchCondition'));
        // If search conditions exist
        if (this.searchConditions) {
            // For each search condition
            _.each(this.searchConditions, (val, key) => {
                // If value is array
                if (Array.isArray(val)) {
                    // For each value
                    val.forEach(v => {
                        // Initialize field as element with name = key
                        const field = this.el.querySelector('[name="' + key + '"][value="' + v + '"]');

                        // If field exists, set as checked
                        if (field) {
                            field.checked = true;
                        }
                    });
                }
                // Else value not array
                else {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector('[name="' + key + '"]');

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });

            this.subviews.shopCntr.setValue(this.searchConditions.shop_id);
        }

        // Initiate search
        this.search();

        return this;
    }

    search(e) {
        console.debug('ContractIndex#search');

        // Initialize data
        let data = {};

        // If called with event
        if (e instanceof Object) {
            e.preventDefault();

            // Get data from form using formToObject
            data = formToObject(e.target);

            // Store search conditions to sessionStorage
            sessionStorage.setItem('contractSearchCondition', JSON.stringify(data));
        }
        // Else if searchConditions exist, use as data
        else if (this.searchConditions) {
            data = this.searchConditions;
        }

        data.inclusions = ['room'];

        // Trigger fetch of contract collection with data as query
        this.subviews.listAddContractCard.collection.search({
            data: data,
            reset: true,
            // On error: errorOnSearch
            error: this.errorOnSearch
        });
    }

    errorOnSearch(collection, response) {
        console.debug('ContractIndex#errorOnSearch');

        // If response status 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
