import { Collection } from 'backbone';
import config from '@/js/app/config';
import ContractBillModel from '../models/contract-bill';

export default class ContractBillsCollection extends Collection {
    preinitialize() {
        // Reference to this collection's model.
        this.model = ContractBillModel;
    }

    initialize(models, options) {
        this.contractId = options.contractId;
    }

    url() {
        return config.api.url + 'contracts/' + this.contractId + '/bills';
    }
}
