import { View } from 'backbone';
import DeleteModalView from '@/js/app/generic/views/delete-modal';
import oAuth2Client from '@/js/app/oauth2-client';
import template from '../templates/item.hbs';

export default class CustomerFileItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click [data-action="download"]': this.download,
            'click [data-action="delete"]': this.delete,
        };
    }

    initialize() {
        // When model is destroyed: remove
        this.listenTo(this.model, 'destroy', this.remove);
    }

    render() {
        console.debug('CustomerFileItem#render');

        this.el.innerHTML = template({
            file: this.model.toJSON()
        });

        return this;
    }

    download($e) {
        console.debug('CustomerFileItem#download');

        $e.preventDefault();

        oAuth2Client.download(
            this.model.url(),
            {
                method: 'GET',
            },
            {
                filename: this.model.get('filename'),
            }
        );
    }

    delete($e) {
        console.debug('CustomerFileItem#delete');

        $e.preventDefault();

        const deleteConfirmation = new DeleteModalView({
            model: this.model
        });

        deleteConfirmation.render();
    }
}
