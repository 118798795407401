import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import ModalView from '@/js/app/generic/views/modal';
import template from '../../templates/unpaid-bills/modal.hbs';

export default class ReinvoiceModalView extends ModalView {
    preinitialize(options) {
        this.unpaidBill = options.unpaidBill;

        this.events = {
            'click [data-action="confirm"]': this.handleConfirmClick,
        };

        this.preinitializeModal();
    }

    render() {
        console.debug('ReinvoiceModalView#render');

        this.renderModal().modalContent.innerHTML = template();

        // Get references to payment method options
        const creditOption = this.el.querySelector('#field-payment_method>option[value="credit"]');
        const directDebitOption = this.el.querySelector('#field-payment_method>option[value="direct_debit"]');
        const moneyTransferOption = this.el.querySelector('#field-payment_method>option[value="money_transfer"]');

        if (this.unpaidBill.paymentMethod === '銀行振込') {
            moneyTransferOption.selected = true;

            this.disableOption(creditOption);
            this.disableOption(directDebitOption);
        } else if (this.unpaidBill.paymentMethod === 'クレジットカード') {
            moneyTransferOption.selected = true;    // Mark: temporarily default to money transfer

            this.enableOption(creditOption);
            // creditOption.selected = true;

            this.disableOption(directDebitOption);
        } else if (this.unpaidBill.paymentMethod === '口座振替') {
            moneyTransferOption.selected = true;    // Mark: temporarily default to money transfer

            this.enableOption(directDebitOption);
            // directDebitOption.selected = true;

            this.disableOption(creditOption);
        }
    }

    enableOption(option) {
        option.disabled = false;
        option.classList.remove('d-none');
    }

    disableOption(option) {
        option.disabled = true;
        option.classList.add('d-none');
    }

    handleConfirmClick(e) {
        console.debug('ReinvoiceModalView#handleConfirmClick');

        // Disable button
        e.currentTarget.disabled = true;

        // Show button spinner
        e.currentTarget.querySelector('.spinner-border').classList.remove('d-none');

        // Reinvoice bill
        oauth2Client.fetchJSON(`${config.api.url}contracts/${this.unpaidBill.contractId}/reinvoice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                bill_no: this.unpaidBill.billNo,
                payment_method: this.el.querySelector('#field-payment_method>option:checked').value
            })
        }).then(() => {
            this.trigger('trt.reinvoice');

            // Hide modal
            this.$el.modal('hide');
        }).finally(() => {
            // Hide button spinner
            e.currentTarget.querySelector('.spinner-border').classList.add('d-none');

            // Enable button
            e.currentTarget.disabled = false;
        });
    }
}