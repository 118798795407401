import { View } from 'backbone';
import template from '../templates/nav.hbs';

export default class NavView extends View {
    preinitialize() {
        this.events = {
            'click #nav a': this.collapse,
        };
    }

    render() {
        console.debug('Nav#render');

        this.el.innerHTML = template();

        return this;
    }

    collapse() {
        // Hide navbar
        this.$el.find('#nav').collapse('hide');
    }
}
