var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-outline-success mr-1\" data-modal=\"loginHistory\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"customer.login_history.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":77},"end":{"line":5,"column":113}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isGiraffeRegisterable") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":11,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-outline-info mr-1\" data-modal=\"giraffe\">Giraffeに登録</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-outline-info ml-auto\" data-modal=\"changelog\">Changelog</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card bg-light\">\n  <div class=\"card-body\">\n    <div class=\"d-flex flex-wrap\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:customers",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":42}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":6,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"admin",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":31}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias1,"consus:customers",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":42}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>";
},"useData":true});