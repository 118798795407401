var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap\">\n        <thead>\n            <tr class=\"thead-light\">\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":35}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.floorspace",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":43}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.base_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":42}}}))
    + "</th>\n                <th>"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_rate_multiline",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":58}}})) != null ? stack1 : "")
    + "</th>\n                <th>"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_duration_multiline",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":62}}})) != null ? stack1 : "")
    + "</th>\n                <th>"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_eligible_min_months_multiline",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":73}}})) != null ? stack1 : "")
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":39}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":38}}}))
    + "</th>\n                <th></th>\n            </tr>\n        </thead>\n        <tbody id=\"roomsTableBody\"></tbody>\n    </table>\n</div>";
},"useData":true});