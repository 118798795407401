import dayjs from 'dayjs';

import { Events } from 'backbone';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';

export default class PriceCheckModel {
    constructor(data, options) {
        _.extend(this, Events)

        this.data = data;

        this.collection = options.collection || undefined;
    }

    get(key) {
        return this.data[key] || undefined;
    }

    skip() {
        return oauth2Client.$ajax({
            method: 'PATCH',
            url: config.api.url + 'contracts/' + this.data.contractId,
            data: {
                price_check_skipped_date: dayjs().format('YYYY-MM-DD'),
            },
        })
            .then(() => {
                this.remove();
            });
    }

    remove() {
        if (this.collection === undefined) {
            throw Error('Reference to collection not provided in model');
        }

        // Remove model from it's collection
        this.collection.remove(this);

        // Trigger "remove" event
        this.trigger('remove');
    }
}