import ErrorModalView from '@/js/app/error/views/modal';
import uploader from '@/js/app/file-uploader/uploader';
import oauth2Client from '@/js/app/oauth2-client';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import { queue } from 'async';
import { View } from 'backbone';
import { isEmpty } from 'underscore';
import ShopCashflowCollection from '../../collections/shop-cashflows';
import template from '../../templates/cashflows/index.hbs';
import PmCashflowsExpensesModalView from './expenses-modal';
import PmCashflowsIncomesModalView from './incomes-modal';
import PmCashflowsItemView from './item';
import PmCashflowsTableView from './table';
import Spinner from '@/js/app/generic/views/spinner';

export default class PmCashflowsView extends View {
    preinitialize() {
        this.className = 'container';

        this.collection = new ShopCashflowCollection();

        this.events = {
            'change [name="shop_id"]': this.handleShopIdChange,
            'click [data-modal="importIncomes"]': this.handleImportIncomesClick,
            'click [data-modal="importExpenses"]': this.handleImportExpensesClick,
            'submit form#frmSearch': this.handleFormSubmit,
            'click [data-action="add"]': this.handleAddClick,
            'click [data-action="upload"]': this.handleUploadClick,
            'change #uploadFile': this.handleUploadFileChange,
            'click [data-action="delete"]': this.handleDeleteClick,
        };

        this.subviews = {
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id"
            }),
            table: new PmCashflowsTableView({
                collection: this.collection,
            })
        };
    }

    initialize() {
        // Listen to events from table subview
        this.listenTo(this.subviews.table, 'trt:has-selected', this.enableDeleteButton);
        this.listenTo(this.subviews.table, 'trt:none-selected', this.disableDeleteButton);
    }

    render() {
        console.debug('PmCashflows#render');

        // Attach template to el
        this.el.innerHTML = template({
            shopId: this.collection.shopId
        });

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });

        // Set el of cashflow list view as #cashflowList then render
        this.subviews.table.setElement(this.el.querySelector('#cashflowList')).render();

        return this;
    }

    handleShopIdChange(e) {
        console.debug('PmCashflows#handleShopIdChange');

        const shopValue = e.currentTarget.value;
        this.hasNotSelected = true;
        this.lastClickedIndex = 0;

        // Empty collection of cashflows
        this.collection.reset([]);

        if (shopValue) {
            // Show UI elements
            this.showSearchForm();
            this.showActionBar();
            this.showList();

            // Set shop ID on collection
            this.collection.shopId = shopValue;
        } else {
            // Hide UI elements
            this.hideSearchForm();
            this.hideActionBar();
            this.hideList();

            // Remove shop ID from collection
            this.collection.shopId = null;
        }

        return this;
    }

    handleImportIncomesClick() {
        console.debug('PmCashflows#handleImportIncomesClick');

        const view = new PmCashflowsIncomesModalView({
            collection: this.collection
        });

        view.render();
    }

    handleImportExpensesClick() {
        console.debug('PmCashflows#handleImportExpensesClick');

        const view = new PmCashflowsExpensesModalView({
            collection: this.collection
        });

        view.render();
    }

    handleFormSubmit(e) {
        console.debug('PmCashflows#handleFormSubmit');

        e.preventDefault();

        const data = {};

        // Get form data
        const formData = new FormData(e.target);

        // For each item in form data
        formData.forEach((value, key) => {
            // Skip empty values
            if (isEmpty(value)) {
                return;
            }

            // Store value on data object via key
            data[key] = value;
        });

        this.collection.fetch({
            data,
            reset: true,
            error: this.errorOnSearch
        })
    }

    errorOnSearch(collection, response) {
        console.debug('PmCashflows#errorOnSearch');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    handleAddClick() {
        console.debug('PmCashflows#handleAddClick');

        const addCashflowView = new PmCashflowsItemView({
            collection: this.collection
        });

        // Insert addCashflowView on the first row of table
        const tableBody = this.el.querySelector('#cashflowTableBody');
        this.el.querySelector('#cashflowTableBody').insertBefore(
            addCashflowView.renderEdit().el,
            tableBody.firstChild
        );
    }

    handleUploadClick() {
        console.debug('PmCashflows#handleUploadClick');

        this.$el.find('#uploadFile').trigger('click');
    }

    handleUploadFileChange(e) {
        console.debug('PmCashflows#handleUploadFileChange');

        this.disableActionButtons();

        // Start spinner on upload button
        const s = new Spinner;
        s.spin(this.el.querySelector('[data-action="upload"]'));

        const files = e.currentTarget.files;
        const name = e.currentTarget.name;
        const uploadStatusContainer = this.$el.find('[data-part="uploadStatus"]');

        // Create file upload queue with handler
        const q = queue((file, callback) => {
            // Upload file
            uploader(name, file,
                {
                    method: 'POST',
                    url: this.collection.url() + '/bulk_insert',
                },
                {
                    uploadStatusContainer
                })
                .then((response) => {
                    // Add new cashflow models to collection (from response)
                    this.collection.add(response);
                })
                .catch(error => {
                    const errorModalView = new ErrorModalView({ error });
                    errorModalView.render();
                })
                .finally(() => {
                    // Enable action buttons
                    this.enableActionButtons();

                    // Toggle delete button to correct state
                    this.toggleDeleteButton();

                    // Stop spinner
                    s.stop();

                    // Call callback to tell queue that this item has been processed
                    callback();
                });
        }, 1);

        // Loop through the FileList
        for (let i = 0; i < files.length; i++) {
            // Add file to queue
            q.push(files[i]);
        }

        // Clear file selector
        e.currentTarget.value = '';
    }

    handleDeleteClick() {
        console.debug('PmCashflows#handleDeleteClick');

        if (confirm('Are you sure you want to delete these cashflows?')) {
            this.bulkDeleteCashflow();
        }
    }

    async bulkDeleteCashflow() {
        console.debug('PmCashflows#deleteCashflow');

        const idList = [];
        for (const view of this.subviews.table.viewList) {
            if (view.isSelected) {
                idList.push(view.model.id);
            }
        }

        // Disable all action buttons
        this.disableActionButtons();

        // Start spinner on delete button
        const s = new Spinner;
        s.spin(this.el.querySelector('[data-action="delete"]'));

        try {
            await oauth2Client.fetchJSON(this.collection.url() + '/bulk_delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idList })
            });

            // Remove cashflow models from collection
            this.collection.remove(idList);

            this.subviews.table.lastClickedIndex = 0;
        } catch (error) {
            const errorModalView = new ErrorModalView({ error });
            errorModalView.render();
        }

        // Enable action buttons
        this.enableActionButtons();

        // Toggle delete button to correct state
        this.toggleDeleteButton();

        // Stop spinner on delete button
        s.stop();
    }

    disableActionButtons() {
        this.el.querySelectorAll('#cashflowActionBar button').forEach(el => el.disabled = true);
    }

    enableActionButtons() {
        this.el.querySelectorAll('#cashflowActionBar button').forEach(el => el.disabled = false);
    }

    toggleDeleteButton() {
        if (this.subviews.table.isAnyRowSelected()) {
            this.enableDeleteButton();
        } else {
            this.disableDeleteButton();
        }
    }

    disableDeleteButton() {
        this.el.querySelector('[data-action="delete"]').disabled = true;
    }

    enableDeleteButton() {
        this.el.querySelector('[data-action="delete"]').disabled = false;
    }

    showSearchForm() {
        this.el.querySelector('#frmSearch').classList.remove('d-none');
    }

    hideSearchForm() {
        this.el.querySelector('#frmSearch').classList.add('d-none');
    }

    showActionBar() {
        this.el.querySelector('#cashflowActionBar').classList.remove('d-none');
    }

    hideActionBar() {
        this.el.querySelector('#cashflowActionBar').classList.add('d-none');
    }

    showList() {
        this.el.querySelector('#cashflowList').classList.remove('d-none');
    }

    hideList() {
        this.el.querySelector('#cashflowList').classList.add('d-none');
    }
}
