var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<td></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>TOTAL</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allShops") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + "<td></td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"targetRent") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":6,"column":23},"end":{"line":6,"column":58}}}))
    + "</td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"askingRent") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":58}}}))
    + "</td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"contractRent") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":8,"column":23},"end":{"line":8,"column":60}}}))
    + "</td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"contractVsTargetRent") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":9,"column":23},"end":{"line":9,"column":67}}}))
    + "%</td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"newRentDiff") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":10,"column":23},"end":{"line":10,"column":59}}}))
    + "</td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"newRentDiffPct") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":61}}}))
    + "%</td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"newRent") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":12,"column":23},"end":{"line":12,"column":55}}}))
    + "</td>\n<td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"lastPriceCheckMonths") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":13,"column":23},"end":{"line":13,"column":67}}}))
    + "</td>\n<td></td>\n<td></td>";
},"useData":true});