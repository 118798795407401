var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return " data-page=\"previous\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " active";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"secondLabel") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":76}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"secondLabel") : depth0)) != null ? lookupProperty(stack1,"disabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":76},"end":{"line":16,"column":120}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"secondLabel") : depth0)) != null ? lookupProperty(stack1,"disabled") : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":121},"end":{"line":16,"column":197}}})) != null ? stack1 : "")
    + ">\n                    <a class=\"page-link\" href=\"#\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</a>\n                </li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-page=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"secondLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "\"";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"thirdLabel") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":36},"end":{"line":22,"column":75}}})) != null ? stack1 : "")
    + "\" data-page=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"thirdLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "\">\n                    <a class=\"page-link\" href=\"#\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"thirdLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</a>\n                </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"fourthLabel") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":36},"end":{"line":28,"column":76}}})) != null ? stack1 : "")
    + "\" data-page=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"fourthLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "\">\n                    <a class=\"page-link\" href=\"#\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"fourthLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</a>\n                </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"fifthLabel") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":75}}})) != null ? stack1 : "")
    + "\" data-page=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"fifthLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "\">\n                    <a class=\"page-link\" href=\"#\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"fifthLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</a>\n                </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"sixthLabel") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":36},"end":{"line":40,"column":75}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"sixthLabel") : depth0)) != null ? lookupProperty(stack1,"disabled") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":75},"end":{"line":40,"column":118}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"sixthLabel") : depth0)) != null ? lookupProperty(stack1,"disabled") : stack1),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":119},"end":{"line":40,"column":193}}})) != null ? stack1 : "")
    + ">\n                    <a class=\"page-link\" href=\"#\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"sixthLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</a>\n                </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-page=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"sixthLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "\"";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"seventhLabel") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":77}}})) != null ? stack1 : "")
    + "\" data-page=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"seventhLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "\">\n                    <a class=\"page-link\" href=\"#\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"seventhLabel") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "</a>\n                </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return " data-page=\"next\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row align-items-center\">\n    <div class=\"col-lg-5 d-flex justify-content-center justify-content-lg-start\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"info") || (depth0 != null ? lookupProperty(depth0,"info") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"info","hash":{},"data":data,"loc":{"start":{"line":2,"column":81},"end":{"line":2,"column":89}}}) : helper)))
    + "</div>\n\n    <div class=\"col-lg-7 d-flex justify-content-center justify-content-lg-end\">\n        <nav aria-label=\"Search results pages\">\n            <ul class=\"pagination mb-0\">\n                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPreviousDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":36},"end":{"line":7,"column":78}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPreviousDisabled") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":79},"end":{"line":7,"column":141}}})) != null ? stack1 : "")
    + ">\n                    <a class=\"page-link\" href=\"#\">Previous</a>\n                </li>\n\n                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirstActive") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":71}}})) != null ? stack1 : "")
    + "\" data-page=\"1\">\n                    <a class=\"page-link\" href=\"#\">1</a>\n                </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"secondLabel") : depth0)) != null ? lookupProperty(stack1,"render") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"thirdLabel") : depth0)) != null ? lookupProperty(stack1,"render") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fourthLabel") : depth0)) != null ? lookupProperty(stack1,"render") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":31,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fifthLabel") : depth0)) != null ? lookupProperty(stack1,"render") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"sixthLabel") : depth0)) != null ? lookupProperty(stack1,"render") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":43,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"seventhLabel") : depth0)) != null ? lookupProperty(stack1,"render") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":49,"column":23}}})) != null ? stack1 : "")
    + "\n                <li class=\"page-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNextDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":36},"end":{"line":51,"column":74}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNextDisabled") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":75},"end":{"line":51,"column":129}}})) != null ? stack1 : "")
    + ">\n                    <a class=\"page-link\" href=\"#\">Next</a>\n                </li>\n            </ul>\n        </nav>\n    </div>\n</div>";
},"useData":true});