var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td class=\"align-middle\">\n                    <a href=\"#offenders/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "</a>\n                </td>\n                <td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/offenderName.js")).call(alias3,depth0,{"name":"offenderName","hash":{},"data":data,"loc":{"start":{"line":16,"column":41},"end":{"line":16,"column":62}}}))
    + "</td>\n                <td class=\"align-middle\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"reason") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":20,"column":29}}})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"badge reason-offender\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/customer/blacklist.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"customer/blacklist","hash":{},"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":85}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap\">\n        <thead>\n            <tr class=\"thead-light\">\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":39}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":41}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"offender.reason",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":43}}}))
    + "</th>\n            </tr>\n        </thead>\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"offenders") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":23,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n</div>";
},"useData":true});