import yuyan from '@/js/app/yuyan';

export default function paymentMethod(method) {
    if (method === 'credit') {
        return yuyan.t("contract.payment_method.credit");
    } else if (method === 'direct_debit') {
        return yuyan.t("contract.payment_method.direct_debit");
    } else if (method === 'money_transfer') {
        return yuyan.t("contract.payment_method.money_transfer");
    }
}
