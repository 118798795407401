import { Collection, history } from 'backbone';
import dayjs from 'dayjs';
import numeral from 'numeral';
import formToObject from '@/js/libs/form-utils';
import config from '@/js/app/config';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import PaginationView from '@/js/app/generic/views/pagination';
import SpinnerView from '@/js/app/generic/views/spinner';
import oauth2Client from '@/js/app/oauth2-client';
import ProductTypesCollection from '@/js/app/product-types/collections/product-types';
import ProductTypeCntrView from '@/js/app/product-types/views/cntr';
import yuyan from '@/js/app/yuyan';
import ProductContractModel from '../models/product-contract';
import addTemplate from '../templates/edit.hbs';
import listTemplate from '../templates/list.hbs';
import ProductView from './product';

export default class ProductContractListAddView extends CardView {
    preinitialize(options = {}) {
        // Initialize defaults
        this.title = yuyan.t('productContract.list');

        this.events = {
            'click [data-action="add"]': this.renderAdd,
            'click [data-action="cancel"]': this.renderList,
            'change [name="start_date"]': this.handleStartDateChange,
            'change [data-rate="flat"]': this.handleFlatRateChange,
            'click [data-action="autofill"]': this.autoFill,
            'submit form#frmEditProductContract': this.saveAdd
        };

        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];

        if (options.buttons === 'hide') {
            this.buttons = [];
        }

        this.contractModel = options.contractModel || null;

        this.productContractModel = new ProductContractModel({
            status: 'pending',
            ...options.modelAttributes
        });
    }

    initialize() {
        this.subviews = {
            product: new ProductView(),
            pagination: new PaginationView({
                collection: this.collection
            }),
            productTypeCntr: new ProductTypeCntrView({
                collection: new ProductTypesCollection,
                field_id: "field-product_type_id"
            })
        };

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });

        // When collection updates/resets; renderList
        this.listenTo(this.collection, 'update reset', this.renderList);
    }

    render() {
        console.debug('ProductContractListAdd#render');

        this.renderCard();

        return this;
    }

    renderList() {
        console.debug('ProductContractListAdd#renderList');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('addBtnCntr')
            .renderListTemplate();

        return this;
    }

    renderListTemplate() {
        console.debug('ProductContractListAdd#renderListTemplate');

        // Attach listTemplate to cardBody with product contract collection
        this.cardBody.innerHTML = listTemplate({
            productContracts: this.collection.toJSON(),
        });

        this.subviews.pagination.setElement(this.cardBody).render();

        return this;
    }

    renderAdd() {
        console.debug('ProductContractListAdd#renderAdd');

        this.hideButtonContainer('addBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderAddTemplate();

        return this;
    }

    renderAddTemplate() {
        console.debug('ProductContractListAdd#renderAddTemplate');

        // Attach addTemplate to el
        this.cardBody.innerHTML = addTemplate();

        // Contract ID
        this.el.querySelector('[data-slot="contractId"]').innerText = this.productContractModel.get('contract_id');

        // Contract ID
        this.el.querySelector('[data-slot="shopName"]').innerText = this.contractModel.get('room').shop.name;

        this.subviews.product.setShopId(this.contractModel.get('room').shop_id);

        // Hide #divStatus
        this.el.querySelector('#divStatus').classList.add('d-none');

        // Set el of product view as #divProduct then render
        this.subviews.product.setElement(this.el.querySelector('#divProduct')).render();

        return this;
    }

    setFees(fees) {
        console.debug('ProductContractListAdd#setFees');

        this.defaultFees = fees;
    }

    handleStartDateChange(e) {
        console.debug('ProductContractListAdd#handleStartDateChange');

        if (e instanceof Object) {
            // Set payment due date to 3 days before start date
            this.el.querySelector('#field-payment_due_date').value = dayjs(e.currentTarget.value).subtract(3, 'day').format('YYYY-MM-DD');

            const price = this.el.querySelector('[name="price"]').value;
            if (price !== '') {
                this.changeFirstMonth(Number(price));
            }
        }
    }

    handleFlatRateChange(e) {
        console.debug('ProductContractListAdd#handleFlatRateChange');

        if (e instanceof Object) {
            const element = e.currentTarget;
            const value = numeral(element.value).value();
            this.changeFirstMonth(value);
        }
    }

    changeFirstMonth(rate) {
        console.debug('ProductContractListAdd#changeFirstMonth');

        const processedStartDate = new Date(this.el.querySelector('[name="start_date"]').value);
        const daysOfMonth = new Date(processedStartDate.getFullYear(), processedStartDate.getMonth() + 1, 0).getDate();
        const remainingDays = daysOfMonth - processedStartDate.getDate() + 1;
        const firstMonth = Math.round((rate / daysOfMonth) * remainingDays);

        this.$el.find('[name="prorated_month"]').val(firstMonth).trigger('change');
    }

    autoFill() {
        console.debug('ProductContractListAdd#autoFill');

        const params = {
            start_date: this.el.querySelector('[name="start_date"]').value,
            price: this.subviews.product.typeModel.get('price'),
            free_days: this.subviews.product.typeModel.get('free_days')
        };

        const url = new URL(config.api.url + 'product-contracts/costs');
        url.search = new URLSearchParams(params);

        oauth2Client.fetch(url, {})
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.json();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((data) => {
                _.each(data, (val, key) => {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector(`[name="${key}"]`);

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                });
            });
    }

    saveAdd(e) {
        console.debug('ProductContractListAdd#saveAdd');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Trigger save of productContract with data as attributes
            this.productContractModel.save(data, {
                // On success: navigate
                success: this.navigate,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    errorOnSave(model, response) {
        console.debug('ProductContractListAdd#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    navigate(model) {
        console.debug('ProductContractListAdd#navigate');

        // Navigate to product-contracts/:id
        history.navigate('product-contracts/' + model.id, { trigger: true });
    }
}
