var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":33}}}))
    + "</h1>\n<hr class=\"my-3\">\n\n<form id=\"frmSearch\" class=\"container mb-3\" autocomplete=\"off\">\n    <div class=\"form-group row\">\n        <label for=\"field-shop\" class=\"col-form-label col-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.shop",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":61},"end":{"line":6,"column":82}}}))
    + "</label>\n        <div class=\"col-2\">\n            <div id=\"divShop\"></div>\n        </div>\n\n        <label for=\"field-product_type\" class=\"col-form-label offset-2 col-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":78},"end":{"line":11,"column":102}}}))
    + "</label>\n        <div class=\"col-3\">\n            <div id=\"divProductType\"></div>\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label for=\"field-contract_id\" class=\"col-form-label col-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":68},"end":{"line":18,"column":90}}}))
    + "</label>\n        <div class=\"col-2\">\n            <input id=\"field-contract_id\" name=\"contract_id\" type=\"text\" class=\"form-control\">\n        </div>\n    </div>\n\n    <div class=\"forn-group row\">\n        <label class=\"col-form-label col-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":44},"end":{"line":25,"column":80}}}))
    + "</label>\n        <div class=\"col\">\n            <div class=\"d-flex flex-wrap border-bottom\">\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"pending\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.pending.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":68}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"price_confirmed\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.price_confirmed.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":76}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"preparing\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.preparing.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":43,"column":70}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"active\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.active.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":49,"column":67}}}))
    + "\n                    </label>\n                </div>\n            </div>\n\n            <div class=\"d-flex flex-wrap border-bottom\">\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"withdraw_requested\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.withdraw_requested.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":58,"column":79}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"withdrawn\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.withdrawn.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":64,"column":70}}}))
    + "\n                    </label>\n                </div>\n            </div>\n\n            <div class=\"d-flex flex-wrap\">\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"cancel_applied\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.cancel_applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":24},"end":{"line":73,"column":75}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"billing_stopped\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.billing_stopped.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":24},"end":{"line":79,"column":76}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"status\" type=\"checkbox\" class=\"form-check-input\" value=\"cancelled\">\n                        "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.cancelled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":24},"end":{"line":85,"column":70}}}))
    + "\n                    </label>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-md-auto\">\n            <button type=\"submit\" class=\"btn btn-primary custom-btn\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":69},"end":{"line":94,"column":91}}}))
    + "</button>\n        </div>\n    </div>\n</form>\n\n<div id=\"listAddProductContract\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});