import { Collection } from 'backbone';

export default class ReportsCollection extends Collection {
    byDate(year, month, chain) {
        let filtered = this.filter(function (record) {
            return record.get('year') === year && record.get('month') === month;
        });

        if (chain) {
            return new ReportsCollection(filtered);
        } else {
            return filtered;
        }
    }

    byOwner(owner, chain) {
        let filtered = this.filter(function (record) {
            return record.get('owner_id') === owner;
        });

        if (chain) {
            return new ReportsCollection(filtered);
        } else {
            return filtered;
        }
    }

    byShop(shop, chain) {
        let filtered = this.filter(function (record) {
            return record.get('shop_id') === shop;
        });

        if (chain) {
            return new ReportsCollection(filtered);
        } else {
            return filtered;
        }
    }
}