import { View, Collection } from 'backbone';
import CityView from '@/js/app/city/views/index';
import SpinnerView from '@/js/app/generic/views/spinner';

export default class CityCntrView extends View {
    initialize(options) {
        // Create subviews
        this.subviews = {
            cities: new CityView({
                collection: this.collection,
                id: options.field_id,
                attributes: {
                    name: options.field_name,
                    'data-number': ''
                }
            })
        };

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('CityCntr#render');

        // Attach cities view to el
        while (this.el.firstChild) {
            this.el.removeChild(this.el.firstChild);
        }
        this.el.appendChild(this.subviews.cities.el);

        return this;
    }

    setValue(value) {
        console.debug('CityCntr#setValue');

        this.subviews.cities.setValue(value);
    }
}
