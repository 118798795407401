import CardView from '@/js/app/generic/views/card';
import oauth2Client from '@/js/app/oauth2-client';
import yuyan from '@/js/app/yuyan';
import template from '../templates/bills.hbs';
import tableTemplate from '../templates/bills-table.hbs';
import noBillTemplate from '../templates/bills-no-bill.hbs';

export default class ContractBillsView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('contract.bills.title');
    }

    initialize() {
        this.getCurrentBill();
        this.getProjectedBill();

        this.listenTo(this.model, 'change', this.getProjectedBill);
    }

    render() {
        console.debug('ContractBills#render');

        this.renderCard();

        this.cardBody.innerHTML = template();

        return this;
    }

    async getCurrentBill() {
        console.debug('ContractBills#getCurrentBill');

        const response = await oauth2Client.fetch(`${this.model.url()}/current-bill`, {})
        const responseData = await response.json();

        if (response.ok) {
            if (responseData) {
                this.el.querySelector('#current').innerHTML = tableTemplate({
                    bill: responseData
                });
            } else {
                this.el.querySelector('#current').innerHTML = noBillTemplate({
                    label: yuyan.t(`contract.bills.no_current_bill`)
                });
            }
        } else {
            this.el.querySelector('#current').innerHTML = noBillTemplate({
                label: yuyan.t(`contract.bills.error`),
                error: true
            });
        }
    }

    async getProjectedBill() {
        console.debug('ContractBills#getProjectedBill');

        const response = await oauth2Client.fetch(`${this.model.url()}/projected-bill`, {})
        const responseData = await response.json();

        if (response.ok) {
            if (responseData) {
                this.el.querySelector('#projected').innerHTML = tableTemplate({
                    bill: responseData
                });
            } else {
                this.el.querySelector('#projected').innerHTML = noBillTemplate({
                    label: yuyan.t(`contract.bills.no_projected_bill`)
                });
            }
        } else {
            this.el.querySelector('#projected').innerHTML = noBillTemplate({
                label: yuyan.t(`contract.bills.error`),
                error: true
            });
        }
    }
}
