import { View } from 'backbone';
import FileCollection from '../collections/files';
import template from '../templates/card.hbs';
import FileDownloadsCardItemView from './card-item';

// Report types

/**
 * Upload and download with history report type
 */
const UPLOAD = 'UPLOAD';

export default class FileDownloadsCardView extends View {
    preinitialize() {
        this.collection = new FileCollection();
        this.files = [
            {
                type: {
                    label: 'プライバシーポリシー',
                    value: 'privacy_policy'
                },
                cardItemType: UPLOAD,
            },
            {
                type: {
                    label: 'ご利用時の注意事項',
                    value: 'usage_precautions'
                },
                cardItemType: UPLOAD,
            },
            {
                type: {
                    label: '解約届',
                    value: 'cancel_notice'
                },
                cardItemType: UPLOAD,
            },
            {
                type: {
                    label: 'セキュリティーキー等返却用紙',
                    value: 'key_return_form'
                },
                cardItemType: UPLOAD,
            },
            {
                type: {
                    label: 'ご解約手続きの流れ',
                    value: 'cancellation_flow'
                },
                cardItemType: UPLOAD,
            },
        ];
        this.previousViews = [];
    }

    initialize() {
        this.listenTo(this.collection, 'sync', this.handleCollectionSync);
    }

    render() {
        console.debug('FileDownloadsCard#render');

        this.el.innerHTML = template();

        this.renderCards();

        return this;
    }

    handleModelChange() {
        console.debug('FileDownloadsCard#handleModelChange');

        this.render();

        this.collection.fetch();
    }

    handleCollectionSync() {
        console.debug('FileDownloadsCard#handleCollectionSync');

        this.renderCards();
    }

    renderCards() {
        console.debug('FileDownloadsCard#renderCards');

        this.trigger('sync');

        const el = this.el.querySelector('[data-slot="items"]');

        while (this.previousViews.length > 0) {
            const view = this.previousViews.pop();
            view.remove();
        }

        const cardItemListFragment = document.createDocumentFragment();

        this.files.forEach(file => {
            const viewOptions = {
                type: file.type,
                collection: new FileCollection(this.collection.filter(model => model.get('type') === file.type.value)),
                criteria: this.criteria,
                hasUpload: file.hasUpload,
            };

            viewOptions.hasUpload = true;

            const view = new FileDownloadsCardItemView(viewOptions);
            this.previousViews.push(view);

            cardItemListFragment.appendChild(view.render().el);
        });

        el.appendChild(cardItemListFragment);

        return this;
    }
}
