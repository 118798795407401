import { View } from 'backbone';
import template from '../templates/table-row.hbs';
import PriceCheckSkipModalView from './skip-modal';

export default class PriceCheckRowView extends View {
    preinitialize(options) {
        this.tagName = 'tr';
        this.events = {
            'click [data-action="skip"]': this.handleClickSkip,
        };

        this.allShops = options.allShops;
    }

    initialize() {
        this.listenTo(this.model, 'remove', this.handleModelRemove);
    }

    render() {
        console.debug('PriceCheckRowView#render');

        // Attach template to el
        this.el.innerHTML = template({
            contract: this.model.data,
            allShops: this.allShops,
        });

        return this;
    }

    handleClickSkip() {
        console.debug('PriceCheckRowView#handleClickSkip');

        // Show skip contract modal
        const skipContractConfirmation = new PriceCheckSkipModalView({
            model: this.model,
        });

        skipContractConfirmation.render();
    }

    handleModelRemove() {
        console.debug('PriceCheckRowView#handleModelRemove');

        // Remove view from DOM
        this.remove();
    }
}