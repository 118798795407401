import formatCurrency from '../formatCurrency';

export default function pmFeeTotal(rows) {
    let pm_fee = 0;

    for (let row of rows) {
        pm_fee += row.pm_fee;
    }

    pm_fee = formatCurrency(pm_fee);

    return '<td class="text-right">' + pm_fee + '</td>';
}
