var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">Confirmation</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n    <p>Are you sure you want to delete this record?</p>\n</div>\n\n<div class=\"modal-footer\">\n    <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":73},"end":{"line":14,"column":95}}}))
    + "</button>\n    <button type=\"button\" class=\"btn btn-danger\" data-action=\"delete\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":70},"end":{"line":15,"column":92}}}))
    + "</button>\n</div>";
},"useData":true});