var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"row no-gutters justify-content-center align-items-center mb-2\">\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"cancel_applied\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.cancel_applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":105}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <span class=\"progress-overlay\">自動</span>\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"billing_stopped\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.billing_stopped.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":106}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"cancelled\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.cancelled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":54},"end":{"line":21,"column":100}}}))
    + "</span>\n        </div>\n    </div>\n</div>";
},"useData":true});