import formToObject from '@/js/libs/form-utils';
import ModalView from '@/js/app/generic/views/modal';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/cancellation-modal.hbs';

export default class ContractCamcellationModalView extends ModalView {
    preinitialize() {
        this.events = {
            'submit form': this.handleFormSubmit,
        };
        this.preinitializeModal();
    }

    render() {
        console.debug('ContractCamcellationModalView#render');

        this.renderModal({ size: 'lg', centered: true })
            .modalContent.innerHTML = template();


        return this;
    }

    handleFormSubmit(e) {
        console.debug('ContractCamcellationModalView#handleFormSubmit');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            const form = e.currentTarget;
            form.classList.add('was-validated');

            this.cancelContract(form);
        }
    }

    async cancelContract(form) {
        console.debug('ContractCamcellationModalView#cancelContract');

        // Hide giraffe error
        const errorEl = this.el.querySelector('#cancellationError');
        errorEl.classList.add('d-none');

        // If form is invalid; return
        if (form.checkValidity() === false) {
            return;
        }

        const data = formToObject(form);

        try {
            const responseData = await oauth2Client.fetchJSON(this.model.url() + '/cancel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            this.model.set(responseData);

            this.$el.modal('hide');
        } catch (responseData) {
            // Set giraffe error text and show
            errorEl.innerText = responseData.message;
            errorEl.classList.remove('d-none');
        }
    }
}
