import { Collection } from 'backbone';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import template from '../templates/list.hbs';

export default class RoomHistoryListView extends CardView {
    preinitialize() {
        this.title = yuyan.t('roomHistory.title');
    }

    initialize() {
        // When collection updates/resets; renderTemplate
        this.listenTo(this.collection, 'update reset', this.renderTemplate);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('RoomHistoryList#render');

        this.renderCard();

        return this;
    }

    renderTemplate() {
        console.debug('RoomHistoryList#renderTemplate');

        // Attach template to cardBody with room history collection
        this.cardBody.innerHTML = template({
            roomHistory: this.collection.toJSON().map(row => {
                row.month = String(row.month).padStart(2, '0');

                return row;
            })
        });

        return this;
    }
}
