var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.titles",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":27}}}))
    + "</h1>\n<hr class=\"my-3\">\n\n<form id=\"frmSearch\" autocomplete=\"off\">\n    <div class=\"container mb-3\">\n        <div class=\"form-group row\">\n            <label for=\"field-name\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":86},"end":{"line":7,"column":107}}}))
    + "</label>\n            <div class=\"col-sm col-lg-6\">\n                <input id=\"field-name\" name=\"name\" type=\"text\" class=\"form-control\">\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-tel\" class=\"col-form-label col-md-3 col-lg-4\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.tel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":84},"end":{"line":16,"column":104}}}))
    + "</label>\n                    <div class=\"col-md col-lg-5\">\n                        <input id=\"field-tel\" name=\"tel\" type=\"text\" class=\"form-control\" maxlength=\"16\" pattern=\"^[0-9]{10,16}$\">\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-email\" class=\"col-form-label col-md-3 col-lg-4\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":86},"end":{"line":25,"column":108}}}))
    + "</label>\n                    <div class=\"col-md\">\n                        <input id=\"field-email\" name=\"email\" type=\"text\" class=\"form-control\">\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field-shop\" class=\"col-form-label col-md-4 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":77},"end":{"line":34,"column":94}}}))
    + "</label>\n            <div class=\"position-relative col-lg-2\">\n                <div id=\"divShop\"></div>\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":69},"end":{"line":41,"column":101}}}))
    + "</label>\n            <div class=\"col-sm\">\n                <div class=\"d-flex flex-wrap\">\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"anti_social\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.cancellation_invoice",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":113},"end":{"line":46,"column":160}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"force_cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.force_cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":114},"end":{"line":51,"column":153}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"claimer\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.claimer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":109},"end":{"line":56,"column":143}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"uncontactable\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.uncontactable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":115},"end":{"line":61,"column":155}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"unpaid_1\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.unpaid_1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":110},"end":{"line":66,"column":145}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"unpaid_2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.unpaid_2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":110},"end":{"line":71,"column":145}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"unpaid_3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.unpaid_3",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":110},"end":{"line":76,"column":145}}}))
    + "\n                        </label>\n                    </div>\n                    <div class=\"form-check-inline\">\n                        <label class=\"col-form-label form-check-label\">\n                            <input name=\"blacklist\" type=\"checkbox\" class=\"form-check-input\" value=\"cancellation_invoice\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.cancellation_invoice",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":122},"end":{"line":81,"column":169}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.second_contact.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":69},"end":{"line":89,"column":106}}}))
    + "</label>\n            <div class=\"col-sm\">\n                <div class=\"form-check-inline\">\n                    <label class=\"col-form-label form-check-label\">\n                        <input name=\"secondary_contact\" type=\"checkbox\" class=\"form-check-input\" value=\"1\" data-unchecked-value=\"0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.second_contact.include",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":132},"end":{"line":93,"column":171}}}))
    + "\n                    </label>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row justify-content-end align-items-center\">\n            <div class=\"col-md-auto\">\n                <button type=\"submit\" class=\"btn btn-primary custom-btn\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":101,"column":73},"end":{"line":101,"column":95}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>\n\n<div id=\"listAddCustomer\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});