var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">再請求確認</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n    <p>この請求書を再請求してもよろしいですか？</p>\n\n    <div class=\"form-group row\">\n        <label for=\"field-payment_method\" class=\"col-form-label col-4\">\n            "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":49}}}))
    + "\n        </label>\n\n        <div class=\"col\">\n            <select id=\"field-payment_method\" class=\"form-control\">\n                <option value=\"credit\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.credit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":39},"end":{"line":19,"column":77}}}))
    + "</option>\n                <option value=\"direct_debit\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.direct_debit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":89}}}))
    + "</option>\n                <option value=\"money_transfer\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_method.money_transfer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":47},"end":{"line":21,"column":93}}}))
    + "</option>\n            </select>\n        </div>\n    </div>\n</div>\n\n<div class=\"modal-footer\">\n    <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":73},"end":{"line":28,"column":95}}}))
    + "</button>\n    <button type=\"button\" class=\"btn btn-primary\" data-action=\"confirm\">\n        <span class=\"spinner-border spinner-border-sm d-none\" role=\"status\" aria-hidden=\"true\"></span>\n        "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"generic.confirm",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":31}}}))
    + "\n    </button>\n</div>";
},"useData":true});