import { Events } from 'backbone';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import PriceCheckModel from './model';

export default class PriceCheckCollection {

    constructor() {
        _.extend(this, Events)
        this.data = null;
        this.total = null;
    }

    fetch(options) {
        this.trigger('request');

        oauth2Client.$ajax(_.extend({
            url: config.api.url + 'reports/price-check',
            dataType: 'json',
        }, options))
            .then((response) => {
                this.data = response.results.map((row) => {
                    return new PriceCheckModel(row, {
                        collection: this,
                    });
                });

                this.total = response.total;

                this.trigger('sync');
            })
            .catch(error => {
                this.trigger('error', error);
            });
    }

    remove(model) {
        // Find index of row in list
        const idx = _.findIndex(this.data, (row) => {
            return row.get('contractId') === model.get('contractId');
        });

        // If not found, return
        if (idx === -1) {
            return;
        }

        // Remove item from array at index
        this.data.splice(idx, 1);
    }
}
