import numeral from 'numeral';
import padId from "../../../handlebars-helpers/customer/id";
import formToObject from '@/js/libs/form-utils';
import { getJidai } from '@/js/libs/jidai';
import AddressView from '@/js/app/address/views/address';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import OffenderModal from '@/js/app/offender/views/modal';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import yuyan from '@/js/app/yuyan';
import readBlankTemplate from '../templates/read-blank.hbs';
import readPersonalTemplate from '../templates/read-personal.hbs';
import readCorporateTemplate from '../templates/read-corporate.hbs';
import updatePersonalTemplate from '../templates/edit-personal.hbs';
import updateCorporateTemplate from '../templates/edit-corporate.hbs';

export default class CustomerReadUpdateView extends CardView {
    preinitialize() {
        this.title = yuyan.t('customer.details');
        // Declare true instance props
        this.events = {
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.renderRead,
            'click [data-action="zendesk"]': this.goToZendesk,
            'click [data-action="offender"]': this.listAsOffender,
            'submit form': this.handleFormSubmit,
        }
    }

    constructor(options = {}) {
        super(options);

        // Initialize defaults
        this.buttons = [
            {
                cntrData: 'offenderBtnCntr',
                text: yuyan.t('offender.list_as_offender'),
                className: 'btn-danger',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'offender'
                    }
                ]
            },
            {
                cntrData: 'zendeskBtnCntr',
                text: yuyan.t('customer.zendesk'),
                className: 'btn-primary',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'zendesk'
                    }
                ]
            },
            {
                cntrData: 'editBtnCntr',
                text: yuyan.t('generic.edit'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'edit'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];

        if (options.isButtonsHidden) {
            this.buttons = [];
        }

        // Create subviews
        this.subviews = {
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop-id",
                readonly: true
            }),

            address: new AddressView({
                showBuilding: true
            }),

            deliveryAddress: new AddressView({
                postId: 'field-delivery-postcode',
                postName: 'delivery_postcode',
                prefId: 'field-delivery-prefecture',
                prefName: 'delivery_prefecture_id',
                cityId: 'field-delivery-city',
                cityName: 'delivery_city_id',
                addressId: 'field-delivery-address',
                addressName: 'delivery_address',
                buildingId: 'field-delivery-building',
                buildingName: 'delivery_building',
                showBuilding: true
            }),

            keyDeliveryAddress: new AddressView({
                postId: 'field-key-delivery-postcode',
                postName: 'key_delivery_postcode',
                prefId: 'field-key-delivery-prefecture',
                prefName: 'key_delivery_prefecture_id',
                cityId: 'field-key-delivery-city',
                cityName: 'key_delivery_city_id',
                addressId: 'field-key-delivery-address',
                addressName: 'key_delivery_address',
                buildingId: 'field-key-delivery-building',
                buildingName: 'key_delivery_building',
                showBuilding: true
            }),

            officeAddress: new AddressView({
                postId: 'field-office-postcode',
                postName: 'office_postcode',
                prefId: 'field-office-prefecture',
                prefName: 'office_prefecture_id',
                cityId: 'field-office-city',
                cityName: 'office_city_id',
                addressId: 'field-office-address',
                addressName: 'office_address',
                buildingId: 'field-office-building',
                buildingName: 'office_building',
                showBuilding: true
            }),
        };

        // When model changes; render
        this.listenTo(this.model, 'change', this.renderRead);

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });
    }

    render() {
        console.debug('CustomerReadUpdate#render');

        this.renderCard()
            .renderReadTemplate();

        return this;
    }

    renderRead() {
        console.debug('CustomerReadUpdate#renderRead');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('editBtnCntr')
            .renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('CustomerReadUpdate#renderReadTemplate');

        if (this.model.get('customer_type') === 'personal') {
            // Attach readPersonalTemplate to cardBody with customer model
            this.cardBody.innerHTML = readPersonalTemplate({
                customer: this.model.toJSON()
            });
        } else if (this.model.get('customer_type') === 'corporate') {
            // Attach readCorporateTemplate to cardBody with customer model
            this.cardBody.innerHTML = readCorporateTemplate({
                customer: this.model.toJSON()
            });
        } else {
            this.cardBody.innerHTML = readBlankTemplate();
        }

        return this;
    }

    renderEdit() {
        console.debug('CustomerReadUpdate#renderEdit');

        this.hideButtonContainer('editBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderEditTemplate();

        return this;
    }

    renderEditTemplate() {
        console.debug('CustomerReadUpdate#renderEditTemplate');

        if (this.model.get('customer_type') === 'personal') {
            // Attach updatePersonalTemplate to cardBody
            this.cardBody.innerHTML = updatePersonalTemplate();
        } else if (this.model.get('customer_type') === 'corporate') {
            // Attach updateCorporateTemplate to cardBody
            this.cardBody.innerHTML = updateCorporateTemplate();
        }

        this.loadYears();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
            disabled: true
        });

        // Set el of address view as #divAddress then render and set value
        this.subviews.address.setElement(this.el.querySelector('#divAddress')).render()
            .setValue(this.model.get('prefecture_id'), this.model.get('city_id'));

        // Set el of deliveryAddress view as #divDeliveryAddress then render and set value
        this.subviews.deliveryAddress.setElement(this.el.querySelector('#divDeliveryAddress')).render()
            .setValue(this.model.get('delivery_prefecture_id'), this.model.get('delivery_city_id'));

        // Set el of keyDeliveryAddress view as #divKeyDeliveryAddress then render and set value
        this.subviews.keyDeliveryAddress.setElement(this.el.querySelector('#divKeyDeliveryAddress')).render()
            .setValue(this.model.get('key_delivery_prefecture_id'), this.model.get('key_delivery_city_id'));

        // Set el of officeAddress view as #divOfficeAddress then render and set value
        this.subviews.officeAddress.setElement(this.el.querySelector('#divOfficeAddress')).render()
            .setValue(this.model.get('office_prefecture_id'), this.model.get('office_city_id'));

        // Pad customer ID
        this.el.querySelector('[data-slot="customerId"]').innerText = padId(this.model.get('id'));

        _.each(this.model.toJSON(), (value, key) => {
            if (Array.isArray(value)) {
                value.forEach(val => {
                    const field = this.el.querySelector(`[name="${key}"][value="${val}"]`);

                    // If field exists, set as checked
                    if (field) {
                        field.checked = true;
                    }
                });
            } else {
                // Initialize field as element with name = key
                let field = this.el.querySelector(`[name="${key}"]`);

                // If field exists
                if (field) {
                    // If field type is checkbox, set checked based on value
                    if (field.type === 'checkbox') {
                        field.checked = Boolean(value); // Force convert value to boolean
                    }
                    // Else if field type is radio
                    else if (field.type === 'radio') {
                        // Find field with the same value
                        field = this.el.querySelector(`[name="${key}"][value="${value}"]`);

                        // If field exists, set checked to true
                        if (field) {
                            field.checked = true;
                        }
                    }
                    // Else if numeral data attribute exists, format appropriately
                    else if (field.dataset.numeral) {
                        field.value = value ? numeral(value).format(field.dataset.numeral) : '';
                    }
                    // Else, set value
                    else {
                        field.value = value;
                    }
                }
            }
        });

        if (this.model.get('dob')) {
            this.el.querySelector('#dobYY').value = this.model.get('dob').substring(0, 4);
            this.el.querySelector('#dobMM').value = this.model.get('dob').substring(5, 7);
            this.el.querySelector('#dobDD').value = this.model.get('dob').substring(8);
        }

        this.subviews.shopcntr.setValue(this.model.get('shop_id'));

        if (this.model.get('giraffe_info_sent')) {
            this.el.querySelector('#giraffeInfoSent').innerText = yuyan.t('customer.giraffe_info_sent');
        } else {
            this.el.querySelector('#giraffeInfoSent').innerText = yuyan.t('customer.giraffe_info_not_sent');
            this.el.querySelector('#giraffeQrCodeAccessStatusContainer').classList.add('d-none');
        }

        return this;
    }

    loadYears() {
        console.debug('CustomerReadUpdate#loadYears');

        const year = (new Date).getFullYear();
        const options = document.createDocumentFragment();

        for (let i = 0; i < 120; i++) {
            const value = year - i;
            const o = document.createElement('option');
            o.value = value.toString();
            o.innerText = value + ' (' + getJidai(value) + ')';
            options.appendChild(o);
        }

        this.el.querySelector('#dobYY').appendChild(options);
    }

    goToZendesk() {
        console.debug('ContractReadUpdate#goToZendesk');
        window.open('https://trunkroomtokyo.zendesk.com/agent/users/' + this.model.get('zendesk_id') + '/requested_tickets', '_blank');
    }

    listAsOffender() {
        console.debug('CustomerReadUpdate#listAsOffender');

        const view = new OffenderModal({ customerModel: this.model });
        view.render();
    }

    handleFormSubmit(e) {
        console.debug('CustomerReadUpdate#handleFormSubmit');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            const dobYY = this.el.querySelector('#dobYY').value;
            const dobMM = this.el.querySelector('#dobMM').value;
            const dobDD = this.el.querySelector('#dobDD').value;
            const dobEl = this.el.querySelector('[name="dob"]');

            if (dobEl && dobYY && dobMM && dobDD) {
                dobEl.value = dobYY + '-' + dobMM + '-' + dobDD;
            }

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Initialize changes as result of changedAttributes of model based on data
            const changes = this.model.changedAttributes(data);

            // If changes exist
            if (changes !== false) {
                // Trigger save of model with changes as attributes
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    // On error: errorOnSave
                    error: this.handleSaveError
                });
            }
            // Else, trigger change of model
            else {
                this.model.trigger('change', this.model);
            }
        }
    }

    handleSaveError(model, response) {
        console.debug('CustomerReadUpdate#handleSaveError');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
