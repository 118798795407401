var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"mx-auto\">\n\n        <p class=\"lead text-center\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.are_you_sure",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":71}}}))
    + "</p>\n        <p class=\"text-center\">\n            <button type=\"button\" class=\"btn btn-primary\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.log_out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":88}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-secondary\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":60},"end":{"line":6,"column":89}}}))
    + "</button>\n        </p>\n\n</div>\n";
},"useData":true});