import { View } from 'backbone';
import ChangelogCollection from '@/js/app/changelog/collections/changelog';
import ChangelogModalView from '@/js/app/changelog/views/modal';
import yuyan from '@/js/app/yuyan';
import template from '../templates/action-bar.hbs';

export default class RoomActionBarView extends View {
    preinitialize(options) {
        // Initialize defaults
        this.events = {
            'click [data-modal="changelog"]': this.handleChangelogClick,
        };

        this.roomId = options.roomId;
    }

    render() {
        console.debug('RoomActionBar#render');

        // Attach template to el
        this.el.innerHTML = template();

        return this;
    }

    handleChangelogClick() {
        console.debug('RoomActionBar#handleChangelogClick');

        const view = new ChangelogModalView({
            title: `${yuyan.t('room.title')} ID: ${this.roomId} Changelog`,
            collection: new ChangelogCollection([], {
                resource: 'rooms',
                resourceId: this.roomId
            })
        });

        view.render();
    }
}
