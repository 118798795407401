import SpinnerView from '@/js/app/generic/views/spinner';
import { Collection, View } from 'backbone';
import template from '../../templates/cashflows/table.hbs';
import PmCashflowsItemView from './item';

export default class PmCashflowsTableView extends View {
    preinitialize() {
        this.lastClickedIndex = 0;
    }

    initialize() {
        this.listenTo(this.collection, 'reset', this.renderList);
        this.listenTo(this.collection, 'update', this.handleCollectionUpdate);

        const s = new SpinnerView;

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    renderList() {
        console.debug('PmCashflows#renderList');

        this.el.innerHTML = template();

        this.viewList = [];

        const trList = document.createDocumentFragment();
        this.collection.forEach(model => {
            const view = this.createView(model);
            this.viewList.push(view);
            trList.appendChild(view.render().el);
        });

        // Add rows to table body
        const el = this.el.querySelector('#cashflowTableBody');
        el.appendChild(trList);

        return this;
    }

    handleCollectionUpdate(collection, option) {
        console.debug('PmCashflows#handleCollectionUpdate');

        if (option.changes.removed.length > 0) {
            for (const removedModel of option.changes.removed) {
                // Find index of view
                const viewIndex = this.viewList.findIndex(view => view.model.id === removedModel.id);

                // Get view
                const view = this.viewList[viewIndex];

                this.stopListening(view, 'select deselect');

                if (view) {
                    view.remove();

                    // Remove view from list
                    this.viewList.splice(viewIndex, 1);
                }
            }
        }

        if (option.changes.added.length > 0) {
            const trList = document.createDocumentFragment();
            for (const addedModel of option.changes.added) {
                const view = this.createView(addedModel);
                this.viewList.push(view);
                trList.appendChild(view.render().el);
            }

            // Add rows to table body
            const el = this.el.querySelector('#cashflowTableBody');
            el.appendChild(trList);
        }

        return this;
    }

    handleCashflowSelect(payload) {
        console.debug('PmCashflows#handleCashflowSelect');

        const viewIndex = this.viewList.indexOf(payload.view);

        if (payload.shiftKey) {
            let viewSelectedList;

            if (viewIndex < this.lastClickedIndex) {
                viewSelectedList = this.viewList.slice(viewIndex + 1, this.lastClickedIndex + 1);
            } else if (viewIndex > this.lastClickedIndex) {
                viewSelectedList = this.viewList.slice(this.lastClickedIndex, viewIndex);
            }

            viewSelectedList.forEach(view => {
                view.isSelected = true;
                view.el.classList.add('table-primary');
                view.el.querySelector('input[type="checkbox"]').checked = true;
            });
        }

        this.lastClickedIndex = viewIndex;

        this.trigger('trt:has-selected');
    }

    handleCashflowDeselect(payload) {
        console.debug('PmCashflows#handleCashflowDeselect');

        const viewIndex = this.viewList.indexOf(payload.view);

        if (payload.shiftKey) {
            let viewDeselectedList;

            if (viewIndex < this.lastClickedIndex) {
                viewDeselectedList = this.viewList.slice(viewIndex + 1, this.lastClickedIndex + 1);
            } else if (viewIndex > this.lastClickedIndex) {
                viewDeselectedList = this.viewList.slice(this.lastClickedIndex, viewIndex);
            }

            viewDeselectedList.forEach(view => {
                view.isSelected = false;
                view.el.classList.remove('table-primary');
                view.el.querySelector('input[type="checkbox"]').checked = false;
            });
        }

        this.lastClickedIndex = viewIndex;

        if (!this.isAnyRowSelected()) {
            this.trigger('trt:none-selected');
        }
    }

    isAnyRowSelected() {
        return this.viewList.some(view => view.isSelected);
    }

    createView(model) {
        const view = new PmCashflowsItemView({
            model: model,
            attributes: {
                'data-id': model.id
            }
        });

        this.listenTo(view, 'trt:select', this.handleCashflowSelect);
        this.listenTo(view, 'trt:deselect', this.handleCashflowDeselect);

        return view;
    }
}