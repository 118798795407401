var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"btn custom-btn btn-dark\" data-action=\"edit\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"generic.edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":77},"end":{"line":14,"column":97}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"align-middle\">\n    <a href=\"#rooms/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"room_no") : stack1), depth0))
    + "</a>\n</td>\n<td class=\"align-middle\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"floorspace") : stack1), depth0))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"money.yen",{"name":"t","hash":{"value":__default(require("../../../handlebars-helpers/formatCurrency.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"base_rate") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":78}}})},"data":data,"loc":{"start":{"line":6,"column":25},"end":{"line":6,"column":80}}}))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"money.yen",{"name":"t","hash":{"value":__default(require("../../../handlebars-helpers/formatCurrency.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"campaign_rate") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":7,"column":47},"end":{"line":7,"column":82}}})},"data":data,"loc":{"start":{"line":7,"column":25},"end":{"line":7,"column":84}}}))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/shop/isShopDefault.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"campaign_duration") : stack1),{"name":"shop/isShopDefault","hash":{},"data":data,"loc":{"start":{"line":8,"column":25},"end":{"line":8,"column":72}}}))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/shop/isShopDefault.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"campaign_eligible_min_months") : stack1),{"name":"shop/isShopDefault","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":83}}}))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/room/status.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),{"name":"room/status","hash":{},"data":data,"loc":{"start":{"line":10,"column":25},"end":{"line":10,"column":54}}}))
    + "</td>\n<td class=\"align-middle text-wrap\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"notes") : stack1), depth0))
    + "</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../../../handlebars-helpers/isAllowed.js")).call(alias3,"consus:rooms",{"name":"isAllowed","hash":{},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":36}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "</td>";
},"useData":true});