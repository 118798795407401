import { View } from 'backbone';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import CustomerModel from '../models/customer';
import template from '../templates/picker.hbs';

export default class CustomerNamePickerView extends View {
    preinitialize(options = {}) {
        this.events = {
            'change #name-picker': this.customerChange,
        };
        this.model = new CustomerModel;

        this.contractModel = options.contractModel;
        this.customerModel = options.customerModel;
    }

    render() {
        console.debug('CustomerNamePicker#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Transform #name-picker to select2
        this.$el.find('#name-picker').select2({
            theme: 'bootstrap4',
            ajax: {
                url: config.api.url + 'customers',
                delay: 250,
                dataType: 'json',
                data: function (params) {
                    return {
                        name: params.term,
                        format: 'select2'
                    };
                },

                transport: function (params, success, failure) {
                    oauth2Client.$ajax(params)
                        .then(success)
                        .catch(failure);
                }
            }
        });

        return this;
    }

    renderName() {
        console.debug('CustomerNamePicker#renderName');

        // If customerModel or contractModel exists
        if (this.customerModel || this.contractModel) {
            // Initialize o as option element
            const o = document.createElement('option');

            // If customerModel exists
            if (this.customerModel) {
                // Set value and innerText of o via customerModel
                o.value = this.customerModel.get('id');
                o.innerText = this.customerModel.get('last_name') + this.customerModel.get('first_name');
            }
            // Else if contractModel exists
            else if (this.contractModel) {
                // Set value and innerText of o via contractModel
                o.value = this.contractModel.get('customer_id');
                o.innerText = this.contractModel.get('customer').last_name + this.contractModel.get('customer').first_name;
            }

            // Clear options of #name-picker then attach o
            const el = this.el.querySelector('#name-picker');
            while (el.firstChild) {
                el.removeChild(el.firstChild);
            }
            el.appendChild(o);
        }

        return this;
    }

    customerChange(e) {
        console.debug('CustomerNamePicker#customerChange');

        if (e instanceof Object) {
            e.preventDefault();

            // Get selected value
            this.customerId = e.target.value
        }

        // Set id of model
        this.model.set('id', this.customerId, { silent: true });

        // Trigger fetch of model
        this.model.fetch();
    }
}
