import { Collection } from 'backbone';
import config from '@/js/app/config';
import ShopModel from '../models/shop';

export default class ShopsCollection extends Collection {
    preinitialize() {
        // Initialize defaults
        this.url = config.api.url + 'shops';

        this.model = ShopModel;
    }
}
