var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<label for='status' class='row align-items-center justify-content-end mx-0 mb-3'>\n    <span class=\"col-4 text-right\"><strong>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":75}}}))
    + ":</strong></span>\n\n    <select id='field-status-filter' name='status' class='form-control col-2'>\n        <option value=''>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":25},"end":{"line":5,"column":55}}}))
    + "</option>\n        <option value='in_house'>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.in_house",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":68}}}))
    + "</option>\n        <option value='on_loan'>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.on_loan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":66}}}))
    + "</option>\n        <option value='on_loan_contractor'>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.on_loan_contractor",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":10,"column":14}}}))
    + "</option>\n        <option value='on_site'>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.on_site",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":66}}}))
    + "</option>\n        <option value='lost'>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.lost",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":29},"end":{"line":12,"column":60}}}))
    + "</option>\n        <option value='blocked'>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.blocked",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":66}}}))
    + "</option>\n    </select>\n\n</label>\n\n<div class='table-responsive'>\n    <table class='table table-sm table-hover text-nowrap'>\n        <thead>\n            <tr class='thead-light'>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":46}}}))
    + "</th>\n                <th id='thRoomNo'>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":34},"end":{"line":23,"column":49}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":52}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.memo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":44}}}))
    + "</th>\n                <th></th>\n            </tr>\n        </thead>\n        <tbody id='securityKeysTableBody'></tbody>\n    </table>\n</div>";
},"useData":true});