import { View } from 'backbone';
import oauth2Client from '@/js/app/oauth2-client';
import config from '@/js/app/config';
import SecurityKeyModel from '../models/security-key';
import template from '../templates/picker.hbs';

export default class SecurityKeyNumberPickerView extends View {
    preinitialize() {
        this.events = {
            'change #security-key-picker': this.securityKeyChange,
        };
    }

    initialize(options) {
        this.model = new SecurityKeyModel;

        this.shopId = options.shopId;
    }

    render() {
        console.debug('SecurityKeyNumberPicker#render');

        this.el.innerHTML = template();

        this.$el.find('#security-key-picker').select2({
            theme: 'bootstrap4',
            ajax: {
                url: config.api.url + 'security-keys',
                delay: 250,
                dataType: 'json',
                data: (params) => {
                    return {
                        shop_id: this.shopId,
                        number: params.term,
                        format: 'select2'
                    };
                },

                transport(params, success, failure) {
                    oauth2Client.$ajax(params)
                        .then(success)
                        .catch(failure);
                }
            }
        });

        return this;
    }

    securityKeyChange($e) {
        console.debug('SecurityKeyNumberPicker#securityKeyChange');

        if ($e instanceof Object) {
            $e.preventDefault();

            // Get form data
            const target = $e.target;
            this.securityKeyId = target.value
        }


        this.model.set('id', this.securityKeyId, { silent: true });

        this.model.fetch();
    }
}
