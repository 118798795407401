var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <th>"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"room.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":35}}}))
    + "</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <th>"
    + container.escapeExpression(__default(require("../../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":37}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap border\">\n        <thead>\n            <tr class=\"thead-light\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shopPerspective") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "                <th>Area</th>\n                <th>Target Rent</th>\n                <th>Asking Rent</th>\n                <th>Asking Rent<br>Difference</th>\n                <th>Asking Rent<br>Difference %</th>\n                <th>Contracted Rent</th>\n                <th>Contracted Rent<br>Difference</th>\n                <th>Contracted Rent<br>Difference %</th>\n            </tr>\n        </thead>\n        <tbody>\n            <tr>\n                <td colspan=\"9\">\n                    <div class=\"d-flex justify-content-center\">\n                        <div class=\"spinner-border\" role=\"status\">\n                            <span class=\"sr-only\">Loading...</span>\n                        </div>\n                    </div>\n                </td>\n            </tr>\n        </tbody>\n    </table>\n</div>";
},"useData":true});