var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>Unit Occupancy Report</h1>\n<hr class=\"my-3\">\n\n<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-shop-id\" class=\"col-form-label col-md-3 col-lg-4\">\n                    "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":37}}}))
    + "\n                </label>\n                <div class=\"position-relative col-md\">\n                    <div id=\"divShop\"></div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-lg\">\n            <div class=\"form-group row\">\n                <label for=\"field-date\" class=\"col-form-label col-md-3 col-lg-4\">\n                    "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(alias1,"reports.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":40}}}))
    + "\n                </label>\n                <div class=\"col-md\" id=\"dateCntr\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-6 offset-lg-6\">\n            <div class=\"d-flex justify-content-end align-items-center\">\n                <div class=\"btn-group btn-group-toggle\" data-toggle=\"buttons\">\n                    <label class=\"btn btn-outline-success active\">\n                        <input type=\"radio\" name=\"mode\" value=\"count\" checked> Count\n                    </label>\n                    <label class=\"btn btn-outline-success\">\n                        <input type=\"radio\" name=\"mode\" value=\"area\"> Area\n                    </label>\n                </div>\n\n                <div class=\"custom-control custom-switch ml-3\">\n                    <input type=\"checkbox\" class=\"custom-control-input\" id=\"groupSwitch\" name=\"group\" checked>\n                    <label class=\"custom-control-label\" for=\"groupSwitch\">Group</label>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<canvas class=\"chart\" style=\"width: 100%; height: 400px\"></canvas>\n\n<div id=\"reportList\" class=\"mb-3\"></div>";
},"useData":true});