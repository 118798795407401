import ModalView from '@/js/app/generic/views/modal';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/giraffe-modal.hbs';

export default class CustomerGiraffeModalView extends ModalView {
    preinitialize() {
        this.events = {
            'click [data-action="register"]': this.handleRegisterClick,
            'keypress': this.handleKeypress,
        };
        this.preinitializeModal();
    }

    render() {
        console.debug('CustomerGiraffeModal#render');

        this.renderModal().modalContent.innerHTML = template();

        return this;
    }

    handleKeypress(e) {
        // If [enter] key pressed
        if (e.keyCode === 13) {
            this.register();
        }
    }

    handleRegisterClick() {
        console.debug('CustomerGiraffeModal#handleRegisterClick');

        this.register();
    }

    async register() {
        const errorEl = this.el.querySelector('#giraffeRegistrationError');
        errorEl.classList.add('d-none');

        const response = await oauth2Client.fetch(this.model.url() + '/register-giraffe', {
            method: 'POST'
        });

        const responseData = await response.json();

        if (response.ok) {
            this.model.set(responseData);
            this.$el.modal('hide');
        } else {
            if (responseData.name === 'GiraffeError') {
                errorEl.innerText = `Giraffe Error: ${responseData.code} - ${responseData.message}`;
            } else if (responseData.name === 'ValidationError') {
                errorEl.innerHTML = this.processValidationErrorContent(responseData);
            } else {
                errorEl.innerText = 'Something went wrong';
            }

            errorEl.classList.remove('d-none');
        }
    }

    processValidationErrorContent(error) {
        console.debug('CustomerGiraffeModal#processValidationErrorContent');

        let content = '';

        error.fields.forEach(field => {
            content += `${field.name}: ${field.message}<br>`;
        });

        return content;
    }
}
