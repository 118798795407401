import { Collection } from 'backbone';
import ModalView from '@/js/app/generic/views/modal';
import SpinnerView from '@/js/app/generic/views/spinner';
import CustomerFileItemView from './item';
import template from '../templates/list.hbs';

export default class CustomerFileListView extends ModalView {
    preinitialize() {
        this.preinitializeModal();
    }

    initialize() {
        // When collection updates; render
        this.listenTo(this.collection, 'add', this.addFileItem);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('CustomerFileList#render');

        this.renderModal({ size: 'xl' }).modalContent.innerHTML = template();

        this.collection.each((model) => {
            const fileItem = new CustomerFileItemView({
                model: model
            })

            this.$el.find('[data-slot="files"]').prepend(fileItem.render().el);
        });

        return this;
    }

    addFileItem(model) {
        console.debug('FileList#addFileItem');

        const fileItem = new CustomerFileItemView({
            model: model
        })

        this.$el.find('[data-slot="files"]').append(fileItem.render().el);
    }
}
