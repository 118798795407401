import { View } from 'backbone';
import ChangelogCollection from '@/js/app/changelog/collections/changelog';
import ChangelogModalView from '@/js/app/changelog/views/modal';
import yuyan from '@/js/app/yuyan';
import template from '../templates/action-bar.hbs';
import CustomerGiraffeModalView from './giraffe-modal';
import CustomerLoginHistoryModalView from './login-history-modal';

export default class CustomerActionBarView extends View {
    preinitialize(options) {
        // Initialize defaults
        this.events = {
            'click [data-modal="loginHistory"]': this.handleLoginHistoryClick,
            'click [data-modal="giraffe"]': this.handleRegisterInGiraffeClick,
            'click [data-modal="changelog"]': this.handleChangelogClick,
        };

        this.customerId = options.customerId;
    }

    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('CustomerActionBar#render');

        // Attach template to el
        this.el.innerHTML = template({
            isGiraffeRegisterable: this.model.get('shop').giraffe_facility_id && !this.model.get('giraffe_info_sent')
        });

        return this;
    }

    handleLoginHistoryClick() {
        console.debug('CustomerActionBar#handleLoginHistoryClick');

        const view = new CustomerLoginHistoryModalView({
            customerId: this.customerId
        });

        view.render();
    }

    handleRegisterInGiraffeClick() {
        console.debug('CustomerActionBar#handleRegisterInGiraffeClick');

        const view = new CustomerGiraffeModalView({
            model: this.model
        });

        view.render();
    }

    handleChangelogClick() {
        console.debug('CustomerActionBar#handleChangelogClick');

        const view = new ChangelogModalView({
            title: `${yuyan.t('customer.title')} ID: ${this.customerId} Changelog`,
            collection: new ChangelogCollection([], {
                resource: 'customers',
                resourceId: this.customerId
            })
        });

        view.render();
    }
}
