import { Collection } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import RoomRowView from './row';
import listTemplate from '../templates/list.hbs';
import addTemplate from '../templates/edit.hbs';

export default class RoomListAddView extends CardView {
    preinitialize() {
        this.title = yuyan.t('room.list');
        this.events = {
            'click [data-action="add"]': this.renderAdd,
            'click [data-action="cancel"]': this.renderList,
            'submit form#frmEditRoom': this.saveAdd,
        };

        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];
    }

    initialize(options) {
        // Initialize defaults
        if (options) {
            this.shopId = options.shopId;
        }

        // Bind this on errorOnSave
        _.bindAll(this, 'errorOnSave');

        // When collection updates/resets; renderList
        this.listenTo(this.collection, 'update reset', this.renderList);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('RoomListAdd#render');

        this.renderCard();

        return this;
    }

    renderList() {
        console.debug('RoomListAdd#renderList');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('addBtnCntr')
            .renderListTemplate();

        return this;
    }

    renderListTemplate() {
        console.debug('RoomListAdd#renderListTemplate');

        // Attach listTemplate to el with rooms collection
        this.cardBody.innerHTML = listTemplate();

        const trList = document.createDocumentFragment();
        this.collection.each((room) => {
            trList.appendChild(new RoomRowView({
                model: room,
            }).render().el);
        });

        // Add rows to table body
        const el = this.el.querySelector('#roomsTableBody');
        while (el.firstChild) {
            el.removeChild(el.firstChild);
        }
        el.appendChild(trList);

        // Transform rooms table to DataTable
        this.$el.find('table').DataTable({
            searching: false,
            columnDefs: [
                { type: 'currency', targets: [0, 2, 3] },
                { width: '15%', targets: 2 },
                { width: '12%', targets: [3, 4, 5] },
                { orderable: false, targets: 8 }
            ]
        });

        return this;
    }

    renderAdd() {
        console.debug('ShopListAdd#renderAdd');

        this.hideButtonContainer('addBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderAddTemplate();

        return this;
    }

    renderAddTemplate() {
        console.debug('RoomListAdd#renderAddTemplate');

        // Attach addTemplate to el
        this.cardBody.innerHTML = addTemplate();

        this.el.querySelector('#field-target-base_rate').readOnly = false;

        return this;
    }

    saveAdd($e) {
        console.debug('RoomListAdd#saveAdd');

        if ($e instanceof Object) {
            $e.preventDefault();
            $e.stopPropagation();

            // Add .was-validated on form
            $e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if ($e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject($e.currentTarget);

            // Add shop_id on data object
            data.shop_id = this.shopId;

            // Trigger create of collection with data as model
            this.collection.create(data, {
                wait: true,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    errorOnSave(model, response) {
        console.debug('RoomListAdd#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
