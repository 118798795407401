import { Collection } from 'backbone';
import config from '@/js/app/config';
import CustomerFileModel from '../models/customer-file';

export default class CustomerFileCollection extends Collection {
    preinitialize() {
        // Reference to this collection's model.
        this.model = CustomerFileModel;
    }

    initialize(models, options) {
        this.customerId = options.customerId;
    }

    url() {
        return config.api.url + 'customers/' + this.customerId + '/files';
    }

    comparator(model) {
        return new Date(model.get('created_at')).getTime();
    }
}
