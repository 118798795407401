import config from '@/js/app/config';
import CollectionWithPagination from '@/js/app/generic/collections/pagination';
import ProductContractModel from '../models/product-contract';

export default class ProductContractsCollection extends CollectionWithPagination {
    preinitialize() {
        // Initialize defaults
        this.url = config.api.url + 'product-contracts';

        this.model = ProductContractModel;
    }
}
