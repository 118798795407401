var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"dropdown-item\" href=\"#\" data-lang=\""
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\">\n\n    <div class=\"content\">\n        <span>&copy; "
    + alias2(__default(require("../../../handlebars-helpers/year.js")).call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":29}}}))
    + " K.K. Trunkroom Tokyo</span>\n\n\n        <div class=\"btn-group dropup\">\n            <button id=\"btn-language-toggle\" type=\"button\" class=\"btn btn-light dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"currentLang") : depth0), depth0))
    + "\n            </button>\n            <div id=\"language-toggle\" class=\"dropdown-menu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"supportedLangs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":28,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n\n</div>";
},"useData":true});