import CardView from '@/js/app/generic/views/card';
import ContractDocumentItemView from './item';
import template from '../templates/list.hbs';

export default class ContractDocumentList extends CardView {
    preinitialize() {
        this.title = '契約書類';
    }

    initialize(options) {
        this.contractId = options.contractId;
        this.documentList = [
            {
                key: 'welcome-package',
                title: 'カギ送付書類'
            },
            {
                key: 'direct-debit-package',
                title: '口座振替書類'
            },
            {
                key: 'key-add-lost',
                title: 'カギ追加・紛失'
            },
            {
                key: 'direct-debit-reapply',
                title: '口振再提出'
            }
        ];
    }

    render() {
        console.debug('ContractDocumentList#render');

        this.renderCard().cardBody.innerHTML = template();

        this.documentList.forEach(doc => {
            const documentView = new ContractDocumentItemView({
                contractId: this.contractId,
                key: doc.key,
                title: doc.title
            });

            this.$el.find('#documentsTableBody').append(documentView.render().el);
        });

        return this;
    }
}
