import { View } from 'backbone';
import dayjs from 'dayjs';
import SelectYearMonth from '../generic/views/select-year-month';
import OwnerCntrView from '../owners/views/cntr';
import LeasingCommissionView from '../pm/views/leasing-commission';
import PmFeeView from '../pm/views/pm-fee';
import template from './index.hbs';
import LeasingCommissionCollection from './leasing-commission/collection';
import LeasingSpeedCollection from './leasing-speed/collection';
import LeasingSpeedView from './leasing-speed/view';
import PmFeeCollection from './pm-fee/collection';

export default class ReportIndexView extends View {
    preinitialize(options) {
        this.title = '';
        this.className = 'container';
        this.events = {
            'change [name="owner_id"], [name="year"], [name="month"]': this.filter,
            'click [data-action="export"]': this.export,
        };
        this.subviews = {
            ownerCntrView: new OwnerCntrView({
                firstOptionText: 'ALL',
                required: true
            }),
            selectYearMonth: new SelectYearMonth({
                includeNextYear: true,
                defaultPreviousMonth: true,
            }),
            reportList: null,
        };

        // Determine reportList view and collection
        if (options.report === 'speed') {
            this.title = 'reports.leasingSpeed.title';

            this.collection = new LeasingSpeedCollection;

            this.subviews.reportList = new LeasingSpeedView({
                collection: new LeasingSpeedCollection
            });
        } else if (options.report === 'commission') {
            this.title = 'reports.leasingCommission.title';

            this.collection = new LeasingCommissionCollection;

            this.subviews.reportList = new LeasingCommissionView({
                collection: new LeasingCommissionCollection
            });
        } else if (options.report === 'pm_fee') {
            this.title = 'reports.pmFee.title';

            this.collection = new PmFeeCollection;

            this.subviews.reportList = new PmFeeView({
                collection: new PmFeeCollection
            });
        } else {
            throw new Error('Report not found');
        }
    }

    initialize() {
        this.listenTo(this.collection, 'update reset', this.filterPreviousMonth);
    }

    render() {
        console.debug('ReportIndex#render');

        // Attach template to el
        this.el.innerHTML = template({
            title: this.title
        });

        // Set el of report list view as #reportList then render
        this.subviews.reportList.setElement(this.el.querySelector('#reportList')).render();

        // Set el of owner cntr view as #divOwners, render then fetch collection
        this.subviews.ownerCntrView.setElement(this.el.querySelector('#divOwners')).render().collection.fetch();

        // Attach select year/month subview to #dateCntr and render
        this.subviews.selectYearMonth.setElement(this.el.querySelector('#dateCntr')).render();

        return this;
    }

    filterPreviousMonth() {
        console.debug('ReportIndex#filterPreviousMonth');

        const lastMonth = dayjs().subtract(1, 'month');
        const year = lastMonth.year();
        const month = lastMonth.month() + 1;

        this.subviews.reportList.collection.set(this.collection.byDate(year, month));
    }

    filter() {
        console.debug('ReportIndex#filter');

        const owner_id = Number(this.el.querySelector('[name="owner_id"]').value);
        const year = Number(this.el.querySelector('[name="year"]').value);
        const month = Number(this.el.querySelector('[name="month"]').value);

        if (owner_id) {
            this.subviews.reportList.collection.set(this.collection.byDate(year, month, true).byOwner(owner_id), {
                reset: true
            });
        } else {
            this.subviews.reportList.collection.set(this.collection.byDate(year, month), {
                reset: true
            });
        }
    }

    export() {
        console.debug('ReportIndex#export');

        this.subviews.reportList.export(
            Number(this.el.querySelector('[name="year"]').value),
            Number(this.el.querySelector('[name="month"]').value),
            Number(this.el.querySelector('[name="owner_id"]').value)
        );
    }
}
