import config from '@/js/app/config';
import CollectionWithPagination from '@/js/app/generic/collections/pagination';
import ContractModel from '../models/contract';

export default class ContractsCollection extends CollectionWithPagination {
    preinitialize() {
        // Initialize defaults
        this.url = config.api.url + 'contracts';

        this.model = ContractModel;
    }
}
