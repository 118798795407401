import { Collection } from 'backbone';
import config from '@/js/app/config';
import CityModel from '../models/city';

export default class CitiesCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'cities';

        // Reference to this collection's model.
        this.model = CityModel;
    }
}
