var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col\">\n    <p>\n        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"file") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n        <a href=\"#\" title=\"Remove\" class=\"pull-right\" style=\"display:none\" data-action=\"clear\">\n            <i class=\"fa fa-times fa-lg\" style=\"color:#000000\" aria-hidden=\"true\"></i>\n        </a>\n    </p>\n    <div class=\"progress\">\n        <div class=\"progress-bar progress-striped progress-bar-animated\" role=\"progressbar\" aria-valuenow=\"0\" aria-valuemin=\"0\" aria-valuemax=\"100\"></div>\n    </div>\n    <p class=\"status-text\" style=\"font-weight: bold\"></p>\n</div>";
},"useData":true});