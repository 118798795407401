var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>\n                    <a href=\"#product-types/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n                </td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"br_item_code") : depth0), depth0))
    + "</td>\n                <td>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"money.yen",{"name":"t","hash":{"value":__default(require("../../../handlebars-helpers/formatCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":69}}})},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":71}}}))
    + "</td>\n                <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_intangible") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":86}}})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"productType.is_intangible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":46},"end":{"line":20,"column":79}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap\">\n        <thead>\n            <tr class=\"thead-light\">\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":44}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.br_item_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":52}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.price",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":45}}}))
    + "</th>\n                <th></th>\n            </tr>\n        </thead>\n\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"productTypes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":22,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n</div>";
},"useData":true});