import { View } from 'backbone';
import template from '../templates/pagination.hbs';
import pageLengthTemplate from '../templates/pagination-length.hbs';

export default class PaginationView extends View {
    preinitialize() {
        // Initialize defaults
        this.events = {
            'click th[data-sort]': this.handleSortClick,
            'change [data-pagination="length"]': this.handlePageLengthChange,
            'click .page-item[data-page]': this.handlePageClick,
        };
        this.sort = null;
        this.sortDirection = true;
    }

    render() {
        console.debug('PaginationView#render');

        const { metadata: { limit, countTotal, count, offset, page, pageTotal } } = this.collection;

        this.el.insertAdjacentHTML('afterbegin', pageLengthTemplate());
        this.el.querySelector('[data-pagination="length"]').value = limit;

        const isPreviousDisabled = page === 1;
        const isFirstActive = page === 1;

        let secondPage = '...',
            thirdPage = page - 1,
            fourthPage = page,
            fifthPage = page + 1,
            sixthPage = '...';

        if (this.isPageBetween(1, 4) || pageTotal < 8) {
            secondPage = 2;
            thirdPage = 3;
            fourthPage = 4;
            fifthPage = 5;

            if (pageTotal < 8) {
                sixthPage = 6
            }
        } else if (this.isPageBetween(pageTotal - 3, pageTotal)) {
            thirdPage = pageTotal - 4;
            fourthPage = pageTotal - 3;
            fifthPage = pageTotal - 2;
            sixthPage = pageTotal - 1;
        }

        const secondLabel = {
            render: pageTotal >= 2,
            page: secondPage,
            active: page === secondPage,
            disabled: secondPage === '...'
        };

        const thirdLabel = {
            render: pageTotal >= 3,
            page: thirdPage,
            active: page === thirdPage,
        };

        const fourthLabel = {
            render: pageTotal >= 4,
            page: fourthPage,
            active: page === fourthPage,
        };

        const fifthLabel = {
            render: pageTotal >= 5,
            page: fifthPage,
            active: page === fifthPage,
        };

        const sixthLabel = {
            render: pageTotal >= 6,
            page: sixthPage,
            active: page === sixthPage,
            disabled: sixthPage === '...'
        };

        const seventhLabel = {
            render: pageTotal >= 7,
            page: pageTotal,
            active: page === pageTotal
        }

        const isNextDisabled = page === pageTotal;

        const from = offset + 1;
        const to = offset + count;

        this.el.insertAdjacentHTML('beforeend', template({
            info: `Showing ${from} to ${to} of ${countTotal} entries`,
            isPreviousDisabled,
            isFirstActive,
            secondLabel,
            thirdLabel,
            fourthLabel,
            fifthLabel,
            sixthLabel,
            seventhLabel,
            isNextDisabled
        }));

        if (this.sort) {
            this.el.querySelector(`th[data-sort="${this.sort}"]`).classList.add(this.sortDirection ? 'asc' : 'desc');
        }

        return this;
    }

    handleSortClick(e) {
        console.debug('PaginationView#handleSortClick');

        const sortEl = e.currentTarget;
        const sort = sortEl.dataset.sort;

        if (this.sort === sort) {
            if (sortEl.classList.contains('asc')) {
                this.sortDirection = false;
                sortEl.classList.replace('asc', 'desc');
                this.collection.setSort(`-${sort}`);
            } else {
                this.sortDirection = true;
                sortEl.classList.replace('desc', 'asc');
                this.collection.setSort(`${sort}`);
            }
        } else {
            this.sort = sort;
            this.sortDirection = true;
            sortEl.classList.add('asc');
            this.collection.setSort(`${sort}`);
        }

        this.el.querySelectorAll('th[data-sort]').forEach(el => {
            if (el.dataset.sort !== sort) {
                el.classList.remove('asc', 'desc');
            }
        });
    }

    handlePageClick(e) {
        console.debug('PaginationView#handlePageClick');

        e.preventDefault();

        const { metadata: { page: current } } = this.collection;

        let page = e.currentTarget.dataset.page;
        let newPage = current;

        if (page === 'previous') {
            newPage--;
        } else if (page === 'next') {
            newPage++;
        } else {
            newPage = Number(page);
        }

        if (newPage !== current) {
            this.collection.setPage(newPage);
        }
    }

    handlePageLengthChange(e) {
        console.debug('PaginationView#handlePageLengthChange');

        this.collection.setLimit(Number(e.currentTarget.value));
    }

    // Utility function
    isPageBetween(min, max) {
        const { metadata: { page } } = this.collection;

        return page >= min && page <= max;
    }
}
