import { View } from 'backbone';
import buttonTemplate from '../templates/button.hbs';
import cardTemplate from '../templates/card.hbs';
import hiddenInputTemplate from '../templates/hidden-input.hbs';

export default class CardView extends View {
    constructor(options = {}) {
        super(options);

        // Initialize defaults
        this.title = this.title || '';
        this.buttons = this.buttons || [];
    }

    renderCard() {
        console.debug('Card#renderCard');

        this.el.innerHTML = cardTemplate({ title: this.title });

        this.renderButtons();

        this.cardBody = this.el.querySelector('[data-part="body"]');

        return this;
    }

    renderButtons() {
        console.debug('Card#renderButtons');

        let buttonHTML = '';
        this.buttons.forEach(button => {
            if (button.isHiddenInput) {
                buttonHTML += hiddenInputTemplate(button);
            } else {
                buttonHTML += buttonTemplate(button);
            }
        });

        this.el.querySelector('[data-part="buttons"]').innerHTML = buttonHTML;

        return this;
    }

    showButtonContainer(container) {
        console.debug('Card#showButtonContainer');

        const cntrEl = this.el.querySelector(`[data-part="${container}"]`);
        if (cntrEl) {
            cntrEl.classList.remove('d-none');
        }

        return this;
    }

    hideButtonContainer(container) {
        console.debug('Card#hideButtonContainer');

        const cntrEl = this.el.querySelector(`[data-part="${container}"]`);
        if (cntrEl) {
            cntrEl.classList.add('d-none');
        }

        return this;
    }
}
