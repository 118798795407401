import { appInsights } from '../app';
import config from './config';
import oAuth2Client from './oauth2-client';

/**
 * User
 */
class User {
    constructor() {
        /** @type {object} */
        /** @todo Convert to Backbone model when users allowed to modify */
        this.info = {};

        /** @type {array} */
        this.permissions = [];

        /** @type {object} */
        /** @todo Convert to Backbone model when users allowed to modify */
        this.preferences = {};
    }

    /**
     * Fetch user info
     */
    fetchInfo() {
        console.debug('User#fetch');

        return oAuth2Client.$ajax({
            url: config.api.url + 'me',
        })
        .then((data) => {
            this.info = data.info;
            this.permissions = data.permissions;
            this.preferences = data.preferences;

            // Set authenticated user context for Application Insights
            appInsights.setAuthenticatedUserContext(this.info.id);
        })
        .catch((err) => {
            if (err.responseJSON) {
                if (err.responseJSON.message) {
                    // Log error message
                    console.error(err.responseJSON.message);
                }
            }

            // Throw new error
            throw new Error('Unable to fetch user info');
        });
    }

    /**
     * Get preference
     * @param {string} name
     * @return mixed
     */
    getPref(name) {
        return this.preferences[name] || null;
    }

    /**
     * Is allowed?
     * @param {string} permission
     * @return bool
     */
    isAllowed(permission) {
        return this.permissions.includes(permission);
    }

    /**
     * Clear info
     */
    clearInfo() {
        console.debug('Clear user info');

        // Clear authenticated user context for Application Insights
        appInsights.clearAuthenticatedUserContext(this.info.id);

        this.info = {};
        this.permissions = [];
        this.preferences = {};
    }
}

export default new User();