import { Collection } from 'backbone';
import CardView from '@/js/app/generic/views/card';
import PaginationView from '@/js/app/generic/views/pagination';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import listTemplate from '../templates/product-contract-list.hbs';

export default class DashboardProductContractListView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('productContract.list');
    }

    initialize() {
        this.subviews = {
            pagination: new PaginationView({
                collection: this.collection
            })
        };

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });

        // When collection updates/resets; renderListTemplate
        this.listenTo(this.collection, 'update reset', this.renderListTemplate);
    }

    render() {
        console.debug('ProductContractListAdd#render');

        this.renderCard();

        return this;
    }

    renderListTemplate() {
        console.debug('ProductContractListAdd#renderListTemplate');

        // Attach listTemplate to cardBody with product contract collection
        this.cardBody.innerHTML = listTemplate({
            productContracts: this.collection.toJSON(),
        });

        this.subviews.pagination.setElement(this.cardBody).render();

        return this;
    }
}
