import { View } from 'backbone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/card-item-history-item.hbs';

dayjs.extend(utc);

export default class FileDownloadsCardItemHistoryItemView extends View {
    preinitialize(options) {
        this.tagName = 'tr';
        this.type = options.type;
        this.events = {
            'click [data-action="download"]': this.handleDownloadClick,
        };
    }

    render() {
        console.debug('FileDownloadsCardItemHistoryItem#render');

        this.el.innerHTML = template({
            model: this.model.toJSON()
        });

        return this;
    }

    handleDownloadClick() {
        console.debug('FileDownloadsCardItemHistoryItem#download');

        const file = this.model.toJSON();

        oauth2Client.download(
            config.api.url + 'files/' + file.type + '/' + file.version,
            {
                method: 'GET'
            },
            {
                filename: `${this.type.label}_v${dayjs.utc(file.version).local().format('YYYYMMDDHHmmss')}.${file.filename.split('.')[1]}`
            }
        );
    }
}
