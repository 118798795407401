var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"cancel_short_settled\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_short_settled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":54},"end":{"line":23,"column":104}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"cancel_settled\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_settled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":54},"end":{"line":31,"column":98}}}))
    + "</span>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col\">\n        <span class=\"progress-overlay\">自動</span>\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"cancel_settled\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"contract.status.cancel_settled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":54},"end":{"line":41,"column":98}}}))
    + "</span>\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row no-gutters justify-content-center align-items-center mb-2\">\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"cancel_applied\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancel_applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":98}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <span class=\"progress-overlay\">自動</span>\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"billing_stopped\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.billing_stopped.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":99}}}))
    + "</span>\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShortContract") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":44,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"keys_returned\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.keys_returned.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":54},"end":{"line":51,"column":97}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"cancelled\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.cancelled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":54},"end":{"line":59,"column":93}}}))
    + "</span>\n        </div>\n    </div>\n</div>";
},"useData":true});