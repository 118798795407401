var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card bg-light\">\n    <div class=\"card-header\">\n        <div class=\"row align-items-center\">\n            <h2 class=\"col-md\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":31},"end":{"line":4,"column":40}}}) : helper)))
    + "</h2>\n\n            <div class=\"col-md\">\n                <div data-part=\"buttons\" class=\"row justify-content-end\"></div>\n            </div>\n        </div>\n    </div>\n    <div data-part=\"body\" class=\"card-body\"></div>\n</div>";
},"useData":true});