import numeral from 'numeral';

export default function formToObject(form) {
    const data = {};
    const fieldList = form.querySelectorAll('input,select,textarea');

    for (const field of fieldList) {
        if (field.name === '' || field.disabled) {
            continue;
        }

        let value;
        const fieldHasGroup = form.querySelectorAll('[name="' + field.name + '"]').length > 1;

        if (fieldHasGroup && field.type !== 'radio' && !data[field.name]) {
            data[field.name] = [];
        }

        // If field type is checkbox
        if (field.type === 'checkbox') {
            if (fieldHasGroup) {
                if (field.checked) {
                    if (field.value === '') {
                        value = null;
                    } else if (typeof field.dataset.number !== 'undefined') {
                        value = numeral(field.value).value();
                    } else {
                        value = field.value;
                    }
                } else {
                    continue;
                }
            } else {
                if (field.checked) {
                    const fieldValue = field.value;

                    if (fieldValue) {
                        if (typeof field.dataset.number !== 'undefined') {
                            value = numeral(fieldValue).value();
                        } else {
                            value = fieldValue;
                        }
                    } else {
                        value = true;
                    }
                } else {
                    const uncheckedValue = field.dataset.uncheckedValue;

                    if (uncheckedValue) {
                        if (typeof field.dataset.number !== 'undefined') {
                            value = numeral(uncheckedValue).value();
                        } else {
                            value = uncheckedValue;
                        }
                    } else {
                        value = false;
                    }
                }
            }
        }

        // Else if field type is radio
        else if (field.type === 'radio') {
            if (field.checked) {
                if (field.value === '') {
                    value = null;
                } else if (typeof field.dataset.number !== 'undefined') {
                    value = numeral(field.value).value();
                } else {
                    value = field.value;
                }
            } else {
                continue;
            }
        }

        // Else
        else {
            if (field.value === '') {
                value = null;
            }
            else if (field.type === 'number' || typeof field.dataset.number !== 'undefined') {
                value = field.value.replace(/\D/g, '');

                if (field.value === '') {
                    value = null;
                } else {
                    value = numeral(field.value).value();
                }
            } else {
                value = field.value;
            }
        }

        if (data[field.name]) {
            if (value !== undefined) {
                data[field.name].push(value);
            }
        } else {
            data[field.name] = value;
        }
    }

    // Convert empty arrays to null
    for (const [field] of Object.entries(data)) {
        if (Array.isArray(data[field]) && data[field].length === 0) {
            data[field] = null;
        }
    }

    return data;
}

export const Numeric = '0123456789';
export const Alpha = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz';
export const AlphaNumeric = Alpha + Numeric;
