export default {
    auth: {
        url: process.env.AUTH_URL || '',
        client_id: process.env.AUTH_CLIENT_ID || '',
        client_secret: '',
    },
    api: {
        url: process.env.API_URL || '',
    },
    webapp: {
        url: process.env.WEBAPP_URL || '',
    },
    applicationInsights: {
        connectionString: process.env.APPINSIGHTS_CONNECTION_STRING || '',
    },
    brandColors: () => tinygradient(['#C54387', '#A6238D']),
    chartColors: () => tinygradient([
        '#6F9EAF', '#A9294F', '#C7753D', '#F6D887', '#B1B493',
        '#008891', '#0F3057', '#ADE498', '#CD5D7D', '#D92027',
    ]).hsv(10, 'long').map(color => Object.create(color)),
    enableServiceWorker: JSON.parse(process.env.ENABLE_SERVICE_WORKER),
    freee: {
        clientId: process.env.FREEE_CLIENT_ID || '',
        redirectUri: process.env.FREEE_REDIRECT_URI || '',
    }
};
