import { Collection } from 'backbone';
import config from '@/js/app/config';

export default class ChangelogCollection extends Collection {
    initialize(models, options) {
        this.resource = options.resource;
        this.resourceId = options.resourceId;
    }

    url() {
        return `${config.api.url}${this.resource}/${this.resourceId}/changelog`;
    }
}