import { Collection } from 'backbone';
import config from '@/js/app/config';

export default class FilesCollection extends Collection {
    url() {
        return config.api.url + 'files/';
    }

    comparator(model) {
        return new Date(model.get('version')).getTime();
    }
}
