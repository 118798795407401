import { View } from 'backbone';
import ErrorModalView from '@/js/app/error/views/modal';
import uploader from '@/js/app/file-uploader/uploader';
import oAuth2Client from '@/js/app/oauth2-client';
import yuyan from '@/js/app/yuyan';
import template from '../templates/unit.hbs';
import ListCustomerFileModal from './list';

export default class CustomerFileUnitView extends View {
    preinitialize() {
        this.events = {
            'click #selectFile': this.handleSelectFileClick,
            'change #file_selector': this.handleFilesChange,
            'click [data-action="download"]': this.handleDownloadClick,
            'click #showList': this.handleClickShowList,
        };
    }

    initialize(options) {
        // Initialize defaults
        this.fileInputName = options.inputName || '';
        this.noInput = options.noInput || false;
    }

    render() {
        console.debug('CustomerFileUnit#render');

        if (this.collection) {
            if (this.collection.isEmpty()) {
                this.file = undefined;
            } else {
                this.file = this.collection.at(-1);
            }

            let pdfOnly = false;
            if (this.unitType === 'registration') {
                pdfOnly = true;
            }

            this.el.innerHTML = template({
                btnName: yuyan.t('customerFile.' + this.unitType),
                noInput: this.noInput,
                fileInputName: this.fileInputName,
                pdfOnly: pdfOnly,
                file: this.file ? this.file.toJSON() : this.file
            });
        }

        return this;
    }

    setupListener() {
        console.debug('CustomerFileUnit#setupListener');

        // When collection adds; render
        this.listenTo(this.collection, 'add remove', this.render);

        return this;
    }

    handleSelectFileClick() {
        console.debug('CustomerFileUnit#handleSelectFileClick');

        // Trigger click on hidden file selector
        this.$el.find('#file_selector').trigger('click');
    }

    handleFilesChange(e) {
        console.debug('CustomerFileUnit#handleFilesChange');

        const file = e.currentTarget.files[0];
        const name = e.currentTarget.name;

        // Check file size
        if (this.checkFileSize(file, 10)) {
            this.uploadFile(name, file);
        } else {
            // Display error message in modal
            const errorModalView = new ErrorModalView({
                error: {
                    name: 'ConsusUiError',
                    message: 'file_size'
                }
            });

            errorModalView.render();
        }

        // Clear file selector
        e.currentTarget.value = '';
    }

    checkFileSize(file, maxSize) {
        // Convert file size to MB
        const sizeInMB = file.size / 1024 / 1024;

        // Return whether file size is within max size
        return sizeInMB <= maxSize;
    }

    uploadFile(name, file) {
        console.debug('CustomerFileUnit#uploadFile');

        const uploadStatusContainer = this.$el.find('#uploadStatus');

        // Create file upload queue with handler
        uploader(name, file, {
            method: 'POST',
            url: this.collection.url() + '/',
        }, {
            uploadStatusContainer: uploadStatusContainer,
        })
            .then((response) => {
                // Add new file model to collection using response data
                this.collection.add(response[name]);
            });
    }

    handleDownloadClick($e) {
        console.debug('CustomerFileUnit#handleDownloadClick');

        $e.preventDefault();

        this.download();
    }

    download() {
        console.debug('CustomerFileUnit#download');

        oAuth2Client.download(
            this.file.url(),
            { method: 'GET' },
            { filename: this.file.get('filename') }
        );
    }

    handleClickShowList($e) {
        console.debug('CustomerFileUnit#handleClickShowList');

        $e.preventDefault();

        this.showList();
    }

    showList() {
        console.debug('CustomerFileUnit#showList');

        const listCustomerFileModal = new ListCustomerFileModal({
            collection: this.collection
        });

        listCustomerFileModal.render();
    }
}
