import { View } from 'backbone';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/action-bar.hbs';

export default class DashboardActionBarView extends View {
    preinitialize() {
        // Initialize defaults
        this.events = {
            'click [data-action="setupFreee"]': this.handleSetupFreeeClick,
        };
    }

    render() {
        console.debug('DashboardActionBar#render');

        // Attach template to el
        this.el.innerHTML = template();

        return this;
    }

    async handleSetupFreeeClick() {
        console.debug('DashboardActionBar#handleSetupFreeeClick');

        // Get state parameter from API
        const response = await oauth2Client.fetch(`${config.api.url}freee/state`, {});
        const { state } = await response.json();

        // Redirect to freee login
        window.location.href = `https://accounts.secure.freee.co.jp/public_api/authorize?client_id=${config.freee.clientId}&redirect_uri=${config.freee.redirectUri}&prompt=select_company&response_type=code&state=${state}`;
    }
}
