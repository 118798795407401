import { View } from 'backbone';
import template from '../templates/update-alert.hbs';

export default class UpdateAlertView extends View {
    preinitialize() {
        this.events = {
            'click [data-action="update"]': this.handleUpdateClick,
        };
    }

    initialize(options) {
        this.onUpdate = options.onUpdate;
    }

    render() {
        console.debug('UpdateAlert#render');

        this.el.innerHTML = template();

        return this;
    }

    handleUpdateClick() {
        console.debug('UpdateAlert#handleUpdateClick');

        this.onUpdate();
    }
}
