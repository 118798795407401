import { history, Router } from 'backbone';
import AccessDeniedView from './authentication/views/access-denied';
import LoginView from './authentication/views/login';
import LogoutView from './authentication/views/logout';
import ContractModel from './contract/models/contract';
import ContractDetailView from './contract/views/detail';
import ContractIndexView from './contract/views/index';
import CsZendeskBulkNotificationView from './cs/views/zendesk-bulk-notification';
import CustomerModel from './customer/models/customer';
import CustomerDetailView from './customer/views/detail';
import CustomerIndexView from './customer/views/index';
import DashboardView from './dashboard/views/dashboard';
import WelcomeDashboardView from './dashboard/views/welcome';
import FileDownloadsIndexView from './files/views';
import LayoutBlank from './layout/views/blank';
import LayoutDefault from './layout/views/default';
import oAuth2Client from './oauth2-client';
import OffenderModel from './offender/models/offender';
import OffenderIndexView from './offender/views';
import OffenderDetailView from './offender/views/detail';
import PmCashflowsView from './pm/views/cashflows';
import PmIncomeDealsView from './pm/views/income-deals';
import PmOwnerDownloadsIndexView from './pm/views/owner-downloads';
import PmShopDownloadsIndexView from './pm/views/shop-downloads';
import PmUnpaidBillsIndexView from './pm/views/unpaid-bills';
import ProductContractModel from './product-contracts/models/product-contract';
import ProductContractIndexView from './product-contracts/views';
import ProductContractDetailView from './product-contracts/views/detail';
import ProductStockIndexView from './product-stocks/views';
import ProductStockDetailView from './product-stocks/views/detail';
import ProductStockModel from './product-stocks/models/product-stock';
import ProductTypeModel from './product-types/models/product-type';
import ProductTypeDetailView from './product-types/views/detail';
import ProductTypeIndexView from './product-types/views/index';
import CancelledRoomsView from './reports/cancelled-rooms/view';
import LeasingHistoryView from './reports/leasing-history/view';
import LeasingStatusView from './reports/leasing-status/view';
import PriceCheckView from './reports/price-check/views/index';
import RentalDiscountView from './reports/rental-discount/view';
import RoomOccupancyView from './reports/room-occupancy/view';
import TenantReportView from './reports/tenant/views';
import ReportsIndexView from './reports/view';
import RoomModel from './room/models/room';
import RoomDetailView from './room/views/detail';
import ShopModel from './shop/models/shop';
import ShopDetailView from './shop/views/detail';
import ShopIndexView from './shop/views/index';
import User from './user';
import yuyan from './yuyan';

// Set base title and separator
const titleBase = document.title;
const titleSeparator = ' | ';

export default class AppRouter extends Router {
    preinitialize() {
        this.layout = null;

        this.routes = {
            '': this.routeDashboard,
            'login': this.routeLogin,
            'logout': this.routeLogout,

            'shops': this.routeShopIndex,
            'shops/:id': this.routeShopDetail,

            'rooms/:id': this.routeRoomDetail,

            'customers': this.routeCustomerIndex,
            'customers/:id': this.routeCustomerDetail,

            'contracts': this.routeContractIndex,
            'contracts/:id': this.routeContractDetail,

            'product-types': this.routeProductTypeIndex,
            'product-types/:id': this.routeProductTypeDetail,

            'product-stocks': this.routeProductStockIndex,
            'product-stocks/:id': this.routeProductStockDetail,

            'product-contracts': this.routeProductContractIndex,
            'product-contracts/:id': this.routeProductContractDetail,

            'reports/leasing-status': this.routeLeasingStatusReport,
            'reports/leasing-speed': this.routeLeasingSpeedReport,
            'reports/room-occupancy': this.routeRoomOccupancyReport,
            'reports/leasing-history': this.routeLeasingHistoryReport,
            'reports/tenant': this.routeTenantReport,
            'reports/rental-discount': this.routeRentalDiscountReport,
            'reports/price-check': this.routePriceCheckReport,

            'offenders': this.routeOffenderIndex,
            'offenders/:id': this.routeOffenderDetail,

            'pm/leasing-commission': this.routeLeasingCommissionReport,
            'pm/pm-fee': this.routePmFeeReport,
            'pm/cashflows': this.routePmCashflows,
            'pm/unpaid-bills': this.routePmUnpaidBills,
            'pm/income-deals': this.routePmIncomeDeals,
            'pm/shop-downloads': this.routePmShopDownloads,
            'pm/owner-downloads': this.routePmOwnerDownloads,

            'cs/cancelled-rooms': this.routeCsCancelledRoomsReport,
            'cs/zendesk-bulk': this.routeCsZendeskBulk,

            'files': this.routeFileDownloads,
        };

        this.noTokenRoutes = [
            this.routeLogin.name,
        ];

        this.routePermissions = {
            'routeCustomerIndex': 'consus:customers',
            'routeCustomerDetail': 'consus:customers',

            'routeContractIndex': 'consus:contracts',
            'routeContractDetail': 'consus:contracts',

            'routeProductTypeIndex': 'consus:product_types',
            'routeProductTypeDetail': 'consus:product_types',

            'routeProductStockIndex': 'consus:product_types',
            'routeProductStockDetail': 'consus:product_types',

            'routeProductContractIndex': 'consus:product_contracts',
            'routeProductContractDetail': 'consus:product_contracts',

            'routeLeasingStatusReport': 'consus:reports.leasing_status',
            'routeLeasingSpeedReport': 'consus:reports',
            'routeRoomOccupancyReport': 'consus:reports',
            'routeLeasingHistoryReport': 'consus:reports.leasing_status',
            'routeTenantReport': 'consus:reports.tenants',
            'routeRentalDiscountReport': 'consus:reports',
            'routePriceCheckReport': 'consus:reports.price_check',

            'routeOffenderIndex': 'admin',
            'routeOffenderDetail': 'admin',

            'routeLeasingCommissionReport': 'consus:pm',
            'routePmFeeReport': 'consus:pm',
            'routePmCashflows': 'consus:pm',
            'routePmUnpaidBills': 'consus:pm',
            'routePmIncomeDeals': 'consus:pm',
            'routePmShopDownloads': 'consus:pm.download',
            'routePmOwnerDownloads': 'consus:pm.download',

            'routeCsCancelledRoomsReport': 'consus:reports',
            'routeCsZendeskBulk': 'consus:reports',

            'routeFileDownloads': 'admin',
        };
    }

    execute(callback, args) {
        // If noTokenRoutes doesn't include name
        if (!this.noTokenRoutes.includes(callback.name)) {
            // If user does NOT have token
            if (!oAuth2Client.hasToken()) {
                sessionStorage.setItem('redirect', history.fragment);

                // Redirect to login
                this.navigate('login', { trigger: true });

                return false;
            }

            // If user has no consus:ui or routePermitted scope, render access denied
            if (
                !User.isAllowed('consus:ui')
                || (this.routePermissions[callback.name] && !User.isAllowed(this.routePermissions[callback.name]))
            ) {
                // Render blank layout
                this.renderLayout(0);

                // Render access_denied template
                this.accessDenied = new AccessDeniedView({
                    el: document.getElementById('content')
                });
                this.accessDenied.render();

                return false;
            }
        }

        console.debug('Router#execute: Executing "' + callback.name + '"');

        // Scroll to top
        window.scrollTo(0, 0);

        if (callback) callback.apply(this, args);
    }

    renderLayout(id) {
        if (id === 1) {
            if (!this.layoutDefault) {
                // Create default layout
                this.layoutDefault = new LayoutDefault({
                    el: document.getElementById('app')
                });
                this.layoutLanguage = yuyan.language();
                this.layoutId = 0;
            }

            if (this.layoutId === 0) {
                this.layoutDefault.render();
                this.layoutId = 1;
            } else {
                if (this.layoutLanguage !== yuyan.language()) {
                    this.layoutLanguage = yuyan.language();
                    this.layoutDefault.render();
                }
            }
        } else if (id === 0) {
            if (!this.layoutBlank) {
                // Create blank layout
                this.layoutBlank = new LayoutBlank({
                    el: document.getElementById('app')
                });
                this.layoutLanguage = yuyan.language();
                this.layoutId = 1;
            }

            if (this.layoutId === 1) {
                this.layoutBlank.render();
                this.layoutId = 0;
            } else {
                if (this.layoutLanguage !== yuyan.language()) {
                    this.layoutLanguage = yuyan.language();
                    this.layoutBlank.render();
                }
            }
        }
    }

    routeLogin() {
        // If token exists, navigate to dashboard
        if (oAuth2Client.hasToken()) {
            this.navigate('/', { trigger: true });
            return;
        }

        // Render layout
        this.renderLayout(0);

        // Create login view, render, and attach to #content
        const login = new LoginView;
        jQuery('#content').html(login.render().el);

        // Update title
        document.title = titleBase;
    }

    routeLogout() {
        // Render layout
        this.renderLayout(1);

        // Create logout view, render, and attach to #content
        const logout = new LogoutView;
        jQuery('#content').html(logout.render().el);

        // Update title
        document.title = yuyan.t('authentication.logout') + titleSeparator + titleBase;
    }

    routeDashboard() {
        // Render layout
        this.renderLayout(1);

        // Create dashboard based on user permissions
        let dashboard;
        if (User.isAllowed('consus:contracts')) {
            dashboard = new DashboardView;
        } else {
            dashboard = new WelcomeDashboardView;
        }

        // Render and attach to #content
        jQuery('#content').html(dashboard.render().el);

        // Update title
        document.title = yuyan.t('dashboard.title') + titleSeparator + titleBase;
    }

    // #region Shops

    routeShopIndex() {
        // Render layout
        this.renderLayout(1);

        // Create shop index view, render, and attach to #content
        const shopIndex = new ShopIndexView;
        jQuery('#content').html(shopIndex.render().el);

        // Update title
        document.title = yuyan.t('shop.title') + titleSeparator + titleBase;
    }

    routeShopDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create shop detail view with new model
        const shopDetail = new ShopDetailView({
            model: new ShopModel({
                id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(shopDetail.render().el);

        // Trigger fetch of model
        shopDetail.model.fetch();

        // Update title
        document.title = yuyan.t('shop.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Product Types

    routeProductTypeIndex() {
        // Render layout
        this.renderLayout(1);

        // Create product type index view, render, and attach to #content
        const productTypeIndex = new ProductTypeIndexView;
        jQuery('#content').html(productTypeIndex.render().el);

        // Update title
        document.title = yuyan.t('productType.title') + titleSeparator + titleBase;
    }

    routeProductTypeDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create product type detail view with new model
        const productTypeDetail = new ProductTypeDetailView({
            model: new ProductTypeModel({
                id: id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(productTypeDetail.render().el);

        // Trigger fetch of model
        productTypeDetail.model.fetch();

        // Update title
        document.title = yuyan.t('productType.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Product Stocks

    routeProductStockIndex() {
        // Render layout
        this.renderLayout(1);

        // Create product stock index view, render, and attach to #content
        const productStockIndex = new ProductStockIndexView;
        jQuery('#content').html(productStockIndex.render().el);

        // Update title
        document.title = yuyan.t('productType.title') + titleSeparator + titleBase;
    }

    routeProductStockDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create product type detail view with new model
        const productStockDetail = new ProductStockDetailView({
            model: new ProductStockModel({
                id: id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(productStockDetail.render().el);

        // Trigger fetch of model
        productStockDetail.model.fetch({
            data: {
                inclusions: ['product_type', 'shop']
            }
        });

        // Update title
        document.title = yuyan.t('productStock.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Product Contracts

    routeProductContractIndex() {
        // Render layout
        this.renderLayout(1);

        // Create product contract index view, render, and attach to #content
        const productContractIndex = new ProductContractIndexView;
        jQuery('#content').html(productContractIndex.render().el);

        // Update title
        document.title = yuyan.t('productContract.title') + titleSeparator + titleBase;
    }

    routeProductContractDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create product contract detail view with new model
        const productContractDetail = new ProductContractDetailView({
            model: new ProductContractModel({
                id: id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(productContractDetail.render().el);

        // Trigger fetch of model
        productContractDetail.model.fetch({
            data: {
                inclusions: ['product_type', 'product_stock', 'shop']
            }
        });

        // Update title
        document.title = yuyan.t('productContract.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Rooms

    routeRoomDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create room detail view with new model
        const roomDetail = new RoomDetailView({
            model: new RoomModel({
                id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(roomDetail.render().el);

        // Trigger fetch of model
        roomDetail.model.fetch({
            data: {
                inclusions: ['shop']
            }
        });

        // Update title
        document.title = yuyan.t('room.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Customers

    routeCustomerIndex() {
        // Render layout
        this.renderLayout(1);

        // Create customer index view, render, and attach to #content
        const customerIndex = new CustomerIndexView;
        jQuery('#content').html(customerIndex.render().el);

        // Update title
        document.title = yuyan.t('customer.title') + titleSeparator + titleBase;
    }

    routeCustomerDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create customer detail view with new model
        const customerDetail = new CustomerDetailView({
            model: new CustomerModel({
                id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(customerDetail.render().el);

        // Trigger fetch of model
        customerDetail.model.fetch({
            data: {
                inclusions: ['address', 'account', 'shop']
            }
        });

        // Update title
        document.title = yuyan.t('customer.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Contracts

    routeContractIndex() {
        // Render layout
        this.renderLayout(1);

        // Create contract index view, render, and attach to #content
        const contractIndex = new ContractIndexView;
        jQuery('#content').html(contractIndex.render().el);

        // Update title
        document.title = yuyan.t('contract.title') + titleSeparator + titleBase;
    }

    routeContractDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create contract detail view with new model
        const contractDetail = new ContractDetailView({
            model: new ContractModel({
                id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(contractDetail.render().el);

        // Trigger fetch of model
        contractDetail.model.fetch({
            data: {
                inclusions: ['customer', 'room']
            }
        });

        // Update title
        document.title = yuyan.t('contract.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Reports

    routeLeasingStatusReport() {
        // Render layout
        this.renderLayout(1);

        const leasingStatusView = new LeasingStatusView();
        jQuery('#content').html(leasingStatusView.render().el);

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    routeLeasingSpeedReport() {
        // Render layout
        this.renderLayout(1);

        // Create report index view, render, and attach to #content
        const reportIndex = new ReportsIndexView({
            report: 'speed'
        });
        jQuery('#content').html(reportIndex.render().el);

        // Trigger fetch of leasing speed collection
        reportIndex.collection.fetch({
            reset: true
        });

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    routeRoomOccupancyReport() {
        // Render layout
        this.renderLayout(1);

        // Create room occupancy view, render, and attach to #content
        const roomOccupancyView = new RoomOccupancyView();
        jQuery('#content').html(roomOccupancyView.render().el);

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    routeLeasingHistoryReport() {
        // Render layout
        this.renderLayout(1);

        // Create leasing history view, render, and attach to #content
        const leasingHistoryView = new LeasingHistoryView();
        jQuery('#content').html(leasingHistoryView.render().el);

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    routeTenantReport() {
        // Render layout
        this.renderLayout(1);

        // Create tenant view, render, and attach to #content
        const tenantView = new TenantReportView();
        jQuery('#content').html(tenantView.render().el);

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    routeRentalDiscountReport() {
        // Render layout
        this.renderLayout(1);

        // Create rental discount view, render, and attach to #content
        const rentalDiscountView = new RentalDiscountView();
        jQuery('#content').html(rentalDiscountView.render().el);

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    routePriceCheckReport() {
        // Render layout
        this.renderLayout(1);

        // Create price check report view, render, and attach to #content
        const priceCheckView = new PriceCheckView();
        jQuery('#content').html(priceCheckView.el);

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Offenders

    routeOffenderIndex() {
        // Render layout
        this.renderLayout(1);

        // Create offender index view, render, and attach to #content
        const offenderIndex = new OffenderIndexView;
        jQuery('#content').html(offenderIndex.render().el);

        // Update title
        document.title = yuyan.t('offender.title') + titleSeparator + titleBase;
    }

    routeOffenderDetail(id) {
        // Render layout
        this.renderLayout(1);

        // Create offender detail view with new model
        const offenderDetail = new OffenderDetailView({
            model: new OffenderModel({
                id,
            })
        });

        // Render and attach to #content
        jQuery('#content').html(offenderDetail.render().el);

        // Trigger fetch of model
        offenderDetail.model.fetch();

        // Update title
        document.title = yuyan.t('offender.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region PM

    routeLeasingCommissionReport() {
        // Render layout
        this.renderLayout(1);

        // Create report index view, render, and attach to #content
        const reportIndex = new ReportsIndexView({
            report: 'commission'
        });
        jQuery('#content').html(reportIndex.render().el);

        // Trigger fetch of leasing commission collection
        reportIndex.collection.fetch({
            reset: true
        });

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    routePmFeeReport() {
        // Render layout
        this.renderLayout(1);

        // Create report index view, render, and attach to #content
        const reportIndex = new ReportsIndexView({
            report: 'pm_fee'
        });
        jQuery('#content').html(reportIndex.render().el);

        // Trigger fetch of leasing commission collection
        reportIndex.collection.fetch({
            reset: true
        });

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    routePmCashflows() {
        // Render layout
        this.renderLayout(1);

        // Create pm cashflows view, render, and attach to #content
        const view = new PmCashflowsView();
        jQuery('#content').html(view.render().el);

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    routePmUnpaidBills() {
        // Render layout
        this.renderLayout(1);

        // Create pm unpaid bills view, render, and attach to #content
        const view = new PmUnpaidBillsIndexView();
        jQuery('#content').html(view.render().el);

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    routePmIncomeDeals() {
        // Render layout
        this.renderLayout(1);

        // Create pm income deals view, render, and attach to #content
        const view = new PmIncomeDealsView();
        jQuery('#content').html(view.render().el);

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    routePmShopDownloads() {
        // Render layout
        this.renderLayout(1);

        // Create pm shop downloads view, render, and attach to #content
        const downloadView = new PmShopDownloadsIndexView();
        jQuery('#content').html(downloadView.render().el);

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    routePmOwnerDownloads() {
        // Render layout
        this.renderLayout(1);

        // Create pm owner downloads view, render, and attach to #content
        const downloadView = new PmOwnerDownloadsIndexView();
        jQuery('#content').html(downloadView.render().el);

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    routeCsCancelledRoomsReport() {
        // Render layout
        this.renderLayout(1);

        // Create cancelled rooms report view, render, and attach to #content
        const view = new CancelledRoomsView();
        jQuery('#content').html(view.el);

        // Update title
        document.title = yuyan.t('reports.title') + titleSeparator + titleBase;
    }

    routeCsZendeskBulk() {
        // Render layout
        this.renderLayout(1);

        // Create Zendesk bulk notification view, render, and attach to #content
        const view = new CsZendeskBulkNotificationView();
        jQuery('#content').html(view.render().el);

        // Update title
        document.title = yuyan.t('pm.title') + titleSeparator + titleBase;
    }

    // #endregion

    // #region Files

    routeFileDownloads() {
        // Render layout
        this.renderLayout(1);

        // Create file downloads view, render, and attach to #content
        const downloadView = new FileDownloadsIndexView();
        jQuery('#content').html(downloadView.render().el);

        // Update title
        document.title = yuyan.t('file.title') + titleSeparator + titleBase;
    }

    // #endregion

}