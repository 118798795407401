import { Collection, history } from 'backbone';
import dayjs from 'dayjs';
import formToObject from '@/js/libs/form-utils';
import config from '@/js/app/config';
import CustomerNamePickerView from '@/js/app/customer/views/name-picker';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import PaginationView from '@/js/app/generic/views/pagination';
import SpinnerView from '@/js/app/generic/views/spinner';
import oauth2Client from '@/js/app/oauth2-client';
import yuyan from '@/js/app/yuyan';
import ContractModel from '../models/contract';
import addTemplate from '../templates/edit.hbs';
import listTemplate from '../templates/list.hbs';
import UnitView from './unit';

export default class ContractListAddView extends CardView {
    preinitialize(options = {}) {
        // Initialize defaults
        this.title = options.title || yuyan.t('contract.list');

        this.events = {
            'click [data-action="add"]': this.renderAdd,
            'click [data-action="cancel"]': this.renderList,
            'change [name="start_date"]': this.handleStartDateChange,
            'change [name="payment_method"]': this.handlePaymentMethodChange,
            'change [name="campaign_duration"]': this.handleCampaignDurationChange,
            'change [data-rate="flat"]': this.handleFlatRateChange,
            'click #btnGetCosts': this.autoFillRates,
            'submit form#frmEditContract': this.saveAdd,
        };

        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: '<i class="fa fa-plus" aria-hidden="true"></i> ' + yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];

        this.contractModel = new ContractModel;

        this.roomModel = options.roomModel;

        this.customerModel = options.customerModel;

        if (options.buttons === 'hide') {
            this.buttons = [];
        }

        this.columns = options.columns;
    }

    initialize() {
        // Create subviews
        this.subviews = {
            pagination: new PaginationView({
                collection: this.collection
            }),
            namePicker: new CustomerNamePickerView({
                customerModel: this.customerModel
            }),

            unit: new UnitView()
        };

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.renderList);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });

        this.initialMonths = 3;
    }

    render() {
        console.debug('ContractListAdd#render');

        this.renderCard();

        return this;
    }

    renderList() {
        console.debug('ContractListAdd#renderList');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('addBtnCntr')
            .renderListTemplate();

        return this;
    }

    renderListTemplate() {
        console.debug('ContractListAdd#renderListTemplate');

        // Attach listTemplate to el with contracts collection
        this.cardBody.innerHTML = listTemplate({
            contracts: this.collection.toJSON(),
            columns: this.columns
        });

        this.subviews.pagination.setElement(this.cardBody).render();

        return this;
    }

    renderAdd() {
        console.debug('ContractListAdd#renderAdd');

        this.hideButtonContainer('addBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderAddTemplate();

        return this;
    }

    renderAddTemplate() {
        console.debug('ContractListAdd#renderAddTemplate');

        this.cardBody.innerHTML = addTemplate({
            isCancellation: false
        });

        // Hide #divStatus
        this.el.querySelector('#divStatus').classList.add('d-none');

        // Set el of namePicker view as #divCustomer then render and renderName
        this.subviews.namePicker.setElement(this.el.querySelector('#divCustomer')).render().renderName();

        // Set el of unit view as #divUnit then render
        this.subviews.unit.setElement(this.el.querySelector('#divUnit')).render();

        this.$el.find('[data-part="longContract"]').addClass('d-none');

        // Set start date to today
        const today = dayjs().format('YYYY-MM-DD');
        this.$el.find('#field-start_date').val(today).trigger('change');

        // Set defaults
        this.el.querySelector('#field-exist-no').checked = true;
        this.$el.find('[name="processing_fee"]').val(this.defaultFees.processingFee).trigger('change');

        // Default to direct debit payment method
        this.el.querySelector('#field-direct_debit').checked = true;
        this.preparePaymentDetails('direct_debit', true);

        // If roomModel exists
        if (this.roomModel) {
            // For each model attribute
            _.each(this.roomModel.toJSON(), (val, key) => {
                // If key is base_rate, campaign_rate or campaign_duration
                if (['base_rate', 'campaign_rate'].includes(key)) {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector(`[name="${key}"]`);

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });

            const campaignEligibleMinMonths = this.roomModel.get('campaign_eligible_min_months') ?? this.roomModel.get('shop').campaign_eligible_min_months;
            const minUsageMonths = 3;   // Default for direct debit

            this.el.querySelector('[name="campaign_eligible_min_months"]').value = campaignEligibleMinMonths >= minUsageMonths ? campaignEligibleMinMonths : minUsageMonths;

            // Set value of unit view
            this.subviews.unit.setValue(this.roomModel.get('shop_id'), this.roomModel.get('id'));

            const roomCampaignDuration = this.roomModel.get('campaign_duration');
            const shopCampaignDuration = this.roomModel.get('shop').campaign_duration;
            if (roomCampaignDuration != null) {
                this.campaignDuration = roomCampaignDuration;
                this.el.querySelector('[name="campaign_duration"]').value = roomCampaignDuration;
            } else {
                this.campaignDuration = shopCampaignDuration;
                this.el.querySelector('[name="campaign_duration"]').value = shopCampaignDuration;
            }

            this.$el.find('[name="base_rate"], [name="campaign_rate"]').trigger('change');
        } else if (this.customerModel) {
            // Set value of unitshop picker
            this.subviews.unit.setValue(this.customerModel.get('shop_id'));
        }

        return this;
    }

    setFees(fees) {
        console.debug('ContractListAdd#setFees');

        this.defaultFees = fees;
    }

    handleStartDateChange(e) {
        console.debug('ContractListAdd#handleStartDateChange');

        if (e instanceof Object) {
            if (e.currentTarget.id === 'field-start_date') {
                // Set deadline to 7 days before start date
                this.el.querySelector('#field-deadline').value = dayjs(e.currentTarget.value).subtract(7, 'day').format('YYYY-MM-DD');

                // Set payment due date to 3 days before start date
                this.el.querySelector('#field-payment_due_date').value = dayjs(e.currentTarget.value).subtract(3, 'day').format('YYYY-MM-DD');

                const baseRate = this.el.querySelector('[name="base_rate"]').value;
                if (baseRate !== '') {
                    this.changeInitialCost();
                }
            }
        }
    }

    handlePaymentMethodChange(e) {
        console.debug('ContractListAdd#handlePaymentMethodChange');

        if (e instanceof Object) {
            this.preparePaymentDetails(e.currentTarget.value, true);
        }
    }

    preparePaymentDetails(paymentMethod, trigger) {
        console.debug('ContractListAdd#preparePaymentDetails');

        let bankFee = 0;
        // If payment method is direct_debit, enable .payment-detail
        if (paymentMethod === 'direct_debit') {
            this.$el.find('.payment-detail').prop('readonly', false).prop('disabled', false);
            bankFee = this.defaultFees.bankFee;
            this.$el.find('[data-part=directDebitFee]').removeClass('d-none');
        }
        // Else if payment method is credit or money_transfer, disable .payment-detail
        else if (paymentMethod === 'credit' || paymentMethod === 'money_transfer') {
            this.$el.find('.payment-detail').prop('readonly', true).prop('disabled', true);
            this.$el.find('[data-part=directDebitFee]').addClass('d-none');
        }

        if (trigger) {
            this.$el.find('[name="bank_fee"]').val(bankFee).trigger('change');
        }
    }

    handleCampaignDurationChange(e) {
        console.debug('ContractListAdd#handleCampaignDurationChange');

        if (e instanceof Object) {
            this.campaignDuration = Number(e.currentTarget.value);

            if (isNaN(this.campaignDuration)) {
                return;
            }

            this.changeInitialCost();
        }
    }

    changeInitialCost() {
        console.debug('ContractListAdd#changeInitialCost');

        let baseRate = Number(this.el.querySelector('[name="base_rate"]').value);
        let campaignRate = Number(this.el.querySelector('[name="campaign_rate"]').value);

        if (this.campaignDuration === 0) {
            this.changeFirstMonth(baseRate);
        } else if (this.campaignDuration >= 1) {
            this.changeFirstMonth(campaignRate);
        }
    }

    changeFirstMonth(rate) {
        console.debug('ContractListAdd#changeFirstMonth');

        const processedStartDate = new Date(this.el.querySelector('[name="start_date"]').value);
        const daysOfMonth = new Date(processedStartDate.getFullYear(), processedStartDate.getMonth() + 1, 0).getDate();
        const remainingDays = daysOfMonth - processedStartDate.getDate() + 1;

        let firstMonth = Math.round((rate / daysOfMonth) * remainingDays);

        this.$el.find('[name="first_month"]').val(firstMonth).trigger('change');
    }

    handleFlatRateChange(e) {
        console.debug('ContractListAdd#handleFlatRateChange');

        if (e instanceof Object) {
            this.changeInitialCost();
        }
    }

    async autoFillRates() {
        console.debug('ContractListAdd#autoFillRates');

        const roomModel = this.subviews.unit.model;
        this.campaignDuration = roomModel.get('campaign_duration') != null ?
            roomModel.get('campaign_duration') :
            roomModel.get('shop').campaign_duration;

        const params = {
            start_date: this.el.querySelector('[name="start_date"]').value,
            base_rate: roomModel.get('base_rate'),
            campaign_rate: roomModel.get('campaign_rate'),
            campaign_duration: this.campaignDuration,
            payment_method: this.el.querySelector('[name="payment_method"]:checked').value,
            initial_months: this.initialMonths
        };

        const url = new URL(config.api.url + 'contracts/costs');
        url.search = new URLSearchParams(params);

        const response = await oauth2Client.fetch(url, {});
        const responseData = await response.json();

        if (response.ok) {
            _.each(responseData, (val, key) => {
                if (key !== 'start_date' && key !== 'payment_method') {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector(`[name="${key}"]`);

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });

            const campaignEligibleMinMonths = roomModel.get('campaign_eligible_min_months') ?? roomModel.get('shop').campaign_eligible_min_months;
            const minUsageMonths = responseData.payment_method === 'credit' ? 2 : 3;

            this.$el.find('[name="campaign_eligible_min_months"]').val(campaignEligibleMinMonths >= minUsageMonths ? campaignEligibleMinMonths : minUsageMonths).trigger('change');
        } else {
            Promise.reject(responseData);
        }
    }

    saveAdd(e) {
        console.debug('ContractListAdd#saveAdd');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);
            data.initial_months = this.initialMonths;
            data.source = 'phone';

            // Take off shop_id from data
            data.shop_id = undefined;

            // Trigger save of contractModel with data as attributes
            this.contractModel.save(data, {
                // On success: navigate
                success: this.navigate,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    errorOnSave(model, response) {
        console.debug('ContractListAdd#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    navigate(model) {
        console.debug('ContractListAdd#navigate');

        // Navigate to contracts/:id
        history.navigate('contracts/' + model.id, { trigger: true });
    }
}
