import { View } from 'backbone';
import CitiesCollection from '@/js/app/city/collections/cities';
import CityCntrView from '@/js/app/city/views/cntr';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import PrefecturesCollection from '@/js/app/prefecture/collections/prefectures';
import PrefCntrView from '@/js/app/prefecture/views/cntr';
import template from '../templates/edit.hbs';

export default class AddressView extends View {
    preinitialize() {
        this.events = {
            'click #lookup': this.lookUpPostcode,
            'change .pref': this.prefChange,
        };
    }

    initialize(options) {
        if (options) {
            this.postId = options.postId ? options.postId : 'field-postcode';
            this.postName = options.postName ? options.postName : 'postcode';
            this.prefId = options.prefId ? options.prefId : 'field-prefecture-edit';
            this.prefName = options.prefName ? options.prefName : 'prefecture_id';
            this.cityId = options.cityId ? options.cityId : 'field-city-edit';
            this.cityName = options.cityName ? options.cityName : 'city_id';
            this.addressId = options.addressId ? options.addressId : 'field-address';
            this.addressName = options.addressName ? options.addressName : 'address';
            this.buildingId = options.buildingId ? options.buildingId : 'field-building';
            this.buildingName = options.buildingName ? options.buildingName : 'building';
            this.showBuilding = options.showBuilding ? options.showBuilding : false;
        } else {
            this.postId = 'field-postcode';
            this.postName = 'postcode';
            this.prefId = 'field-prefecture-edit';
            this.prefName = 'prefecture_id';
            this.cityId = 'field-city-edit';
            this.cityName = 'city_id';
            this.addressId = 'field-address';
            this.addressName = 'address';
            this.buildingId = 'field-building';
            this.buildingName = 'building';
            this.showBuilding = false;
        }

        this.subviews = {
            prefcntr: new PrefCntrView({
                collection: new PrefecturesCollection,
                field_id: this.prefId,
                field_name: this.prefName
            }),

            citycntr: new CityCntrView({
                collection: new CitiesCollection,
                field_id: this.cityId,
                field_name: this.cityName
            }),
        };
    }

    render() {
        console.debug('Address#render');

        this.el.innerHTML = template({
            postcode: {
                name: this.postName,
                id: this.postId
            },
            prefecture: this.prefId,
            city: this.cityId,
            address: {
                name: this.addressName,
                id: this.addressId
            },
            building: {
                show: this.showBuilding,
                name: this.buildingName,
                id: this.buildingId
            }
        });

        this.subviews.prefcntr.setElement(this.el.querySelector('#divPrefecture')).render();
        this.subviews.prefcntr.collection.fetch();

        this.subviews.citycntr.setElement(this.el.querySelector('#divCity')).render();

        this.$el.find('#' + this.prefId).select2({
            theme: 'bootstrap4',
        });
        this.$el.find('#' + this.cityId).select2({
            theme: 'bootstrap4',
        });

        return this;
    }

    lookUpPostcode() {
        console.debug('Address#lookUpPostcode');

        const fieldPostcode = this.el.querySelector('#' + this.postId);
        fieldPostcode.classList.remove('is-invalid');

        oauth2Client.$ajax({
            url: config.api.url + 'postcodes/' + fieldPostcode.value
        })
            .then((postcode) => {
                this.setValue(postcode.prefecture_id, postcode.city_id);
                this.el.querySelector('[name="' + this.addressName + '"]').value = postcode.neighborhood;
            })
            .catch((error) => {
                console.log(error);
                fieldPostcode.classList.add('is-invalid');
            })
    }

    prefChange($e) {
        console.debug('Address#prefChange');

        const data = {};
        if ($e instanceof Object) {
            $e.preventDefault();

            // Get form data
            const target = $e.target;
            data["prefecture_id"] = target.value
        }

        // Initiate fetch with form data
        this.subviews.citycntr.collection.fetch({
            data: data,
        });
    }

    setValue(prefectureId, cityId) {
        this.subviews.prefcntr.setValue(prefectureId);
        this.subviews.citycntr.setValue(cityId);
    }
}
