import { View } from 'backbone';
import RoomsCollection from '@/js/app/room/collections/rooms';
import RoomListAddView from '@/js/app/room/views/list-add';
import SecurityKeysCollection from '@/js/app/security-key/collections/security-keys';
import ListAddSecurityKeyView from '@/js/app/security-key/views/list-add';
import ShopFileListView from '@/js/app/shop-file/views/list';
import ShopActionBarView from './action-bar';
import ShopReadUpdateView from './read-update';
import template from '../templates/detail.hbs';

export default class ShopDetailView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';
    }

    initialize() {
        this.subviews = {
            actionBar: new ShopActionBarView({
                model: this.model
            }),

            shopCard: new ShopReadUpdateView({
                model: this.model,
            }),

            shopFileListCard: new ShopFileListView({
                shopId: this.model.get('id')
            }),

            listAddRoomCard: new RoomListAddView({
                collection: new RoomsCollection,
                shopId: this.model.get('id')
            }),

            listSecurityKeyCard: new ListAddSecurityKeyView({
                collection: new SecurityKeysCollection,
                shopId: this.model.get('id'),
                parentView: 'shop'
            })
        };

        // When model changes name; updateTitle
        this.listenTo(this.model, 'change:name', this.updateTitle);

        // When model changes is_migration_ready; handleMigrationBadgeDisplay
        this.listenTo(this.model, 'change:is_migration_ready', this.handleMigrationBadgeDisplay);
    }

    render() {
        console.debug('ShopDetail#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of action bar view as #actionBar then render
        this.subviews.actionBar.setElement(this.el.querySelector('#actionBar'));

        // Set el of shop detail view as #details then render
        this.subviews.shopCard.setElement(this.el.querySelector('#details')).render();

        // Set el of shop file list view as #listFile then render
        this.subviews.shopFileListCard.setElement(this.el.querySelector('#listFile')).render();

        // Set el of room list view as #listAddRoom then render
        this.subviews.listAddRoomCard.setElement(this.el.querySelector('#listAddRoom')).render();

        // Set el of security key list view as #listAddSecurityKey then render
        this.subviews.listSecurityKeyCard.setElement(this.el.querySelector('#listAddSecurityKey')).render();

        // Initialize data object with shop_id
        const data = {
            shop_id: this.model.get('id'),
            order: 'room_no',
        };

        // Trigger fetch of room collection with data as query
        this.subviews.listAddRoomCard.collection.fetch({
            data: data,
            reset: true
        });

        // Trigger fetch of security key collection with data as query
        this.subviews.listSecurityKeyCard.collection.fetch({
            data: {
                shop_id: this.model.get('id'),
                inclusions: ['room']
            },
            reset: true
        });

        return this;
    }

    updateTitle(model, value) {
        console.debug('ShopDetail#updateTitle');

        // Change text of #title based on value
        this.el.querySelector('#title').innerText = value;
    }

    handleMigrationBadgeDisplay(model, value) {
        console.debug('ShopDetail#handleMigrationBadgeDisplay');

        if (value === 1) {
            // Remove "d-none" from class list
            this.el.querySelector('[data-part="migrationReadyBadge"]').classList.remove('d-none');
        }
    }
}
