import ModalView from '@/js/app/generic/views/modal';
import template from '../../templates/owner-downloads/card-item-history.hbs';
import PmOwnerDownloadsCardItemHistoryItemView from './card-item-history-item';

export default class PmOwnerDownloadsCardItemHistoryView extends ModalView {
    preinitialize(options) {
        this.type = options.type;
        this.preinitializeModal();
    }

    render() {
        console.debug('PmOwnerDownloadsCardItemHistory#render');

        this.renderModal().modalContent.innerHTML = template({
            title: this.type.label
        });

        this.collection.each((model) => {
            const historyItem = new PmOwnerDownloadsCardItemHistoryItemView({
                type: this.type,
                ownerModel: this.model,
                model: model
            });

            this.$el.find('[data-slot="history"]').prepend(historyItem.render().el);
        });

        return this;
    }
}
