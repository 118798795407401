import { Model } from 'backbone';
import config from '@/js/app/config';

export default class OffenderModel extends Model {
    constructor(options) {
        super(options);

        this.urlRoot = config.api.url + 'offenders';
    }
}
