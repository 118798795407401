var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<td id=\"tdNumber\" class=\"align-middle\">\n    <input id=\"field-number\" name=\"number\" type=\"text\" class=\"form-control\">\n</td>\n<td id=\"tdRoomPicker\" class=\"align-middle\"></td>\n<td class=\"align-middle\">\n    <select id=\"field-status\" name=\"status\" class=\"form-control\">\n        <option value=\"in_house\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.in_house",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":68}}}))
    + "</option>\n        <option value=\"on_loan\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.on_loan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":66}}}))
    + "</option>\n        <option value=\"on_loan_contractor\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.on_loan_contractor",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":43},"end":{"line":9,"column":88}}}))
    + "</option>\n        <option value=\"on_site\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.on_site",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":10,"column":66}}}))
    + "</option>\n        <option value=\"lost\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.lost",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":29},"end":{"line":11,"column":60}}}))
    + "</option>\n        <option value=\"blocked\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"securityKey.status.blocked",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":66}}}))
    + "</option>\n    </select>\n</td>\n<td id=\"tdNumber\" class=\"align-middle\">\n    <input id=\"field-memo\" name=\"memo\" type=\"text\" class=\"form-control\">\n</td>\n<td>\n    <button type=\"button\" class=\"btn custom-btn btn-primary\" data-action=\"save\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":80},"end":{"line":19,"column":100}}}))
    + "</button>\n    <button type=\"button\" class=\"btn custom-btn btn-secondary\" data-action=\"cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":84},"end":{"line":20,"column":106}}}))
    + "</button>\n    <p id=\"errorText\" class=\"text-danger mb-0 d-none\"></p>\n</td>";
},"useData":true});