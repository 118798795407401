export default function objectToForm(objectData, el) {
    // For each search condition
    _.each(objectData, (val, key) => {
        // If value is array
        if (Array.isArray(val)) {
            // For each value
            val.forEach(v => {
                // Initialize field as element with name = key
                const field = el.querySelector('[name="' + key + '"][value="' + v + '"]');

                // If field exists, set as checked
                if (field) {
                    field.checked = true;
                }
            });
        }
        // Else value not array
        else {
            // Initialize field as element with name = key
            const fieldList = el.querySelectorAll('[name="' + key + '"]');
            const field = fieldList[0];

            // If field exists
            if (field) {
                if (field.type === 'checkbox' || field.type === 'radio') {
                    if (fieldList.length > 1) {
                        fieldList.forEach(f => {
                            if (val != null && f.value === val.toString()) {
                                f.checked = true;
                            } else {
                                f.checked = false;
                            }
                        });
                    } else {
                        if (val != null && field.value === val.toString() || val === true) {
                            field.checked = true;
                        } else {
                            field.checked = false;
                        }
                    }
                } else {
                    // Set value
                    field.value = val;
                }
            }
        }
    });
}
