import { View, history } from 'backbone';
import yuyan from '@/js/app/yuyan';
import template from '../templates/footer.hbs';

export default class FooterView extends View {
    preinitialize() {
        this.events = {
            'click #language-toggle a': this.changeLanguage,
        };
    }

    render() {
        console.debug('Footer#render');

        this.el.innerHTML = template({
            currentLang: yuyan.languageName(),
            supportedLangs: yuyan.supportedLanguages,
        });

        return this;
    }

    changeLanguage($e) {
        console.debug('Footer#changeLanguage');

        $e.preventDefault();

        // Set language to "lang" data attribute
        yuyan.language($e.currentTarget.dataset.lang);

        // Load translations
        yuyan.loadTranslations()
            .then(() => {
                // Reload current route
                history.loadUrl(history.getFragment());
            });
    }
}
