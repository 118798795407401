import oauth2Client from '../oauth2-client';
import { extend } from 'underscore';
import UploadStatusView from './views/upload-status';

export default function (name, file, xhrOptions, options) {
    const promise = new Promise((resolve, reject) => {
        console.debug('uploader');

        if (!(file instanceof Blob)) {
            throw 'Parameter "file" is not instance of Blob';
        }

        if (!xhrOptions.responseType) {
            xhrOptions.responseType = 'json';
        }

        xhrOptions.data = new FormData;
        xhrOptions.data.append(name, file);

        // Add accept header
        xhrOptions.headers = extend({}, xhrOptions.headers, {
            'Accept': 'application/json',
        });

        oauth2Client.getToken()
            .then((token) => {
                xhrOptions.headers = extend({}, xhrOptions.headers, {
                    'Authorization': token.token_type + ' ' + token.access_token,
                });

                const xhr = new XMLHttpRequest();

                const uploadStatus = new UploadStatusView({
                    file: file,
                    request: xhr
                });

                options.uploadStatusContainer.append(uploadStatus.render().el);

                xhr.addEventListener('load', () => {
                    if (xhr.status.toString()[0] === '2') {
                        xhr.dispatchEvent(
                            new CustomEvent('success')
                        );

                        resolve(xhr.response);
                    } else {
                        xhr.dispatchEvent(
                            new ErrorEvent('error', {
                                error: xhr.response
                            })
                        );
                    }
                });

                xhr.addEventListener('error', error => {
                    reject(error.error);
                });

                xhr.addEventListener('abort', () => {
                    const error = new Error('Upload cancelled');
                    error.name = 'Abort';
                    reject(error);
                });

                xhr.open(xhrOptions.method, xhrOptions.url, true);

                for (let key in xhrOptions.headers) {
                    xhr.setRequestHeader(key, xhrOptions.headers[key]);
                }

                xhr.responseType = xhrOptions.responseType;

                xhr.send(xhrOptions.data);
            });


    });

    return promise;
}