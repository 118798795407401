import { Collection } from 'backbone';
import config from '@/js/app/config';

export default class CustomerLoginHistoryCollection extends Collection {
    initialize(models, options) {
        this.customerId = options.customerId;
    }

    url() {
        return config.api.url + 'customers/' + this.customerId + '/login-history';
    }
}
