import { View } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import ProductContractsCollection from '../collections/product-contracts';
import template from '../templates/index.hbs';
import ProductContractListAddView from './list-add';
import ProductTypeCntrView from '@/js/app/product-types/views/cntr';
import ProductTypesCollection from '@/js/app/product-types/collections/product-types';

export default class ProductContractIndexView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';

        this.events = {
            'submit form#frmSearch': this.search,
        };
    }

    initialize() {
        this.subviews = {
            listAddProductContractCard: new ProductContractListAddView({
                buttons: 'hide',
                collection: new ProductContractsCollection,
            }),

            shopCntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop"
            }),

            productTypeCntr: new ProductTypeCntrView({
                collection: new ProductTypesCollection,
                field_id: 'field-product_type'
            })
        };
    }

    render() {
        console.debug('ProductContractIndex#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of product contract list view as #listAddProductContract then render
        this.subviews.listAddProductContractCard.setElement(this.el.querySelector('#listAddProductContract')).render();

        this.subviews.shopCntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopCntr.collection.fetch();

        this.subviews.productTypeCntr.setElement(this.el.querySelector('#divProductType')).render();
        this.subviews.productTypeCntr.collection.fetch();

        // Get product contract search conditions from sessionStorage
        this.searchConditions = JSON.parse(sessionStorage.getItem('productContractSearchCondition'));

        // If search conditions exist
        if (this.searchConditions) {
            // For each search condition
            _.each(this.searchConditions, (val, key) => {
                // If value is array
                if (Array.isArray(val)) {
                    // For each value
                    val.forEach(v => {
                        // Initialize field as element with name = key
                        const field = this.el.querySelector('[name="' + key + '"][value="' + v + '"]');

                        // If field exists, set as checked
                        if (field) {
                            field.checked = true;
                        }
                    });
                }
                // Else value not array
                else {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector('[name="' + key + '"]');

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });

            this.subviews.shopCntr.setValue(this.searchConditions.shop_id);
            this.subviews.productTypeCntr.setValue(this.searchConditions.product_type_id);
        }

        // Initiate search
        this.search();

        return this;
    }

    search(e) {
        console.debug('ProductContractIndex#search');

        // Initialize data
        let data = {};

        // If called with event
        if (e instanceof Object) {
            e.preventDefault();

            // Get data from form using formToObject
            data = formToObject(e.target);

            // Store search conditions to sessionStorage
            sessionStorage.setItem('productContractSearchCondition', JSON.stringify(data));
        }
        // Else if searchConditions exist, use as data
        else if (this.searchConditions) {
            data = this.searchConditions;
        }

        data.inclusions = ['product_type'];

        // Trigger fetch of product contract collection with data as query
        this.subviews.listAddProductContractCard.collection.search({
            data: data,
            reset: true,
            // On error: errorOnSearch
            error: this.errorOnSearch
        });
    }

    errorOnSearch(collection, response) {
        console.debug('ProductContractIndex#errorOnSearch');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
