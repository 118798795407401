var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"col offset-2 text-right\">\n		<button class=\"btn btn-secondary\" type=\"button\" disabled>\n			<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n			Loading...\n		</button>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"model") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":15,"column":1},"end":{"line":51,"column":8}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"col-2\">"
    + alias2(__default(require("../../../handlebars-helpers/localtime.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"created_at") : stack1),"YYYY-MM-DD HH:mm:ss",{"name":"localtime","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":72}}}))
    + "</div>\n	<div class=\"col-4\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"created_by_name") : stack1), depth0))
    + "</div>\n	<div class=\"col text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUploading") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":29,"column":9}}})) != null ? stack1 : "")
    + "		<button type=\"button\" class=\"btn btn-primary\" data-action=\"download\">\n			<i class=\"fa fa-download\" aria-hidden=\"true\"></i>\n		</button>\n		<button type=\"button\" class=\"btn btn-dark\" data-action=\"history\">\n			<i class=\"fa fa-history\" aria-hidden=\"true\"></i>\n		</button>\n	</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"btn btn-outline-success\" type=\"button\" disabled>\n			<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n			Loading...\n		</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "		<button type=\"button\" class=\"btn btn-outline-success\" data-action=\"upload\">\n			<i class=\"fa fa-upload\" aria-hidden=\"true\"></i>\n		</button>\n		<input name=\"file\" class=\"d-none\" type=\"file\" accept=\".pdf\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"col offset-2 text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isUploading") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":39,"column":2},"end":{"line":49,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"btn btn-success\" type=\"button\" disabled>\n			<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n			Loading...\n		</button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "		<button type=\"button\" class=\"btn btn-success\" data-action=\"upload\">\n			<i class=\"fa fa-upload\" aria-hidden=\"true\"></i>\n		</button>\n		<input name=\"file\" class=\"d-none\" type=\"file\" accept=\".pdf\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-part=\"uploadStatus\"></div>\n\n<div class=\"row\">\n	<div class=\"col-4\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isLoading") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":1},"end":{"line":53,"column":8}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});