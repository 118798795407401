import { View } from 'backbone';
import readTemplate from '../../templates/cashflows/item-read.hbs'
import editTemplate from '../../templates/cashflows/item-edit.hbs'
import editButtonsTemplate from '../../templates/cashflows/item-edit-buttons.hbs'
import ErrorModalView from '@/js/app/error/views/modal';
import formToObject from '@/js/libs/form-utils';
import ShopCashflowModel from '../../models/shop-cashflow';

export default class PmCashflowsItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click input[type="checkbox"]': this.handleCheckboxClick,
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.handleCancelClick,
            'click [data-action="save"]': this.handleSaveClick,
        };

        this.isSelected = false;
    }

    initialize() {
        _.bindAll(this, 'errorOnSave');

        if (!this.model) {
            this.model = new ShopCashflowModel(null, {
                shopId: this.collection.shopId
            });
        }

        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('PmCashflowsItem#render');

        // Attach read template to el
        this.el.innerHTML = readTemplate({
            cashflow: this.model.toJSON(),
            isSelected: this.isSelected
        });

        this.el.classList.remove('table-active');

        return this;
    }

    renderEdit() {
        console.debug('PmCashflowsItem#renderEdit');

        this.el.innerHTML = editTemplate({
            cashflow: this.model.toJSON(),
            isSelected: this.isSelected
        });

        this.el.querySelector('[data-slot="buttons"]').innerHTML = editButtonsTemplate();

        this.el.classList.add('table-active');

        if (!this.model.isNew()) {
            this.el.querySelector('[name="contract_id"]').value = this.model.get('contract_id');
            this.el.querySelector('[name="transaction_date"]').value = this.model.get('transaction_date');
            this.el.querySelector('[name="period_sales"]').value = this.model.get('period_sales');
            this.el.querySelector('[name="category"]').value = this.model.get('category');
            this.el.querySelector('[name="vendor"]').value = this.model.get('vendor');
            this.el.querySelector('[name="item"]').value = this.model.get('item');
            this.el.querySelector('[name="income"]').value = this.model.get('income');
            this.el.querySelector('[name="billing_method"]').value = this.model.get('billing_method');
            this.el.querySelector('[name="tax_rate"]').value = this.model.get('tax_rate') * 100;
            this.el.querySelector('[name="expense"]').value = this.model.get('expense');
            this.el.querySelector('[name="note"]').value = this.model.get('note');
        }

        return this;
    }

    handleCheckboxClick(e) {
        console.debug('PmCashflowsItem#handleCheckboxClick');

        this.isSelected = e.currentTarget.checked;

        const payload = {
            view: this,
            shiftKey: e.shiftKey
        };

        if (this.isSelected) {
            this.el.classList.add('table-primary');
            this.trigger('trt:select', payload);
        } else {
            this.el.classList.remove('table-primary');
            this.trigger('trt:deselect', payload);
        }
    }

    handleSaveClick() {
        console.debug('PmCashflowsItem#handleSaveClick');

        this.el.querySelector('[data-slot="buttons"]').innerHTML = editButtonsTemplate({
            isSaving: true
        });

        const data = formToObject(this.el);
        data.tax_rate = data.tax_rate != null ? data.tax_rate / 100 : null;

        if (this.model.isNew()) {
            this.collection.create(data, {
                wait: true,
                success: () => {
                    this.remove();
                },
                error: this.errorOnSave
            });
        } else {
            const changes = this.model.changedAttributes(data);
            if (changes !== false) {
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    error: this.errorOnSave
                });
            } else {
                this.model.trigger('change', this.model);
            }
        }
    }

    errorOnSave(model, response) {
        console.debug('PmCashflowsItem#errorOnSave');

        this.el.querySelector('[data-slot="buttons"]').innerHTML = editButtonsTemplate();

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    handleCancelClick() {
        console.debug('PmCashflowsItem#handleCancelClick');

        if (this.model.isNew()) {
            this.remove();
        } else {
            this.render();
        }
    }
}