import { Collection, View } from 'backbone';
import config from '@/js/app/config';
import SelectYearMonth from '@/js/app/generic/views/select-year-month';
import SpinnerView from '@/js/app/generic/views/spinner';
import oauth2Client from '@/js/app/oauth2-client';
import OwnerCntrView from '@/js/app/owners/views/cntr';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import LeasingStatusCollection from './collection';
import template from './templates/index.hbs';
import monthTemplate from './templates/month.hbs';
import shopTemplate from './templates/shop.hbs';
import tableTemplate from './templates/table.hbs';

export default class LeasingStatusView extends View {
    preinitialize() {
        this.className = 'container';
        this.collection = new LeasingStatusCollection;
        this.events = {
            'change [name="perspective_switch"]': this.handlePerspectiveChange,
            'change #eomSwitch': this.handleEomChange,
            'change #tsuboSwitch': this.handleTsuboChange,
            'click [data-action="export"]': this.handleExportClick,
            'change [name="owner_id"], [name="year"], [name="month"]': this.filterByOYM,
            'change [name="shop_id"]': this.filterByShop,
        };
        this.subviews = {
            ownerCntrView: new OwnerCntrView({
                firstOptionText: 'ALL',
                required: true
            }),
            shopCntrView: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop-id",
                hasAll: true
            }),
            selectYearMonth: new SelectYearMonth({
                includeNextYear: true,
            }),
        };
        this.perspective = 'month';
        this.isEomShown = false;
        this.isTsuboShown = false;
    }

    initialize() {
        // When collection updates/resets; handleCollectionReset
        this.listenTo(this.collection, 'update reset', this.handleCollectionReset);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('LeasingStatus#render');

        // Attach template to el
        this.el.innerHTML = template({
            isMonthPerspective: (this.perspective === 'month'),
            isShopPerspective: (this.perspective === 'shop'),
        });

        this.reportBodyEl = this.el.querySelector('#reportBody');

        this.renderMonthPerspective();

        return this;
    }

    handleCollectionReset() {
        console.debug('LeasingStatus#handleCollectionReset');

        if (this.perspective === 'shop') {
            this.renderShopPerspectiveTable();
        } else if (this.perspective === 'month') {
            this.renderMonthPerspectiveTable();
        }
    }

    handlePerspectiveChange(e) {
        console.debug('LeasingStatus#handlePerspectiveChange');

        this.perspective = e.currentTarget.value;
        this.isEomShown = false;
        this.isTsuboShown = false;

        if (this.perspective === 'shop') {
            this.renderShopPerspective();
        } else if (this.perspective === 'month') {
            this.renderMonthPerspective();
        }
    }

    handleEomChange(e) {
        console.debug('LeasingStatus#handleEomChange');

        this.isEomShown = e.currentTarget.checked;

        if (this.perspective === 'shop') {
            this.renderShopPerspectiveTable();
        } else if (this.perspective === 'month') {
            this.renderMonthPerspectiveTable();
        }
    }

    handleTsuboChange(e) {
        console.debug('LeasingStatus#handleTsuboChange');

        this.isTsuboShown = e.currentTarget.checked;

        if (this.perspective === 'shop') {
            this.renderShopPerspectiveTable();
        } else if (this.perspective === 'month') {
            this.renderMonthPerspectiveTable();
        }
    }

    handleExportClick() {
        console.debug('LeasingStatus#handleExportClick');

        if (this.perspective === 'shop') {
            this.exportShopPerspectiveReport();
        } else if (this.perspective === 'month') {
            this.exportMonthPerspectiveReport();
        }
    }

    //#region Month Perspective

    renderMonthPerspective() {
        console.debug('LeasingStatus#renderMonthPerspective');

        // Attach perspective template to body el
        this.reportBodyEl.innerHTML = monthTemplate();

        // Set el of owner cntr view as #divOwners, render then fetch collection
        this.subviews.ownerCntrView.setElement(this.el.querySelector('#divOwners')).render().collection.fetch();

        // Attach select year/month subview to #dateCntr and render
        this.subviews.selectYearMonth.setElement(this.el.querySelector('#dateCntr')).render();

        this.filterByOYM();

        return this;
    }

    renderMonthPerspectiveTable() {
        console.debug('LeasingStatus#renderMonthPerspectiveTable');

        this.el.querySelector('#reportTable').innerHTML = tableTemplate({
            leasingStatus: this.collection.toJSON(),
            total: this.collection.total,
            isMonthPerspective: this.perspective === 'month',
            isEomShown: this.isEomShown,
            isTsuboShown: this.isTsuboShown
        });

        // Transform table to DataTable
        this.$el.find('table').DataTable({
            searching: false,
            paging: false,
            info: false,
            order: [0, 'desc'],
        });

        return this;
    }

    filterByOYM() {
        console.debug('LeasingStatus#filterByOYM');

        const ownerId = Number(this.el.querySelector('[name="owner_id"]').value);
        const year = Number(this.el.querySelector('[name="year"]').value);
        const month = Number(this.el.querySelector('[name="month"]').value);

        this.collection.fetch({
            data: {
                owner_id: ownerId ? ownerId : undefined,
                year,
                month,
                perspective: this.perspective
            },
            reset: true
        });
    }

    exportMonthPerspectiveReport() {
        console.debug('LeasingStatus#exportMonthReport');

        const year = Number(this.el.querySelector('[name="year"]').value);
        const month = Number(this.el.querySelector('[name="month"]').value);
        const owner_id = Number(this.el.querySelector('[name="owner_id"]').value);
        const params = {
            year,
            month,
            perspective: 'month'
        };

        if (owner_id > 0) {
            params.owner_id = owner_id;
        }

        const url = new URL(`${config.api.url}reports/leasing-status`);
        url.search = new URLSearchParams(params);

        oauth2Client.xlsxDownload(
            url,
            {
                method: 'GET',
            },
            {
                filename: `Leasing Status Report - ${year}${month}.xlsx`
            }
        );
    }

    //#endregion

    //#region Shop Perspective

    renderShopPerspective() {
        console.debug('LeasingStatus#renderShopPerspective');

        // Attach perspective template to body el
        this.reportBodyEl.innerHTML = shopTemplate();

        // Set el of shop cntr view as #divShops, render then fetch collection
        this.subviews.shopCntrView.setElement(this.el.querySelector('#divShops')).render().collection.fetch();

        this.filterByShop();

        return this;
    }

    renderShopPerspectiveTable() {
        console.debug('LeasingStatus#renderShopPerspectiveTable');

        this.el.querySelector('#reportTable').innerHTML = tableTemplate({
            leasingStatus: this.collection.toJSON(),
            isMonthPerspective: this.perspective === 'month',
            isEomShown: this.isEomShown,
            isTsuboShown: this.isTsuboShown
        });

        // Transform table to DataTable
        this.$el.find('table').DataTable({
            searching: false,
            paging: false,
            info: false,
            order: [0, 'desc'],
        });

        return this;
    }

    filterByShop() {
        console.debug('LeasingStatus#filterByShop');

        const shopId = Number(this.el.querySelector('[name="shop_id"]').value);

        this.collection.fetch({
            data: {
                shop_id: shopId ? shopId : undefined,
                perspective: this.perspective
            },
            reset: true
        });
    }

    exportShopPerspectiveReport() {
        console.debug('LeasingStatus#exportShopPerspectiveReport');

        const shopSelectEl = this.el.querySelector('[name="shop_id"]');
        const shopId = Number(shopSelectEl.value);
        const params = {
            perspective: 'shop'
        };

        let shopName = '';
        if (shopId > 0) {
            params.shop_id = shopId;
            shopName = shopSelectEl.options[shopSelectEl.selectedIndex].text;
        } else {
            shopName = 'ALL';
        }

        const url = new URL(`${config.api.url}reports/leasing-status`);
        url.search = new URLSearchParams(params);

        oauth2Client.xlsxDownload(
            url,
            {
                method: 'GET',
            },
            {
                filename: `Leasing Status Report - ${shopName}.xlsx`
            }
        );
    }

    //#endregion

}
