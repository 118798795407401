import { View } from 'backbone';
import template from '../templates/detail.hbs';
import ProductStockReadUpdateView from './read-update';

export default class ProductStockDetailView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';
    }

    initialize() {
        this.subviews = {
            productStockCard: new ProductStockReadUpdateView({
                model: this.model,
            })
        };

        // When model changes label; updateTitle
        this.listenTo(this.model, 'change:label', this.updateTitle);
    }

    render() {
        console.debug('ProductStockDetail#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of product type detail view as #details then render
        this.subviews.productStockCard.setElement(this.el.querySelector('#details')).render();

        return this;
    }

    updateTitle(model, value) {
        console.debug('ProductStockDetail#updateTitle');

        // Change text of #title based on value
        this.el.querySelector('#title').innerText = value;
    }
}
