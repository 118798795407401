import { View } from 'backbone';
import productStockStatus from '@/js/handlebars-helpers/productStockStatus';

export default class ProductStockPickerView extends View {
    preinitialize() {
        this.tagName = 'select';
        this.className = 'form-control';
        this.attributes = {
            'name': 'product_stock_id',
            'data-number': ''
        };
    }

    initialize() {
        // Bind this on render
        _.bindAll(this, 'render');

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);

        this.hasLoaded = false;
    }

    render() {
        console.debug('ProductStockPicker#render');

        // Initialize options as document fragment
        const options = document.createDocumentFragment();

        // Append option element to options
        options.appendChild(document.createElement('option'));

        // For each model in collection
        this.collection.each((v) => {
            // Initialize o as option element
            const o = document.createElement('option');

            // Set value and innerText of o
            o.value = v.get('id');
            o.innerText = v.get('label') + ' (' + productStockStatus(v.get('status')) + ')';

            // Append o to options
            options.appendChild(o);
        });

        // Clear options of el
        while (this.el.firstChild) {
            this.el.removeChild(this.el.firstChild);
        }

        // Attach options to el
        this.el.appendChild(options);

        this.hasLoaded = true;

        if (this.value !== undefined) {
            // If value exists setValue
            this.setValue();
        }

        return this;
    }

    setValue(value) {
        console.debug('ProductStockPicker#setValue');

        if (value !== undefined) {
            this.value = value;
        }

        // Set value of select element and trigger change event
        if (this.hasLoaded) {
            this.el.value = this.value;
            this.$el.trigger('change');
        }
    }
}
