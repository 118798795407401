import { View } from 'backbone';
import { isEmpty } from 'underscore';
import CitiesCollection from '@/js/app/city/collections/cities';
import CityCntrView from '@/js/app/city/views/cntr';
import ErrorModalView from '@/js/app/error/views/modal';
import PrefecturesCollection from '@/js/app/prefecture/collections/prefectures';
import PrefCntrView from '@/js/app/prefecture/views/cntr';
import ShopsCollection from '../collections/shops';
import ShopListAddView from './list-add';
import template from '../templates/index.hbs';

export default class ShopIndexView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';

        this.events = {
            'submit form#frmSearch': this.search,
            'change #field-prefecture': this.prefChange,
        };
    }

    initialize() {
        this.subviews = {
            prefcntr: new PrefCntrView({
                collection: new PrefecturesCollection,
                field_id: "field-prefecture",
                field_name: "prefecture_id"
            }),

            citycntr: new CityCntrView({
                collection: new CitiesCollection,
                field_id: "field-city",
                field_name: "city_id"
            }),

            listAddShopCard: new ShopListAddView({
                collection: new ShopsCollection,
            })
        };
    }

    render() {
        console.debug('ShopIndex#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of prefectures view as #divPrefecture then render
        this.subviews.prefcntr.setElement(this.el.querySelector('#divPrefecture')).render();

        // Trigger fetch of prefecture collection
        this.subviews.prefcntr.collection.fetch();

        // Set el of cities view as #divCity then render
        this.subviews.citycntr.setElement(this.el.querySelector('#divCity')).render();

        // Transform prefectures input to select2
        this.$el.find('#field-prefecture').select2({
            theme: 'bootstrap4',
        });

        // Transform cities input to select2
        this.$el.find('#field-city').select2({
            theme: 'bootstrap4',
        });

        // Set el of shop list view as #listAddShop then render
        this.subviews.listAddShopCard.setElement(this.el.querySelector('#listAddShop')).render();

        // Get shop search conditions from sessionStorage
        this.searchConditions = JSON.parse(sessionStorage.getItem('shopSearchCondition'));
        // If search conditions exist
        if (this.searchConditions) {
            // For each search condition
            _.each(this.searchConditions, (val, key) => {
                // Initialize field as element with name = key
                const field = this.el.querySelector('[name="' + key + '"]');

                // If field exists, set value
                if (field) {
                    field.value = val;
                }
            });

            this.subviews.prefcntr.setValue(this.searchConditions.prefecture_id);
            this.subviews.citycntr.setValue(this.searchConditions.city_id);
        }

        // Initiate search
        this.search();

        return this;
    }

    search(e) {
        console.debug('ShopIndex#search');

        // Initialize data
        let data = {};
        if (e instanceof Object) {
            e.preventDefault();

            // Get form data
            const formData = new FormData(e.target);

            // For each item in form data
            formData.forEach((value, key) => {
                // Skip empty values
                if (isEmpty(value)) {
                    return;
                }

                // Store value on data object via key
                data[key] = value;
            });

            // Store search conditions to sessionStorage
            sessionStorage.setItem('shopSearchCondition', JSON.stringify(data));
        }
        // Else if searchConditions exist, use it as data
        else if (this.searchConditions) {
            data = this.searchConditions;
        }

        // Trigger fetch of shop collection with data as query
        this.subviews.listAddShopCard.collection.fetch({
            data: data,
            reset: true,
            // On error: errorOnSearch
            error: this.errorOnSearch
        });
    }

    errorOnSearch(collection, response) {
        console.debug('ShopIndex#errorOnSearch');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    prefChange(e) {
        console.debug('ShopIndex#prefChange');

        // Initialize data
        const data = {};
        if (e instanceof Object) {
            e.preventDefault();

            // Get select input
            const target = e.target;

            // Store value on data object
            data["prefecture_id"] = target.value
        }

        // Trigger fetch of city collection with data as query
        this.subviews.citycntr.collection.fetch({
            data: data,
        });
    }
}
