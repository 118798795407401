import { View } from 'backbone';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../templates/detail.hbs';
import ProductContractActionBarView from './action-bar';
import ProductContractReadUpdateView from './read-update';

export default class ProductContractDetailView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'container';
    }

    initialize() {
        this.subviews = {
            actionBar: new ProductContractActionBarView({
                productContractId: this.model.id
            }),

            productContractCard: new ProductContractReadUpdateView({
                model: this.model,
            }),
        };

        oauth2Client.fetch(`${config.api.url}contracts/fees`, {})
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.json();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((data) => {
                this.subviews.productContractCard.setFees(data);
            });
    }

    render() {
        console.debug('ProductContractDetail#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of action bar view as #actionBar then render
        this.subviews.actionBar.setElement(this.el.querySelector('#actionBar')).render();

        // Set el of product contract detail view as #details then render
        this.subviews.productContractCard.setElement(this.el.querySelector('#details')).render();

        // Change text of #title based on model.id
        this.el.querySelector('#title').innerText = this.model.get('id');

        return this;
    }
}
