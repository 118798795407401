var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customerFile.title",{"name":"t","hash":{"smart_count":2},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":68}}}))
    + "</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n    <div class=\"table-responsive\">\n        <table class=\"table table-sm table-hover text-nowrap\">\n            <thead>\n                <tr class=\"thead-light\">\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"file.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":41}}}))
    + "</th>\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"file.uploaded_at",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":48}}}))
    + "</th>\n                    <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"file.size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":41}}}))
    + "</th>\n                    <th></th>\n                </tr>\n            </thead>\n            <tbody data-slot=\"files\"></tbody>\n        </table>\n    </div>\n</div>";
},"useData":true});