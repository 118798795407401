import { Collection } from 'backbone';
import config from '@/js/app/config';
import RoomModel from '../models/room';

export default class RoomsCollection extends Collection {
    preinitialize() {
        // Initialize defaults
        this.url = config.api.url + 'rooms';
        this.model = RoomModel;
    }
}
