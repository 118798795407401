import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default function(time, format) {
    // If time is null or undefined; return blank string
    if (time == null) {
        return '';
    }

    return dayjs.utc(time).local().format(format);
}