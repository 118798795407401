var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"generic.yes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":62,"column":27}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"generic.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":8},"end":{"line":64,"column":26}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../handlebars-helpers/room/description.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"room/description","hash":{},"data":data,"loc":{"start":{"line":71,"column":51},"end":{"line":71,"column":78}}}))
    + "<br>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":40}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"room_no") : stack1), depth0))
    + "</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":25},"end":{"line":5,"column":42}}}))
    + "</dt>\n    <dd class=\"col-md-2\"><a href=\"#shops/"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"shop_id") : stack1), depth0))
    + "\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a></dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":25},"end":{"line":8,"column":44}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/room/status.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),{"name":"room/status","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":54}}}))
    + "</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.base_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":25},"end":{"line":13,"column":47}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"money.yen",{"name":"t","hash":{"value":__default(require("../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"base_rate") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":78}}})},"data":data,"loc":{"start":{"line":14,"column":25},"end":{"line":14,"column":80}}}))
    + "</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.target_base_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":25},"end":{"line":16,"column":54}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"money.yen",{"name":"t","hash":{"value":__default(require("../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"target_base_rate") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":17,"column":47},"end":{"line":17,"column":85}}})},"data":data,"loc":{"start":{"line":17,"column":25},"end":{"line":17,"column":87}}}))
    + "</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_rate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":25},"end":{"line":21,"column":51}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"money.yen",{"name":"t","hash":{"value":__default(require("../../../handlebars-helpers/formatCurrency.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"campaign_rate") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":22,"column":47},"end":{"line":22,"column":82}}})},"data":data,"loc":{"start":{"line":22,"column":25},"end":{"line":22,"column":84}}}))
    + "</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.campaign_duration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":25},"end":{"line":24,"column":55}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/shop/isShopDefault.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"campaign_duration") : stack1),{"name":"shop/isShopDefault","hash":{},"data":data,"loc":{"start":{"line":25,"column":25},"end":{"line":25,"column":72}}}))
    + "</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.campaign_eligible_min_months",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":25},"end":{"line":27,"column":66}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/shop/isShopDefault.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"campaign_eligible_min_months") : stack1),{"name":"shop/isShopDefault","hash":{},"data":data,"loc":{"start":{"line":28,"column":25},"end":{"line":28,"column":83}}}))
    + "</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.floorspace",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":25},"end":{"line":32,"column":48}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"floorspace") : stack1), depth0))
    + " 帖</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.area",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":25},"end":{"line":35,"column":42}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"area") : stack1), depth0))
    + " m²</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.door_width",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":25},"end":{"line":38,"column":48}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"door_width") : stack1), depth0))
    + " cm</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.width",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":25},"end":{"line":43,"column":43}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"width") : stack1), depth0))
    + " cm</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.height",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":25},"end":{"line":46,"column":44}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"height") : stack1), depth0))
    + " cm</dd>\n\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.depth",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":25},"end":{"line":49,"column":43}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"depth") : stack1), depth0))
    + " cm</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.lock_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":25},"end":{"line":54,"column":49}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"lock_number") : stack1), depth0))
    + "</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.is_featured",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":25},"end":{"line":59,"column":49}}}))
    + "</dt>\n    <dd class=\"col-md-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"is_featured") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":65,"column":15}}})) != null ? stack1 : "")
    + "    </dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.description.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":25},"end":{"line":70,"column":55}}}))
    + "</dt>\n    <dd class=\"col-md-2\">"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"description") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":25},"end":{"line":71,"column":91}}})) != null ? stack1 : "")
    + "</dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"room.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":25},"end":{"line":75,"column":43}}}))
    + "</dt>\n    <dd class=\"col-md-10\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"notes") : stack1), depth0))
    + "</dd>\n</div>";
},"useData":true});