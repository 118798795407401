var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n    <div class=\"form-group row\">\n        <label for=\"field-date\" class=\"col-form-label col-md-3 col-lg-4\">\n            "
    + alias2(__default(require("../../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"reports.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":32}}}))
    + "\n        </label>\n        <div class=\"col-md\" id=\"dateCntr\"></div>\n    </div>\n\n    <p id=\"importError\" class=\"text-danger mt-3 mb-0 d-none\"></p>\n</div>\n\n<div class=\"modal-footer\">\n    <button type=\"submit\" class=\"btn custom-btn btn-primary\" data-action=\"import\">\n        <i class=\"fa fa-cloud-download\" aria-hidden=\"true\"></i> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"buttonText") : depth0), depth0))
    + "\n    </button>\n</div>";
},"useData":true});