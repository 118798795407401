import { View } from 'backbone';
import dayjs from 'dayjs';
import ContractsCollection from '@/js/app/contract/collections/contracts';
import ListAddContractView from '@/js/app/contract/views/list-add';
import ProductContractsCollection from '@/js/app/product-contracts/collections/product-contracts';
import yuyan from '@/js/app/yuyan';
import template from '../templates/index.hbs';
import DashboardActionBarView from './action-bar';
import DashboardProductContractListView from './product-contract-list';

export default class DashboardView extends View {
    preinitialize() {
        this.className = 'container';
    }

    initialize() {
        this.cancelColletion = new ContractsCollection;

        // Create subviews
        this.subviews = {
            actionBar: new DashboardActionBarView(),

            listExpressContractCard: new ListAddContractView({
                title: yuyan.t('contract.list') + ' (' + yuyan.t('contract.express') + ')',
                buttons: 'hide',
                collection: new ContractsCollection,
                columns: {
                    todo: true
                }
            }),

            listRegularContractCard: new ListAddContractView({
                title: yuyan.t('contract.list') + ' (' + yuyan.t('contract.regular') + ')',
                buttons: 'hide',
                collection: new ContractsCollection,
                columns: {
                    todo: true
                }
            }),

            listProductContractCard: new DashboardProductContractListView({
                collection: new ProductContractsCollection
            }),

            listCancelRequestedContractCard: new ListAddContractView({
                title: yuyan.t('contract.list') + ' (' + yuyan.t('contract.status.cancel') + ')',
                buttons: 'hide',
                collection: this.cancelColletion,
                columns: {
                    todo: true
                }
            })
        };

        this.listenTo(this.cancelColletion, 'reset', this.checkBilling);
    }

    render() {
        console.debug('Dashboard#render');

        this.el.innerHTML = template();

        this.subviews.actionBar.setElement(this.el.querySelector('#actionBar')).render();

        this.subviews.listExpressContractCard.setElement(this.el.querySelector('#divExpressContract')).render();

        this.subviews.listRegularContractCard.setElement(this.el.querySelector('#divRegularContract')).render();

        this.subviews.listProductContractCard.setElement(this.el.querySelector('#divProductContract')).render();

        this.subviews.listCancelRequestedContractCard.setElement(this.el.querySelector('#divCancelRequestedContract')).render();

        this.subviews.listExpressContractCard.collection.search({
            data: {
                status: ['pending', 'approved_us', 'documents_sent', 'applied', 'approved_bank', 'initial_payment_confirmed', 'keys_sent'],
                is_express: 1,
                inclusions: ['room'],
                order_by: ['updated_at']
            },
            reset: true
        });

        this.subviews.listRegularContractCard.collection.search({
            data: {
                status: ['pending', 'approved_us', 'documents_sent', 'applied', 'approved_bank', 'initial_payment_confirmed', 'keys_sent'],
                is_express: 0,
                inclusions: ['room'],
                order_by: ['updated_at']
            },
            reset: true
        });

        this.subviews.listProductContractCard.collection.search({
            data: {
                is_dashboard: 1,
                inclusions: ['shop'],
                order_by: ['updated_at']
            },
            reset: true
        });

        this.subviews.listCancelRequestedContractCard.collection.search({
            data: {
                status: ['cancel_applied', 'billing_stopped', 'cancel_short_settled', 'cancel_settled', 'keys_returned'],
                inclusions: ['room'],
                order_by: ['updated_at']
            },
            reset: true
        });

        return this;
    }

    checkBilling() {
        console.debug('Dashboard#checkBilling');
        const today = dayjs();
        const secondOfThisMonth = dayjs().set('date', 2);

        const hasBadContract = this.cancelColletion.detect(contract => contract.status === 'cancel_applied' && today.isSame(contract.end_date, 'month') && (today.isSame(secondOfThisMonth, 'day') || today.isAfter(secondOfThisMonth, 'day')));

        if (hasBadContract) {
            this.el.querySelector('#badContractAlert').classList.remove('d-none');
        }
    }
}
