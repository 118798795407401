var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <table class=\"table table-sm table-hover\" style=\"table-layout: auto;\">\n        <thead>\n            <tr class=\"thead-light\">\n                <th scope=\"col\">Timestamp</th>\n                <th scope=\"col\">Field</th>\n                <th scope=\"col\">Value</th>\n                <th scope=\"col\">Changed By</th>\n            </tr>\n        </thead>\n\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"logs") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":29,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <th class=\"text-nowrap\" scope=\"row\">"
    + alias2(__default(require("../../../handlebars-helpers/localtime.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"timestamp") : depth0),"YYYY-MM-DD HH:mm",{"name":"localtime","hash":{},"data":data,"loc":{"start":{"line":24,"column":52},"end":{"line":24,"column":99}}}))
    + "</th>\n                <td class=\"text-nowrap\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"field") : depth0), depth0))
    + "</td>\n                <td class=\"text-break\">"
    + alias2(__default(require("../../../handlebars-helpers/changelogValue.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"changelogValue","hash":{},"data":data,"loc":{"start":{"line":26,"column":39},"end":{"line":26,"column":68}}}))
    + "</td>\n                <td class=\"text-nowrap\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"changed_by") : depth0), depth0))
    + "</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n    <h5 class=\"modal-title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h5>\n\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n\n<div class=\"modal-body\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isLoading") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});