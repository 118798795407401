var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <th class=\"text-center\">Type</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <th class=\"text-center\">Occupied</th>\n            <th class=\"text-center\">Occupied %</th>\n            <th class=\"text-center\">Vacant</th>\n            <th class=\"text-center\">Vacant %</th>\n            <th class=\"text-center\">Out of Service</th>\n            <th class=\"text-center\">Out of Service %</th>\n            <th class=\"text-center\">Total</th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <th class=\"text-center\">Occupied (m&sup2;)</th>\n            <th class=\"text-center\">Occupied (m&sup2;) %</th>\n            <th class=\"text-center\">Vacant (m&sup2;)</th>\n            <th class=\"text-center\">Vacant (m&sup2;) %</th>\n            <th class=\"text-center\">Out of Service (m&sup2;)</th>\n            <th class=\"text-center\">Out of Service (m&sup2;) %</th>\n            <th class=\"text-center\">Total (m&sup2;)</th>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isGrouped") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "            <th class=\"text-center\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"size") : depth0), depth0))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isCountMode") : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":51,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th class=\"text-center\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</th>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"occupied") : depth0)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"occupied") : depth0)) != null ? lookupProperty(stack1,"countPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":37,"column":35},"end":{"line":37,"column":82}}}))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"vacant") : depth0)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"vacant") : depth0)) != null ? lookupProperty(stack1,"countPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":39,"column":35},"end":{"line":39,"column":80}}}))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"unavailable") : depth0)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"unavailable") : depth0)) != null ? lookupProperty(stack1,"countPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":41,"column":35},"end":{"line":41,"column":85}}}))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</td>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"occupied") : depth0)) != null ? lookupProperty(stack1,"area") : stack1), depth0))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"occupied") : depth0)) != null ? lookupProperty(stack1,"areaPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":45,"column":35},"end":{"line":45,"column":81}}}))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"vacant") : depth0)) != null ? lookupProperty(stack1,"area") : stack1), depth0))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"vacant") : depth0)) != null ? lookupProperty(stack1,"areaPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":47,"column":35},"end":{"line":47,"column":79}}}))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"unavailable") : depth0)) != null ? lookupProperty(stack1,"area") : stack1), depth0))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"unavailable") : depth0)) != null ? lookupProperty(stack1,"areaPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":49,"column":35},"end":{"line":49,"column":84}}}))
    + "</td>\n            <td class=\"text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"area") : stack1), depth0))
    + "</td>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "colspan=\"2\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-sm table-bordered table-hover text-nowrap\">\n    <thead class=\"thead-light\">\n        <tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGrouped") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "            <th class=\"text-center\">Size (帖)</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCountMode") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n    </thead>\n\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"roomOccupancyList") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":53,"column":17}}})) != null ? stack1 : "")
    + "    </tbody>\n\n    <tfoot class=\"thead-light\">\n        <tr>\n            <th class=\"text-right\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGrouped") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":35},"end":{"line":58,"column":70}}})) != null ? stack1 : "")
    + ">TOTAL</th>\n            <th class=\"text-right\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"occupied") : stack1), depth0))
    + "</th>\n            <th class=\"text-right\">"
    + alias3(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"occupiedPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":60,"column":35},"end":{"line":60,"column":77}}}))
    + "</th>\n            <th class=\"text-right\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"vacant") : stack1), depth0))
    + "</th>\n            <th class=\"text-right\">"
    + alias3(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"vacantPercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":62,"column":35},"end":{"line":62,"column":75}}}))
    + "</th>\n            <th class=\"text-right\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"unavailable") : stack1), depth0))
    + "</th>\n            <th class=\"text-right\">"
    + alias3(__default(require("../../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"unavailablePercentage") : stack1),"0.0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":64,"column":35},"end":{"line":64,"column":80}}}))
    + "</th>\n            <th class=\"text-right\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? lookupProperty(stack1,"total") : stack1), depth0))
    + "</th>\n        </tr>\n    </tfoot>\n</table>";
},"useData":true,"useDepths":true});