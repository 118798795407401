import { Collection } from 'backbone';
import config from '@/js/app/config';
import OffenderModel from '../models/offender';

export default class OffendersCollection extends Collection {
    constructor(options) {
        super(options);

        this.url = config.api.url + 'offenders';
        this.model = OffenderModel;
    }
}
