import ModalView from './modal';
import template from '../templates/delete-modal.hbs';

export default class DeleteModalView extends ModalView {
    preinitialize() {
        this.events = {
            'click [data-action="delete"]': this.handleClickDelete,
            'keypress': this.handleKeypress,
        };
        this.preinitializeModal();
    }

    render() {
        console.debug('DeleteModal#render');

        this.renderModal().modalContent.innerHTML = template();

        return this;
    }

    handleKeypress(e) {
        // If [enter] key pressed
        if (e.keyCode === 13) {
            this.delete();
        }
    }

    handleClickDelete() {
        console.debug('DeleteModal#handleClickDelete');

        this.delete();
    }

    delete() {
        this.model.destroy({
            wait: true
        }).then(() => {
            this.$el.modal('hide');
        });
    }
}
