import { Model } from 'backbone';
import config from '@/js/app/config';

export default class ContractFileModel extends Model {
    preinitialize() {
        this.idAttribute = 'filename';
    }

    urlRoot() {
        return config.api.url + 'contracts/' + this.contractId + '/files';
    }

    initialize(attrs, options) {
        this.contractId = options.contractId || options.collection.contractId || null;
    }
}
