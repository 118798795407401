var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form autocomplete=\"off\" novalidate>\n    <div class=\"modal-header\">\n        <h5 class=\"modal-title\">解約確認</h5>\n\n        <button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n            <span aria-hidden=\"true\">&times;</span>\n        </button>\n    </div>\n\n    <div class=\"modal-body\">\n        <div class=\"form-group row\">\n            <label for=\"field-reason\" class=\"col-form-label col-3\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":52}}}))
    + "\n            </label>\n            <div class=\"col-4\">\n                <select id=\"field-reason\" name=\"reason\" data-part=\"cancelSelect\"\n                    class=\"form-control\">\n                    <option value=\"\"></option>\n                    <option value=\"moving\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.moving",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":80}}}))
    + "</option>\n                    <option value=\"renovating\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.renovating",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":20,"column":88}}}))
    + "</option>\n                    <option value=\"price\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.price",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":42},"end":{"line":21,"column":78}}}))
    + "</option>\n                    <option value=\"facility\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.facility",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":45},"end":{"line":22,"column":84}}}))
    + "</option>\n                    <option value=\"service\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.service",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":44},"end":{"line":23,"column":82}}}))
    + "</option>\n                    <option value=\"service\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.name_change",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":44},"end":{"line":24,"column":86}}}))
    + "</option>\n                    <option value=\"other\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_reason.other",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":42},"end":{"line":25,"column":78}}}))
    + "</option>\n                </select>\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"field-comment\" class=\"col-form-label col-3\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.cancel_comment",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":47}}}))
    + "\n            </label>\n            <div class=\"col-lg\">\n                <textarea id=\"field-comment\" name=\"comment\" rows=\"10\" type=\"text\"\n                    class=\"form-control\"></textarea>\n            </div>\n        </div>\n\n        <div class=\"form-group row mb-0\">\n            <label for=\"field-end_date\" class=\"col-form-label col-3\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.end_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":42,"column":41}}}))
    + "\n            </label>\n            <div class=\"col-4\">\n                <input id=\"field-end_date\" name=\"end_date\" type=\"date\" class=\"form-control\" value=\""
    + alias2(__default(require("../../../handlebars-helpers/contract/endOfNextMonth.js")).call(alias1,{"name":"contract/endOfNextMonth","hash":{},"data":data,"loc":{"start":{"line":45,"column":99},"end":{"line":45,"column":128}}}))
    + "\">\n            </div>\n        </div>\n\n        <p id=\"cancellationError\" class=\"text-danger mt-3 mb-0 d-none\"></p>\n    </div>\n\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":77},"end":{"line":53,"column":99}}}))
    + "</button>\n        <button type=\"submit\" class=\"btn btn-primary\" data-action=\"register\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.register",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":77},"end":{"line":54,"column":101}}}))
    + "</button>\n    </div>\n</form>";
},"useData":true});