import ModalView from '@/js/app/generic/views/modal';
import template from '../templates/unassign-modal.hbs';

export default class SecurityKeyUnassignModal extends ModalView {
    preinitialize(options) {
        this.events = {
            'click [data-action="unassign"]': this.handleUnassignClick,
            'keypress': this.handleKeypress,
        };
        this.readEditView = options.readEditView;
        this.preinitializeModal();
    }

    render() {
        console.debug('SecurityKeyUnassignModal#render');

        this.renderModal().modalContent.innerHTML = template();

        return this;
    }

    handleKeypress(e) {
        // If [enter] key pressed
        if (e.keyCode === 13) {
            this.unassign();
        }
    }

    handleUnassignClick() {
        console.debug('SecurityKeyUnassignModal#handleUnassignClick');
        this.unassign();
    }

    unassign() {
        const changes = this.model.changedAttributes({ room_id: null });
        if (changes !== false) {
            this.model.save(changes, {
                patch: true,
                wait: true,
                error: this.errorOnSave
            })
                .then(() => {
                    this.$el.modal('hide');

                    if (this.readEditView) {
                        this.readEditView.remove();

                        this.model.collection.remove(this.model);
                    }
                });

        } else {
            this.$el.modal('hide');
        }
    }
}
