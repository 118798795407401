import { View } from 'backbone';
import config from '@/js/app/config';
import ContractsCollection from '@/js/app/contract/collections/contracts';
import ListAddContractView from '@/js/app/contract/views/list-add';
import CustomerFilesCollection from '@/js/app/customer-file/collections/customer-files';
import CustomerFileIndexView from '@/js/app/customer-file/views/index';
import oauth2Client from '@/js/app/oauth2-client';
import CustomerActionBarView from './action-bar';
import CustomerReadUpdateView from './read-update';
import template from '../templates/detail.hbs';

export default class CustomerDetailView extends View {
    preinitialize() {
        this.className = 'container';
    }

    initialize() {
        this.subviews = {
            actionBar: new CustomerActionBarView({
                customerId: this.model.id,
                model: this.model
            }),

            customerCard: new CustomerReadUpdateView({
                model: this.model,
            }),

            listCustomerFileCard: new CustomerFileIndexView({
                collection: new CustomerFilesCollection([], {
                    customerId: this.model.get('id')
                }),
                model: this.model,
            }),

            listAddContractCard: new ListAddContractView({
                collection: new ContractsCollection,
                customerModel: this.model,
                columns: {
                    status: true
                }
            })
        };

        oauth2Client.fetch(`${config.api.url}contracts/fees`, {})
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.json();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((data) => {
                this.subviews.listAddContractCard.setFees(data);
            });

        // When model changes last_name/first_name; updateTitle
        this.listenTo(this.model, 'change', this.updateTitle);

        // When model changes is_migrated; handleMigratedBadgeDisplay
        this.listenTo(this.model, 'change:is_migrated', this.handleMigratedBadgeDisplay);
    }

    render() {
        console.debug('CustomerDetail#render');

        // Attach template to el
        this.el.innerHTML = template();

        // Set el of action bar view as #actionBar then render
        this.subviews.actionBar.setElement(this.el.querySelector('#actionBar'));

        // Set el of customer detail view as #details then render
        this.subviews.customerCard.setElement(this.el.querySelector('#details')).render();

        // Set el of customer file list view as #listAddFile then render
        this.subviews.listCustomerFileCard.setElement(this.el.querySelector('#listAddFile')).render();

        // Set el of contract list view as #listAddContract then render
        this.subviews.listAddContractCard.setElement(this.el.querySelector('#listAddContract')).render();

        // Trigger fetch of customer file collection
        this.subviews.listCustomerFileCard.collection.fetch({
            reset: true
        });

        // Trigger fetch of contract collection with data as query
        this.subviews.listAddContractCard.collection.search({
            data: {
                customer_id: this.model.get('id'),
                inclusions: ['room']
            },
            reset: true
        });

        return this;
    }

    updateTitle() {
        console.debug('CustomerDetail#updateTitle');

        if (this.model.get('customer_type') === 'personal') {
            // Change text of #title based on last name and first name
            this.el.querySelector('#title').innerText = this.model.get('last_name') + this.model.get('first_name');
        } else if (this.model.get('customer_type') === 'corporate') {
            // Change text of #title based on company_name
            this.el.querySelector('#title').innerText = this.model.get('company_name');
        }
    }

    handleMigratedBadgeDisplay(model, value) {
        console.debug('CustomerDetail#handleMigratedBadgeDisplay');

        if (value === 1) {
            // Remove "d-none" from class list
            this.el.querySelector('[data-part="migratedBadge"]').classList.remove('d-none');
        }
    }
}
