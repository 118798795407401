var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"shop") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</td>\n                <td class=\"text-right\">"
    + alias1(__default(require("../../../handlebars-helpers/formatCurrency.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"pm_fee") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":13,"column":39},"end":{"line":13,"column":69}}}))
    + "</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n    <table class=\"table table-sm table-hover text-nowrap border\">\n        <thead>\n            <tr class=\"thead-light\">\n                <th>"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":37}}}))
    + "</th>\n                <th class=\"text-right\">"
    + ((stack1 = __default(require("../../../handlebars-helpers/t.js")).call(alias1,"reports.pm_fee",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":63}}})) != null ? stack1 : "")
    + "</th>\n            </tr>\n        </thead>\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pmFee") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":15,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n        <tfoot>\n            <tr>\n                <td>TOTAL</td>\n                "
    + ((stack1 = __default(require("../../../handlebars-helpers/reports/pmFeeTotal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"pmFee") : depth0),{"name":"reports/pmFeeTotal","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":48}}})) != null ? stack1 : "")
    + "\n            </tr>\n        </tfoot>\n    </table>\n</div>";
},"useData":true});