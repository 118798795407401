var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form autocomplete=\"off\" novalidate>\n    <div class=\"modal-body\">\n        <div class=\"form-group row\">\n            <label for=\"field-source\" class=\"col-form-label col-2 text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"offender.source",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":78},"end":{"line":4,"column":101}}}))
    + "</label>\n            <div class=\"col\">\n                <input type=\"text\" name=\"source\" id=\"field-source\" class=\"form-control\" value=\"TRT\">\n            </div>\n        </div>\n\n        <div class=\"form-group row align-items-center\">\n            <label class=\"col-form-label col-2 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"offender.reason",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":62},"end":{"line":11,"column":85}}}))
    + "</label>\n            <div class=\"col\">\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-anti_social\" value=\"anti_social\">\n                    <label class=\"form-check-label\" for=\"reason-anti_social\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.anti_social",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":77},"end":{"line":15,"column":115}}}))
    + "</label>\n                </div>\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-force_cancel\" value=\"force_cancel\">\n                    <label class=\"form-check-label\" for=\"reason-force_cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.force_cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":78},"end":{"line":19,"column":117}}}))
    + "</label>\n                </div>\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-claimer\" value=\"claimer\">\n                    <label class=\"form-check-label\" for=\"reason-claimer\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.claimer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":73},"end":{"line":23,"column":107}}}))
    + "</label>\n                </div>\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-uncontactable\" value=\"uncontactable\">\n                    <label class=\"form-check-label\" for=\"reason-uncontactable\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.uncontactable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":79},"end":{"line":27,"column":119}}}))
    + "</label>\n                </div>\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-unpaid_1\" value=\"unpaid_1\">\n                    <label class=\"form-check-label\" for=\"reason-unpaid_1\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.unpaid_1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":74},"end":{"line":31,"column":109}}}))
    + "</label>\n                </div>\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-unpaid_2\" value=\"unpaid_2\">\n                    <label class=\"form-check-label\" for=\"reason-unpaid_2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.unpaid_2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":74},"end":{"line":35,"column":109}}}))
    + "</label>\n                </div>\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-unpaid_3\" value=\"unpaid_3\">\n                    <label class=\"form-check-label\" for=\"reason-unpaid_3\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.unpaid_3",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":74},"end":{"line":39,"column":109}}}))
    + "</label>\n                </div>\n                <div class=\"form-check form-check-inline\">\n                    <input class=\"form-check-input\" type=\"checkbox\" name=\"reason\" id=\"reason-cancellation_invoice\" value=\"cancellation_invoice\">\n                    <label class=\"form-check-label\" for=\"reason-cancellation_invoice\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"customer.blacklist.cancellation_invoice",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":86},"end":{"line":43,"column":133}}}))
    + "</label>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"modal-footer\">\n        <div class=\"row justify-content-end align-items-center\">\n            <div class=\"col-auto\">\n                <button type=\"button\" class=\"btn btn-secondary btn-block\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":95},"end":{"line":52,"column":117}}}))
    + "</button>\n            </div>\n            <div class=\"col-auto\">\n                <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":72},"end":{"line":55,"column":92}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>";
},"useData":true});