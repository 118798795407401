import { View } from 'backbone';
import Spinner from '@/js/app/generic/views/spinner';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import PriceCheckCollection from '../collection';
import template from '../templates/index.hbs';
import tableTemplate from '../templates/table.hbs';
import tableFooterTemplate from '../templates/table-footer.hbs';
import PriceCheckRowView from './row';

export default class PriceCheckView extends View {
    preinitialize() {
        this.className = 'container';

        this.events = {
            'change [name="shop_id"]': this.handleShopIdChange,
        };

        this.collection = new PriceCheckCollection();
        this.shopsCollection = new ShopsCollection;

        this.subviews = {
            shopcntr: new ShopCntrView({
                collection: this.shopsCollection,
                field_id: "field-shop_id",
                hasAll: true
            })
        };

        this.spinner = new Spinner;

        this.allShops = true;
    }

    initialize() {
        this.render();

        this.listenTo(this.collection, 'sync', this.handleCollectionSync);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', () => {
            this.spinner.spin(this.el.querySelector('#reportList'));
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', () => {
            this.spinner.stop();
        });

        // Fetch collections
        this.collection.fetch();
        this.shopsCollection.fetch();
    }

    render() {
        console.debug('PriceCheckView#render');

        this.el.innerHTML = template();

        this.renderTable();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });

        // Transform table to DataTable
        // this.$el.find('table').DataTable({
        //     searching: false,
        //     paging: false,
        //     info: false,
        //     order: [0, 'desc'],
        // });

        return this;
    }

    renderTable() {
        this.el.querySelector('#reportList').innerHTML = tableTemplate({
            allShops: this.allShops,
        });
    }

    renderTableRows() {
        console.debug('PriceCheckView#renderTableRows');

        const fragment = new DocumentFragment;

        this.collection.data.forEach((row) => {
            const view = new PriceCheckRowView({
                model: row,
                allShops: this.allShops,
            });

            fragment.append(view.render().el);
        });

        this.$el.find('#reportList tbody').html(fragment);

        return this;
    }

    renderTableFooter() {
        console.debug('PriceCheckView#renderTableFooter');

        const footer = tableFooterTemplate({
            total: this.collection.total,
            allShops: this.allShops,
        });

        this.$el.find('#reportList tfoot').html(footer);
    }

    handleCollectionSync() {
        console.debug('PriceCheckView#handleCollectionSync');

        this.renderTableRows();
        this.renderTableFooter();
    }

    handleShopIdChange(e) {
        console.debug('PriceCheckView#handleShopIdChange');

        const shopValue = e.currentTarget.value;
        const data = {};

        if (shopValue == 'all') {
            this.allShops = true;
        } else {
            data.shop_id = Number(shopValue);
            this.allShops = false;
        }

        this.renderTable();

        this.collection.fetch({ data });
    }
}
