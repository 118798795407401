import { Collection } from 'backbone';
import ModalView from '@/js/app/generic/views/modal';
import SpinnerView from '@/js/app/generic/views/spinner';
import ShopFileHistoryItemView from './history-item';
import template from '../templates/history.hbs';

export default class ShopFileHistoryView extends ModalView {
    preinitialize() {
        this.preinitializeModal();
    }

    initialize() {
        // When collection updates; render
        this.listenTo(this.collection, 'add', this.addAgreementItem);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('ShopFileHistory#render');

        this.renderModal().modalContent.innerHTML = template();

        this.collection.each(model => this.addAgreementItem(model));

        return this;
    }

    addAgreementItem(model) {
        console.debug('ShopFileHistory#addAgreementItem');

        const agreementItem = new ShopFileHistoryItemView({
            model
        });

        this.$el.find('[data-slot="agreements"]').append(agreementItem.render().el);
    }
}