import { View } from 'backbone';
import dayjs from 'dayjs';
import config from '@/js/app/config';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../../templates/shop-downloads/card-item.hbs';

export default class PmShopDownloadsCardItemNoCollectionView extends View {
    preinitialize(options) {
        this.className = 'col my-2';
        this.type = options.type;
        this.criteria = options.criteria;
        this.fileExtension = options.fileExtension;
        this.events = {
            'click [data-action="download"]': this.handleDownloadClick,
        };
    }

    render() {
        console.debug('PmShopDownloadsCardItemNoCollection#renderTemplate');

        // Attach template to el
        this.el.innerHTML = template({
            title: this.type.label,
            hasDownload: true,
            hasButton: true
        });

        return this;
    }

    handleDownloadClick() {
        console.debug('PmShopDownloadsCardItemNoCollection#handleDownloadClick');

        const version = dayjs().format('YYYYMMDDHHmmss');

        oauth2Client.download(
            config.api.url + 'shops/' + this.model.id + '/reports/' + this.criteria.period + '/' + this.type.value,
            {
                method: 'GET'
            },
            {
                filename: `${this.model.get('name')}_${this.criteria.period}_${this.type.label}_v${version}.${this.fileExtension}`
            }
        );
    }
}
