import formToObject from '@/js/libs/form-utils';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import User from '@/js/app/user';
import readTemplate from '../templates/read.hbs';
import updateTemplate from '../templates/edit.hbs';

export default class RoomReadUpdateView extends CardView {
    preinitialize() {
        this.title = yuyan.t('room.details');
        this.events = {
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.renderRead,
            'submit form#frmEditRoom': this.saveUpdate,
        };

        // If user has one of the rooms permissions
        if (User.isAllowed('consus:rooms') || User.isAllowed('consus:rooms.status') || User.isAllowed('consus:rooms.notes')) {
            // Include edit and cancel buttons
            this.buttons = [
                {
                    cntrData: 'editBtnCntr',
                    text: yuyan.t('generic.edit'),
                    className: 'btn-dark',
                    isHidden: false,
                    dataset: [
                        {
                            label: 'action',
                            value: 'edit'
                        }
                    ]
                },
                {
                    cntrData: 'cancelBtnCntr',
                    text: yuyan.t('generic.cancel'),
                    className: 'btn-secondary',
                    isHidden: true,
                    dataset: [
                        {
                            label: 'action',
                            value: 'cancel'
                        }
                    ]
                },
            ];
        }
    }

    initialize() {
        // Bind this on errorOnSave
        _.bindAll(this, 'errorOnSave');

        // When model changes; renderRead
        this.listenTo(this.model, 'change', this.renderRead);

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });
    }

    render() {
        console.debug('RoomReadUpdate#render');

        this.renderCard();

        return this;
    }

    renderRead() {
        console.debug('RoomReadUpdate#renderRead');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('editBtnCntr')
            .renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('RoomReadUpdate#renderReadTemplate');

        // Attach readTemplate to el with room model
        this.cardBody.innerHTML = readTemplate({
            room: this.model.toJSON(),
        });

        return this;
    }

    renderEdit() {
        console.debug('RoomReadUpdate#renderEdit');

        this.hideButtonContainer('editBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderEditTemplate();

        // If not allowed access to rooms
        if (!User.isAllowed('consus:rooms')) {
            // Disable all fields
            this.el.querySelectorAll('[name]').forEach((el) => {
                el.disabled = true;
            });

            // If allowed to access room status; enable field
            if (User.isAllowed('consus:rooms.status')) {
                this.el.querySelector('[data-scope="consus:rooms.status"]').disabled = false;
            }

            // If allowed to access room notes; enable field
            if (User.isAllowed('consus:rooms.notes')) {
                this.el.querySelector('[data-scope="consus:rooms.notes"]').disabled = false;
            }
        }

        return this;
    }

    renderEditTemplate() {
        console.debug('RoomReadUpdate#renderEditTemplate');

        // Attach updateTemplate to el
        this.cardBody.innerHTML = updateTemplate();

        // If model exists
        if (this.model) {
            // For each model attribute
            _.each(this.model.toJSON(), (val, key) => {
                // If value is array
                if (Array.isArray(val)) {
                    // For each value in array
                    val.forEach(v => {
                        // Initialize field as element with name = key
                        const field = this.el.querySelector('[name="' + key + '"][value="' + v + '"]');

                        // If field exists, set as checked
                        if (field) {
                            field.checked = true;
                        }
                    });
                } else {
                    // Initialize field as element with name = key
                    const field = this.el.querySelector('[name="' + key + '"]');

                    // If field exists, set value
                    if (field) {
                        field.value = val;
                    }
                }
            });
        }

        return this;
    }

    saveUpdate($e) {
        console.debug('RoomReadUpdate#saveUpdate');

        if ($e instanceof Object) {
            $e.preventDefault();
            $e.stopPropagation();

            // Add .was-validated on form
            $e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if ($e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject($e.currentTarget);

            // Initialize changes as result of changedAttributes of model based on data
            const changes = this.model.changedAttributes(data);

            // If changes exist
            if (changes !== false) {
                // Trigger save of model with changes as attributes
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    // On error: errorOnSave
                    error: this.errorOnSave
                });
            }
            // Else, trigger change of model
            else {
                this.model.trigger('change', this.model);
            }
        }
    }

    errorOnSave(model, response) {
        console.debug('RoomReadUpdate#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
