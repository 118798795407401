var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form id=\"frmEditProductType\" autocomplete=\"off\" novalidate>\n    <div class=\"row\">\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-name\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":44}}}))
    + "\n                </label>\n                <div class=\"col\">\n                    <input id=\"field-name\" name=\"name\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-br_item_code\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.br_item_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":52}}}))
    + "\n                </label>\n                <div class=\"col\">\n                    <input id=\"field-br_item_code\" name=\"br_item_code\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-price\" class=\"col-form-label col-4 text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.price",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":81},"end":{"line":29,"column":106}}}))
    + "</label>\n                <div class=\"col-4\">\n                    <div class=\"input-group\">\n                        <div class=\"input-group-prepend\">\n                            <span class=\"input-group-text\">¥</span>\n                        </div>\n                        <input id=\"field-price\" name=\"price\" type=\"text\" class=\"form-control text-right\" data-number>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-free_days\" class=\"col-form-label col-4 text-right\">Free days</label>\n                <div class=\"col-4\">\n                    <input id=\"field-free_days\" name=\"free_days\" type=\"text\" class=\"form-control\" data-number>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-is_intangible\"\n                    class=\"col-form-label col-4 text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.is_intangible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":60},"end":{"line":55,"column":93}}}))
    + "</label>\n                <div class=\"col-4 d-flex\">\n                    <div class=\"form-check-inline\">\n                        <input id=\"field-is_intangible\" type=\"checkbox\" class=\"form-check-input\" name=\"is_intangible\"\n                            value=\"1\" data-unchecked-value=\"0\" data-number>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-lg-8\">\n            <div class=\"form-group row\">\n                <label for=\"field-notes\"\n                    class=\"col-form-label col-md-4 col-lg-3 text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.notes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":75},"end":{"line":71,"column":100}}}))
    + "</label>\n                <div class=\"col-md\">\n                    <textarea id=\"field-notes\" name=\"notes\" rows=\"10\" type=\"text\" class=\"form-control\"></textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-auto\">\n            <button type=\"button\" class=\"btn btn-secondary btn-block\" data-action=\"cancel\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":16},"end":{"line":82,"column":38}}}))
    + "\n            </button>\n        </div>\n        <div class=\"col-auto\">\n            <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":68},"end":{"line":86,"column":88}}}))
    + "</button>\n        </div>\n    </div>\n</form>";
},"useData":true});