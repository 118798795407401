import { Collection } from 'backbone';
import config from '@/js/app/config';

export default class OwnerReportCollection extends Collection {
    initialize(models, options) {
        if (options) {
            this.ownerId = options.ownerId;
        }
    }

    url() {
        return config.api.url + 'owners/' + this.ownerId + '/reports';
    }

    comparator(model) {
        return new Date(model.get('version')).getTime();
    }
}
