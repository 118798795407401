import { Collection } from 'backbone';
import config from '@/js/app/config';
import ProductStockModel from '../models/product-stock';

export default class ProductStocksCollection extends Collection {
    preinitialize() {
        // Initialize defaults
        this.url = config.api.url + 'product-stocks';

        this.model = ProductStockModel;
    }
}
