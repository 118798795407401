import formToObject from '@/js/libs/form-utils';
import { getJidai } from '@/js/libs/jidai';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import { history } from 'backbone';
import numeral from 'numeral';
import readTemplate from '../templates/read.hbs';
import updateTemplate from '../templates/edit.hbs';

export default class OffenderReadUpdateView extends CardView {
    preinitialize() {
        this.title = yuyan.t('offender.details');
        // Declare true instance props
        this.events = {
            'click [data-action="edit"]': this.renderEdit,
            'click [data-action="cancel"]': this.renderRead,
            'click [data-action="delete"]': this.handleDeleteClick,
            'submit form': this.handleFormSubmit,
        }
    }

    constructor(options) {
        super(options);

        // Initialize defaults
        this.buttons = [
            {
                cntrData: 'deleteBtnCntr',
                text: yuyan.t('generic.delete'),
                className: 'btn-danger',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'delete'
                    }
                ]
            },
            {
                cntrData: 'editBtnCntr',
                text: yuyan.t('generic.edit'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'edit'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            },
        ];

        // When model changes; render
        this.listenTo(this.model, 'change', this.renderRead);

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });
    }

    render() {
        console.debug('OffenderReadUpdate#render');

        this.renderCard()
            .renderReadTemplate();

        return this;
    }

    renderRead() {
        console.debug('OffenderReadUpdate#renderRead');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('editBtnCntr')
            .renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('OffenderReadUpdate#renderReadTemplate');

        // Attach readTemplate to cardBody with offender model
        this.cardBody.innerHTML = readTemplate({
            offender: this.model.toJSON()
        });

        return this;
    }

    renderEdit() {
        console.debug('OffenderReadUpdate#renderEdit');

        this.hideButtonContainer('editBtnCntr')
            .showButtonContainer('cancelBtnCntr')
            .renderEditTemplate();

        return this;
    }

    renderEditTemplate() {
        console.debug('OffenderReadUpdate#renderEditTemplate');

        // Attach updateTemplate to cardBody
        this.cardBody.innerHTML = updateTemplate();

        this.loadYears();

        _.each(this.model.toJSON(), (value, key) => {
            if (Array.isArray(value)) {
                value.forEach(val => {
                    const field = this.el.querySelector(`[name="${key}"][value="${val}"]`);

                    // If field exists, set as checked
                    if (field) {
                        field.checked = true;
                    }
                });
            } else {
                // Initialize field as element with name = key
                let field = this.el.querySelector(`[name="${key}"]`);

                // If field exists
                if (field) {
                    // If field type is checkbox, set checked based on value
                    if (field.type === 'checkbox') {
                        field.checked = Boolean(value); // Force convert value to boolean
                    }
                    // Else if field type is radio
                    else if (field.type === 'radio') {
                        // Find field with the same value
                        field = this.el.querySelector(`[name="${key}"][value="${value}"]`);

                        // If field exists, set checked to true
                        if (field) {
                            field.checked = true;
                        }
                    }
                    // Else if numeral data attribute exists, format appropriately
                    else if (field.dataset.numeral) {
                        field.value = value ? numeral(value).format(field.dataset.numeral) : '';
                    }
                    // Else, set value
                    else {
                        field.value = value;
                    }
                }
            }
        });

        if (this.model.get('dob')) {
            this.el.querySelector('#dobYY').value = this.model.get('dob').substring(0, 4);
            this.el.querySelector('#dobMM').value = this.model.get('dob').substring(5, 7);
            this.el.querySelector('#dobDD').value = this.model.get('dob').substring(8);
        }

        return this;
    }

    loadYears() {
        console.debug('OffenderReadUpdate#loadYears');

        const year = (new Date).getFullYear();
        const options = document.createDocumentFragment();

        for (let i = 0; i < 120; i++) {
            const value = year - i;
            const o = document.createElement('option');
            o.value = value.toString();
            o.innerText = value + ' (' + getJidai(value) + ')';
            options.appendChild(o);
        }

        this.el.querySelector('#dobYY').appendChild(options);
    }

    handleDeleteClick() {
        console.debug('OffenderReadUpdate#handleDeleteClick');

        if (confirm('Are you sure you want to delete this offender?')) {
            this.model.destroy({ success: this.navigate });
        }
    }

    navigate() {
        console.debug('OffenderReadUpdate#navigate');

        // Navigate to offenders
        history.navigate('offenders', { trigger: true });
    }

    handleFormSubmit(e) {
        console.debug('OffenderReadUpdate#handleFormSubmit');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            const dobYY = this.el.querySelector('#dobYY').value;
            const dobMM = this.el.querySelector('#dobMM').value;
            const dobDD = this.el.querySelector('#dobDD').value;
            const dobEl = this.el.querySelector('[name="dob"]');

            if (dobEl && dobYY && dobMM && dobDD) {
                dobEl.value = dobYY + '-' + dobMM + '-' + dobDD;
            }

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);

            // Initialize changes as result of changedAttributes of model based on data
            const changes = this.model.changedAttributes(data);

            // If changes exist
            if (changes !== false) {
                // Trigger save of model with changes as attributes
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    // On error: errorOnSave
                    error: this.handleSaveError
                });
            }
            // Else, trigger change of model
            else {
                this.model.trigger('change', this.model);
            }
        }
    }

    handleSaveError(model, response) {
        console.debug('OffenderReadUpdate#handleSaveError');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
