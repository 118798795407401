import { View } from 'backbone';
import ChangelogCollection from '@/js/app/changelog/collections/changelog';
import ChangelogModalView from '@/js/app/changelog/views/modal';
import yuyan from '@/js/app/yuyan';
import template from '../templates/action-bar.hbs';
import ShopGiraffeModalView from './giraffe-modal';

export default class ShopActionBarView extends View {
    preinitialize() {
        // Initialize defaults
        this.events = {
            'click [data-modal="giraffe"]': this.handleRegisterInGiraffeClick,
            'click [data-modal="changelog"]': this.handleChangelogClick,
        };
    }

    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('ShopActionBar#render');

        // Attach template to el
        this.el.innerHTML = template({
            hasFacilityId: Boolean(this.model.get('giraffe_facility_id'))
        });

        return this;
    }

    handleRegisterInGiraffeClick() {
        console.debug('ShopActionBar#handleRegisterInGiraffeClick');

        const view = new ShopGiraffeModalView({
            model: this.model
        });

        view.render();
    }

    handleChangelogClick() {
        console.debug('ShopActionBar#handleChangelogClick');
        const view = new ChangelogModalView({
            title: `${yuyan.t('shop.title')} ID: ${this.model.get('name')} Changelog`,
            collection: new ChangelogCollection([], {
                resource: 'shops',
                resourceId: this.model.get('id')
            })
        });

        view.render();
    }
}
