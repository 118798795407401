var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<td></td>\n<td></td>\n<td>\n    <button class=\"btn btn-secondary\" type=\"button\" disabled>\n        <span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n        Loading...\n    </button>\n</td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"model") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":50,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/localtime.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"version") : stack1),"YYYY-MM-DD HH:mm:ss",{"name":"localtime","hash":{},"data":data,"loc":{"start":{"line":15,"column":25},"end":{"line":15,"column":74}}}))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"created_by_name") : stack1), depth0))
    + "</td>\n<td class=\"text-right\">\n    <button type=\"button\" class=\"btn btn-primary\" data-action=\"download\">\n        <i class=\"fa fa-download\" aria-hidden=\"true\"></i>\n    </button>\n    <button type=\"button\" class=\"btn btn-dark\" data-action=\"history\">\n        <i class=\"fa fa-history\" aria-hidden=\"true\"></i>\n    </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGenerating") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "</td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <button class=\"btn btn-outline-success\" type=\"button\" disabled>\n        <span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n        Loading...\n    </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"btn btn-outline-success\" data-action=\"generate\">\n        <i class=\"fa fa-refresh\" aria-hidden=\"true\"></i>\n    </button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td></td>\n<td></td>\n<td class=\"text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isGenerating") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "</td>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <button class=\"btn btn-success\" type=\"button\" disabled>\n        <span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n        Loading...\n    </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"btn btn-success\" data-action=\"generate\">\n        <i class=\"fa fa-refresh\" aria-hidden=\"true\"></i>\n    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"align-middle\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</td>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isLoading") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":52,"column":7}}})) != null ? stack1 : "");
},"useData":true});