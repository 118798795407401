import { View } from 'backbone';
import Chart from 'chart';
import dayjs from 'dayjs';
import config from '@/js/app/config';
import SelectYearMonth from '@/js/app/generic/views/select-year-month';
import SpinnerView from '@/js/app/generic/views/spinner';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import RoomOccupancyCollection from './collection';
import template from './templates/index.hbs';
import tableTemplate from './templates/table.hbs';

export default class RoomOccupancyView extends View {
    preinitialize() {
        this.className = 'container';
        this.collection = new RoomOccupancyCollection();
        this.events = {
            'change [name="shop_id"]': this.handleShopIdChange,
            'change [name="year"], [name="month"]': this.handleDateChange,
            'change [name="mode"]': this.handleModeChange,
            'change [name="group"]': this.handleGroupChange,
        };
        this.subviews = {
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop-id",
                hasAll: true
            }),
            selectYearMonth: new SelectYearMonth({
                minYear: 2021,
                defaultPreviousMonth: true,
            })
        };
    }

    initialize() {
        const today = dayjs().subtract(1, 'month');
        this.year = today.year();
        this.month = today.month() + 1;
        this.isCountMode = true;
        this.isGrouped = 1;

        this.listenTo(this.collection, 'sync', this.handleCollectionReset);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            s.spin(this.el);
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function () {
            s.stop();
        });
    }

    render() {
        console.debug('RoomOccupancy#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });
        this.subviews.shopcntr.setValue('all');

        // Attach select year/month subview to #dateCntr and render
        this.subviews.selectYearMonth.setElement(this.el.querySelector('#dateCntr')).render();

        return this;
    }

    handleShopIdChange(e) {
        console.debug('RoomOccupancy#handleShopIdChange');
        const value = e.currentTarget.value;
        this.shopId = value === 'all' ? undefined : value;

        this.collection.fetch({
            data: {
                shop_id: this.shopId,
                year: this.year,
                month: this.month,
                is_grouped: this.isGrouped
            }
        });

        return this;
    }

    handleDateChange() {
        console.debug('PmIncomeDeals#handleDateChange');

        this.year = this.el.querySelector('[name="year"]').value;
        this.month = Number(this.el.querySelector('[name="month"]').value);

        this.collection.fetch({
            data: {
                shop_id: this.shopId,
                year: this.year,
                month: this.month,
                is_grouped: this.isGrouped
            }
        });
    }

    handleModeChange(e) {
        console.debug('RoomOccupancy#handleModeChange');

        this.isCountMode = e.currentTarget.value === 'count';

        this.handleCollectionReset();
    }

    handleGroupChange(e) {
        console.debug('RoomOccupancy#handleGroupChange');
        this.isGrouped = e.currentTarget.checked ? 1 : 0;

        this.collection.fetch({
            data: {
                shop_id: this.shopId,
                year: this.year,
                month: this.month,
                is_grouped: this.isGrouped
            }
        });

        return this;
    }

    handleCollectionReset() {
        console.debug('RoomOccupancy#handleCollectionReset');

        // Get and sort collection data
        const collection = this.collection.toJSON();

        // Render chart
        this.renderChart(collection);

        const vacant = collection.reduce((previousValue, { vacant }) => {
            if (this.isCountMode) {
                return previousValue + vacant.count;
            } else {
                return Number((previousValue + vacant.area).toFixed(1));
            }
        }, 0);

        const occupied = collection.reduce((previousValue, { occupied }) => {
            if (this.isCountMode) {
                return previousValue + occupied.count;
            } else {
                return Number((previousValue + occupied.area).toFixed(1));
            }
        }, 0);

        const unavailable = collection.reduce((previousValue, { unavailable }) => {
            if (this.isCountMode) {
                return previousValue + unavailable.count;
            } else {
                return Number((previousValue + unavailable.area).toFixed(1));
            }
        }, 0);

        const total = vacant + occupied + unavailable;

        this.el.querySelector('#reportList').innerHTML = tableTemplate({
            isCountMode: this.isCountMode,
            isGrouped: this.isGrouped,
            roomOccupancyList: collection,
            total: {
                vacant,
                vacantPercentage: vacant / total * 100,
                unavailable,
                unavailablePercentage: unavailable / total * 100,
                occupied,
                occupiedPercentage: occupied / total * 100,
                total
            }
        });

        return this;
    }

    renderChart(collection) {
        console.debug('RoomOccupancy#renderChart');

        // Setup colors
        const colors = config.brandColors().hsv(2, 'short');
        const pink = Object.create(colors[0]);
        const purple = Object.create(colors[1]);
        const gray = Object.create(tinycolor('gray'));

        // Setup chart data
        const chartData = {
            labels: collection.map(roomOccupancy => this.isGrouped ? roomOccupancy.type : roomOccupancy.size),
            datasets: [
                // Occupied
                {
                    label: 'Occupied',
                    data: collection.map(({ occupied }) => this.isCountMode ? occupied.count : occupied.area),
                    borderColor: purple.toRgbString(),
                    backgroundColor: purple.toRgbString(),
                    hoverBackgroundColor: purple.brighten().toRgbString(),
                },
                // Vacant
                {
                    label: 'Vacant',
                    data: collection.map(({ vacant }) => this.isCountMode ? vacant.count : vacant.area),
                    borderColor: pink.toRgbString(),
                    backgroundColor: pink.toRgbString(),
                    hoverBackgroundColor: pink.brighten().toRgbString(),
                },
                // Out of Service
                {
                    label: 'Out of Service',
                    data: collection.map(({ unavailable }) => this.isCountMode ? unavailable.count : unavailable.area),
                    borderColor: gray.toRgbString(),
                    backgroundColor: gray.toRgbString(),
                    hoverBackgroundColor: gray.brighten().toRgbString(),
                }
            ],
        };

        // If chart doesn't exists; create chart
        if (!this.chart) {
            const ctx = this.el.querySelector('canvas.chart');
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        position: 'bottom',
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [{
                            stacked: true,
                        }],
                    },
                },
            });
        }
        // Else update chart
        else {
            this.chart.data = chartData;
            this.chart.update();
        }

        return this;
    }
}
