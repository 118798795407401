var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"align-middle\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"room_no") : stack1), depth0))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"floorspace") : stack1), depth0))
    + "</td>\n<td class=\"align-middle\">\n    <div class=\"input-group\">\n        <div class=\"input-group-prepend\">\n            <span class=\"input-group-text\">¥</span>\n        </div>\n        <input name=\"base_rate\" type=\"text\" class=\"form-control text-right\" data-number required>\n    </div>\n</td>\n<td class=\"align-middle\">\n    <div class=\"input-group\">\n        <div class=\"input-group-prepend\">\n            <span class=\"input-group-text\">¥</span>\n        </div>\n        <input name=\"campaign_rate\" type=\"text\" class=\"form-control text-right\" data-number required>\n    </div>\n</td>\n<td class=\"align-middle\">\n    <div class=\"input-group\">\n        <input name=\"campaign_duration\" type=\"text\" class=\"form-control text-right\" data-number>\n        <div class=\"input-group-append\">\n            <span class=\"input-group-text\">月</span>\n        </div>\n    </div>\n</td>\n<td class=\"align-middle\">\n    <div class=\"input-group\">\n        <input name=\"campaign_eligible_min_months\" type=\"text\" class=\"form-control text-right\" data-number>\n        <div class=\"input-group-append\">\n            <span class=\"input-group-text\">月</span>\n        </div>\n    </div>\n</td>\n<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/room/status.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),{"name":"room/status","hash":{},"data":data,"loc":{"start":{"line":36,"column":25},"end":{"line":36,"column":54}}}))
    + "</td>\n<td class=\"align-middle text-wrap\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"notes") : stack1), depth0))
    + "</td>\n<td>\n    <button type=\"button\" class=\"btn custom-btn btn-primary\" data-action=\"save\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":80},"end":{"line":39,"column":100}}}))
    + "</button>\n    <button type=\"button\" class=\"btn custom-btn btn-secondary\" data-action=\"cancel\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":84},"end":{"line":40,"column":106}}}))
    + "</button>\n</td>";
},"useData":true});