const periods = [
    {
        startYear: 2019,
        names: [
            "令和", "れいわ", "Reiwa"
        ]
    },
    {
        startYear: 1989,
        names: [
            "平成", "へいせい", "Heisei"
        ]
    },
    {
        startYear: 1926,
        names: [
            "昭和", "しょうわ", "Showa"
        ]
    },
    {
        startYear: 1912,
        names: [
            "大正", "たいしょう", "Taisho"
        ]
    },
    {
        startYear: 1868,
        names: [
            "明治", "めいじ", "Meiji"
        ]
    }
];

export function getJidai(year) {
    for (const value of periods) {
        if (year >= value.startYear) {
            const nen = year - value.startYear + 1;
            return value.names[0] + ' ' + nen;
        }
    }
}