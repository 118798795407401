import { View } from 'backbone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import oauth2Client from '@/js/app/oauth2-client';
import template from '../../templates/owner-downloads/card-item-history-item.hbs';

dayjs.extend(utc);

export default class PmOwnerDownloadsCardItemHistoryItemView extends View {
    preinitialize(options) {
        this.tagName = 'tr';
        this.type = options.type;
        this.ownerModel = options.ownerModel;
        this.events = {
            'click [data-action="download"]': this.handleDownloadClick,
        };
    }

    render() {
        console.debug('PmOwnerDownloadsCardItemHistoryItem#render');

        this.el.innerHTML = template({
            model: this.model.toJSON()
        });

        return this;
    }

    handleDownloadClick() {
        console.debug('PmOwnerDownloadsCardItemHistoryItem#download');

        const file = this.model.toJSON();

        oauth2Client.download(
            this.model.url() + '/' + file.period + '/' + file.type + '/' + file.version,
            {
                method: 'GET'
            },
            {
                filename: `${this.ownerModel.get('name')}_${file.period}_${this.type.label}_v${dayjs.utc(file.version).local().format('YYYYMMDDHHmmss')}.${file.filename.split('.')[1]}`
            }
        );
    }
}
