import { View } from 'backbone';

import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import ShopUnpaidBillsCollection from '../../collections/unpaid-bills';
import tableTemplate from '../../templates/unpaid-bills/table.hbs';
import template from '../../templates/unpaid-bills/index.hbs';
import ReinvoiceModalView from './reinvoice-modal';

export default class PmUnpaidBillsView extends View {
    preinitialize() {
        this.className = 'container';
        this.collection = new ShopUnpaidBillsCollection();
        this.events = {
            'change [name="shop_id"]': this.handleShopIdChange,
            'click [data-action="reinvoice"]': this.handleReinvoiceClick,
        };
        this.subviews = {
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id"
            })
        };
    }

    initialize() {
        this.listenTo(this.collection, 'sync remove', this.renderList);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            this.el.querySelector('#reportList').innerHTML = tableTemplate({
                isLoading: true,
            });
        });


        // When collection error request; stop spinner
        this.listenTo(this.collection, 'error', function () {
            this.el.querySelector('#reportList').innerHTML = tableTemplate({
                isLoading: false,
                unpaidBills: []
            });
        });
    }

    render() {
        console.debug('PmUnpaidBills#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });

        return this;
    }

    renderList() {
        console.debug('PmUnpaidBills#renderList');

        this.el.querySelector('#reportList').innerHTML = tableTemplate({
            isLoading: false,
            unpaidBills: this.collection.toJSON()
        });

        // Transform unpaid bills table to DataTable
        this.$el.find('table').DataTable({
            searching: false,
            paging: false,
            info: false,
            order: [0, ['desc']],
        });
    }

    handleShopIdChange(e) {
        console.debug('PmUnpaidBills#handleShopIdChange');
        const value = e.currentTarget.value;

        if (value) {
            this.collection.shopId = value;
            this.collection.fetch({ reset: true });
        }

        return this;
    }

    handleReinvoiceClick(e) {
        console.debug('PmUnpaidBills#handleReinvoiceClick');

        // Get unpaid bill from collection
        const unpaidBill = this.collection.findWhere({
            contractId: Number(e.currentTarget.dataset.contract),
            billNo: e.currentTarget.dataset.invoice,
        });

        // Create and render modal
        const view = new ReinvoiceModalView({
            unpaidBill: unpaidBill.toJSON(),
        });
        view.render();

        // When reinvoice event triggered, remove unpaid bill from collection
        view.on('trt.reinvoice', () => {
            this.collection.remove(unpaidBill);
        });
    }
}
