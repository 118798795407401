import { Collection, history } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import { getJidai } from '@/js/libs/jidai';
import AddressView from '@/js/app/address/views/address';
import ErrorModalView from '@/js/app/error/views/modal';
import CardView from '@/js/app/generic/views/card';
import PaginationView from '@/js/app/generic/views/pagination';
import SpinnerView from '@/js/app/generic/views/spinner';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import yuyan from '@/js/app/yuyan';
import CustomerModel from '../models/customer';
import addCorporateTemplate from '../templates/edit-corporate.hbs';
import addPersonalTemplate from '../templates/edit-personal.hbs';
import listTemplate from '../templates/list.hbs';

export default class CustomerListAddView extends CardView {
    preinitialize() {
        // Initialize defaults
        this.title = yuyan.t('customer.list');
        this.events = {
            'click [data-action="add"]': this.renderAdd,
            'click [data-action="add"][data-type="personal"]': this.renderPersonalTemplate,
            'click [data-action="add"][data-type="corporate"]': this.renderCorporateTemplate,
            'click [data-action="cancel"]': this.renderList,
            'submit form#frmEditCustomer': this.saveAdd,
        };
        this.buttons = [
            {
                cntrData: 'personalBtnCntr',
                text: '<i class="fa fa-plus" aria-hidden="true"></i> ' + yuyan.t('customer.type.personal'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    },
                    {
                        label: 'type',
                        value: 'personal'
                    }
                ]
            },
            {
                cntrData: 'corporateBtnCntr',
                text: '<i class="fa fa-plus" aria-hidden="true"></i> ' + yuyan.t('customer.type.corporate'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    },
                    {
                        label: 'type',
                        value: 'corporate'
                    }
                ]
            },
            {
                cntrData: 'cancelBtnCntr',
                text: yuyan.t('generic.cancel'),
                className: 'btn-secondary',
                isHidden: true,
                dataset: [
                    {
                        label: 'action',
                        value: 'cancel'
                    }
                ]
            }
        ];
        this.customerModel = new CustomerModel;
        this.type = '';
    }

    initialize() {
        this.subviews = {
            pagination: new PaginationView({
                collection: this.collection
            }),

            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop-id"
            }),

            address: new AddressView({
                showBuilding: true
            }),

            deliveryAddress: new AddressView({
                postId: 'field-delivery-postcode',
                postName: 'delivery_postcode',
                prefId: 'field-delivery-prefecture',
                prefName: 'delivery_prefecture_id',
                cityId: 'field-delivery-city',
                cityName: 'delivery_city_id',
                addressId: 'field-delivery-address',
                addressName: 'delivery_address',
                buildingId: 'field-delivery-building',
                buildingName: 'delivery_building',
                showBuilding: true
            }),

            keyDeliveryAddress: new AddressView({
                postId: 'field-key-delivery-postcode',
                postName: 'key_delivery_postcode',
                prefId: 'field-key-delivery-prefecture',
                prefName: 'key_delivery_prefecture_id',
                cityId: 'field-key-delivery-city',
                cityName: 'key_delivery_city_id',
                addressId: 'field-key-delivery-address',
                addressName: 'key_delivery_address',
                buildingId: 'field-key-delivery-building',
                buildingName: 'key_delivery_building',
                showBuilding: true
            }),

            officeAddress: new AddressView({
                postId: 'field-office-postcode',
                postName: 'office_postcode',
                prefId: 'field-office-prefecture',
                prefName: 'office_prefecture_id',
                cityId: 'field-office-city',
                cityName: 'office_city_id',
                addressId: 'field-office-address',
                addressName: 'office_address',
                buildingId: 'field-office-building',
                buildingName: 'office_building',
                showBuilding: true
            }),
        };

        // When collection updates/resets; renderList
        this.listenTo(this.collection, 'update reset', this.renderList);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('CustomerListAdd#render');

        this.renderCard();

        return this;
    }

    renderList() {
        console.debug('CustomerListAdd#renderList');

        this.hideButtonContainer('cancelBtnCntr')
            .showButtonContainer('personalBtnCntr')
            .showButtonContainer('corporateBtnCntr')
            .renderListTemplate();

        return this;
    }

    renderListTemplate() {
        console.debug('CustomerListAdd#renderListTemplate');

        // Attach listTemplate to el with customers collection
        this.cardBody.innerHTML = listTemplate({
            customers: this.collection.toJSON(),
        });

        this.subviews.pagination.setElement(this.cardBody).render();

        return this;
    }

    renderAdd() {
        console.debug('CustomerListAdd#renderAdd');

        this.hideButtonContainer('personalBtnCntr')
            .hideButtonContainer('corporateBtnCntr')
            .showButtonContainer('cancelBtnCntr');

        return this;
    }

    renderPersonalTemplate() {
        console.debug('CustomerListAdd#renderPersonalTemplate');

        this.cardBody.innerHTML = addPersonalTemplate();
        this.type = 'personal';
        this.renderSubviews();

        return this;
    }

    renderCorporateTemplate() {
        console.debug('CustomerListAdd#renderCorporateTemplate');

        this.cardBody.innerHTML = addCorporateTemplate();
        this.type = 'corporate';
        this.renderSubviews();

        return this;
    }

    renderSubviews() {
        console.debug('CustomerListAdd#renderSubviews');

        this.loadYears();

        // Set el of address view as #divAddress then render
        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });

        this.subviews.address.setElement(this.el.querySelector('#divAddress')).render();
        this.subviews.deliveryAddress.setElement(this.el.querySelector('#divDeliveryAddress')).render();
        this.subviews.keyDeliveryAddress.setElement(this.el.querySelector('#divKeyDeliveryAddress')).render();
        this.subviews.officeAddress.setElement(this.el.querySelector('#divOfficeAddress')).render();

        return this;
    }

    loadYears() {
        console.debug('CustomerListAdd#loadYears');

        const year = (new Date).getFullYear();
        const options = document.createDocumentFragment();

        for (let i = 0; i < 120; i++) {
            const value = year - i;
            const o = document.createElement('option');
            o.value = value.toString();
            o.innerText = value + ' (' + getJidai(value) + ')';
            options.appendChild(o);
        }

        this.el.querySelector('#dobYY').appendChild(options);
    }

    saveAdd(e) {
        console.debug('CustomerListAdd#saveAdd');

        if (e instanceof Object) {
            e.preventDefault();
            e.stopPropagation();

            const dobYY = this.el.querySelector('#dobYY').value;
            const dobMM = this.el.querySelector('#dobMM').value;
            const dobDD = this.el.querySelector('#dobDD').value;

            if (this.el.querySelector('[name="dob"]') && dobYY && dobMM && dobDD) {
                this.el.querySelector('[name="dob"]').value = dobYY + '-' + dobMM + '-' + dobDD;
            }

            // Add .was-validated on form
            e.currentTarget.classList.add('was-validated');

            // If form not valid, return
            if (e.currentTarget.checkValidity() === false) {
                return;
            }

            // Initialize data as result of formToObject
            const data = formToObject(e.currentTarget);
            data.customer_type = this.type;

            // Trigger save of customerModel with data as attributes
            this.customerModel.save(data, {
                // On success: navigate
                success: this.navigate,
                // On error: errorOnSave
                error: this.errorOnSave
            });
        }
    }

    errorOnSave(_, response) {
        console.debug('CustomerListAdd#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    navigate(model) {
        console.debug('CustomerListAdd#navigate');

        // Navigate to customers/:id
        history.navigate('customers/' + model.id, { trigger: true });
    }
}
