import { queue } from 'async';
import { View } from 'backbone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import uploader from '@/js/app/file-uploader/uploader';
import oauth2Client from '@/js/app/oauth2-client';
import User from '@/js/app/user';
import template from '../templates/card-item.hbs';
import FileDownloadsCardItemHistoryView from './card-item-history';

dayjs.extend(utc);

export default class FileDownloadsCardItemView extends View {
    preinitialize(options) {
        this.className = 'col my-2';
        this.type = options.type;
        this.criteria = options.criteria;
        this.hasUpload = options.hasUpload !== false;
        this.events = {
            'click [data-action="upload"]': this.handleUploadClick,
            'change [name="file"]': this.handleFileChange,
            'click [data-action="download"]': this.handleDownloadClick,
            'click [data-action="history"]': this.handleHistoryClick,
        };
        this.isUploading = false;
    }

    initialize() {
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('FileDownloadsCardItem#render');

        const hasUpload = User.isAllowed('admin') && this.hasUpload;
        const hasDownload = this.collection.length > 0;

        // Attach template to el
        this.el.innerHTML = template({
            title: this.type.label,
            hasUpload,
            isUploading: this.isUploading,
            isLoading: this.isUploading,
            hasDownload,
            hasHistory: hasDownload,
            hasButton: hasUpload || hasDownload
        });

        return this;
    }

    handleUploadClick() {
        console.debug('FileDownloadsCardItem#handleUploadClick');

        this.$el.find('[name="file"]').trigger('click');
    }

    handleFileChange(e) {
        console.debug('FileDownloadsCardItem#handleFileChange');

        this.isUploading = true;
        this.render();

        const files = e.currentTarget.files;
        const name = e.currentTarget.name;
        const uploadStatusContainer = this.$el.find('[data-part="uploadStatus"]');

        // Create file upload queue with handler
        const q = queue((file, callback) => {
            uploader(name, file,
                {
                    method: 'PUT',
                    url: this.collection.url() + '/' + this.type.value,
                },
                {
                    uploadStatusContainer
                })
                .then((response) => {
                    this.isUploading = false;

                    // Add new file model to collection using response data
                    this.collection.add(response);
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (let i = 0; i < files.length; i++) {
            // Add file to queue
            q.push(files[i]);
        }

        // Clear file selector
        e.currentTarget.value = '';
    }

    handleDownloadClick() {
        console.debug('FileDownloadsCardItem#handleDownloadClick');

        const file = this.collection.at(-1).toJSON();

        oauth2Client.download(
            this.collection.url() + file.type + '/' + file.version,
            {
                method: 'GET'
            },
            {
                filename: `${this.type.label}_v${dayjs.utc(file.version).local().format('YYYYMMDDHHmmss')}.${file.filename.split('.')[1]}`
            }
        );
    }

    handleHistoryClick() {
        console.debug('FileDownloadsCardItem#handleHistoryClick');

        const historyView = new FileDownloadsCardItemHistoryView({
            type: this.type,
            collection: this.collection
        });

        historyView.render();
    }
}