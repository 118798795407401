import { View } from 'backbone';
import config from '@/js/app/config';
import ContractsCollection from '@/js/app/contract/collections/contracts';
import ListAddContractView from '@/js/app/contract/views/list-add';
import oauth2Client from '@/js/app/oauth2-client';
import RoomHistoryCollection from '@/js/app/room-history/collections/room-history';
import ListRoomHistoryView from '@/js/app/room-history/views/list';
import SecurityKeysCollection from '@/js/app/security-key/collections/security-keys';
import ListAddSecurityKeyView from '@/js/app/security-key/views/list-add';
import User from '@/js/app/user';
import template from '../templates/detail.hbs';
import RoomActionBarView from './action-bar';
import RoomReadUpdateView from './read-update';

export default class RoomDetailView extends View {
    preinitialize() {
        this.className = 'container';
    }

    initialize() {
        // Create subviews
        this.subviews = {
            actionBar: new RoomActionBarView({
                roomId: this.model.id
            }),

            roomCard: new RoomReadUpdateView({
                model: this.model
            }),

            listAddContractCard: new ListAddContractView({
                collection: new ContractsCollection,
                roomModel: this.model,
                columns: {
                    status: true
                }
            }),

            listRoomHistoryCard: new ListRoomHistoryView({
                collection: new RoomHistoryCollection
            }),
        };

        oauth2Client.fetch(`${config.api.url}contracts/fees`, {})
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.json();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((data) => {
                this.subviews.listAddContractCard.setFees(data);
            });

        // When model changes room_no; updateTitle
        this.listenTo(this.model, 'change:room_no', this.updateTitle);

        // When model changes shop_id; renderListAddSecurityKeyView
        this.listenTo(this.model, 'change:shop_id', this.renderListAddSecurityKeyView);

        // When model changes shop; updateShopName
        this.listenTo(this.model, 'change:shop', this.updateShopName);
    }

    render() {
        console.debug('RoomDetail#render')

        const isAdmin = User.isAllowed('consus:shops');

        // Attach template to el
        this.el.innerHTML = template({
            hasRoomHistory: isAdmin
        });

        // Set el of action bar view as #actionBar then render
        this.subviews.actionBar.setElement(this.el.querySelector('#actionBar')).render();

        // Set el of room detail view as #details then render
        this.subviews.roomCard.setElement(this.el.querySelector('#details')).render();

        // Set el of contract list view as #listAddContract then render
        this.subviews.listAddContractCard.setElement(this.el.querySelector('#listAddContract')).render();

        // Initialize data object with room_id
        const data = { 'room_id': this.model.get('id') };

        // Trigger fetch of contract collection with data as query
        this.subviews.listAddContractCard.collection.search({
            data: data,
            reset: true
        });

        if (isAdmin) {
            // Set el of room history list view as #listRoomHistory then render
            this.subviews.listRoomHistoryCard.setElement(this.el.querySelector('#listRoomHistory')).render();

            // Trigger fetch of room history collection with data as query
            this.subviews.listRoomHistoryCard.collection.fetch({
                data: data,
                reset: true
            });
        }

        return this;
    }

    updateTitle(model, value) {
        console.debug('RoomDetail#updateTitle');

        // Change text of #title based on value
        this.el.querySelector('#title').innerText = value;
    }

    updateShopName(model, value) {
        console.debug('RoomDetail#updateShopName');

        // Change text of #shop-name based on value
        this.el.querySelector('#shop-name').innerText = value.name;
    }

    renderListAddSecurityKeyView(model) {
        console.debug('RoomDetail#renderListAddSecurityKeyView');

        this.subviews.listSecurityKeyCard = new ListAddSecurityKeyView({
            collection: new SecurityKeysCollection,
            shopId: model.get('shop_id'),
            parentView: 'room',
            roomId: model.get('id')
        });

        // Set el of security key list view as #listAddSecurityKey then render
        this.subviews.listSecurityKeyCard.setElement(this.el.querySelector('#listAddSecurityKey')).render();

        // Trigger fetch of security key collection with data as query
        this.subviews.listSecurityKeyCard.collection.fetch({
            data: {
                room_id: model.get('id'),
                inclusions: ['room']
            },
            reset: true
        });
    }
}
