import { View } from 'backbone';
import SelectYearMonth from '@/js/app/generic/views/select-year-month';
import SpinnerView from '@/js/app/generic/views/spinner';
import CancelledRoomsCollection from './collection';
import template from './templates/index.hbs';
import tableTemplate from './templates/table.hbs';

export default class CancelledRoomsView extends View {
    preinitialize() {
        this.className = 'container';

        this.events = {
            'change [name="year"],[name="month"]': this.handleDateChange,
        };

        this.subviews = {
            selectYearMonth: new SelectYearMonth,
        };

        this.collection = new CancelledRoomsCollection();

        this.spinner = new SpinnerView();
    }

    initialize() {
        this.render();

        this.listenTo(this.collection, 'sync', this.handleCollectionSync);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            this.spinner.spin(this.el.querySelector('#reportList'));
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function () {
            this.spinner.stop();
        });

        this.fetchCollection();
    }

    render() {
        console.debug('CancelledRoomsView#render');

        this.el.innerHTML = template();

        // Attach select year/month subview to #dateCntr and render
        this.subviews.selectYearMonth.setElement(this.el.querySelector('#dateCntr')).render();

        this.renderTable();
    }

    renderTable() {
        console.debug('CancelledRoomsView#renderTable');

        this.el.querySelector('#reportList').innerHTML = tableTemplate({
            rooms: this.collection.toJSON(),
        });
    }

    handleCollectionSync() {
        console.debug('CancelledRoomsView#handleCollectionSync');

        this.renderTable();
    }

    handleDateChange() {
        console.debug('CancelledRoomsView#handleDateChange');

        this.fetchCollection();
    }

    fetchCollection() {
        console.debug('CancelledRoomsView#fetchCollection');

        const year = this.el.querySelector('[name="year"]').value;
        const month = this.el.querySelector('[name="month"]').value;

        this.collection.fetch({
            data: {
                end_month: `${year}-${month}`,
            }
        });
    }
}