import config from '@/js/app/config';
import CollectionWithPagination from '@/js/app/generic/collections/pagination';
import CustomerModel from '../models/customer';

export default class CustomersCollection extends CollectionWithPagination {
    preinitialize() {
        // Initialize defaults
        this.url = config.api.url + 'customers';

        this.model = CustomerModel;
    }
}
