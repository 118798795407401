import { Collection } from 'backbone';
import CardView from '@/js/app/generic/views/card';
import SpinnerView from '@/js/app/generic/views/spinner';
import yuyan from '@/js/app/yuyan';
import ReadEditSecurityKeyView from './read-edit';
import template from '../templates/list.hbs';

export default class SecurityKeyListView extends CardView {
    preinitialize() {
        this.title = yuyan.t('securityKey.list');
        this.events = {
            'click [data-action="add"]': this.handleAddClick,
            'change #field-status-filter': this.handleListFilter,
        };

        this.buttons = [
            {
                cntrData: 'addBtnCntr',
                text: '<i class="fa fa-plus" aria-hidden="true"></i> ' + yuyan.t('generic.add'),
                className: 'btn-dark',
                isHidden: false,
                dataset: [
                    {
                        label: 'action',
                        value: 'add'
                    }
                ]
            },
        ];
    }

    initialize(options) {
        // Initialize defaults

        this.shopId = options.shopId;
        this.parentView = options.parentView;
        this.roomId = options.roomId;

        this.statusToFilter = null;

        // When collection updates; render
        this.listenTo(this.collection, 'update reset', this.renderList);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('ShopListAdd#render');

        this.renderCard();

        return this;
    }

    renderList() {
        console.debug('SecurityKeyListAdd#renderList');

        this.cardBody.innerHTML = template();

        if (this.parentView === 'room') {
            this.el.querySelector('#thRoomNo').classList.add('d-none');
        }

        const trList = document.createDocumentFragment();
        this.collection.each((sk) => {
            trList.appendChild(new ReadEditSecurityKeyView({
                model: sk,
                parentView: this.parentView,
            }).render().el);
        });

        // Add rows to table body
        const el = this.el.querySelector('#securityKeysTableBody');
        while (el.firstChild) {
            el.removeChild(el.firstChild);
        }
        el.appendChild(trList);

        // Transform rooms table to DataTable
        this.$el.find('table').DataTable({
            searching: this.parentView === 'room' ? false : true,
        });

        if (this.statusToFilter) {
            this.el.querySelector('#field-status-filter').value = this.statusToFilter;
        }

        return this;
    }

    handleAddClick() {
        console.debug('SecurityKeyListAdd#handleAddClick');

        const addSecurityKeyView = new ReadEditSecurityKeyView({
            collection: this.collection,
            parentView: this.parentView,
            shopId: this.shopId,
            roomId: this.roomId
        });

        // Insert addSecurityKeyView view on the first row of #this.tableId
        this.el.querySelector('#securityKeysTableBody').insertBefore(
            addSecurityKeyView.renderEdit().el,
            this.el.querySelector('#securityKeysTableBody').firstChild
        );
    }

    handleListFilter(e) {
        console.debug('SecurityKeyListAdd#handleListFilter');

        const statusToFilter = e.target.options[e.target.selectedIndex].value;

        this.statusToFilter = statusToFilter;

        this.collection.fetch({
            data: {
                shop_id: this.shopId,
                status: statusToFilter
            }
        })
    }
}
