var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.titles",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":23}}}))
    + "</h1>\n<hr class=\"my-3\">\n\n<form id=\"frmSearch\" autocomplete=\"off\">\n    <div class=\"container mb-3\">\n        <div class=\"form-group row\">\n            <label for=\"field-name\" class=\"col-form-label col-sm-4 col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":86},"end":{"line":7,"column":103}}}))
    + "</label>\n            <div class=\"col-sm col-lg-6\">\n                <input id=\"field-name\" name=\"name\" type=\"text\" class=\"form-control\">\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-prefecture\" class=\"col-form-label col-sm-4 col-md-3 col-lg-4\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"address.prefecture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":100},"end":{"line":16,"column":126}}}))
    + "</label>\n                    <div class=\"position-relative col-sm col-md-3 col-lg-5\">\n                        <div id=\"divPrefecture\"></div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-lg\">\n                <div class=\"form-group row\">\n                    <label for=\"field-city\" class=\"col-form-label col-sm-4 col-md-3 col-lg-4\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"address.city",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":94},"end":{"line":25,"column":114}}}))
    + "</label>\n                    <div class=\"position-relative col-sm col-md-5 col-lg-7\">\n                        <div id=\"divCity\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row justify-content-end align-items-center\">\n            <div class=\"col-md-auto\">\n                <button type=\"submit\" class=\"btn btn-primary custom-btn\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":73},"end":{"line":35,"column":95}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>\n\n<div id=\"listAddShop\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});