import { View, Collection } from 'backbone';
import SpinnerView from '@/js/app/generic/views/spinner';
import OwnerView from './index';
import OwnersCollection from '../collections/owners';

export default class OwnerCntrView extends View {
    preinitialize() {
        this.collection = new OwnersCollection;
    }

    initialize(options) {
        // Create subviews
        this.subviews = {
            owners: new OwnerView({
                collection: this.collection,
                attributes: {
                    name: 'owner_id',
                    required: options.required,
                    'data-number': ''
                },
                firstOptionText: options.firstOptionText,
                hasAll: options.hasAll
            })
        };

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('OwnerCntr#render');

        // Attach owners view to el
        while (this.el.firstChild) {
            this.el.removeChild(this.el.firstChild);
        }
        this.el.appendChild(this.subviews.owners.el);

        return this;
    }

    setValue(value) {
        console.debug('OwnerCntr#setValue');

        this.subviews.owners.setValue(value);

        return this;
    }
}
