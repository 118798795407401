import { Collection, View } from 'backbone';
import Chart from 'chart';
import dayjs from 'dayjs';
import numeral from 'numeral';
import SpinnerView from '@/js/app/generic/views/spinner';
import OwnerCntrView from '@/js/app/owners/views/cntr';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import LeasingStatusCollection from '../leasing-status/collection';
import tableTemplate from './templates/table.hbs';
import template from './templates/index.hbs';

export default class LeasingHistoryView extends View {
    preinitialize() {
        this.className = 'container';
        this.collection = new LeasingStatusCollection();
        this.filteredCollection = new LeasingStatusCollection();
        this.events = {
            'change [name="owner_id"]': this.handleOwnerIdChange,
            'change [name="shop_id"]': this.handleShopIdChange,
            'change [name="category"]': this.handleCategoryChange,
            'change [name="range"]': this.handleRangeChange,
            'change [name="year"]': this.handleYearChange,
        };
        this.subviews = {
            ownercntr: new OwnerCntrView({
                hasAll: true
            }),
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id",
                hasAll: true
            })
        };
        this.filterOptions = {
            category: 'rent',
            range: 'all'
        };
    }

    initialize() {
        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });

        this.listenTo(this.collection, 'update reset', this.handleCollectionUpdate);
    }

    render() {
        console.debug('LeasingHistory#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.collection.fetch({
            data: {
                perspective: 'month'
            },
            reset: true
        });

        this.subviews.ownercntr.setElement(this.el.querySelector('#divOwners')).render();
        this.subviews.ownercntr.collection.fetch();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });

        this.loadYears();

        return this;
    }

    loadYears() {
        console.debug('LeasingHistory#loadYears');

        const options = document.createDocumentFragment();
        const today = new Date(Date.now());
        let year = today.getFullYear();

        for (year; year >= 2019; year--) {
            const value = year;
            const o = document.createElement('option');
            o.value = value;
            o.innerText = value;
            options.appendChild(o);
        }

        this.el.querySelector('[name="year"]').appendChild(options);
    }

    handleOwnerIdChange(e) {
        console.debug('LeasingHistory#handleOwnerIdChange');

        const value = e.currentTarget.value;

        const data = { perspective: 'month' };

        if (value === 'all') {
            this.subviews.shopcntr.collection.fetch();
        } else {
            data.owner_id = Number(value);
            this.subviews.shopcntr.collection.fetch({ data: { owner_id: value } });
        }

        this.collection.fetch({
            data,
            reset: true
        });

        return this;
    }

    handleShopIdChange(e) {
        console.debug('RentalDiscount#handleShopIdChange');

        const shopValue = e.currentTarget.value;
        const ownerValue = this.el.querySelector('#field-owner_id').value;
        const data = { perspective: 'month' };

        if (ownerValue !== 'all') {
            data.owner_id = Number(ownerValue);
        }

        if (shopValue !== 'all') {
            data.shop_id = Number(shopValue);
        }

        this.collection.fetch({
            data,
            reset: true
        });

        return this;
    }

    handleCategoryChange(e) {
        console.debug('LeasingHistory#handleCategoryChange');

        this.filterOptions.category = e.currentTarget.value;
        this.filter();
    }

    handleRangeChange(e) {
        console.debug('LeasingHistory#handleRangeChange');

        if (e.currentTarget.value === 'all') {
            this.el.querySelector('[name="year"]').disabled = true;
            this.filterOptions.range = 'all'
        } else if (e.currentTarget.value === 'pastYear') {
            this.el.querySelector('[name="year"]').disabled = true;
            this.filterOptions.range = 'pastYear'
        } else {
            this.el.querySelector('[name="year"]').disabled = false;
            this.filterOptions.range = Number(this.el.querySelector('[name="year"]').value);
        }

        this.filter();
    }

    handleYearChange(e) {
        console.debug('LeasingHistory#handleYearChange');

        this.filterOptions.range = Number(e.currentTarget.value);
        this.filter();
    }

    handleCollectionUpdate() {
        console.debug('LeasingHistory#handleCollectionUpdate');

        this.filter(true);
    }

    filter(isReset) {
        console.debug('LeasingHistory#filter');

        let fromDate, toDate;
        if (this.filterOptions.range === 'all') {
            fromDate = dayjs('2018-12-01');
            toDate = dayjs();
        } else if (this.filterOptions.range === 'pastYear') {
            toDate = dayjs();
            fromDate = toDate.subtract(11, 'month').startOf('month');
        } else {
            fromDate = dayjs(`${this.filterOptions.range}-01-01`);
            toDate = dayjs(`${this.filterOptions.range}-12-01`);
        }

        this.filteredCollection.set(this.collection.filterHistory({
            fromYear: fromDate.year(),
            fromMonth: fromDate.month() + 1,
            toYear: toDate.year(),
            toMonth: toDate.month() + 1
        }), {
            reset: true
        });

        // Get grouped collection list
        const groupedCollectionList = this.filteredCollection.toJSON().reduce((previousList, data) => {
            // Find grouped collection
            const groupedCollection = previousList.find(gc => gc.label === data.label);

            // Compose model data
            const model = { ...data };
            delete model.label;

            if (groupedCollection) {
                groupedCollection.models.push(model);
            } else {
                previousList.push({
                    label: data.label,
                    models: [model]
                });
            }

            return previousList;
        }, []);

        // Determine labels
        const labels = [];
        let loopDate = fromDate.clone();
        while (loopDate.isSame(toDate, 'month') || loopDate.isBefore(toDate, 'month')) {
            labels.push(loopDate.clone());

            loopDate = loopDate.add(1, 'month');
        }

        if (isReset) {
            this.datasetHiddenList = groupedCollectionList.map(() => false);
            this.datasetHiddenList.unshift(false);
        }

        // Render chart
        this.renderChart(groupedCollectionList, labels);

        // Render table
        this.el.querySelector('#reportList').innerHTML = tableTemplate({
            years: labels.reduce((yearList, label) => {
                const year = yearList.find(year => year.value === label.year());
                if (year) {
                    year.count += 1;
                } else {
                    yearList.push({
                        value: label.year(),
                        count: 1
                    });
                }

                return yearList;
            }, []),
            months: labels.map(label => label.format('MMM')),
            shopNames: groupedCollectionList.map(groupedCollection => groupedCollection.label),
            rowData: groupedCollectionList.map(groupedCollection => {
                let row = [];
                if (this.filterOptions.category === 'rent') {
                    row = groupedCollection.models.map(model => numeral(model.rent).format('0'));
                } else if (this.filterOptions.category === 'roomOccupancy') {
                    row = groupedCollection.models.map(model => numeral(model.room_occupancy).format('0.0'));
                } else if (this.filterOptions.category === 'rentPercentage') {
                    row = groupedCollection.models.map(model => numeral(model.rent_occupancy).format('0.0'));
                } else if (this.filterOptions.category === 'areaPercentage') {
                    row = groupedCollection.models.map(model => numeral(model.area_occupancy).format('0.0'));
                } else if (this.filterOptions.category === 'rentPerArea') {
                    row = groupedCollection.models.map(model => numeral(model.rent_per_area).format('0'));
                }

                const firstModel = groupedCollection.models[0];
                const firstModelPeriod = `${firstModel.year}-${firstModel.month.toString().padStart(2, '0')}-01`;
                const fromLabel = labels[0];
                if (fromLabel.isBefore(firstModelPeriod, 'month')) {
                    // Add null at the start of the array
                    row = Array(dayjs(firstModelPeriod).diff(fromLabel, 'month')).fill(null).concat(row);
                }

                const lastModel = groupedCollection.models[groupedCollection.models.length - 1];
                const lastModelPeriod = `${lastModel.year}-${lastModel.month.toString().padStart(2, '0')}-01`;
                const toLabel = labels[labels.length - 1];
                if (toLabel.isAfter(lastModelPeriod, 'month')) {
                    // Add null at the end of the array
                    row = row.concat(Array(toLabel.diff(lastModelPeriod, 'month')).fill(null));
                }

                return row;
            }),
        });
    }

    renderChart(groupedCollectionList, labels) {
        console.debug('LeasingHistory#renderChart');

        const colorLength = groupedCollectionList.length + 1;

        // Setup colors
        const colors = tinygradient(['red', 'green', 'blue'].slice(0, colorLength)).hsv(colorLength, 'short').map(color => Object.create(color));

        // Setup chart data
        const chartData = {
            labels: labels.map(label => label.format('YYYY/MM')),
            datasets: []
        };

        // If category is in the array add target dataset
        if (['roomOccupancy', 'rentPercentage', 'areaPercentage'].includes(this.filterOptions.category)) {
            // Target 90%
            chartData.datasets.unshift({
                label: 'Target',
                data: Array(labels.length).fill(90),
                fill: false,
                lineTension: 0,
                borderColor: colors[0].toRgbString(),
                backgroundColor: colors[0].toRgbString(),
                hidden: this.datasetHiddenList[0]
            });
        }

        // Shop data
        chartData.datasets.push(...groupedCollectionList.map((groupedCollection, index) => {
            const dataset = {
                label: groupedCollection.label,
                data: [],
                fill: false,
                lineTension: 0,
                borderColor: colors[index + 1].toRgbString(),
                backgroundColor: colors[index + 1].toRgbString(),
                hidden: this.datasetHiddenList[index + 1]
            };

            if (this.filterOptions.category === 'rent') {
                dataset.data = groupedCollection.models.map(model => numeral(model.rent).format('0'));
            } else if (this.filterOptions.category === 'roomOccupancy') {
                dataset.data = groupedCollection.models.map(model => numeral(model.room_occupancy).format('0.0'));
            } else if (this.filterOptions.category === 'rentPercentage') {
                dataset.data = groupedCollection.models.map(model => numeral(model.rent_occupancy).format('0.0'));
            } else if (this.filterOptions.category === 'areaPercentage') {
                dataset.data = groupedCollection.models.map(model => numeral(model.area_occupancy).format('0.0'));
            } else if (this.filterOptions.category === 'rentPerArea') {
                dataset.data = groupedCollection.models.map(model => numeral(model.rent_per_area).format('0'));
            }

            const firstModel = groupedCollection.models[0];
            const firstModelPeriod = `${firstModel.year}-${firstModel.month.toString().padStart(2, '0')}-01`;
            const fromLabel = labels[0];
            if (fromLabel.isBefore(firstModelPeriod, 'month')) {
                dataset.data = Array(dayjs(firstModelPeriod).diff(fromLabel, 'month')).fill(null).concat(dataset.data);
            }

            return dataset;
        }));

        // If chart doesn't exists; create chart
        if (!this.chart) {
            const ctx = this.el.querySelector('canvas.chart');
            this.chart = new Chart(ctx, {
                type: 'line',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        position: 'bottom',
                        onClick: this.handleLegendClick.bind(this)
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }]
                    }
                },
            });
        }
        // Else update chart
        else {
            this.chart.data = chartData;
            this.chart.update();
        }
    }

    handleLegendClick(e, legendItem) {
        console.debug('LeasingHistory#handleLegendClick');

        const index = legendItem.datasetIndex;
        const meta = this.chart.getDatasetMeta(index);

        // See controller.isDatasetVisible comment
        if (meta.hidden === null) {
            meta.hidden = !this.chart.data.datasets[index].hidden;
            this.datasetHiddenList[index] = !this.datasetHiddenList[index];
        } else {
            meta.hidden = null;
            this.datasetHiddenList[index] = false;
        }

        // We hid a dataset ... rerender the chart
        this.chart.update();
    }
}