var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"table-responsive\">\n        <table class=\"table table-sm table-hover text-nowrap mb-0\">\n            <thead>\n                <tr class=\"thead-light\">\n                    <th>Contract No.</th>\n                    <th>Period_Act</th>\n                    <th>Period_Acc</th>\n                    <th>Category</th>\n                    <th>Item</th>\n                    <th>Billing Method</th>\n                    <th>Income</th>\n                    <th>C-tax %</th>\n                    <th>Note</th>\n                </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"cashflows") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":40,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td class=\"align-middle text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"contract_id") : depth0), depth0))
    + "</td>\n                    <td class=\"align-middle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"transaction_date") : depth0), depth0))
    + "</td>\n                    <td class=\"align-middle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"period_sales") : depth0), depth0))
    + "</td>\n                    <td class=\"align-middle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"category") : depth0), depth0))
    + "</td>\n                    <td class=\"align-middle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"item") : depth0), depth0))
    + "</td>\n                    <td class=\"align-middle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"billing_method") : depth0), depth0))
    + "</td>\n                    <td class=\"align-middle text-right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"income") : depth0), depth0))
    + "</td>\n                    <td class=\"align-middle text-right\">"
    + alias2(__default(require("../../../../handlebars-helpers/numeral.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tax_rate") : depth0),"0%",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":37,"column":56},"end":{"line":37,"column":86}}}))
    + "</td>\n                    <td class=\"align-middle\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"note") : depth0), depth0))
    + "</td>\n                </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert alert-danger\" role=\"alert\">\n    <h2 class=\"alert-heading\">\n        <i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i>\n        "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n    </h2>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cashflows") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":44,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});