import { Model } from 'backbone';
import config from '@/js/app/config';

export default class ContractDocumentModel extends Model {
    preinitialize() {
        this.idAttribute = 'version';
    }

    initialize(_, options) {
        this.contractId = options.contractId || options.collection.contractId || null;
        this.document = options.document || options.collection.document || null;
    }

    urlRoot() {
        return config.api.url + 'contracts/' + this.contractId + '/documents/' + this.document;
    }
}
