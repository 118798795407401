import { View } from 'backbone';
import template from '../templates/index.hbs';
import FileDownloadsCardView from './card';
import FilesCollection from '../collections/files';

export default class FileDownloadsIndexView extends View {
    preinitialize() {
        this.className = 'container';
        this.collection = new FilesCollection();
        this.subviews = {
            card: new FileDownloadsCardView({
                collection: this.collection
            })
        };
    }

    initialize() {
        this.listenTo(this.subviews.card, 'request', function () {
            this.el.querySelector('#spinner').classList.remove('d-none');
        });

        this.listenTo(this.subviews.card, 'sync', function () {
            this.el.querySelector('#spinner').classList.add('d-none');
        });

        this.collection.fetch();
    }

    render() {
        console.debug('FileDownloads#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.subviews.card.setElement(this.el.querySelector('#downloadCard')).render();

        return this;
    }
}
