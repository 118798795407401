import { View } from 'backbone';
import CustomersCollection from '@/js/app/customer/collections/customers';
import ShopsCollection from '@/js/app/shop/collections/shops';
import template from '../templates/customer-map.hbs';
import google from 'google';

export default class CustomerMapView extends View {
    preinitialize() {
        this.shopId = null;
        this.isCustomerDataReady = false;
        this.isShopDataReady = false;
        this.shopCollection = new ShopsCollection();
        this.customerCollection = new CustomersCollection();

        // Create objects for use in Google Maps
        this.latlngBounds = new google.maps.LatLngBounds();
        this.heatMap = new google.maps.visualization.HeatmapLayer({
            maxIntensity: 2,
        });
        this.shopMarkers = [];
        this.visibleMarkersCount = 0;
    }

    initialize() {
        this.listenTo(this.shopCollection, 'reset', this.handleShopCollectionReset);
        this.listenTo(this.customerCollection, 'reset', this.handleCustomerCollectionReset);
    }

    render() {
        console.debug('CustomerMap#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.shopCollection.fetch({ reset: true });

        this.customerCollection.fetch({
            reset: true,
            data: {
                fields: 'shop_id,latitude,longitude'
            }
        });

        this.initializeMap();

        return this;
    }

    initializeMap() {
        console.debug('CustomerMap#initializeMap');

        // Initialize map canvas
        this.mapCanvas = new google.maps.Map(this.el.querySelector('#customerMap'), {
            mapId: 'CUSTOMER_MAP',
            center: new google.maps.LatLng(35.704502, 139.6168695),
            zoom: 15,
            minZoom: 5,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: window.innerWidth >= 768 ? true : false,
            scaleControl: true,
            scrollwheel: false,
            panControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            mapTypeControl: false,
            clickableLabels: false,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style'],
            },
        });

        this.mapCanvas.mapTypes.set('map_style', new google.maps.StyledMapType(
            [
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }]
                }
            ], { name: 'Styled Map' }
        ));

        this.mapCanvas.setMapTypeId('map_style');

        this.heatMap.setMap(this.mapCanvas);

        this.recenterMap();
    }

    recenterMap() {
        // Fit map to bounds
        if (!this.latlngBounds.isEmpty()) {
            this.mapCanvas.fitBounds(this.latlngBounds, 0);

            if (this.visibleMarkersCount === 1) {
                this.mapCanvas.setZoom(15);
            }
        }
    }

    handleShopCollectionReset() {
        console.debug('CustomerMap#handleShopCollectionReset');

        const shopList = this.shopCollection.toJSON();

        shopList.forEach(shop => {
            if (shop.latitude && shop.longitude) {
                const trtLogo = document.createElement('img');
                trtLogo.src = '/img/logo_trt_square_2022.png';
                trtLogo.width = 25;
                trtLogo.height = 25;

                const shopMarker = new google.maps.marker.AdvancedMarkerElement({
                    map: this.mapCanvas,
                    draggable: false,
                    position: new google.maps.LatLng(shop.latitude, shop.longitude),
                    title: shop.name,
                    content: trtLogo,
                });

                // Extend marker bounds to include current marker
                this.latlngBounds.extend(shopMarker.position);

                this.shopMarkers.push({ id: shop.id, marker: shopMarker });
                this.visibleMarkersCount++;
            }
        });

        this.isShopDataReady = true;
        this.recenterMap();
    }

    handleCustomerCollectionReset() {
        console.debug('CustomerMap#handleCustomerCollectionReset');

        this.isCustomerDataReady = true;
        this.renderMap();
    }

    filter(shopId, ownerId) {
        console.debug('CustomerMap#filter');

        if (ownerId === 'all') {
            this.ownerId = null;
        } else {
            this.ownerId = ownerId;
        }

        if (this.isShopDataReady) {
            this.renderMarkers();
        }

        if (shopId === 'all') {
            this.shopId = null;
        } else {
            this.shopId = shopId;
        }

        if (this.isCustomerDataReady) {
            this.renderMap();
        }

        return this;
    }

    renderMarkers() {
        console.debug('CustomerMap#renderMarkers');

        const shopList = this.shopCollection.toJSON();

        // Create objects for use in Google Maps
        this.latlngBounds = new google.maps.LatLngBounds();
        this.visibleMarkersCount = 0

        shopList.forEach(shop => {
            const shopMarker = this.shopMarkers.find(sm => sm.id === shop.id);

            if (shopMarker) {
                if (this.ownerId === null || shop.owner_id === this.ownerId) {
                    shopMarker.marker.setMap(this.mapCanvas);

                    // Extend marker bounds to include current marker
                    this.latlngBounds.extend(shopMarker.marker.position);

                    this.visibleMarkersCount++;
                } else {
                    shopMarker.marker.setMap(null);
                }
            }
        });
        this.recenterMap();
    }


    renderMap() {
        console.debug('CustomerMap#renderMap');

        const heatmapData = [];

        this.customerCollection.toJSON().forEach(customer => {
            // If shopId exists, filter out customers without the same shop id
            if (this.shopId) {
                if (Array.isArray(this.shopId)) {
                    if (!this.shopId.includes(customer.shop_id)) {
                        return;
                    }
                } else if (customer.shop_id !== this.shopId) {
                    return
                }
            }

            if (customer.latitude && customer.longitude) {
                const customerPosition = new google.maps.LatLng(customer.latitude, customer.longitude);

                heatmapData.push(customerPosition);
            }
        });

        this.heatMap.setData(heatmapData);

        this.recenterMap();
    }
}