import { Collection } from 'backbone';
import ModalView from '@/js/app/generic/views/modal';
import SpinnerView from '@/js/app/generic/views/spinner';
import SecurityKeyLogsCollection from '../collections/security-key-logs';
import template from '../templates/modal.hbs';

export default class SecurityKeyLogModalView extends ModalView {
    preinitialize() {
        this.collection = new SecurityKeyLogsCollection();
        this.preinitializeModal();
    }

    initialize(options) {
        this.keyNumber = options.keyNumber;

        this.listenTo(this.collection, 'reset', this.loadCollection);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el.querySelector('.modal-body'));
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('SecurityKeyLogModal#render');

        this.renderModal({ centered: true }).modalContent.innerHTML = template({
            keyNumber: this.keyNumber,
            logs: []
        });

        return this;
    }

    loadCollection(collection) {
        console.debug('SecurityKeyLogModal#loadCollection');

        this.modalContent.innerHTML = template({
            keyNumber: this.keyNumber,
            logs: collection.toJSON()
        });
    }
}
