import { View } from 'backbone';
import Chart from 'chart';
import TenantReportModel from '../model';
import OwnerCntrView from '@/js/app/owners/views/cntr';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import template from '../templates/index.hbs';
import SpinnerView from '@/js/app/generic/views/spinner';
import config from '@/js/app/config';
import yuyan from '@/js/app/yuyan';
import CustomerMapView from './customer-map';

export default class TenantReportView extends View {
    preinitialize() {
        this.className = 'container';
        this.model = new TenantReportModel();
        this.events = {
            'change [name="owner_id"]': this.handleOwnerIdChange,
            'change [name="shop_id"]': this.handleShopIdChange,
        };
        this.subviews = {
            ownercntr: new OwnerCntrView({
                hasAll: true
            }),
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop-id",
                hasAll: true
            }),
            customerMap: new CustomerMapView()
        };
    }

    initialize() {
        this.listenTo(this.model, 'sync', this.handleModelReset);

        const s = new SpinnerView();
        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            s.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            s.stop();
        });
    }

    render() {
        console.debug('TenantReport#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.subviews.ownercntr.setElement(this.el.querySelector('#divOwners')).render();
        this.subviews.ownercntr.collection.fetch();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });
        this.subviews.shopcntr.setValue('all');

        this.subviews.customerMap.setElement(this.el.querySelector('#map')).render();

        return this;
    }

    handleOwnerIdChange(e) {
        console.debug('TenantReport#handleOwnerIdChange');

        const value = e.currentTarget.value;

        if (value === 'all') {
            this.subviews.shopcntr.collection.fetch();
        } else {
            this.subviews.shopcntr.collection.fetch({ data: { owner_id: value } });
        }

        return this;
    }

    handleShopIdChange(e) {
        console.debug('TenantReport#handleShopIdChange');

        const shopValue = e.currentTarget.value;
        const ownerValue = this.el.querySelector('#field-owner_id').value;
        const data = {};
        let shopId = 'all', ownerId = 'all';

        if (ownerValue !== 'all') {
            data.owner_id = Number(ownerValue);
            ownerId = Number(ownerValue);
            shopId = this.subviews.shopcntr.collection.toJSON().map(s => s.id);
        }

        if (shopValue !== 'all') {
            data.shop_id = Number(shopValue);
            shopId = Number(shopValue);
        }

        this.model.fetch({
            data
        });

        this.subviews.customerMap.filter(shopId, ownerId);

        return this;
    }

    handleModelReset() {
        console.debug('TenantReport#handleModelReset');

        const modelData = this.model.toJSON();

        this.renderTypeChart(modelData.typeSet);
        this.renderAgeChart(modelData.ageSet);
        this.renderGenderChart(modelData.genderSet);
        this.renderPaymentMethod(modelData.paymentMethodSet);
        this.renderContractDuration(modelData.contractDurationSet);
    }

    renderTypeChart(typeSet) {
        console.debug('TenantReport#renderTypeChart');

        // Setup chart data
        const chartData = {
            labels: typeSet.map(typeElement => yuyan.t(`customer.type.${typeElement.type}`)),
            datasets: [{
                data: typeSet.map(typeElement => typeElement.count),
                borderColor: config.chartColors().map(color => color.toRgbString()),
                backgroundColor: config.chartColors().map(color => color.toRgbString()),
                hoverBackgroundColor: config.chartColors().map(color => color.brighten().toRgbString())
            }]
        };

        // If type chart doesn't exists; create chart
        if (!this.typeChart) {
            const ctx = this.el.querySelector('canvas#typeChart');

            this.typeChart = new Chart(ctx, {
                type: 'pie',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        position: 'right',
                        labels: {
                            generateLabels: generateLegendLabels
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: generateTooltipLabels
                        }
                    }
                }
            });
        }
        // Else update chart
        else {
            this.typeChart.data = chartData;
            this.typeChart.update();
        }
    }

    renderAgeChart(ageSet) {
        console.debug('TenantReport#renderAgeChart');

        // Setup chart data
        const chartData = {
            labels: ageSet.map(ageElement => yuyan.t(`reports.age.${ageElement.bracket}`)),
            datasets: [{
                data: ageSet.map(ageElement => ageElement.count),
                borderColor: config.chartColors().map(color => color.toRgbString()),
                backgroundColor: config.chartColors().map(color => color.toRgbString()),
                hoverBackgroundColor: config.chartColors().map(color => color.brighten().toRgbString())
            }]
        };

        // If age chart doesn't exists; create chart
        if (!this.ageChart) {
            const ctx = this.el.querySelector('canvas#ageChart');

            this.ageChart = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: generateTooltipLabels
                        }
                    }
                }
            });
        }
        // Else update chart
        else {
            this.ageChart.data = chartData;
            this.ageChart.update();
        }
    }

    renderGenderChart(genderSet) {
        console.debug('TenantReport#renderGenderChart');

        // Setup chart data
        const chartData = {
            labels: genderSet.map(genderElement => {
                if (genderElement.gender === 'M') {
                    return yuyan.t('customer.male');
                } else if (genderElement.gender === 'F') {
                    return yuyan.t('customer.female');
                }
            }),
            datasets: [{
                data: genderSet.map(genderElement => genderElement.count),
                borderColor: config.chartColors().map(color => color.toRgbString()),
                backgroundColor: config.chartColors().map(color => color.toRgbString()),
                hoverBackgroundColor: config.chartColors().map(color => color.brighten().toRgbString())
            }]
        };

        // If gender chart doesn't exists; create chart
        if (!this.genderChart) {
            const ctx = this.el.querySelector('canvas#genderChart');

            this.genderChart = new Chart(ctx, {
                type: 'pie',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        position: 'right',
                        labels: {
                            generateLabels: generateLegendLabels
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: generateTooltipLabels
                        }
                    }
                }
            });
        }
        // Else update chart
        else {
            this.genderChart.data = chartData;
            this.genderChart.update();
        }
    }

    renderPaymentMethod(paymentMethodSet) {
        console.debug('TenantReport#renderPaymentMethod');

        // Setup chart data
        const chartData = {
            labels: paymentMethodSet.map(paymentMethodElement => yuyan.t(`contract.payment_method.${paymentMethodElement.paymentMethod}`)),
            datasets: [{
                data: paymentMethodSet.map(paymentMethodElement => paymentMethodElement.count),
                borderColor: config.chartColors().map(color => color.toRgbString()),
                backgroundColor: config.chartColors().map(color => color.toRgbString()),
                hoverBackgroundColor: config.chartColors().map(color => color.brighten().toRgbString())
            }]
        };

        // If payment method chart doesn't exists; create chart
        if (!this.paymentMethodChart) {
            const ctx = this.el.querySelector('canvas#paymentMethodChart');

            this.paymentMethodChart = new Chart(ctx, {
                type: 'pie',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        position: 'right',
                        labels: {
                            generateLabels: generateLegendLabels
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: generateTooltipLabels
                        }
                    }
                }
            });
        }
        // Else update chart
        else {
            this.paymentMethodChart.data = chartData;
            this.paymentMethodChart.update();
        }
    }

    renderContractDuration(contractDurationSet) {
        console.debug('TenantReport#renderContractDuration');

        // Setup chart data
        const chartData = {
            labels: contractDurationSet.map(contractDurationElement => yuyan.t(`reports.duration.${contractDurationElement.duration}`)),
            datasets: [{
                data: contractDurationSet.map(contractDurationElement => contractDurationElement.count),
                borderColor: config.chartColors().map(color => color.toRgbString()),
                backgroundColor: config.chartColors().map(color => color.toRgbString()),
                hoverBackgroundColor: config.chartColors().map(color => color.brighten().toRgbString())
            }]
        };

        // If contract duration chart doesn't exists; create chart
        if (!this.contractDurationChart) {
            const ctx = this.el.querySelector('canvas#contractDurationChart');

            this.contractDurationChart = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: generateTooltipLabels
                        }
                    }
                }
            });
        }
        // Else update chart
        else {
            this.contractDurationChart.data = chartData;
            this.contractDurationChart.update();
        }
    }
}

function generateLegendLabels(chart) {
    const data = chart.data;

    if (data.labels.length && data.datasets.length) {
        return data.labels.map((label, i) => {
            const meta = chart.getDatasetMeta(0);
            const ds = data.datasets[0];
            const arc = meta.data[i];
            const style = meta.controller.getStyle(i);
            const dataArr = chart.config.data.datasets[arc._datasetIndex].data;
            const value = dataArr[arc._index];
            let sum = 0;

            dataArr.forEach(data => {
                sum += data;
            });

            const percentage = (value * 100 / sum).toFixed(1) + '%';

            return {
                text: `${label}: ${value} (${percentage})`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                hidden: isNaN(ds.data[i]) || arc.hidden,

                // Extra data used for toggling the correct item
                index: i
            };
        });
    }

    return [];
}

function generateTooltipLabels(tooltipItem, data) {
    let label = data.labels[tooltipItem.index] || '';
    const dataArr = data.datasets[tooltipItem.datasetIndex].data;
    const value = dataArr[tooltipItem.index];
    let sum = 0;

    dataArr.forEach(data => {
        sum += data;
    });

    const percentage = (value * 100 / sum).toFixed(1) + '%';

    return `${label}: ${value} (${percentage})`;
}
