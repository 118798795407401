var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\"#shops/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"productStock") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"productStock") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <dt class=\"col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":60}}}))
    + "</dt>\n    <dd class=\"col-md-3 col-lg-4\">\n        <a href=\"#product-types/"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"productStock") : depth0)) != null ? lookupProperty(stack1,"productType") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n            "
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"productStock") : depth0)) != null ? lookupProperty(stack1,"productType") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n        </a>\n    </dd>\n\n    <dt class=\"col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":51}}}))
    + "</dt>\n    <dd class=\"col-md-3 col-lg-4\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"productStock") : depth0)) != null ? lookupProperty(stack1,"shop") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "    </dd>\n</div>\n\n<div class=\"row\">\n    <dt class=\"col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":60}}}))
    + "</dt>\n    <dd class=\"col-md-3 col-lg-4\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"productStock") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</dd>\n\n    <dt class=\"col-md-3 col-lg-2\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":34},"end":{"line":21,"column":67}}}))
    + "</dt>\n    <dd class=\"col-md-3 col-lg-4\">"
    + alias2(__default(require("../../../handlebars-helpers/productStockStatus.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"productStock") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),{"name":"productStockStatus","hash":{},"data":data,"loc":{"start":{"line":22,"column":34},"end":{"line":22,"column":78}}}))
    + "</dd>\n</div>";
},"useData":true});