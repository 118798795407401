import { View, Collection } from 'backbone';
import SpinnerView from '@/js/app/generic/views/spinner';
import template from '../templates/pm-fee.hbs'

export default class PmFeeView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);

        const s = new SpinnerView();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                s.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                s.stop();
            }
        });
    }

    render() {
        console.debug('PmFee#render');

        // Attach template to el with pmFee collection
        this.el.innerHTML = template({
            pmFee: this.collection.toJSON(),
        });

        // Transform contracts table to DataTable
        this.$el.find('table').DataTable({
            searching: false,
            paging: false,
            info: false,
            order: [1, 'desc'],
        });

        return this;
    }
}
