import { View, history } from 'backbone';
import oAuth2Client from '@/js/app/oauth2-client';
import User from '@/js/app/user';
import template from '../templates/access_denied.hbs';

export default class AccessDeniedView extends View {
    preinitialize() {
        this.events = {
            'click #redirect': this.redirect,
        };
    }

    render() {
        console.debug('AccessDenied#render');

        this.el.innerHTML = template();

        return this;
    }

    redirect() {
        console.debug('AccessDenied#redirect');

        // Clear user data
        User.clearInfo();

        // Clear access token
        oAuth2Client.clearToken();

        // Navigate to "login"
        history.navigate('login', { trigger: true });
    }
}
