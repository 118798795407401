var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"bg-light py-5 px-3\" style=\"height: 100vh;\">\n    <div class=\"container text-center\">\n        <h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.access_denied",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":48}}}))
    + "</h1>\n        <p>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.access_sub",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":44}}}))
    + "</p>\n        <button id=\"redirect\" type=\"button\" class=\"btn btn-primary\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.access_button",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":104}}}))
    + "</button>\n    </div>\n</div>";
},"useData":true});