var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div id=\"alertServiceWorker\" class=\"alert alert-primary d-flex justify-content-between\">\n  <p class=\"align-self-center mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"update.available",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":60}}}))
    + "</p>\n  <button type=\"button\" class=\"btn btn-primary\" data-action=\"update\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"update.action",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":69},"end":{"line":3,"column":90}}}))
    + "</button>\n</div>";
},"useData":true});