import { View } from 'backbone';
import ProductStocksCollection from '@/js/app/product-stocks/collections/product-stocks';
import ProductStockModel from '@/js/app/product-stocks/models/product-stock';
import ProductStockCntrView from '@/js/app/product-stocks/views/cntr';
import ProductTypesCollection from '@/js/app/product-types/collections/product-types';
import ProductTypeCntrView from '@/js/app/product-types/views/cntr';
import template from '../templates/product.hbs';
import ProductTypeModel from '@/js/app/product-types/models/product-type';

export default class ProductView extends View {
    preinitialize() {
        // Initialize defaults
        this.events = {
            'change #field-product-type-edit': this.productTypeChange,
            'change #field-product-stock-edit': this.productStockChange,
        };

        this.subviews = {
            productTypeCntr: new ProductTypeCntrView({
                collection: new ProductTypesCollection,
                field_id: "field-product-type-edit"
            }),

            productStockCntr: new ProductStockCntrView({
                collection: new ProductStocksCollection,
                field_id: "field-product-stock-edit"
            }),
        };

        this.shopId = null;
        this.typeModel = new ProductTypeModel;
        this.stockModel = new ProductStockModel;
    }

    render() {
        console.debug('Product#render');

        this.el.innerHTML = template();

        this.subviews.productTypeCntr.setElement(this.el.querySelector('#divProductType')).render();
        this.subviews.productTypeCntr.collection.fetch();

        this.subviews.productStockCntr.setElement(this.el.querySelector('#divProductStock')).render();

        this.$el.find('#field-product-type-edit').select2({
            theme: 'bootstrap4',
        });
        this.$el.find('#field-product-stock-edit').select2({
            theme: 'bootstrap4',
        });

        return this;
    }

    productTypeChange(e) {
        console.debug('Product#shopChange');

        if (e instanceof Object) {
            e.preventDefault();

            // Set id of typeModel then trigger fetch
            this.typeModel.set('id', e.target.value).fetch();

            // Initiate fetch with form data
            this.subviews.productStockCntr.collection.fetch({
                data: {
                    product_type_id: e.target.value,
                    shop_id: this.shopId
                },
                reset: true
            });
        }
    }

    productStockChange(e) {
        console.debug('Product#roomChange');

        if (e instanceof Object) {
            e.preventDefault();

            // Set id of stockModel then trigger fetch
            this.stockModel.set('id', e.target.value).fetch();
        }
    }

    setValue(typeId, stockId) {
        this.subviews.productTypeCntr.setValue(typeId);
        this.subviews.productStockCntr.setValue(stockId);
    }

    setShopId(value) {
        this.shopId = value;

        return this;
    }
}
