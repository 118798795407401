import { Collection } from 'backbone';
import config from '@/js/app/config';

export default class ShopUnpaidBillsCollection extends Collection {
    initialize(models, options = {}) {
        this.shopId = options.shopId;
    }

    url() {
        return config.api.url + 'shops/' + this.shopId + '/unpaid-bills';
    }
}
