var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"alert alert-success alert-dismissible\" role=\"alert\">\n    <strong>チケットは作成されました。</strong>\n    <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert alert-danger alert-dismissible\" role=\"alert\">\n    <strong>以下の顧客番号は見つかりませんでした:</strong>\n    <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\n        <span aria-hidden=\"true\">&times;</span>\n    </button>\n    <br>\n    "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"invalidCustomerList") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":17,"column":54}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(depth0, depth0))
    + "<br>";
},"6":function(container,depth0,helpers,partials,data) {
    return "        <label class=\"btn btn-secondary\">\n            <input type=\"radio\" name=\"options\" value=\"private\"> 社内メモ\n        </label>\n        <label class=\"btn btn-secondary active\">\n            <input type=\"radio\" name=\"options\" value=\"public\" checked> パブリック\n        </label>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <label class=\"btn btn-secondary active\">\n            <input type=\"radio\" name=\"options\" value=\"private\" checked> 社内メモ\n        </label>\n        <label class=\"btn btn-secondary\">\n            <input type=\"radio\" name=\"options\" value=\"public\"> パブリック\n        </label>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "form-public";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"customerList") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":45},"end":{"line":44,"column":92}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "&#10;";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ticketSubject") : depth0), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    return "selected";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ticketMessage") : depth0), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    return "d-none";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRedirect") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invalidCustomerList") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":19,"column":7}}})) != null ? stack1 : "")
    + "\n<div id=\"ticketType\" class=\"btn-group btn-group-toggle mb-4\" data-toggle=\"buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPublicTicket") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n<form id=\"frmEdit\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPublicTicket") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":26},"end":{"line":39,"column":66}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"d-flex\">\n        <div class=\"form-group w-50 mr-3\">\n            <label for=\"customers\"><strong>顧客番号</strong></label>\n            <textarea class=\"form-control\" id=\"customers\" rows=\"14\"\n                required>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customerList") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":25},"end":{"line":44,"column":99}}})) != null ? stack1 : "")
    + "</textarea>\n        </div>\n\n        <div class=\"form-group w-50 ml-3\">\n            <div class=\"mb-4\">\n                <label for=\"subject\"><strong>件名</strong></label>\n                <input name=\"subject\" type=\"text\" id=\"subject\" class=\"form-control\"\n                    value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketSubject") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":27},"end":{"line":51,"column":72}}})) != null ? stack1 : "")
    + "\" required>\n            </div>\n\n            <div class=\"mb-4\">\n                <label for=\"status\"><strong>ステータス</strong></label>\n                <select name=\"status\" type=\"text\" id=\"status\" class=\"form-control\" required>\n                    <option value=\"open\" "
    + ((stack1 = __default(require("../../../../handlebars-helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketStatus") : depth0),"===","open",{"name":"ifCond","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":41},"end":{"line":58,"column":98}}})) != null ? stack1 : "")
    + ">オープン</option>\n                    <option value=\"pending\" "
    + ((stack1 = __default(require("../../../../handlebars-helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketStatus") : depth0),"===","pending",{"name":"ifCond","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":44},"end":{"line":59,"column":104}}})) != null ? stack1 : "")
    + ">保留中</option>\n                    <option value=\"hold\" "
    + ((stack1 = __default(require("../../../../handlebars-helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketStatus") : depth0),"===","hold",{"name":"ifCond","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":41},"end":{"line":60,"column":98}}})) != null ? stack1 : "")
    + ">待機中</option>\n                    <option value=\"solved\" "
    + ((stack1 = __default(require("../../../../handlebars-helpers/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketStatus") : depth0),"===","solved",{"name":"ifCond","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":43},"end":{"line":61,"column":102}}})) != null ? stack1 : "")
    + ">解決済み</option>\n                </select>\n            </div>\n\n            <div class=\"mb-3\">\n                <label for=\"message\"><strong>メッセージ</strong></label>\n                <textarea class=\"form-control\" id=\"message\" rows=\"6\"\n                    required>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketMessage") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":29},"end":{"line":69,"column":74}}})) != null ? stack1 : "")
    + "</textarea>\n            </div>\n\n            <div id=\"publicWarning\" class=\"alert alert-warning mb-0 "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPublicTicket") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":68},"end":{"line":72,"column":111}}})) != null ? stack1 : "")
    + "\" role=\"alert\">\n                このチケットは公開され、選択されたユーザーに送信されます。\n            </div>\n        </div>\n    </div>\n\n\n    <div class=\"w-100 d-flex justify-content-end\">\n        <button class=\"btn btn-primary\">プレビュー</button>\n    </div>\n</form>";
},"useData":true});