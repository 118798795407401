var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"row\">\n    <div class=\"col-lg\">\n        <div class=\"form-group row\">\n            <label for=\"field-product_type_id\" class=\"col-form-label col-4 text-right\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productType.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":40}}}))
    + "\n            </label>\n            <div class=\"position-relative col\">\n                <div id=\"divProductType\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-lg\">\n        <div class=\"form-group row\">\n            <label for=\"field-product_stock_id\" class=\"col-form-label col-4 text-right\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productStock.label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":42}}}))
    + "\n            </label>\n            <div class=\"position-relative col\">\n                <div id=\"divProductStock\"></div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});