import { Events, View } from 'backbone';
import dayjs from 'dayjs';
import SelectYearMonth from '@/js/app/generic/views/select-year-month';
import ShopsCollection from '@/js/app/shop/collections/shops';
import ShopCntrView from '@/js/app/shop/views/cntr';
import template from '../../templates/shop-downloads/index.hbs';
import PmShopDownloadsCardView from './card';

class Criteria {
    constructor() {
        this.period = dayjs().subtract(1, 'month').format('YYYYMM');
        this.shopId = null;
    }
}
_.extend(Criteria.prototype, Events, {
    setPeriod(value, options = {}) {
        this.period = value;

        if (!options.silent) {
            this.trigger('change:period', value);
            this.trigger('change', { period: this.period });
        }
    },
    setShopId(value, options = {}) {
        this.shopId = value;

        if (!options.silent) {
            this.trigger('change:shopId', value);
            this.trigger('change', { shopId: this.shopId });
        }
    }
});

export default class PmShopDownloadsIndexView extends View {
    preinitialize() {
        this.className = 'container';
        this.criteria = new Criteria();
        this.events = {
            'change [name="shop_id"]': this.handleShopIdChange,
            'change [name="year"], [name="month"]': this.handleDateChange,
        };
        this.subviews = {
            shopcntr: new ShopCntrView({
                collection: new ShopsCollection,
                field_id: "field-shop_id"
            }),
            selectYearMonth: new SelectYearMonth({
                minYear: 2021,
                includeNextYear: true,
                defaultPreviousMonth: true,
            }),
            card: new PmShopDownloadsCardView({
                criteria: this.criteria
            })
        };
    }

    initialize() {
        this.listenTo(this.subviews.card, 'request', function () {
            this.el.querySelector('#spinner').classList.remove('d-none');
        });

        this.listenTo(this.subviews.card, 'sync', function () {
            this.el.querySelector('#spinner').classList.add('d-none');
        });
    }

    render() {
        console.debug('PmShopDownloads#render');

        // Attach template to el
        this.el.innerHTML = template();

        this.subviews.shopcntr.setElement(this.el.querySelector('#divShop')).render();
        this.subviews.shopcntr.collection.fetch();
        this.$el.find('#field-shop-id').select2({
            theme: 'bootstrap4',
        });

        // Attach select year/month subview to #dateCntr and render
        this.subviews.selectYearMonth.setElement(this.el.querySelector('#dateCntr')).render();

        this.subviews.card.setElement(this.el.querySelector('#downloadCard'));

        return this;
    }

    handleShopIdChange(e) {
        console.debug('PmShopDownloads#handleShopIdChange');

        const shopValue = e.currentTarget.value;

        if (shopValue) {
            this.criteria.setShopId(Number(shopValue));
        } else {
            this.criteria.setShopId(null);
        }

        return this;
    }

    handleDateChange() {
        console.debug('PmShopDownloads#handleDateChange');

        this.criteria.setPeriod(
            this.el.querySelector('[name="year"]').value +
            this.el.querySelector('[name="month"]').value
        );
    }
}
