import { View } from 'backbone';
import ChangelogCollection from '@/js/app/changelog/collections/changelog';
import ChangelogModalView from '@/js/app/changelog/views/modal';
import yuyan from '@/js/app/yuyan';
import template from '../templates/action-bar.hbs';

export default class ProductContractActionBarView extends View {
    preinitialize(options) {
        // Initialize defaults
        this.events = {
            'click [data-modal="changelog"]': this.handleChangelogClick,
        };

        this.productContractId = options.productContractId;
    }

    render() {
        console.debug('ProductContractActionBar#render');

        // Attach template to el
        this.el.innerHTML = template();

        return this;
    }

    handleChangelogClick() {
        console.debug('ProductContractActionBar#handleChangelogClick');

        const view = new ChangelogModalView({
            title: `${yuyan.t('productContract.title')} ID: ${this.productContractId} Changelog`,
            collection: new ChangelogCollection([], {
                resource: 'product-contracts',
                resourceId: this.productContractId
            })
        });

        view.render();
    }
}
