var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"customerList") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":20,"column":25}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <th scope=\"row\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "</th>\n                    <td>"
    + alias2(__default(require("../../../../handlebars-helpers/customer/name.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"customer/name","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":48}}}))
    + "</td>\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"shop_name") : depth0), depth0))
    + "</td>\n                </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"invalidCustomerList") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":29,"column":25}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                <tr class=\"table-danger\">\n                    <th scope=\"row\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n                    <td>顧客が見つかりません</td>\n                    <td></td>\n                </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <p class=\"text-danger\">いくつかの顧客番号は見つかりませんでした。一旦戻って確認してください。</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "-public";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ticketSubject") : depth0), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../../../handlebars-helpers/zendeskTicketStatus.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ticketStatus") : depth0),{"name":"zendeskTicketStatus","hash":{},"data":data,"loc":{"start":{"line":50,"column":36},"end":{"line":50,"column":72}}}));
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ticketMessage") : depth0), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    return "        <div>\n            <div class=\"alert alert-warning\" role=\"alert\">このチケットは公開され、選択されたユーザーに送信されます。</div>\n        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex\">\n    <div class=\"w-50 mr-3\">\n        <p><strong>顧客一覧</strong></p>\n        <table class=\"table\">\n            <thead class=\"thead-dark\">\n                <tr>\n                    <th scope=\"col\">ID</th>\n                    <th scope=\"col\">名前</th>\n                    <th scope=\"col\">店舗</th>\n                </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"customerList") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invalidCustomerList") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invalidCustomerList") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"w-50 ml-3\">\n        <div class=\"mb-3\">\n            <p><strong>件名</strong></p>\n            <p class=\"border"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPublicTicket") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":28},"end":{"line":42,"column":64}}})) != null ? stack1 : "")
    + " p-3\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketSubject") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":61}}})) != null ? stack1 : "")
    + "\n            </p>\n        </div>\n\n        <div class=\"mb-3\">\n            <p><strong>ステータス</strong></p>\n            <p class=\"border"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPublicTicket") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":28},"end":{"line":49,"column":64}}})) != null ? stack1 : "")
    + " p-3\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketStatus") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":50,"column":79}}})) != null ? stack1 : "")
    + "\n            </p>\n        </div>\n\n        <div>\n            <p><strong>メッセージ</strong></p>\n            <p class=\"border"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPublicTicket") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":28},"end":{"line":56,"column":64}}})) != null ? stack1 : "")
    + " p-3\" style=\"white-space: pre-wrap;\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ticketMessage") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":101},"end":{"line":56,"column":146}}})) != null ? stack1 : "")
    + "</p>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPublicTicket") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"w-100 d-flex justify-content-end\">\n            <button id=\"btnBack\" class=\"btn btn-secondary mr-3\">戻る</button>\n            <button id=\"btnSubmit\" class=\"btn btn-primary\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invalidCustomerList") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":59},"end":{"line":67,"column":101}}})) != null ? stack1 : "")
    + ">\n                送信\n                <span class=\"spinner-border spinner-border-sm d-none\" role=\"status\" aria-hidden=\"true\"></span>\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true});