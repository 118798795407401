import { View } from 'backbone';
import numeral from 'numeral';
import ErrorModalView from '@/js/app/error/views/modal';
import readTemplate from '../templates/read-row.hbs';
import editTemplate from '../templates/edit-row.hbs';

export default class RoomRowView extends View {
    preinitialize() {
        this.tagName = 'tr';
        this.events = {
            'click [data-action="edit"]': this.handleEditClick,
            'click [data-action="save"]': this.handleSaveClick,
            'click [data-action="cancel"]': this.handleCancelClick,
        };
    }

    initialize() {
        _.bindAll(this, 'errorOnSave');

        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('RoomRow#render');

        this.el.innerHTML = readTemplate({
            room: this.model.toJSON()
        });

        return this;
    }

    renderEdit() {
        console.debug('RoomRow#renderEdit');

        this.el.innerHTML = editTemplate({
            room: this.model.toJSON()
        });

        this.el.querySelector('[name="base_rate"]').value = this.model.get('base_rate');
        this.el.querySelector('[name="campaign_rate"]').value = this.model.get('campaign_rate');
        this.el.querySelector('[name="campaign_duration"]').value = this.model.get('campaign_duration');
        this.el.querySelector('[name="campaign_eligible_min_months"]').value = this.model.get('campaign_eligible_min_months');

        return this;
    }

    handleEditClick() {
        console.debug('RoomRow#handleEditClick');

        this.renderEdit();
    }

    handleSaveClick($e) {
        console.debug('RoomRow#handleSaveClick');

        if ($e instanceof Object) {
            $e.preventDefault();

            const fieldList = [
                this.el.querySelector('[name="base_rate"]'),
                this.el.querySelector('[name="campaign_rate"]'),
                this.el.querySelector('[name="campaign_duration"]'),
                this.el.querySelector('[name="campaign_eligible_min_months"]')
            ];

            const data = {};
            let hasError = false;
            for (const field of fieldList) {
                field.classList.remove('is-invalid');

                if ((field.required && String(field.value).trim() === '') || isNaN(Number(field.value))) {
                    hasError = true;
                    field.classList.add('is-invalid');
                } else {
                    data[field.name] = numeral(field.value).value();
                }
            }

            if (hasError) {
                return;
            }

            const changes = this.model.changedAttributes(data);
            if (changes !== false) {
                this.model.save(changes, {
                    patch: true,
                    wait: true,
                    error: this.errorOnSave
                });
            } else {
                this.model.trigger('change', this.model);
            }
        }
    }

    handleCancelClick() {
        console.debug('RoomRow#handleCancelClick');

        this.render();
    }

    errorOnSave(model, response) {
        console.debug('RoomRow#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }
}
