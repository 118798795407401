var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"row no-gutters justify-content-center align-items-center mb-2\">\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"applied\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":91}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"approved_bank\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.approved_bank.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":54},"end":{"line":12,"column":97}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"keys_sent\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.keys_sent.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":54},"end":{"line":20,"column":93}}}))
    + "</span>\n        </div>\n    </div>\n    <div class=\"col\">\n        <hr>\n    </div>\n    <div class=\"col-auto align-self-stretch status-outline\" data-progress=\"active\">\n        <div class=\"d-flex h-100\">\n            <span class=\"status-label vertical-label\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.status.active.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":54},"end":{"line":28,"column":90}}}))
    + "</span>\n        </div>\n    </div>\n</div>";
},"useData":true});