var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"align-middle\">\n    <input type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isSelected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":59}}})) != null ? stack1 : "")
    + ">\n</td>\n<td class=\"align-middle\">\n    <input name=\"contract_id\" type=\"text\" class=\"form-control text-right\" data-number>\n</td>\n<td class=\"align-middle\">\n    <input name=\"transaction_date\" type=\"date\" class=\"form-control\">\n</td>\n<td class=\"align-middle\">\n    <input name=\"period_sales\" type=\"date\" class=\"form-control\">\n</td>\n<td class=\"align-middle\">\n    <input name=\"category\" type=\"text\" class=\"form-control\" style=\"min-width: 180px;\">\n</td>\n<td class=\"align-middle\">\n    <input name=\"vendor\" type=\"text\" class=\"form-control\" style=\"min-width: 180px;\">\n</td>\n<td class=\"align-middle\">\n    <input name=\"item\" type=\"text\" class=\"form-control\" style=\"min-width: 100px;\">\n</td>\n<td class=\"align-middle\">\n    <input name=\"billing_method\" type=\"text\" class=\"form-control\">\n</td>\n<td class=\"align-middle\">\n    <input name=\"income\" type=\"text\" class=\"form-control text-right\" style=\"min-width: 80px;\" data-number>\n</td>\n<td class=\"align-middle\">\n    <input name=\"expense\" type=\"text\" class=\"form-control text-right\" style=\"min-width: 80px;\" data-number>\n</td>\n<td class=\"align-middle\">\n    <input name=\"tax_rate\" type=\"text\" class=\"form-control text-right\" data-number>\n</td>\n<td class=\"align-middle\">\n    <input name=\"note\" type=\"text\" class=\"form-control\" style=\"width: 200px;\">\n</td>\n<td data-slot=\"buttons\"></td>";
},"useData":true});