import Yuyan from '@/js/libs/yuyan';
import Polyglot from 'polyglot';

// Initialize and export Yuyan
export default new Yuyan({
    supportedLanguages: {
        'en': 'English',
        'ja': '日本語',
    },
    defaultLanguage: 'en',
    polyglot: new Polyglot(),
});