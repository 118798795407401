var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"d-flex justify-content-between\">\n    <h1 class=\"mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":21},"end":{"line":2,"column":43}}}))
    + ": <span id=\"title\"></span></h1>\n\n    <div class=\"my-auto d-none\" data-part=\"migratedBadge\">\n        <span class=\"badge badge-primary\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.is_migrated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":70}}}))
    + "</span>\n    </div>\n</div>\n\n<hr class=\"my-3\">\n\n<div id=\"actionBar\" class=\"position-relative mb-3\"></div>\n\n<div id=\"details\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"listAddProduct\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"bills\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"listDocument\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"listAddFile\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"customerDetails\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>\n\n<div id=\"listAddCustomerFile\" class=\"position-relative mb-3\" style=\"min-height:100px;\"></div>";
},"useData":true});