var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"btn custom-btn btn-danger\" data-action=\"unassign\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"generic.unassign",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":83},"end":{"line":11,"column":107}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"align-middle\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"securityKey") : depth0)) != null ? lookupProperty(stack1,"number") : stack1), depth0))
    + "</td>\n<td id=\"tdRoomPicker\" class=\"align-middle\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"securityKey") : depth0)) != null ? lookupProperty(stack1,"room") : stack1)) != null ? lookupProperty(stack1,"room_no") : stack1), depth0))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(__default(require("../../../handlebars-helpers/securityKeyStatus.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"securityKey") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),{"name":"securityKeyStatus","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":67}}}))
    + "</td>\n<td class=\"align-middle\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"securityKey") : depth0)) != null ? lookupProperty(stack1,"memo") : stack1), depth0))
    + "</td>\n<td>\n    <button type=\"button\" class=\"btn custom-btn btn-dark\" data-action=\"showLog\">\n        <i class=\"fa fa-history\" aria-hidden=\"true\"></i>\n    </button>\n    <button type=\"button\" class=\"btn custom-btn btn-dark\" data-action=\"edit\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"generic.edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":77},"end":{"line":9,"column":97}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasUnassign") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "</td>";
},"useData":true});