import numeral from '../numeral';

export default function leasingSpeedTotal(rows) {
    const total = {
        rooms: 0,
        rooms_occupied: 0,
        room_occupancy: 0,
        room_speed: 0,
        room_occupancy_speed: 0,
        initial_leased_rooms: 0,
    };

    for (let row of rows) {
        total.rooms += row.rooms;
        total.rooms_occupied += row.rooms_occupied;
        total.initial_leased_rooms += row.shop.initial_leased_rooms;
        total.room_speed += row.room_speed;
    }

    total.room_occupancy = numeral((total.rooms_occupied / total.rooms * 100), '0.0');
    total.room_occupancy_speed = numeral((total.room_speed / total.rooms) * 100, '0.0');
    total.room_speed = numeral(total.room_speed, '0.0');

    return '<td class="text-right">' + total.rooms + '</td>' +
        '<td class="text-right">' + total.initial_leased_rooms + '</td>' +
        '<td class="text-right">' + total.rooms_occupied + '</td>' +
        '<td class="text-right">' + total.room_occupancy + '%</td>' +
        '<td class="text-right">' + total.room_speed + '</td>' +
        '<td class="text-right">' + total.room_occupancy_speed + '%</td>';
}
