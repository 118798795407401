import { View } from 'backbone';
import ErrorModalView from '@/js/app/error/views/modal';
import RoomsCollection from '@/js/app/room/collections/rooms';
import RoomCntrView from '@/js/app/room/views/cntr';
import SecurityKeyLogModalView from '@/js/app/security-key-log/views/modal';
import yuyan from '@/js/app/yuyan';
import SecurityKeyModel from '../models/security-key';
import SecurityKeyNumberPicker from './number-picker';
import SecurityKeyUnassignModalView from './unassign-modal';
import readTemplate from '../templates/read.hbs';
import editTemplate from '../templates/edit.hbs';

export default class SecurityKeyReadEditView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click [data-action="showLog"]': this.handleShowLogClick,
            'click [data-action="edit"]': this.handleEditClick,
            'click [data-action="unassign"]': this.handleUnassignClick,
            'click [data-action="save"]': this.handleSaveClick,
            'click [data-action="cancel"]': this.handleCancelClick,
        };
    }

    initialize(options) {
        this.shopId = options.shopId;
        this.parentView = options.parentView;
        this.roomId = options.roomId;

        this.subviews = {
            roomcntr: new RoomCntrView({
                collection: new RoomsCollection,
                field_id: "field-room-edit"
            }),

            numberPicker: new SecurityKeyNumberPicker({
                shopId: this.shopId
            })
        };

        this.numberPickerModel = this.subviews.numberPicker.model;

        _.bindAll(this, 'errorOnSave');

        if (!this.model) {
            this.model = new SecurityKeyModel;
        }

        // When model changes; render
        this.listenTo(this.model, 'change', this.render);

        this.listenTo(this.numberPickerModel, 'change', this.changeKey);
    }

    render() {
        console.debug('SecurityKeyReadEdit#render');

        let hasRoom = this.model && this.model.get('room') ? true : false;

        this.el.innerHTML = readTemplate({
            securityKey: this.model.toJSON(),
            hasUnassign: hasRoom && this.parentView === 'room'
        });

        if (this.parentView === 'room') {
            this.el.querySelector('#tdRoomPicker').classList.add('d-none');
        }

        return this;
    }

    renderEdit() {
        console.debug('SecurityKeyReadEdit#renderEdit')

        this.el.innerHTML = editTemplate();

        if (this.parentView === 'shop') {
            this.subviews.roomcntr.setElement(this.el.querySelector('#tdRoomPicker')).render();
            this.$el.find('#field-room-edit').select2({
                theme: 'bootstrap4',
            });

            if (this.model.isNew()) {
                this.subviews.roomcntr.collection.fetch({
                    data: {
                        shop_id: this.shopId
                    },
                });
            } else {
                this.el.querySelector('[name="status"]').value = this.model.get('status');
                this.el.querySelector('[name="number"]').value = this.model.get('number');
                this.el.querySelector('[name="memo"]').value = this.model.get('memo');
                this.subviews.roomcntr.collection.fetch({
                    data: {
                        shop_id: this.model.get('shop_id')
                    },
                });
                this.subviews.roomcntr.setValue(this.model.get('room_id'));
            }
        } else if (this.parentView === 'room') {
            this.el.querySelector('#tdRoomPicker').classList.add('d-none');

            if (this.model.isNew()) {
                this.subviews.numberPicker.setElement(this.el.querySelector('#tdNumber')).render();
            } else {
                this.el.querySelector('[name="status"]').value = this.model.get('status');
                this.el.querySelector('[name="number"]').value = this.model.get('number');
                this.el.querySelector('[name="memo"]').value = this.model.get('memo');
                this.el.querySelector('[name="number"]').readOnly = true;
            }
        }

        return this;
    }

    changeKey(model, options) {
        console.debug('SecurityKeyReadEdit#changeKey');

        if (options.setModel) {
            this.model.set(model.attributes);
            this.collection.add(this.model, { silent: true });

            return;
        } else {
            this.el.querySelector('[name="status"]').value = model.get('status');
            this.el.querySelector('[name="memo"]').value = model.get('memo');
        }

        const roomId = model.get('room_id');
        if (roomId) {
            this.el.querySelector('[data-action="save"]').disabled = true;

            if (roomId === this.roomId) {
                this.el.querySelector('#errorText').innerText = yuyan.t('securityKey.error.already_assigned_here');
            } else {
                this.el.querySelector('#errorText').innerText = yuyan.t('securityKey.error.already_assigned');
            }

            this.el.querySelector('#errorText').classList.remove('d-none');
        } else if (model.get('status') === 'lost') {
            this.el.querySelector('[data-action="save"]').disabled = true;
            this.el.querySelector('#errorText').innerText = yuyan.t('securityKey.error.lost');
            this.el.querySelector('#errorText').classList.remove('d-none');
        } else {
            this.el.querySelector('[data-action="save"]').disabled = false;
            this.el.querySelector('#errorText').innerText = '';
            this.el.querySelector('#errorText').classList.add('d-none');
        }
    }

    handleEditClick() {
        console.debug('SecurityKeyReadEdit#handleEditClick');

        this.el.scrollIntoView(true);

        this.renderEdit();
    }

    handleUnassignClick() {
        console.debug('SecurityKeyReadEdit#handleUnassignClick');

        const unassignModal = new SecurityKeyUnassignModalView({
            model: this.model,
            readEditView: this.parentView === 'room' ? this : undefined
        });

        unassignModal.render();
    }

    handleSaveClick($e) {
        console.debug('SecurityKeyReadEdit#handleSaveClick');

        const data = {};
        if ($e instanceof Object) {
            $e.preventDefault();

            if (this.parentView === 'shop') {
                data.number = this.el.querySelector('[name="number"]').value;
                data.status = this.el.querySelector('[name="status"]').value;
                data.memo = this.el.querySelector('[name="memo"]').value;
                const roomId = this.el.querySelector('[name="room_id"').value;
                if (roomId) {
                    data.room_id = roomId;
                } else {
                    data.room_id = null;
                }

                if (this.model.isNew()) {
                    data.shop_id = this.shopId;
                    this.collection.create(data, {
                        wait: true,
                        error: this.errorOnSave
                    });
                } else {
                    const changes = this.model.changedAttributes(data);
                    if (changes !== false) {
                        this.model.save(changes, {
                            patch: true,
                            wait: true,
                            error: this.errorOnSave
                        });
                    } else {
                        this.model.trigger('change', this.model);
                    }
                }
            } else if (this.parentView === 'room') {
                data.status = this.el.querySelector('[name="status"]').value;
                data.memo = this.el.querySelector('[name="memo"]').value;

                if (this.model.isNew()) {
                    data.room_id = this.roomId;
                    const changes = this.numberPickerModel.changedAttributes(data);
                    if (changes !== false) {
                        this.numberPickerModel.save(changes, {
                            patch: true,
                            wait: true,
                            setModel: true,
                            error: this.errorOnSave
                        });
                    }
                } else {
                    const changes = this.model.changedAttributes(data);
                    if (changes !== false) {
                        this.model.save(changes, {
                            patch: true,
                            wait: true,
                            error: this.errorOnSave
                        });
                    } else {
                        this.model.trigger('change', this.model);
                    }
                }
            }
        }
    }

    errorOnSave(model, response) {
        console.debug('SecurityKeyReadEdit#errorOnSave');

        // If response status is 400 or above
        if (response.status >= 400) {
            const errorModalView = new ErrorModalView({
                error: response.responseJSON
            });

            errorModalView.render();
        }
    }

    handleCancelClick() {
        console.debug('SecurityKeyReadEdit#handleCancelClick');
        if (this.model.isNew()) {
            this.remove();
        } else {
            this.render();
        }
    }

    handleShowLogClick() {
        console.debug('SecurityKeyReadEdit#handleShowLogClick');

        const logView = new SecurityKeyLogModalView({
            keyNumber: this.model.get('number')
        });

        logView.render().collection.fetch({
            data: {
                security_key_id: this.model.get('id')
            },
            reset: true
        });
    }
}
