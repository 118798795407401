var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form id=\"frmEditProductContract\" autocomplete=\"off\" novalidate>\n    <div class=\"row\">\n        <div class=\"col-6 offset-6\">\n            <div id=\"divStatus\" class=\"form-group row\">\n                <label for=\"field-status\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":56}}}))
    + "\n                </label>\n                <div class=\"col\">\n                    <select id=\"field-status\" name=\"status\" class=\"form-control\">\n                        <optgroup label=\"契約関係\">\n                            <option value=\"pending\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.pending.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":96}}}))
    + "</option>\n                            <option value=\"price_confirmed\">\n                                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.price_confirmed.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":84}}}))
    + "\n                            </option>\n                            <option value=\"preparing\">\n                                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.preparing.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":16,"column":78}}}))
    + "\n                            </option>\n                            <option value=\"active\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.active.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":51},"end":{"line":18,"column":94}}}))
    + "</option>\n                        </optgroup>\n                        <optgroup label=\"取消関係\">\n                            <option value=\"withdraw_requested\">\n                                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.withdraw_requested.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":22,"column":87}}}))
    + "\n                            </option>\n                            <option value=\"withdrawn\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.withdrawn.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":54},"end":{"line":24,"column":100}}}))
    + "</option>\n                        </optgroup>\n                        <optgroup label=\"解約関係\">\n                            <option value=\"cancel_applied\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.cancel_applied.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":59},"end":{"line":27,"column":110}}}))
    + "</option>\n                            <option value=\"billing_stopped\">\n                                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.billing_stopped.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":29,"column":84}}}))
    + "\n                            </option>\n                            <option value=\"cancelled\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.status.cancelled.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":54},"end":{"line":31,"column":100}}}))
    + "</option>\n                        </optgroup>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <p>\n        <span class=\"row\">\n            <span class=\"col-2 text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":43},"end":{"line":41,"column":65}}}))
    + " </span>\n            <span class=\"col-4\" data-slot=\"contractId\"></span>\n\n            <span class=\"col-2 text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"shop.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":43},"end":{"line":44,"column":60}}}))
    + " </span>\n            <span class=\"col-4\" data-slot=\"shopName\"></span>\n        </span>\n    </p>\n\n\n    <div id=\"divProduct\"></div>\n\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-start_date\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.start_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":54}}}))
    + "\n                </label>\n                <div class=\"col-6\">\n                    <input id=\"field-start_date\" name=\"start_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-payment_due_date\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.payment_due_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":20},"end":{"line":67,"column":53}}}))
    + "\n                </label>\n                <div class=\"col-6\">\n                    <input id=\"field-payment_due_date\" name=\"payment_due_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-end_date\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.end_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":20},"end":{"line":80,"column":52}}}))
    + "\n                </label>\n                <div class=\"col-6\">\n                    <input id=\"field-end_date\" name=\"end_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-cancelled_date\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.cancelled_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":20},"end":{"line":91,"column":58}}}))
    + "\n                </label>\n                <div class=\"col-6\">\n                    <input id=\"field-cancelled_date\" name=\"cancelled_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <div class=\"form-group row\">\n                <label for=\"field-withdrawn_date\" class=\"col-form-label col-4 text-right\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.withdrawn_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":20},"end":{"line":104,"column":58}}}))
    + "\n                </label>\n                <div class=\"col-6\">\n                    <input id=\"field-withdrawn_date\" name=\"withdrawn_date\" type=\"date\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row align-items-end mb-3\">\n        <div class=\"col-lg-auto\">\n            <h3>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"contract.rate.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":115,"column":16},"end":{"line":115,"column":43}}}))
    + "</h3>\n        </div>\n        <div class=\"col-lg-auto\">\n            <button type=\"button\" class=\"btn btn-secondary\" data-action=\"autofill\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.fill",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":83},"end":{"line":118,"column":103}}}))
    + "</button>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-price\" class=\"col-form-label col-4 text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.price",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":125,"column":81},"end":{"line":125,"column":110}}}))
    + "</label>\n                <div class=\"col-4\">\n                    <div class=\"input-group\">\n                        <div class=\"input-group-prepend\">\n                            <span class=\"input-group-text\">¥</span>\n                        </div>\n                        <input id=\"field-price\" name=\"price\" type=\"text\" class=\"form-control text-right\" data-number\n                            data-rate=\"flat\">\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col\">\n            <div class=\"form-group row\">\n                <label for=\"field-free_days\" class=\"col-form-label col-4 text-right\">Free days</label>\n                <div class=\"col-4\">\n                    <input id=\"field-free_days\" name=\"free_days\" type=\"text\" class=\"form-control\" data-number>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label for=\"field-prorated_month\" class=\"col-form-label col-2 text-right\">\n            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"productContract.first_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":150,"column":12},"end":{"line":150,"column":47}}}))
    + "\n        </label>\n        <div class=\"col-2\">\n            <div class=\"input-group\">\n                <div class=\"input-group-prepend\">\n                    <span class=\"input-group-text\">¥</span>\n                </div>\n                <input id=\"field-prorated_month\" name=\"prorated_month\" type=\"text\" class=\"form-control text-right\"\n                    data-number>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row justify-content-end align-items-center\">\n        <div class=\"col-auto\">\n            <button type=\"button\" class=\"btn btn-secondary btn-block\" data-action=\"cancel\">\n                "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":166,"column":16},"end":{"line":166,"column":38}}}))
    + "\n            </button>\n        </div>\n        <div class=\"col-auto\">\n            <button type=\"submit\" class=\"btn btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"generic.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":170,"column":68},"end":{"line":170,"column":88}}}))
    + "</button>\n        </div>\n    </div>\n</form>";
},"useData":true});