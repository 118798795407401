import { View } from 'backbone';
import config from '@/js/app/config';
import CustomersCollection from '@/js/app/customer/collections/customers';
import SpinnerView from '@/js/app/generic/views/spinner';
import oauth2Client from '@/js/app/oauth2-client';
import ShopsCollection from '@/js/app/shop/collections/shops';
import editTemplate from '../templates/zendesk-bulk-notification/edit.hbs';
import template from '../templates/zendesk-bulk-notification/index.hbs';
import previewTemplate from '../templates/zendesk-bulk-notification/preview.hbs';

export default class CsZendeskBulkNotificationView extends View {
    preinitialize() {
        this.className = 'container';
        this.collection = new CustomersCollection();
        this.shopsCollection = new ShopsCollection();

        this.customerList = null;
        this.invalidCustomerList = null;
        this.ticketSubject = null;
        this.ticketStatus = null;
        this.ticketMessage = null;
        this.isPublicTicket = false;

        this.events = {
            'submit form#frmEdit': this.handlePreviewClick,
            'click #btnBack': this.handleBack,
            'click #btnSubmit': this.handleSubmit,
            'change #ticketType': this.handleTicketTypeChange
        };
    }

    initialize() {
        this.spinner = new SpinnerView();

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            this.spinner.spin(this.el);
        });

        // When collection sync / error request; stop spinner
        this.listenTo(this.collection, 'sync error', function () {
            this.spinner.stop();
        });

        const zendeskFormData = sessionStorage.getItem('zendeskBulk');

        if (zendeskFormData) {
            const { customerList, invalidCustomerList, ticketSubject, ticketStatus, ticketMessage, isPublicTicket } = JSON.parse(sessionStorage.getItem('zendeskBulk'));

            this.customerList = customerList;
            this.invalidCustomerList = invalidCustomerList;
            this.ticketSubject = ticketSubject;
            this.ticketStatus = ticketStatus;
            this.ticketMessage = ticketMessage;
            this.isPublicTicket = isPublicTicket;
        }
    }

    render() {
        console.debug('PmZendeskBulk#render');

        // Attach template to el
        this.el.innerHTML = template({
            invalidCustomerList: this.invalidCustomerList
        });

        this.contentEl = this.el.querySelector('#bulk-content');
        this.renderEdit();

        return this;
    }

    async handlePreviewClick(e) {
        console.debug('CsZendeskBulkEdit#handlePreviewClick');

        e.preventDefault();

        const customerIdArray = Array.from(this.$el.find('#customers').val().trim().split('\n'));

        const customerListData = await this.collection.fetch({
            data: {
                id: customerIdArray,
                fields: 'id,first_name,last_name,company_name,customer_type,shop_id'
            },
            processData: true
        })

        // Set array of unique shop IDs
        const shopIdArray = new Set();

        customerListData.results.map((customer) => {
            shopIdArray.add(customer.shop_id)
        })

        const shopData = await this.shopsCollection.fetch({
            data: { id: [...shopIdArray] }
        })

        // Add shop name to customer object
        customerListData.results.map((customer) => {
            const customerShop = shopData.filter(shop => shop.id === customer.shop_id)
            customer.shop_name = customerShop[0].name;
        })

        this.customerList = customerListData.results;
        this.ticketSubject = this.$el.find('#subject').val();
        this.ticketStatus = this.$el.find('#status').val();
        this.ticketMessage = this.$el.find('#message').val();

        // Extract invalid customer IDs
        const invalidCustomerIdArray = customerIdArray.filter((id) => !this.customerList.some((customer) => parseInt(id) === customer.id))

        this.invalidCustomerList = invalidCustomerIdArray;

        const data = {
            customerList: this.customerList,
            invalidCustomerList: this.invalidCustomerList,
            ticketSubject: this.ticketSubject,
            ticketStatus: this.ticketStatus,
            ticketMessage: this.ticketMessage,
            isPublicTicket: this.isPublicTicket
        }

        sessionStorage.setItem('zendeskBulk', JSON.stringify(data));

        this.renderPreview();
    }

    handleBack() {
        console.debug('CsZendeskBulkEdit#handleBack');

        this.renderEdit();
    }

    handleSubmit(e) {
        console.debug('CsZendeskBulkEdit#handleSubmit');

        let confirmationAlert = '';

        if (this.isPublicTicket) {
            confirmationAlert = 'This ticket is marked as public and will be sent to all selected users. \n\nAre you sure you want to submit to Zendesk?';
        } else {
            confirmationAlert = 'Are you sure you want to submit this ticket to Zendesk?';
        }

        if (window.confirm(confirmationAlert)) {
            const customerIdArray = this.customerList.map(customer => customer.id)

            oauth2Client.fetchJSON(`${config.api.url}tools/zendesk/bulk-notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    customer_id: customerIdArray,
                    subject: this.ticketSubject,
                    status: this.ticketStatus,
                    message: this.ticketMessage,
                    is_public_ticket: this.isPublicTicket
                })
            }).then(() => {
                // Clear saved data
                sessionStorage.removeItem('zendeskBulk');
                this.customerList = null;
                this.invalidCustomerList = null;
                this.ticketSubject = null;
                this.ticketStatus = null;
                this.ticketMessage = null;
                this.isPublicTicket = false;

                // Redirect to edit template with success message
                this.renderEdit({
                    isRedirect: true
                });
            }).catch(() => {
                // Hide button spinner
                e.currentTarget.querySelector('.spinner-border').classList.add('d-none');

                // Enable button
                e.currentTarget.disabled = false;
            });
        }
    }

    handleTicketTypeChange(e) {
        console.debug('CsZendeskBulkEdit#handleTicketTypeChange');

        const ticketType = e.target.value;
        const publicForm = this.el.querySelector('#frmEdit');
        const publicWarningEl = this.el.querySelector('#publicWarning');

        this.isPublicTicket = ticketType === 'public' ? true : false;

        if (this.isPublicTicket) {
            publicForm.classList.add('form-public');
            publicWarningEl.classList.remove('d-none');
        } else {
            publicForm.classList.remove('form-public');
            publicWarningEl.classList.add('d-none');
        }
    }

    renderPreview() {
        console.debug('CsZendeskBulkEdit#renderPreview');

        this.contentEl.innerHTML = previewTemplate({
            customerList: this.customerList,
            invalidCustomerList: this.invalidCustomerList,
            ticketSubject: this.ticketSubject,
            ticketStatus: this.ticketStatus,
            ticketMessage: this.ticketMessage,
            isPublicTicket: this.isPublicTicket
        });
    }

    renderEdit(isRedirect) {
        console.debug('CsZendeskBulkEdit#renderEdit');

        this.contentEl.innerHTML = editTemplate({
            customerList: this.customerList,
            invalidCustomerList: this.invalidCustomerList,
            ticketSubject: this.ticketSubject,
            ticketStatus: this.ticketStatus,
            ticketMessage: this.ticketMessage,
            isPublicTicket: this.isPublicTicket,
            isRedirect
        });
    }
}
