import { Collection } from 'backbone';
import config from '@/js/app/config';
import PrefectureModel from '../models/prefecture';

export default class PrefecturesCollection extends Collection {
    preinitialize() {
        this.url = config.api.url + 'prefectures';

        // Reference to this collection's model.
        this.model = PrefectureModel;
    }
}
