import { View } from 'backbone';

export default class OwnerIndexView extends View {
    preinitialize() {
        this.tagName = 'select';
        this.className = 'form-control';
        this.id = 'field-owner_id';
    }

    initialize(options) {
        // Bind this on render
        _.bindAll(this, 'render');

        this.firstOptionText = options.firstOptionText || '';
        this.hasAll = options.hasAll || false;

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('OwnerIndex#render');

        // If collection contains models
        if (this.collection.length > 0) {
            // Initialize options as document fragment
            const options = document.createDocumentFragment();

            if (this.hasAll) {
                const all = document.createElement('option');

                // Set value and innerText of all
                all.value = 'all';
                all.innerText = 'ALL';

                // Append all to options
                options.appendChild(all);
            } else {
                const firstOption = document.createElement('option');
                firstOption.innerText = this.firstOptionText;

                // Append option element to options
                options.appendChild(firstOption);
            }

            // For each model in collection
            this.collection.each((v) => {
                // Initialize o as option element
                const o = document.createElement('option');

                // Set value and innerText of o
                o.value = v.get('id');
                o.innerText = v.get('name');

                // Append o to options
                options.appendChild(o);
            });

            // Clear options of el
            while (this.el.firstChild) {
                this.el.removeChild(this.el.firstChild);
            }

            // Attach options to el
            this.el.appendChild(options);
        }


        // If value exists, setValue
        if (this.value) {
            this.setValue();
        }

        return this;
    }

    setValue(value) {
        console.debug('OwnerIndex#setValue');

        if (value !== undefined) {
            this.value = value;
        }

        // Set value of select element and trigger change event
        this.el.value = this.value;
        this.$el.trigger('change');
    }
}
